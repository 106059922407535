<template>
    <div>
        <div
            v-if="editorStore.getEditor"
            class="editor__header z-index-1 d-flex border-bottom w-100"
        >
            <div class="d-flex justify-content-between w-100">
                <div class="d-flex editor-btn-div">
                    <template v-for="(item, index) in items">
                        <div
                            class="divide-x border-end"
                            v-if="item.type === 'divider'"
                            :key="`divider${index}`"
                        />
                        <menu-item
                            v-else
                            :key="index"
                            v-bind="item"
                            :item="item"
                            :dropdownComponent="getDropdownComponent(item)"
                            :editor="editorStore.getEditor"
                            :currentMenu="currentMenu"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import {useEditorStore} from '@/Stores/EditorStore';
import MenuItem from './MenuItem.vue';

export default defineComponent({
    components: {
        MenuItem,
    },

    setup() {
        // Get editor store
        const editorStore = useEditorStore();

        return { editorStore };
    },

    data() {
        return {
            isActive: false,
            currentMenu: {},
            items: [
                {
                    name: 'bold',
                    icon: 'bold',
                    title: this.__('Bold'),
                    action: () =>
                        this.editorStore.getEditor.chain().focus().toggleBold().run(),
                    isActive: () => this.editorStore.getEditor.isActive('bold'),
                },
                {
                    name: 'italic',
                    icon: 'italic',
                    title: this.__('Italic'),
                    action: () =>
                        this.editorStore.getEditor.chain().focus().toggleItalic().run(),
                    isActive: () => this.editorStore.getEditor.isActive('italic'),
                },
                {
                    name: 'strikethrough',
                    icon: 'strikethrough',
                    title: this.__('Strike'),
                    action: () =>
                        this.editorStore.getEditor.chain().focus().toggleStrike().run(),
                    isActive: () => this.editorStore.getEditor.isActive('strike'),
                },
                /*        {
          name: 'code',
          icon: 'code-view',
          title: this.__('Code'),
          action: () =>
            this.editorStore.getEditor
              .chain()
              .focus()
              .toggleCode()
              .run(),
          isActive: () => this.editorStore.getEditor.isActive('code'),
        },*/
                {
                    name: 'mark',
                    icon: 'mark-pen-line',
                    title: this.__('Highlight'),
                    action: () =>
                        this.editorStore.getEditor.chain().focus().toggleHighlight().run(),
                    isActive: () => this.editorStore.getEditor.isActive('highlight'),
                },
                {
                    type: 'divider',
                },
                {
                    name: 'heading-1',
                    icon: 'h-1',
                    title: this.__('Heading 1'),
                    action: () =>
                        this.editorStore.getEditor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 1 })
                            .run(),
                    isActive: () =>
                        this.editorStore.getEditor.isActive('heading', { level: 1 }),
                },
                {
                    name: 'heading-2',
                    icon: 'h-2',
                    title: this.__('Heading 2'),
                    action: () =>
                        this.editorStore.getEditor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 2 })
                            .run(),
                    isActive: () =>
                        this.editorStore.getEditor.isActive('heading', { level: 2 }),
                },
                {
                    name: 'heading-3',
                    icon: 'h-3',
                    title: this.__('Heading 3'),
                    action: () =>
                        this.editorStore.getEditor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 3 })
                            .run(),
                    isActive: () =>
                        this.editorStore.getEditor.isActive('heading', { level: 3 }),
                },
                {
                    name: 'paragraph',
                    icon: 'paragraph',
                    title: this.__('Paragraph'),
                    action: () =>
                        this.editorStore.getEditor.chain().focus().setParagraph().run(),
                    isActive: () => this.editorStore.getEditor.isActive('paragraph'),
                },
                {
                    name: 'list-unordered',
                    icon: 'list-unordered',
                    title: this.__('Bullet List'),
                    action: () =>
                        this.editorStore.getEditor.chain().focus().toggleBulletList().run(),
                    isActive: () => this.editorStore.getEditor.isActive('bulletList'),
                },
                {
                    name: 'list-ordered',
                    icon: 'list-ordered',
                    title: this.__('Ordered List'),
                    action: () =>
                        this.editorStore.getEditor.chain().focus().toggleOrderedList().run(),
                    isActive: () => this.editorStore.getEditor.isActive('orderedList'),
                },
                /*{
          name: 'task-list',
          icon: 'list-check-2',
          title: this.__('Task List'),
          action: () =>
            this.editorStore.getEditor
              .chain()
              .focus()
              .toggleTaskList()
              .run(),
          isActive: () => this.editorStore.getEditor.isActive('taskList'),
        },
        {
          name: 'code-block',
          icon: 'code-box-line',
          title: this.__('Code Block'),
          action: () =>
            this.editorStore.getEditor
              .chain()
              .focus()
              .toggleCodeBlock()
              .run(),
          isActive: () => this.editorStore.getEditor.isActive('codeBlock'),
        },*/
                {
                    type: 'divider',
                },
                {
                    name: 'blockquote',
                    icon: 'double-quotes-l',
                    title: this.__('Blockquote'),
                    action: () =>
                        this.editorStore.getEditor.chain().focus().toggleBlockquote().run(),
                    isActive: () => this.editorStore.getEditor.isActive('blockquote'),
                },
                {
                    name: 'seperator',
                    icon: 'separator',
                    title: this.__('Horizontal Rule'),
                    action: () =>
                        this.editorStore.getEditor.chain().focus().setHorizontalRule().run(),
                },
                {
                    type: 'divider',
                },
                {
                    name: 'text-wrap',
                    icon: 'text-wrap',
                    title: this.__('Hard Break'),
                    action: () =>
                        this.editorStore.getEditor.chain().focus().setHardBreak().run(),
                },
                {
                    name: 'format-clear',
                    icon: 'format-clear',
                    title: this.__('Clear Format'),
                    action: () =>
                        this.editorStore.getEditor
                            .chain()
                            .focus()
                            .clearNodes()
                            .unsetAllMarks()
                            .run(),
                },
                {
                    type: 'divider',
                },
                {
                    name: 'link',
                    icon: 'link',
                    title: this.__('Link'),
                    action: () => {
                        // Getting the current editor instance
                        const editor = this.editorStore.getEditor.view;

                        // Retrieve link attributes if the selection is within a link
                        const linkAttributes = editor.getLinkAttributes ? editor.getLinkAttributes() : {};

                        // Check if we got any attributes (indicating the selection is within a link)
                        if (Object.keys(linkAttributes).length) {
                            // If we have attributes, prepare to edit the existing link
                            this.editorStore.setCurrentLink(linkAttributes);
                        } else {
                            // No link attributes found, prepare to insert a new link. This might involve clearing previous attributes.
                            this.editorStore.setCurrentLink({ href: '', target: '' });
                        }

                        // Finally, open the link modal
                        this.editorStore.setLinkModal(true);
                    },
                    isActive: () => this.editorStore.getEditor.isActive('link'),
                },
                {
                    name: 'unlink',
                    icon: 'link-unlink',
                    title: this.__('Unlink'),
                    action: () => {
                        this.editorStore.getEditor.chain().focus().unsetLink().run();
                    },
                    isActive: () => this.editorStore.getEditor.isActive('link'),
                },
                {
                    type: 'divider',
                },
                /*
                {
                    icon: 'image-2-fill',
                    title: this.__('Upload Image'),
                    action: () =>{
                        emitter.emit('document-image-modal-opened');
                    }
                },
                {
                    type: 'divider',
                },*/
                {
                    name: 'table',
                    icon: 'table-2',
                    title: this.__('Insert Table'),

                    action: () => {},
                },
                {
                    type: 'divider',
                },
                {
                    name: 'undo',
                    icon: 'arrow-go-back-line',
                    title: this.__('Undo'),
                    action: () => this.editorStore.getEditor.chain().focus().undo().run(),
                },
                {
                    name: 'redo',
                    icon: 'arrow-go-forward-line',
                    title: this.__('Redo'),
                    action: () => this.editorStore.getEditor.chain().focus().redo().run(),
                },
                {
                    type: 'divider',
                },
            ],
        };
    },

    methods: {
        getDropdownComponent(item) {
            if (item.name === 'table') {
                return 'TableDropdown';
            }

            return null;
        },
    },
});
</script>

<style lang="scss">
.editor__header {
    background-color: #fafcfc !important;
}

@media (min-width: 320px) and (max-width: 767px) {
    .editor-btn-div {
        flex-wrap: wrap;
    }
}
</style>
