import Swal from 'sweetalert2';

export default {
    methods: {
        showTrialLimitExhaustedPopUp() {
            Swal.fire({
                text: this.__(
                    'You have reached your credits limit. You cannot perform this action.'
                ),
                icon: 'warning',
                buttonsStyling: false,
                confirmButtonText: this.__('Activate Subscription'),
                showCancelButton: true,
                cancelButtonText: this.__('No'),
                customClass: {
                    confirmButton: 'btn btn-success me-5',
                    cancelButton: 'btn btn-danger',
                    container: 'z-large',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    // eslint-disable-next-line no-undef
                    this.$inertia.visit(route('billing'));
                }
            });
        },
    },
};
