<template>
    <div
        :id="'seo_check_accordion_collapse_' + id"
        class="accordion-collapse collapse"
        :aria-labelledby="'seo_check_accordion_header_' + id"
        data-bs-parent="#seo_check_accordion"
    >
        <div class="accordion-body bg-white">
            <ul class="list-unstyled">
                <li v-for="check in checks" class="d-flex my-2">
                        <span
                            v-if="check.type === 'good'"
                            class="svg-icon svg-icon-muted svg-icon-3 text-success me-2"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 448 512"
                                fill="currentColor"
                            >
                                <path
                                    d="M337 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-143 143L97 127c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l80 80c9.4 9.4 24.6 9.4 33.9 0L337 81zM441 201c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-247 247L41 295c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L143 465c9.4 9.4 24.6 9.4 33.9 0L441 201z"
                                />
                            </svg>
                        </span>
                    <span
                        v-else-if="check.type === 'minor'"
                        class="svg-icon svg-icon-muted svg-icon-3 text-warning me-2"
                    >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 448 512"
                                fill="currentColor"
                            >
                                <path
                                    d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"
                                />
                            </svg>
                        </span>
                    <span
                        v-else
                        class="svg-icon svg-icon-muted svg-icon-3 text-danger me-2"
                    >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 512 512"
                                fill="currentColor"
                            >
                                <path
                                    d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                />
                            </svg>
                        </span>
                    <span
                        class="text-gray-800"
                        v-html="check.label"
                    ></span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        checks: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.svg-icon {
    display: inline-block;
}
</style>
