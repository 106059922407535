<template>
    <div class="d-flex ms-6 mt-2 mb-6">
        <button class="btn btn-sm btn-light-primary" @click.prevent="addAction">
            {{ __('Add Writing Point') }}
        </button>
        <button
            class="btn btn-sm btn-light-primary ms-5"
            :class="{ 'disabled': isGenerating }"
            @click.prevent="generateAction"
        >
            {{ generateButtonText }}
        </button>
    </div>
</template>

<script>
export default {
    props: {
        isGenerating: {
            type: Boolean,
            default: false,
        },
        generatingH2Index: {
            type: Number,
            default: null,
        },
        h2Index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        generateButtonText() {
            return this.isGenerating && this.generatingH2Index === this.h2Index
                ? this.__('Generating...')
                : this.__('Regenerate Writing Points');
        },
    },
    emits: ['add', 'generate'],
    methods: {
        addAction() {
            this.$emit('add');
        },
        generateAction() {
            this.$emit('generate', this.h2Index);
        },
    },
};
</script>

<style scoped>
.disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>
