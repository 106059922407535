<template>
    <div class="aside-menu flex-column-fluid">
        <!--begin::Aside Menu-->
        <div
            class="my-5"
            style="height: 87vh"
            id="kt_aside_menu_wrapper"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside_menu"
            data-kt-scroll-offset="0"
        >
            <!--begin::Menu-->
            <div
                class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                id="#kt_aside_menu"
                data-kt-menu="true"
                data-kt-menu-expand="false"
            >
                <!-- <div class="menu-item">
                    <div class="menu-content pt-8 pb-2">
                        <span
                            class="menu-section text-muted text-uppercase fs-8 ls-1"
                            >{{ __('Switch team or project') }}</span
                        >
                    </div>
                </div> -->

               <TeamSelection></TeamSelection>

                <div
                    data-kt-menu-trigger="click"
                    class="menu-item menu-accordion pt-4"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                            <span class="svg-icon svg-icon-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <rect
                                        x="14"
                                        y="4.00488"
                                        width="7"
                                        height="7"
                                        rx="2.5"
                                        fill="#45577B"
                                        stroke="#45577B"
                                        stroke-width="1.5"
                                    />
                                    <mask
                                        id="path-2-inside-1_367_2084"
                                        fill="white"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16.5 13.2549C14.7051 13.2549 13.25 14.71 13.25 16.5049V18.5049C13.25 20.2998 14.7051 21.7549 16.5 21.7549H18.5C20.2949 21.7549 21.75 20.2998 21.75 18.5049V16.5049C21.75 14.71 20.2949 13.2549 18.5 13.2549H16.5Z"
                                        />
                                    </mask>
                                    <g filter="url(#filter0_i_367_2084)">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16.5 13.2549C14.7051 13.2549 13.25 14.71 13.25 16.5049V18.5049C13.25 20.2998 14.7051 21.7549 16.5 21.7549H18.5C20.2949 21.7549 21.75 20.2998 21.75 18.5049V16.5049C21.75 14.71 20.2949 13.2549 18.5 13.2549H16.5Z"
                                            fill="#CAD5FB"
                                        />
                                    </g>
                                    <path
                                        d="M13.5 16.5049C13.5 14.848 14.8431 13.5049 16.5 13.5049V13.0049C14.567 13.0049 13 14.5719 13 16.5049H13.5ZM13.5 18.5049V16.5049H13V18.5049H13.5ZM16.5 21.5049C14.8431 21.5049 13.5 20.1617 13.5 18.5049H13C13 20.4379 14.567 22.0049 16.5 22.0049V21.5049ZM18.5 21.5049H16.5V22.0049H18.5V21.5049ZM21.5 18.5049C21.5 20.1617 20.1569 21.5049 18.5 21.5049V22.0049C20.433 22.0049 22 20.4379 22 18.5049H21.5ZM21.5 16.5049V18.5049H22V16.5049H21.5ZM18.5 13.5049C20.1569 13.5049 21.5 14.848 21.5 16.5049H22C22 14.5719 20.433 13.0049 18.5 13.0049V13.5049ZM16.5 13.5049H18.5V13.0049H16.5V13.5049Z"
                                        fill="url(#paint0_linear_367_2084)"
                                        fill-opacity="0.7"
                                        mask="url(#path-2-inside-1_367_2084)"
                                    />
                                    <mask
                                        id="path-4-inside-2_367_2084"
                                        fill="white"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M6.50024 13.2549C4.70532 13.2549 3.25024 14.71 3.25024 16.5049V18.5049C3.25024 20.2998 4.70532 21.7549 6.50024 21.7549H8.50024C10.2952 21.7549 11.7502 20.2998 11.7502 18.5049V16.5049C11.7502 14.71 10.2952 13.2549 8.50024 13.2549H6.50024Z"
                                        />
                                    </mask>
                                    <g filter="url(#filter1_i_367_2084)">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M6.50024 13.2549C4.70532 13.2549 3.25024 14.71 3.25024 16.5049V18.5049C3.25024 20.2998 4.70532 21.7549 6.50024 21.7549H8.50024C10.2952 21.7549 11.7502 20.2998 11.7502 18.5049V16.5049C11.7502 14.71 10.2952 13.2549 8.50024 13.2549H6.50024Z"
                                            fill="#CAD5FB"
                                        />
                                    </g>
                                    <path
                                        d="M3.50024 16.5049C3.50024 14.848 4.84339 13.5049 6.50024 13.5049V13.0049C4.56725 13.0049 3.00024 14.5719 3.00024 16.5049H3.50024ZM3.50024 18.5049V16.5049H3.00024V18.5049H3.50024ZM6.50024 21.5049C4.84339 21.5049 3.50024 20.1617 3.50024 18.5049H3.00024C3.00024 20.4379 4.56725 22.0049 6.50024 22.0049V21.5049ZM8.50024 21.5049H6.50024V22.0049H8.50024V21.5049ZM11.5002 18.5049C11.5002 20.1617 10.1571 21.5049 8.50024 21.5049V22.0049C10.4332 22.0049 12.0002 20.4379 12.0002 18.5049H11.5002ZM11.5002 16.5049V18.5049H12.0002V16.5049H11.5002ZM8.50024 13.5049C10.1571 13.5049 11.5002 14.848 11.5002 16.5049H12.0002C12.0002 14.5719 10.4332 13.0049 8.50024 13.0049V13.5049ZM6.50024 13.5049H8.50024V13.0049H6.50024V13.5049Z"
                                        fill="url(#paint1_linear_367_2084)"
                                        fill-opacity="0.7"
                                        mask="url(#path-4-inside-2_367_2084)"
                                    />
                                    <mask
                                        id="path-6-inside-3_367_2084"
                                        fill="white"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M6.50024 3.25488C4.70532 3.25488 3.25024 4.70996 3.25024 6.50488V8.50488C3.25024 10.2998 4.70532 11.7549 6.50024 11.7549H8.50024C10.2952 11.7549 11.7502 10.2998 11.7502 8.50488V6.50488C11.7502 4.70996 10.2952 3.25488 8.50024 3.25488H6.50024Z"
                                        />
                                    </mask>
                                    <g filter="url(#filter2_i_367_2084)">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M6.50024 3.25488C4.70532 3.25488 3.25024 4.70996 3.25024 6.50488V8.50488C3.25024 10.2998 4.70532 11.7549 6.50024 11.7549H8.50024C10.2952 11.7549 11.7502 10.2998 11.7502 8.50488V6.50488C11.7502 4.70996 10.2952 3.25488 8.50024 3.25488H6.50024Z"
                                            fill="#CAD5FB"
                                        />
                                    </g>
                                    <path
                                        d="M3.50024 6.50488C3.50024 4.84803 4.84339 3.50488 6.50024 3.50488V3.00488C4.56725 3.00488 3.00024 4.57189 3.00024 6.50488H3.50024ZM3.50024 8.50488V6.50488H3.00024V8.50488H3.50024ZM6.50024 11.5049C4.84339 11.5049 3.50024 10.1617 3.50024 8.50488H3.00024C3.00024 10.4379 4.56725 12.0049 6.50024 12.0049V11.5049ZM8.50024 11.5049H6.50024V12.0049H8.50024V11.5049ZM11.5002 8.50488C11.5002 10.1617 10.1571 11.5049 8.50024 11.5049V12.0049C10.4332 12.0049 12.0002 10.4379 12.0002 8.50488H11.5002ZM11.5002 6.50488V8.50488H12.0002V6.50488H11.5002ZM8.50024 3.50488C10.1571 3.50488 11.5002 4.84803 11.5002 6.50488H12.0002C12.0002 4.57189 10.4332 3.00488 8.50024 3.00488V3.50488ZM6.50024 3.50488H8.50024V3.00488H6.50024V3.50488Z"
                                        fill="url(#paint2_linear_367_2084)"
                                        fill-opacity="0.7"
                                        mask="url(#path-6-inside-3_367_2084)"
                                    />
                                    <defs>
                                        <filter
                                            id="filter0_i_367_2084"
                                            x="13.25"
                                            y="13.2549"
                                            width="8.5"
                                            height="8.5"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="BackgroundImageFix"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur
                                                stdDeviation="1.5"
                                            />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect1_innerShadow_367_2084"
                                            />
                                        </filter>
                                        <filter
                                            id="filter1_i_367_2084"
                                            x="3.25024"
                                            y="13.2549"
                                            width="8.5"
                                            height="8.5"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="BackgroundImageFix"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur
                                                stdDeviation="1.5"
                                            />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect1_innerShadow_367_2084"
                                            />
                                        </filter>
                                        <filter
                                            id="filter2_i_367_2084"
                                            x="3.25024"
                                            y="3.25488"
                                            width="8.5"
                                            height="8.5"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="BackgroundImageFix"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur
                                                stdDeviation="1.5"
                                            />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect1_innerShadow_367_2084"
                                            />
                                        </filter>
                                        <linearGradient
                                            id="paint0_linear_367_2084"
                                            x1="13.4625"
                                            y1="14.5299"
                                            x2="20.9"
                                            y2="20.9049"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stop-color="white"
                                                stop-opacity="0.15"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="white"
                                                stop-opacity="0.44"
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint1_linear_367_2084"
                                            x1="3.46274"
                                            y1="14.5299"
                                            x2="10.9002"
                                            y2="20.9049"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stop-color="white"
                                                stop-opacity="0.15"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="white"
                                                stop-opacity="0.44"
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint2_linear_367_2084"
                                            x1="3.46274"
                                            y1="4.52988"
                                            x2="10.9002"
                                            y2="10.9049"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stop-color="white"
                                                stop-opacity="0.15"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="white"
                                                stop-opacity="0.44"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title">
                            <Link :href="route('dashboard')" class="d-inline cursor-pointer">
                                {{ __('Dashboard') }}
                            </Link>
                        </span>
                    </span>
                </div>
                <div
                    class="menu-item show menu-accordion mt-3"
                    @click="toggle($event)"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: assets/media/icons/duotune/general/gen055.svg-->
                            <span class="svg-icon svg-icon-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                >
                                    <g filter="url(#filter0_d_367_2089)">
                                        <path
                                            d="M3.39404 11.7532L6.57602 8.5712C6.79859 8.34863 7.11296 8.24384 7.42455 8.28835L10.2633 8.5578C10.3321 8.56433 10.3737 8.63702 10.3445 8.69964L8.23168 13.2271C8.05096 13.6144 7.64415 13.844 7.21923 13.7986L3.95973 13.4502C3.14396 13.3337 2.81135 12.3359 3.39404 11.7532Z"
                                            fill="#45577B"
                                        />
                                    </g>
                                    <g filter="url(#filter1_d_367_2089)">
                                        <path
                                            d="M13.2933 21.6529L16.4752 18.4709C16.6978 18.2483 16.8026 17.934 16.7581 17.6224L16.4886 14.7836C16.4821 14.7148 16.4094 14.6732 16.3468 14.7024L11.8193 16.8153C11.4321 16.996 11.2025 17.4028 11.2479 17.8277L11.5962 21.0872C11.7127 21.903 12.7106 22.2356 13.2933 21.6529Z"
                                            fill="#45577B"
                                        />
                                    </g>
                                    <g filter="url(#filter2_i_367_2089)">
                                        <path
                                            d="M7.88109 14.8264L10.2199 17.1652C10.7954 17.7407 11.66 17.9102 12.3908 17.5519C14.1233 16.7026 17.3084 14.965 19.3036 12.8138C21.5275 10.416 22.3547 6.61843 22.6609 4.27726C22.8081 3.15184 21.8945 2.23821 20.7691 2.3854C18.4279 2.6916 14.6304 3.51881 12.2325 5.74277C10.0813 7.73792 8.34374 10.9231 7.4944 12.6555C7.13614 13.3863 7.30561 14.2509 7.88109 14.8264Z"
                                            fill="#CAD5FB"
                                        />
                                    </g>
                                    <path
                                        d="M10.3083 17.0769L7.96948 14.738C7.42938 14.1979 7.27337 13.3904 7.60664 12.7106C8.45534 10.9794 10.1846 7.81265 12.3175 5.83442C14.6846 3.63905 18.4471 2.81515 20.7853 2.50935C21.8293 2.3728 22.6735 3.21704 22.537 4.26105C22.2312 6.59925 21.4073 10.3618 19.2119 12.7288C17.2337 14.8618 14.0669 16.591 12.3358 17.4397C11.656 17.773 10.8484 17.617 10.3083 17.0769Z"
                                        stroke="url(#paint0_linear_367_2089)"
                                        stroke-opacity="0.7"
                                        stroke-width="0.25"
                                    />
                                    <g filter="url(#filter3_d_367_2089)">
                                        <path
                                            d="M14.7074 7.51065C13.9263 8.2917 13.9263 9.55803 14.7074 10.3391C15.4884 11.1201 16.7547 11.1201 17.5358 10.3391C18.3168 9.55803 18.3168 8.2917 17.5358 7.51065C16.7547 6.7296 15.4884 6.7296 14.7074 7.51065Z"
                                            fill="#45577B"
                                        />
                                    </g>
                                    <g filter="url(#filter4_d_367_2089)">
                                        <path
                                            d="M6.92918 18.1169L4.10075 20.9454M4.80786 15.9956L3.39365 17.4098M9.0505 20.2382L7.63629 21.6525"
                                            stroke="#45577B"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            shape-rendering="crispEdges"
                                        />
                                    </g>
                                    <defs>
                                        <filter
                                            id="filter0_d_367_2089"
                                            x="1.1001"
                                            y="6.27832"
                                            width="11.2539"
                                            height="9.526"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.35 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_367_2089"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_367_2089"
                                                result="shape"
                                            />
                                        </filter>
                                        <filter
                                            id="filter1_d_367_2089"
                                            x="9.24219"
                                            y="12.693"
                                            width="9.52588"
                                            height="11.2539"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.35 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_367_2089"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_367_2089"
                                                result="shape"
                                            />
                                        </filter>
                                        <filter
                                            id="filter2_i_367_2089"
                                            x="7.30688"
                                            y="2.36975"
                                            width="15.3696"
                                            height="15.3696"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="BackgroundImageFix"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur
                                                stdDeviation="1.5"
                                            />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect1_innerShadow_367_2089"
                                            />
                                        </filter>
                                        <filter
                                            id="filter3_d_367_2089"
                                            x="12.1216"
                                            y="4.9248"
                                            width="8"
                                            height="8"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.35 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_367_2089"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_367_2089"
                                                result="shape"
                                            />
                                        </filter>
                                        <filter
                                            id="filter4_d_367_2089"
                                            x="0.643555"
                                            y="13.2456"
                                            width="11.157"
                                            height="11.1569"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.35 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_367_2089"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_367_2089"
                                                result="shape"
                                            />
                                        </filter>
                                        <linearGradient
                                            id="paint0_linear_367_2089"
                                            x1="16.8567"
                                            y1="1.46107"
                                            x2="21.6237"
                                            y2="14.4138"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stop-color="white"
                                                stop-opacity="0.15"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="white"
                                                stop-opacity="0.44"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title">{{ __('SEO Suite') }}</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion">
                        <!--begin:Menu item-->
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('serp-beater.list')"
                                class="menu-link"
                            >
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('SERP Beater')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link href="/keyword-research/list" class="menu-link">
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('Keyword Research')
                                }}</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div
                    class="menu-item show menu-accordion mt-3"
                    @click="toggle($event)"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <span class="svg-icon svg-icon-primary svg-icon-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="29"
                                    height="28"
                                    viewBox="0 0 29 28"
                                    fill="none"
                                >
                                    <g filter="url(#filter0_i_367_2096)">
                                        <path
                                            d="M8.89405 25.4007C8.11389 26.1826 6.84804 26.1836 6.0667 25.4029L3.5739 22.9119C2.79922 22.1378 2.79071 20.8842 3.5548 20.0996L12.7843 10.6229C13.3485 10.0436 14.1226 9.71675 14.9309 9.71647L17.683 9.71555C18.5359 9.71527 19.2172 10.4259 19.1817 11.2787L19.0646 14.0877C19.0333 14.839 18.7213 15.5511 18.1905 16.0832L8.89405 25.4007Z"
                                            fill="#CAD5FB"
                                        />
                                    </g>
                                    <path
                                        d="M8.80563 25.3124C8.07423 26.0454 6.88749 26.0463 6.15498 25.3144L3.66219 22.8235C2.93593 22.0977 2.92794 20.9225 3.64428 20.1869L12.8738 10.7102C13.4145 10.155 14.1563 9.84178 14.9309 9.84152L17.6831 9.8406C18.4648 9.84034 19.0894 10.4918 19.0568 11.2735L18.9397 14.0825C18.9097 14.8025 18.6108 15.4849 18.102 15.9948L8.80563 25.3124Z"
                                        stroke="url(#paint0_linear_367_2096)"
                                        stroke-opacity="0.7"
                                        stroke-width="0.25"
                                    />
                                    <g filter="url(#filter1_d_367_2096)">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M5.98709 17.9609L3.73396 20.2744C3.06538 20.9609 3.07283 22.0578 3.75067 22.7352L6.24347 25.2261C6.92714 25.9092 8.03476 25.9084 8.7174 25.2242L10.9832 22.9533L5.98709 17.9609Z"
                                            fill="#45577B"
                                        />
                                    </g>
                                    <g filter="url(#filter2_bdi_367_2096)">
                                        <path
                                            d="M20.5716 16.9667C20.7659 16.6438 21.2341 16.6438 21.4284 16.9667L21.796 17.5775C21.8658 17.6935 21.9797 17.7762 22.1116 17.8068L22.806 17.9676C23.1732 18.0527 23.3178 18.4979 23.0708 18.7825L22.6035 19.3208C22.5147 19.423 22.4712 19.5569 22.4829 19.6918L22.5446 20.402C22.5772 20.7774 22.1984 21.0526 21.8514 20.9056L21.195 20.6275C21.0704 20.5747 20.9296 20.5747 20.805 20.6275L20.1486 20.9056C19.8016 21.0526 19.4228 20.7774 19.4554 20.402L19.5171 19.6918C19.5288 19.5569 19.4853 19.423 19.3965 19.3208L18.9292 18.7825C18.6822 18.4979 18.8268 18.0527 19.194 17.9676L19.8884 17.8068C20.0203 17.7762 20.1342 17.6935 20.204 17.5775L20.5716 16.9667Z"
                                            fill="#CAD5FB"
                                        />
                                        <path
                                            d="M20.6787 17.0312C20.8244 16.789 21.1756 16.789 21.3213 17.0312L21.6889 17.642C21.7761 17.787 21.9185 17.8904 22.0833 17.9286L22.7778 18.0894C23.0532 18.1532 23.1617 18.4871 22.9764 18.7006L22.5091 19.2389C22.3981 19.3667 22.3438 19.534 22.3584 19.7026L22.42 20.4128C22.4445 20.6944 22.1604 20.9007 21.9002 20.7905L21.2438 20.5124C21.088 20.4464 20.912 20.4464 20.7562 20.5124L20.0998 20.7905C19.8396 20.9007 19.5555 20.6944 19.58 20.4128L19.6416 19.7026C19.6562 19.534 19.6019 19.3667 19.4909 19.2389L19.0236 18.7006C18.8383 18.4871 18.9468 18.1532 19.2222 18.0894L19.9167 17.9286C20.0815 17.8904 20.2239 17.787 20.3111 17.642L20.6787 17.0312Z"
                                            stroke="url(#paint1_linear_367_2096)"
                                            stroke-opacity="0.7"
                                            stroke-width="0.25"
                                        />
                                    </g>
                                    <g filter="url(#filter3_bdi_367_2096)">
                                        <path
                                            d="M21.5716 4.96674C21.7659 4.64383 22.2341 4.64383 22.4284 4.96674L22.796 5.5775C22.8658 5.69351 22.9797 5.77624 23.1116 5.80679L23.806 5.96763C24.1732 6.05266 24.3178 6.4979 24.0708 6.7825L23.6035 7.3208C23.5147 7.42305 23.4712 7.55692 23.4829 7.6918L23.5446 8.40196C23.5772 8.77743 23.1984 9.0526 22.8514 8.90558L22.195 8.62751C22.0704 8.5747 21.9296 8.5747 21.805 8.62751L21.1486 8.90558C20.8016 9.0526 20.4228 8.77743 20.4554 8.40196L20.5171 7.6918C20.5288 7.55692 20.4853 7.42305 20.3965 7.3208L19.9292 6.7825C19.6822 6.4979 19.8268 6.05266 20.194 5.96763L20.8884 5.80679C21.0203 5.77624 21.1342 5.69351 21.204 5.5775L21.5716 4.96674Z"
                                            fill="#CAD5FB"
                                        />
                                        <path
                                            d="M21.6787 5.0312C21.8244 4.78902 22.1756 4.78902 22.3213 5.0312L22.6889 5.64196C22.7761 5.78696 22.9185 5.89038 23.0833 5.92857L23.7778 6.0894C24.0532 6.15318 24.1617 6.48711 23.9764 6.70056L23.5091 7.23886C23.3981 7.36667 23.3438 7.534 23.3584 7.70261L23.42 8.41277C23.4445 8.69437 23.1604 8.90075 22.9002 8.79048L22.2438 8.51242C22.088 8.4464 21.912 8.4464 21.7562 8.51242L21.0998 8.79048C20.8396 8.90075 20.5555 8.69437 20.58 8.41277L20.6416 7.70261C20.6562 7.534 20.6019 7.36667 20.4909 7.23886L20.0236 6.70056C19.8383 6.48711 19.9468 6.15318 20.2222 6.0894L20.9167 5.92857C21.0815 5.89038 21.2239 5.78696 21.3111 5.64196L21.6787 5.0312Z"
                                            stroke="url(#paint2_linear_367_2096)"
                                            stroke-opacity="0.7"
                                            stroke-width="0.25"
                                        />
                                    </g>
                                    <g filter="url(#filter4_bdi_367_2096)">
                                        <path
                                            d="M6.5716 5.96674C6.76593 5.64383 7.23407 5.64383 7.4284 5.96674L7.79596 6.5775C7.86578 6.69351 7.97965 6.77624 8.11155 6.80679L8.806 6.96763C9.17316 7.05266 9.31782 7.4979 9.07077 7.7825L8.60349 8.3208C8.51473 8.42305 8.47124 8.55692 8.48294 8.6918L8.54457 9.40196C8.57716 9.77743 8.19842 10.0526 7.8514 9.90558L7.19504 9.62751C7.07038 9.5747 6.92962 9.5747 6.80496 9.62751L6.1486 9.90558C5.80158 10.0526 5.42284 9.77743 5.45543 9.40196L5.51706 8.6918C5.52876 8.55692 5.48527 8.42305 5.39651 8.3208L4.92923 7.7825C4.68218 7.4979 4.82684 7.05266 5.194 6.96763L5.88845 6.80679C6.02035 6.77624 6.13422 6.69351 6.20404 6.5775L6.5716 5.96674Z"
                                            fill="#CAD5FB"
                                        />
                                        <path
                                            d="M6.6787 6.0312C6.82444 5.78902 7.17556 5.78902 7.3213 6.0312L7.68886 6.64196C7.77613 6.78696 7.91847 6.89038 8.08335 6.92857L8.7778 7.0894C9.05317 7.15318 9.16166 7.48711 8.97637 7.70056L8.50909 8.23886C8.39815 8.36667 8.34378 8.534 8.35841 8.70261L8.42004 9.41277C8.44448 9.69437 8.16043 9.90075 7.90016 9.79048L7.24381 9.51242C7.08797 9.4464 6.91203 9.4464 6.75619 9.51242L6.09984 9.79048C5.83957 9.90075 5.55552 9.69437 5.57996 9.41277L5.64159 8.70261C5.65622 8.534 5.60185 8.36667 5.49091 8.23886L5.02363 7.70056C4.83834 7.48711 4.94683 7.15318 5.2222 7.0894L5.91665 6.92857C6.08153 6.89038 6.22387 6.78696 6.31114 6.64196L6.6787 6.0312Z"
                                            stroke="url(#paint3_linear_367_2096)"
                                            stroke-opacity="0.7"
                                            stroke-width="0.25"
                                        />
                                    </g>
                                    <defs>
                                        <filter
                                            id="filter0_i_367_2096"
                                            x="2.9873"
                                            y="9.71558"
                                            width="16.1958"
                                            height="16.2722"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="BackgroundImageFix"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur
                                                stdDeviation="1.5"
                                            />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect1_innerShadow_367_2096"
                                            />
                                        </filter>
                                        <filter
                                            id="filter1_d_367_2096"
                                            x="1.2373"
                                            y="15.9609"
                                            width="11.7458"
                                            height="11.777"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.35 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_367_2096"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_367_2096"
                                                result="shape"
                                            />
                                        </filter>
                                        <filter
                                            id="filter2_bdi_367_2096"
                                            x="14.8064"
                                            y="12.7246"
                                            width="12.3872"
                                            height="12.2213"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feGaussianBlur
                                                in="BackgroundImageFix"
                                                stdDeviation="1"
                                            />
                                            <feComposite
                                                in2="SourceAlpha"
                                                operator="in"
                                                result="effect1_backgroundBlur_367_2096"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.1 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="effect1_backgroundBlur_367_2096"
                                                result="effect2_dropShadow_367_2096"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect2_dropShadow_367_2096"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur
                                                stdDeviation="1.5"
                                            />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect3_innerShadow_367_2096"
                                            />
                                        </filter>
                                        <filter
                                            id="filter3_bdi_367_2096"
                                            x="15.8064"
                                            y="0.724609"
                                            width="12.3872"
                                            height="12.2213"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feGaussianBlur
                                                in="BackgroundImageFix"
                                                stdDeviation="1"
                                            />
                                            <feComposite
                                                in2="SourceAlpha"
                                                operator="in"
                                                result="effect1_backgroundBlur_367_2096"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.1 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="effect1_backgroundBlur_367_2096"
                                                result="effect2_dropShadow_367_2096"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect2_dropShadow_367_2096"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur
                                                stdDeviation="1.5"
                                            />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect3_innerShadow_367_2096"
                                            />
                                        </filter>
                                        <filter
                                            id="filter4_bdi_367_2096"
                                            x="0.806396"
                                            y="1.72461"
                                            width="12.3872"
                                            height="12.2213"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feGaussianBlur
                                                in="BackgroundImageFix"
                                                stdDeviation="1"
                                            />
                                            <feComposite
                                                in2="SourceAlpha"
                                                operator="in"
                                                result="effect1_backgroundBlur_367_2096"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.1 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="effect1_backgroundBlur_367_2096"
                                                result="effect2_dropShadow_367_2096"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect2_dropShadow_367_2096"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur
                                                stdDeviation="1.5"
                                            />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect3_innerShadow_367_2096"
                                            />
                                        </filter>
                                        <linearGradient
                                            id="paint0_linear_367_2096"
                                            x1="9.508"
                                            y1="24.5129"
                                            x2="4.43351"
                                            y2="14.6161"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stop-color="white"
                                                stop-opacity="0.15"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="white"
                                                stop-opacity="0.44"
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint1_linear_367_2096"
                                            x1="18.3875"
                                            y1="17.0799"
                                            x2="23.2"
                                            y2="21.2049"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stop-color="white"
                                                stop-opacity="0.15"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="white"
                                                stop-opacity="0.44"
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint2_linear_367_2096"
                                            x1="19.3875"
                                            y1="5.07988"
                                            x2="24.2"
                                            y2="9.20488"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stop-color="white"
                                                stop-opacity="0.15"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="white"
                                                stop-opacity="0.44"
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="paint3_linear_367_2096"
                                            x1="4.3875"
                                            y1="6.07988"
                                            x2="9.2"
                                            y2="10.2049"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stop-color="white"
                                                stop-opacity="0.15"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="white"
                                                stop-opacity="0.44"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </span>
                        </span>
                        <span class="menu-title">{{ __('AI Tools') }}</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion">
                        <div class="menu-item">
                            <Link :href="route('wizard')" class="menu-link">
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('Article Wizard')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <Link :href="route('ai-assistant.selection')"
                                class="menu-link"
                            >
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('AI Assistant')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <Link :href="
                                    route('image-generator-collections.list')
                                "
                                class="menu-link"
                            >
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('Image Generator')
                                }}</span>
                            </Link>
                        </div>
                        <!--
                        <div class="menu-item">
                            <Link :href="route('creation.open-form')"
                                class="menu-link"
                            >
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('Open Form')
                                }}</span>
                            </Link>
                        </div>-->
                        <div class="menu-item">
                            <Link :href="route('chat-gpt')" class="menu-link">
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('AI Chat')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('bulk.list')" class="menu-link">
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('Bulk processing')
                                }}</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div
                    class="menu-item show menu-accordion mt-3"
                    @click="toggle($event)"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <span class="svg-icon svg-icon-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <g filter="url(#filter0_i_367_2112)">
                                        <path
                                            d="M2 8.00488C2 5.24346 4.23858 3.00488 7 3.00488H8.95615C9.925 3.00488 10.8342 3.47278 11.3973 4.26117L12.1027 5.2486C12.6658 6.03699 13.575 6.50488 14.5439 6.50488H17C19.7614 6.50488 22 8.74346 22 11.5049V16.0049C22 18.7663 19.7614 21.0049 17 21.0049H7C4.23858 21.0049 2 18.7663 2 16.0049V8.00488Z"
                                            fill="#CAD5FB"
                                        />
                                    </g>
                                    <path
                                        d="M2.125 8.00488C2.125 5.31249 4.30761 3.12988 7 3.12988H8.95615C9.88463 3.12988 10.756 3.57828 11.2956 4.33382L12.0009 5.32125C12.5875 6.14249 13.5346 6.62988 14.5439 6.62988H17C19.6924 6.62988 21.875 8.81249 21.875 11.5049V16.0049C21.875 18.6973 19.6924 20.8799 17 20.8799H7C4.30761 20.8799 2.125 18.6973 2.125 16.0049V8.00488Z"
                                        stroke="url(#paint0_linear_367_2112)"
                                        stroke-opacity="0.7"
                                        stroke-width="0.25"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M20.9713 8.46666C20.7028 5.95834 18.5795 4.00488 15.9999 4.00488H11.1921C11.2647 4.08602 11.3332 4.17152 11.3972 4.26117L12.1025 5.2486C12.6657 6.03699 13.5749 6.50488 14.5437 6.50488H16.9999C18.6185 6.50488 20.0574 7.27398 20.9713 8.46666Z"
                                        fill="#45577B"
                                    />
                                    <g filter="url(#filter1_d_367_2112)">
                                        <path
                                            d="M12 16.5049H18"
                                            stroke="#45577B"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            shape-rendering="crispEdges"
                                        />
                                    </g>
                                    <defs>
                                        <filter
                                            id="filter0_i_367_2112"
                                            x="2"
                                            y="3.00488"
                                            width="20"
                                            height="18"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="BackgroundImageFix"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur
                                                stdDeviation="1.5"
                                            />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect1_innerShadow_367_2112"
                                            />
                                        </filter>
                                        <filter
                                            id="filter1_d_367_2112"
                                            x="9.25"
                                            y="13.7549"
                                            width="11.5"
                                            height="5.5"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.35 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_367_2112"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_367_2112"
                                                result="shape"
                                            />
                                        </filter>
                                        <linearGradient
                                            id="paint0_linear_367_2112"
                                            x1="2.5"
                                            y1="5.70488"
                                            x2="18.4185"
                                            y2="20.8654"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stop-color="white"
                                                stop-opacity="0.15"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="white"
                                                stop-opacity="0.44"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </span>
                        </span>
                        <span class="menu-title">{{ __('Documents') }}</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion">
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('document.new')" class="menu-link">
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('Editor')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('documents.list')"
                                class="menu-link"
                            >
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('My Documents')
                                }}</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div
                    class="menu-item show menu-accordion mt-3"
                    @click="toggle($event)"
                >
                    <span class="menu-link">
                        <span class="menu-icon">
                            <span class="svg-icon svg-icon-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <g filter="url(#filter0_i_367_2119)">
                                        <rect
                                            x="2"
                                            y="2.00488"
                                            width="20"
                                            height="20"
                                            rx="5"
                                            fill="#CAD5FB"
                                        />
                                    </g>
                                    <rect
                                        x="2.125"
                                        y="2.12988"
                                        width="19.75"
                                        height="19.75"
                                        rx="4.875"
                                        stroke="url(#paint0_linear_367_2119)"
                                        stroke-opacity="0.7"
                                        stroke-width="0.25"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M14.75 2.25488L14.75 21.7549H13.25V2.25488H14.75Z"
                                        fill="#45577B"
                                    />
                                    <g filter="url(#filter1_d_367_2119)">
                                        <path
                                            d="M14 14.0049L21 14.0049"
                                            stroke="#45577B"
                                            stroke-width="1.5"
                                            stroke-linecap="square"
                                            stroke-linejoin="round"
                                        />
                                    </g>
                                    <defs>
                                        <filter
                                            id="filter0_i_367_2119"
                                            x="2"
                                            y="2.00488"
                                            width="20"
                                            height="20"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="BackgroundImageFix"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur
                                                stdDeviation="1.5"
                                            />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect1_innerShadow_367_2119"
                                            />
                                        </filter>
                                        <filter
                                            id="filter1_d_367_2119"
                                            x="11.25"
                                            y="11.2549"
                                            width="12.5"
                                            height="5.5"
                                            filterUnits="userSpaceOnUse"
                                            color-interpolation-filters="sRGB"
                                        >
                                            <feFlood
                                                flood-opacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="out"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.35 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="BackgroundImageFix"
                                                result="effect1_dropShadow_367_2119"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="effect1_dropShadow_367_2119"
                                                result="shape"
                                            />
                                        </filter>
                                        <linearGradient
                                            id="paint0_linear_367_2119"
                                            x1="2.5"
                                            y1="5.00488"
                                            x2="20"
                                            y2="20.0049"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stop-color="white"
                                                stop-opacity="0.15"
                                            />
                                            <stop
                                                offset="1"
                                                stop-color="white"
                                                stop-opacity="0.44"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </span>
                        </span>
                        <span class="menu-title">{{ __('Misc') }}</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion">
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('creation.output')"
                                class="menu-link"
                            >
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('Output')
                                }}</span>
                            </Link>
                        </div>
                        <div class="menu-item">
                            <!--begin:Menu link-->
                            <Link :href="route('translate')" class="menu-link">
                                <span class="menu-bullet">
                                    <span
                                        class="bullet bullet-dot"
                                    ></span> </span
                                ><span class="menu-title">{{
                                    __('Translate')
                                }}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::Trigger-->
        <!--end::Aside Menu-->
    </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import { directive } from 'vue3-click-away';
import TeamSelection from '@/Layouts/Aside/TeamSelection.vue';

export default {
    components: {
        Link,
        TeamSelection,
    },

    directives: {
        ClickAway: directive,
    },

    props: {
        user: Object,
    },

    mounted() {
        console.log(this.route('dashboard'));
        let menuItems = document.getElementsByClassName('menu-link');
        Array.from(menuItems).forEach(function (menuItem) {
            menuItem.addEventListener('click', function () {
                emitter.emit('close-aside-mobile-menu');
            });
        });

        let menuTrigger = document.querySelectorAll(
            '.menu-accordion[data-kt-menu-trigger=click]'
        );
        menuTrigger.forEach((item) => {
            item.addEventListener('click', function () {
                item.classList.toggle('hover');
                item.classList.toggle('show');
            });
        });
    },

    methods: {
        toggle($event) {
            let s = $event.target.closest('.menu-item').classList;
            s.contains('show') ? s.remove('show') : s.add('show');
        },
        onClickAway(event) {
            this.toggle(event);
        },
    },
    computed: {
        currentProjectName() {
            return this.$page.props.user.current_project
                ? this.$page.props.user.current_project.name
                : '';
        },
        planBasedLtd() {
            return (
                this.$page.props.user.current_team.lifetime_subscriptions.filter(
                    (subscription) => {
                        return subscription.plan;
                    }
                ).length > 0
            );
        },
        planBasedLtdStandard() {
            return (
                this.$page.props.user.current_team.lifetime_subscriptions.filter(
                    (subscription) => {
                        return subscription.plan === 'standard';
                    }
                ).length > 0
            );
        },
        planBasedLtdProfessional() {
            return (
                this.$page.props.user.current_team.lifetime_subscriptions.filter(
                    (subscription) => {
                        return subscription.plan === 'professional';
                    }
                ).length > 0
            );
        },
        userIsOnTrial() {
            return this.$page.props.user.on_trial;
        },
        hasAccessToSerpBeater() {
            return (
                this.userIsOnTrial ||
                this.hasProfessionalPlan ||
                this.$page.props.user.current_team.lifetime_subscriptions
                    .length > 1
            );
        },
        hasAccessToKeywordResearch() {
            return (
                this.userIsOnTrial ||
                this.hasProfessionalPlan ||
                this.$page.props.user.current_team.lifetime_subscriptions
                    .length > 1
            );
        },
        hasAccessToTranslationForm() {
            return (
                this.userIsOnTrial ||
                this.hasStandardPlan ||
                this.hasProfessionalPlan ||
                this.$page.props.user.current_team.lifetime_subscriptions
                    .length > 0
            );
        },
        hasStandardPlan() {
            return (
                (this.user.current_team.subscriptions.length > 0
                    ? this.getPlanIds('standard').includes(
                          this.user.current_team.subscriptions[0].paddle_plan.toString()
                      )
                    : false) || this.planBasedLtdStandard
            );
        },
        hasProfessionalPlan() {
            return (
                (this.user.current_team.subscriptions.length > 0
                    ? this.getPlanIds('professional').includes(
                          this.$page.props.user.current_team.subscriptions[0].paddle_plan.toString()
                      )
                    : false) || this.planBasedLtdProfessional
            );
        },
        hasAddons() {
            return this.user.current_team.subscriptions.length > 0
                ? this.user.current_team.subscriptions[0].addons.length > 0
                : false;
        },
    },
};
</script>
