<template>
    <div :class="wrapperClasses">
        <div :class="spinnerClasses" role="status">
            <span class="visually-hidden">{{ __('Loading...') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        spinnerClasses: {
            type: String,
            default: 'spinner-border spinner-border',
        },
        wrapperClasses: {
            type: String,
            default: 'd-flex align-items-center justify-content-center',
        },
    },
};
</script>
