<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_keyword_research_expand"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="kt_modal_keyword_research_expand"
        aria-hidden="true"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-900px" v-if="cluster">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header">
                    <!--begin::Heading-->
                    <h2 class="text-capitalize">{{ cluster.name }}</h2>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                            >
                                <path
                                    d="M338.1 413.4c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L203.3 256 349.4 109.9c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L192 244.7 45.9 98.6c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L180.7 256 34.6 402.1c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0L192 267.3 338.1 413.4z"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body">
                    <p class="total-search-volume">
                        <span>{{ __('Total Search Cluster') }}</span> :
                        {{ cluster.keywords_sum_search_volume || 0 }}
                    </p>

                    <p>
                        <b>
                            {{ __('Keywords') }} ({{ keywordIds?.length }}/
                            {{ cluster.keywords?.length }})</b
                        >
                    </p>

                    <div
                        id="keyword_table_wrapper"
                        class="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                        <div class="table-responsive">
                            <table
                                class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer overflow-hidden"
                                id="keyword_table"
                                aria-describedby="keyword-table"
                            >
                                <!--begin::Table head-->
                                <thead>
                                    <!--begin::Table row-->
                                    <tr
                                        class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                                    >
                                        <th
                                            class="sorting_disabled"
                                            rowspan="1"
                                            colspan="1"
                                            aria-label=""
                                        >
                                            <div
                                                class="form-check form-check-sm form-check-custom form-check-solid me-3"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    v-model="allSelected"
                                                    checked="checked"
                                                    @change="selectAll"
                                                />
                                            </div>
                                        </th>
                                        <th
                                            class="sorting cursor-pointer keyword-column text-gray-700"
                                            tabindex="0"
                                            aria-controls="keyword_table"
                                            rowspan="1"
                                            colspan="1"
                                            aria-label="Keyword"
                                            @click="sortColumn('keyword')"
                                        >
                                            {{ __('Keyword') }}
                                        </th>
                                        <th
                                            class="sorting cursor-pointer keyword-column text-gray-700 text-center"
                                            tabindex="0"
                                            aria-controls="keyword_table"
                                            rowspan="1"
                                            colspan="1"
                                            aria-label="Keyword"
                                            @click="sortColumn('keyword')"
                                        >
                                            {{ __('Competition') }}
                                        </th>
                                        <th
                                            class="sorting cursor-pointer text-gray-700 text-center"
                                            tabindex="0"
                                            aria-controls="keyword_table"
                                            rowspan="1"
                                            colspan="1"
                                            aria-label="Search Volume"
                                            @click="sortColumn('search_volume')"
                                        >
                                            {{ __('Search Volume') }}
                                        </th>
                                        <th
                                            class="text-gray-700 text-end"
                                            tabindex="0"
                                            aria-controls="keyword_table"
                                            rowspan="1"
                                            colspan="1"
                                            aria-label="Search Volume Graphic"
                                        >
                                            {{ __('Monthly Searches') }}
                                        </th>
                                    </tr>
                                    <!--end::Table row-->
                                </thead>
                                <!--end::Table head-->
                                <!--begin::Table body-->
                                <tbody class="fw-bold text-gray-600">
                                    <tr
                                        v-for="(
                                            keyword, index
                                        ) in sortedKeywords"
                                        :key="index"
                                    >
                                        <!--begin::Checkbox-->
                                        <td>
                                            <div
                                                class="form-check form-check-sm form-check-custom form-check-solid"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    checked="checked"
                                                    @change="
                                                        allSelected = false;
                                                        toggleKeywordSelection(
                                                            keyword.id
                                                        );
                                                    "
                                                    :value="keyword.id"
                                                    :disabled="index === 0"
                                                />
                                            </div>
                                        </td>
                                        <!--end::Checkbox-->
                                        <td>
                                            <div
                                                class="d-flex align-items-center text-gray-700 keyword-column"
                                            >
                                                {{ keyword.keyword }}
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                class="d-flex justify-content-center text-gray-700 text-center"
                                            >
                                                <span
                                                    class="badge"
                                                    :class="
                                                        difficultyClass(
                                                            keyword.competition
                                                        )
                                                    "
                                                    >{{
                                                        __(keyword.competition)
                                                    }}</span
                                                >
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                class="d-flex justify-content-center text-gray-700"
                                            >
                                                {{ keyword.search_volume || 0 }}
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                class="apexcharts-canvas d-flex align-items-center text-gray-700 w-150px mh-30px"
                                            >
                                                <vue-apex-charts
                                                    class="mt-6"
                                                    type="bar"
                                                    :options="chartOptions"
                                                    :series="
                                                        getMonthlySearchSeries(
                                                            keyword.monthly_searches
                                                        )
                                                    "
                                                ></vue-apex-charts>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--end::Table body-->
                        </div>
                    </div>
                    <div class="text-end">
                        <Link
                            href="#"
                            class="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_serp_beater_wizard_modal"
                            @click.stop="
                                openSerpBeaterModal('new', null, cluster.name)
                            "
                        >
                            <span>
                                <i class="bi bi-pencil-square"></i>
                            </span>
                            {{ __('Start SERP Beater') }}
                        </Link>
                    </div>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import { Link } from '@inertiajs/vue3';
import keyword from '../Components/Fields/Keyword.vue';
import { Modal } from 'bootstrap';

export default {
    components: {
        Link,
        VueApexCharts,
    },
    props: {
        cluster: Object,
    },
    data() {
        return {
            allSelected: false,
            keywordIds: [],
            selectedKeywordId: '',
            currentSort: '',
            sortOrder: 'asc',
            chartOptions: {
                chart: {
                    id: 'basic-bar',
                    toolbar: {
                        show: false,
                    },
                    // Set padding for chart
                    parentHeightOffset: 0, // remove offset
                    offsetY: -10, // adjust the vertical position
                    offsetX: 0,
                },
                tooltip: {
                    show: false, // Hide tooltip
                },
                legend: {
                    show: false, // Hide legend
                },
                dataLabels: {
                    enabled: false, // This line disables the data labels
                },
                xaxis: {
                    categories: [
                        this.__('January'),
                        this.__('February'),
                        this.__('March'),
                        this.__('April'),
                        this.__('May'),
                        this.__('June'),
                        this.__('July'),
                        this.__('August'),
                        this.__('September'),
                        this.__('October'),
                        this.__('November'),
                        this.__('December'),
                    ],
                    labels: {
                        show: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                },
                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },
        };
    },

    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_keyword_research_expand')
        );

        // Initialize keywordIds with all keyword ids for pre-selection
        this.keywordIds =
            this.cluster?.keywords?.map((keyword) => keyword.id) || [];
        this.allSelected = true;
    },

    computed: {
        keyword() {
            return keyword;
        },
        sortedKeywords() {
            // Clone the array to avoid modifying the original data
            const keywords = [...(this.cluster?.keywords || [])];
            if (this.currentSort) {
                keywords.sort((a, b) => {
                    if (this.sortOrder === 'asc') {
                        return a[this.currentSort] > b[this.currentSort]
                            ? 1
                            : -1;
                    } else {
                        return a[this.currentSort] < b[this.currentSort]
                            ? 1
                            : -1;
                    }
                });
            }
            return keywords;
        },
    },

    methods: {
        sortColumn(column) {
            if (this.currentSort === column) {
                // If we're already sorting by this column, reverse the order
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                // Otherwise, sort by the new column in ascending order
                this.currentSort = column;
                this.sortOrder = 'asc';
            }
        },
        openSerpBeaterModal(action, uuid = null, keyword = null) {
            /*const keyword = this.cluster.keywords.find(keyword => keyword.id === this.selectedKeywordId);*/
            emitter.emit('opened-serp-beater-wizard-modal', {
                action: action,
                uuid: uuid,
                keyword: keyword,
            });
        },
        selectAll() {
            this.keywordIds = this.allSelected
                ? this.cluster?.keywords?.map((keyword) => keyword.id)
                : [];
        },
        isKeywordSelected(keywordId) {
            return this.keywordIds.includes(keywordId);
        },
        toggleKeywordSelection(keywordId) {
            const index = this.keywordIds.indexOf(keywordId);
            if (index > -1) {
                this.keywordIds.splice(index, 1);
            } else {
                this.keywordIds.push(keywordId);
            }
        },
        getMonthlySearchSeries(monthlySearches) {
            if (!Array.isArray(monthlySearches)) return [];

            const sortedData = monthlySearches.sort(
                (a, b) => a.month - b.month
            );
            const seriesData = sortedData.map((item) => item.search_volume);

            return [
                {
                    name: this.__('Monthly Searches'),
                    data: seriesData,
                },
            ];
        },
        difficultyClass(difficulty) {
            let difficultyClass = 'badge-light-white';
            switch (true) {
                case difficulty === 'LOW':
                    difficultyClass = 'badge-light-success';
                    break;
                case difficulty === 'MEDIUM':
                    difficultyClass = 'badge-light-warning';
                    break;
                case difficulty === 'HIGH':
                    difficultyClass = 'badge-light-danger';
                    break;
            }
            return difficultyClass;
        },
    },
};
</script>

<style lang="scss">
#kt_modal_keyword_research_expand {
    .modal-header {
        padding: 30px 40px 20px;
        border-bottom: 0px;

        .btn-icon {
            position: absolute;
            right: 15px;
            opacity: 1;
            top: 15px;

            svg path {
                color: #5e6278 !important;
            }
        }
    }

    .modal-body {
        padding: 0px 40px 40px;

        .keyword-column {
            width: 40%; /* or however wide you want it */
        }

        /* for other columns to be as narrow as possible */
        table th:not(.keyword-column),
        table td:not(.keyword-column) {
            width: 1%;
            white-space: nowrap;
        }

        #keyword_table_wrapper {
            height: 400px;
            overflow: auto;
        }

        .total-search-volume {
            padding: 6px 20px;
            border: 1px solid #ccc;
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 1.5rem;

            span {
                color: var(--bs-active-primary);
            }
        }

        th {
            text-transform: none;

            &:first-child {
                width: 5%;
            }

            &:last-child {
                width: 15%;
            }
        }

        .apexcharts-grid,
        .apexcharts-xaxis {
            display: none;
        }
    }
}
</style>
