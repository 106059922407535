<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_new_keyword_research"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-1000px">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header">
                    <!--begin::Heading-->
                    <h2>{{ __('Start new analysis') }}</h2>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body m-5">
                    <!--begin:Form-->
                    <form
                        id="kt_modal_new_keyword_research_form"
                        class="form"
                        action="#"
                        @submit.prevent="sendSubmission"
                    >
                        <!--end::Heading-->
                        <div class="row">
                            <div class="col-6">
                                <!--begin::Input group-->
                                <div class="d-flex flex-column mb-8 fv-row">
                                    <!--begin::Label-->
                                    <label
                                        class="d-flex align-items-center fs-6 fw-bold mb-2"
                                    >
                                        <span class="required">{{
                                            __('Keyword')
                                        }}</span>
                                        <i
                                            class="fas fa-exclamation-circle ms-2 fs-7"
                                            data-bs-toggle="tooltip"
                                            title="{{ __('Specify a project name for future usage and reference') }}"
                                        ></i>
                                    </label>
                                    <!--end::Label-->
                                    <GoogleSearchSuggester
                                        :initial-value="keyword"
                                        @suggestion-selected="onSuggestionSelected"
                                    ></GoogleSearchSuggester>
                                </div>
                                <!--end::Input group-->
                            </div>
                            <div class="col-4">
                                <!--begin::Input group-->
                                <div class="d-flex flex-column mb-8">
                                    <label class="fs-6 fw-bold mb-2">{{
                                        __('Location')
                                    }}</label>
                                    <Multiselect
                                        v-model="selectedLocation"
                                        :options="renderedLocationOptions"
                                        :searchable="true"
                                        :no-options-text="__('Loading...')"
                                        trackBy="name"
                                        label="name"
                                        @focusin="fetchLocations"
                                        @select="onChangeLocation"
                                        class="form-select form-control-solid d-flex px-1 py-2"
                                    >
                                        <template
                                            v-slot:singlelabel="{ value }"
                                        >
                                            <div
                                                class="multiselect-single-label"
                                            >
                                                <div class="d-flex">
                                                    <div
                                                        class="d-flex align-items-center ms-2"
                                                    >
                                                        {{ value.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>

                                        <template v-slot:option="{ option }">
                                            <div class="d-flex flex-grow-1">
                                                <div
                                                    class="d-flex justify-content-between flex-grow-1"
                                                >
                                                    <div
                                                        class="d-flex align-items-center ms-2"
                                                    >
                                                        {{ option.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </Multiselect>
                                </div>
                                <!--end::Input group-->
                            </div>
                            <div class="col-2">
                                <div class="d-flex flex-column mb-8">
                                    <label class="fs-6 fw-bold mb-2">{{
                                        __('Language')
                                    }}</label>
                                    <Language
                                        size="btn-md"
                                        :show-formality="false"
                                        :lang="lang"
                                        :allowed-languages="allowedLanguages"
                                        :show-flag="false"
                                        :disabled="languageDisabled"
                                        @language-change="onLanguageChange"
                                    >
                                    </Language>
                                </div>
                            </div>
                        </div>
                        <!--begin::Actions-->
                        <div class="d-flex justify-content-end">
                            <button
                                type="reset"
                                id="kt_modal_new_keyword_research_cancel"
                                class="btn btn-lg btn-light me-3"
                                @click="closeModal"
                            >
                                {{ __('Cancel') }}
                            </button>
                            <button
                                type="submit"
                                id="kt_modal_new_keyword_research_submit"
                                class="btn btn-lg btn-primary"
                                :class="{
                                    disabled:
                                        keyword.length === 0 ||
                                        !selectedLocation,
                                }"
                            >
                                <span v-if="!loading" class="indicator-label">{{
                                    __('Start')
                                }}</span>
                                <span v-else class="indicator-progress d-block"
                                    >{{ __('Please wait...') }}
                                    <span
                                        class="spinner-border spinner-border-sm align-middle ms-2"
                                    ></span
                                ></span>
                            </button>
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end:Form-->
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import GoogleSearchSuggester from "@/Components/GoogleSearchSuggester/GoogleSearchSuggester.vue";
import Multiselect from '@vueform/multiselect';
import Language from '@/Layouts/SettingElements/Language.vue';
import { Modal } from 'bootstrap';
import { useToast } from 'vue-toastification';

export default {
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },

    props: {
        user: Object,
    },
    components: {
        GoogleSearchSuggester,
        Multiselect,
        Language,
    },
    data() {
        return {
            keyword: '',
            selectedLocation: null,
            locationOptions: {},
            lang:
                this.$page.props.user.user_info.prompt_settings &&
                this.$page.props.user.user_info.prompt_settings.targetLang !==
                    undefined
                    ? this.$page.props.user.user_info.prompt_settings.targetLang
                    : 'en',
            modal: {},
            loading: false,
            languageDisabled: true,
            allowedLanguages: [],
            currentSupportedLanguages: [],
        };
    },
    mounted() {
        let query = this.getUserQueryIfExists();

        this.modal = new Modal(
            document.getElementById('kt_modal_new_keyword_research'),
            {
                backdrop: this.userHasCurrentProject ? 'dynamic' : 'static',
            }
        );

        if (query) {
            this.keyword = query;
            this.modal.show();
        }

        this.fetchSupportedLanguages();
    },
    methods: {
        getUserQueryIfExists() {
            const urlParams = new URLSearchParams(window.location.search);
            const query = urlParams.get('query');

            return query || '';
        },
        onSuggestionSelected(suggestion) {
            this.keyword = suggestion;
        },
        fetchLocations() {
            if (this.locationOptions.length > 0) {
                return;
            }

            let vm = this;
            axios
                .get('/api/locations')
                .then((response) => {
                    vm.locationOptions = response.data.filter((location) => {
                        return location.languages.some((language) => {
                            return vm.currentSupportedLanguages.includes(
                                language.language_code
                            );
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchLocationLanguages() {
            let vm = this;
            axios
                .get('/api/location-languages/' + this.selectedLocation)
                .then((response) => {
                    vm.allowedLanguages = response.data.filter((language) => {
                        return vm.currentSupportedLanguages.includes(language);
                    });

                    vm.lang = response.data[0];
                    vm.languageDisabled = false;
                });
        },
        fetchSupportedLanguages() {
            let vm = this;
            axios
                .get('/api/languages/language-codes')
                .then((response) => {
                    vm.currentSupportedLanguages = response.data;
                });
        },
        onChangeLocation() {
            this.fetchLocationLanguages();
        },
        onLanguageChange(ev) {
            this.lang = ev;
        },
        sendSubmission() {
            let vm = this;
            this.loading = true;
            let data = {
                keyword: this.keyword,
                se: this.selectedLocation,
                locationCode: this.selectedLocation,
                languageCode: this.lang,
            };
            axios.post('/api/keyword-researches', data)
                .then((response) => {
                    this.loading = false;
                    this.closeModal();
                    Inertia.reload({ only: ['user'] });
                    emitter.emit('new-keyword-research-added');
                })
                .catch((error) => {
                    vm.toast.error(error.response.data.message);
                });
        },
        closeModal() {
            this.modal.hide();
        },
    },
    computed: {
        userHasCurrentProject() {
            return true;
        },
        renderedLocationOptions() {
            let entries = [];
            for (const [key, value] of Object.entries(this.locationOptions)) {
                entries.push({
                    value: value.location_code,
                    name: this.__(value.location_name),
                    countryIsoCode: value.country_iso_code,
                });
            }

            // sort by name
            entries.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            return entries;
        },
    },
};
</script>
