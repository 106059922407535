<template>
    <div class="d-flex mt-8 mb-3">
        <h2>{{ __('Frequently Asked Questions') }}</h2>
    </div>

    <div class="d-flex">
        <draggable
            class="w-100 dragFaqArea"
            :list="articleWizardStore.getSelectedFaqList"
            @change="$emit('rebuild-selected-article-outline')"
            :group="{ name: 'faqsIdeas', pull: 'clone', put: true }"
            @add="added"
        >
            <div
                class="h2-element-wrapper d-flex justify-content-between"
                v-for="faq in articleWizardStore.getSelectedFaqList"
                :key="faq.id"
            >
                <div class="d-flex faq-h2 flex-column flex-1">
                    <div class="d-flex flex-1 justify-content-between">
                        <button
                            class="h2-element heading-button btn d-flex align-items-center p-0"
                        >
                            <span class="badge badge-secondary me-2">H2</span>
                            <span
                                class="heading-content"
                                contenteditable="true"
                                @blur="
                                    updateFaqQuestion(
                                        faq,
                                        $event.target.innerText
                                    )
                                "
                            >
                                {{ faq.question }}
                            </span>
                        </button>
                        <span
                            class="btn btn-sm btn-remove p-0"
                            @click="removeFaqListItem(faq.id)"
                        >
                            <i class="bi bi-trash"></i>
                        </span>
                    </div>
                </div>
            </div>
        </draggable>
    </div>

    <div class="d-flex mt-4">
        <button class="btn btn-sm btn-light-primary" @click="addFaq">
            {{ __('Add FAQ') }}
        </button>
        <button
            class="btn btn-sm btn-light-primary ms-5"
            :class="[generatingFaqIdeas ? 'disabled' : '']"
            @click="generateFaqIdeas"
        >
            {{
                generatingFaqIdeas
                    ? __('Generating...')
                    : __('Generate FAQ Ideas')
            }}
        </button>
    </div>

    <div
        class="my-4 p-4 border border-2 rounded border-primary generated-ideas-accordion"
    >
        <a
            data-bs-toggle="collapse"
            href="#generatedFaqCollapse"
            role="button"
            aria-expanded="true"
            aria-controls="generatedFaqCollapse"
        >
            {{ __('Generated FAQ Ideas') }}
            <i class="arrow-icon bi bi-chevron-down"></i>
            <i class="arrow-icon bi bi-chevron-up"></i>
        </a>
        <div class="collapse show" id="generatedFaqCollapse">
            <div class="card card-body">
                <GeneratedFaqIdeas
                    v-if="!generatingFaqIdeas"
                    @rebuild-selected-article-outline="
                        $emit('rebuild-selected-article-outline')
                    "
                >
                </GeneratedFaqIdeas>
                <div
                    v-else
                    class="d-flex align-items-center justify-content-center bg-lighten p-10"
                >
                    <div class="spinner-border spinner-border" role="status">
                        <span class="visually-hidden">{{
                            __('Loading...')
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import GeneratedFaqIdeas from '@/Layouts/Article/Partials/Wizard/Guided/Partials/GeneratedFaqIdeas.vue';

export default {
    components: {
        draggable: VueDraggableNext,
        GeneratedFaqIdeas,
    },

    setup() {
        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        // Make it available inside methods
        return { articleWizardStore };
    },

    data() {
        return {
            generatingFaqIdeas: false,
        };
    },

    watch: {
        'articleWizardStore.currentStep': {
            handler(newVal) {
                if (newVal === 2) {
                    if (
                        this.articleWizardStore.getAiGeneratedFaqIdeas.length ===
                            0 ||
                        this.articleWizardStore.getArticleTitle !==
                            this.articleWizardStore.getPreviousArticleTitle
                    ) {
                        this.articleWizardStore.setAiGeneratedFaqIdeas([]);
                        this.generateFaqIdeas();
                    }
                }
            },
            immediate: true,
        },
    },

    emits: ['rebuild-selected-article-outline'],

    methods: {
        added() {
            let uniqueFaqs = [
                ...new Map(
                    this.articleWizardStore.getSelectedFaqList.map(
                        (selectedFaq) => [selectedFaq.id, selectedFaq]
                    )
                ).values(),
            ];

            this.articleWizardStore.setSelectedFaqList(uniqueFaqs);
            this.$emit('rebuild-selected-article-outline');
        },
        updateFaqQuestion(faq, newQuestion) {
            faq.question = newQuestion;
            this.$emit('rebuild-selected-article-outline');
        },
        generateFaqIdeas() {
            let vm = this;
            vm.generatingFaqIdeas = true;
            let payload = this.getPayload();

            axios
                .post('/api/article/ai-assistant/generate-faq-ideas', payload)
                .then((response) => {
                    let faqIdeas = [];

                    try {
                        faqIdeas = JSON.parse(
                            response.data.generated_faq_ideas[0]?.text
                        )?.faq_questions;
                    } catch (error) {
                        vm.toast.error(vm.__('Some error occured'));
                    }

                    vm.articleWizardStore.setAiGeneratedFaqIdeas(faqIdeas);
                    vm.generatingFaqIdeas = false;
                })
                .catch((error) => {
                    vm.toast.error(vm.__('Some error occured'));
                    vm.generatingFaqIdeas = false;
                });
        },
        getPayload() {
            let payload = this.articleWizardStore.getArticleOutlineRequestPayload;

            if (0 !== Object.keys(payload).length) {
                payload.options = {
                    ...this.articleWizardStore.getOpenAiOptions,
                    ...{ outputs: 1 },
                };

                return payload;
            }

            return {
                prompts: {
                    description: this.articleWizardStore.getArticleTitle,
                    name: '',
                },
                options: {
                    ...this.articleWizardStore.getOpenAiOptions,
                    ...{ outputs: 1 },
                },
                lang: this.articleWizardStore.getLanguage,
                formality: this.articleWizardStore.getFormality,
                headline: this.articleWizardStore.getArticleTitle,
                outline: this.articleWizardStore.getArticleOutline,
                keyword: this.articleWizardStore.getFocusKeyword,
            };
        },
        removeFaqListItem(faqId) {
            let newFaqList = this.articleWizardStore.getSelectedFaqList?.filter(
                (faq) => faq.id !== faqId
            );
            this.articleWizardStore.setSelectedFaqList(newFaqList);

            this.$emit('rebuild-selected-article-outline');
        },
        addFaq() {
            const newFaq = {
                question: 'New FAQ',
                id: `generated-faq-${Date.now()}`,
            };

            this.articleWizardStore.addToSelectedFaqList(newFaq);
            this.$emit('rebuild-selected-article-outline');
        },
    },
};
</script>

<style lang="scss">
.faq-h2 {
    &:hover {
        .btn-remove {
            display: block;
        }
    }
}
.dragFaqArea {
    width: 85% !important;
}
</style>
