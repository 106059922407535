<template>
    <app-layout
        :title="__('Keyword Research')"
        :view="keywordResearch?.keyword"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ keywordResearch?.keyword }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ keywordResearch?.keyword }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{
                        __(
                            'Here you can find all the keywords that have been created.'
                        )
                    }}
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap flex-stack pb-7">
            <!--begin::Title-->
            <div class="d-flex flex-wrap align-items-center my-1">
                <ul class="nav nav-pills me-0 mb-2 mb-sm-0" role="tablist">
                    <li class="nav-item me-5" v-if="gridView">
                        <select
                            class="form-select"
                            aria-label="sort-by-select"
                            v-model="sortBY"
                            @change="sortByChanged"
                        >
                            <option value="">{{ __('Sort By:') }}</option>
                            <option value="search_volume">
                                {{ __('Search Volume') }}
                            </option>
                            <option value="keyword_count">
                                {{ __('Keywords in Cluster') }}
                            </option>
                        </select>
                    </li>
                    <li class="nav-item me-5" v-if="gridView">
                        <button
                            class="btn btn-primary sort-btn"
                            :class="{ disabled: !sortBY }"
                            @click="changeSortType"
                        >
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 576 512"
                                    v-if="'desc' == sortType"
                                >
                                    <path
                                        d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 576 512"
                                    v-else
                                >
                                    <path
                                        d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z"
                                    />
                                </svg>
                            </span>
                        </button>
                    </li>
                    <li class="nav-item me-5 position-relative">
                        <input
                            type="search"
                            class="form-control ps-11"
                            :placeholder="__('Search Keyword')"
                            v-model="search"
                            @keypress="searchChanged"
                        />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                            class="position-absolute top-50 search-icon"
                        >
                            <path
                                d="M384 208A176 176 0 1 0 32 208a176 176 0 1 0 352 0zM343.3 366C307 397.2 259.7 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 51.7-18.8 99-50 135.3L507.3 484.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L343.3 366z"
                            />
                        </svg>
                    </li>
                </ul>
            </div>
            <!--end::Title-->

            <!--begin::Controls-->
            <div class="d-flex flex-wrap my-1">
                <!--begin::Tab nav-->
                <ul class="nav nav-pills me-0 mb-2 mb-sm-0" role="tablist">
                    <li class="nav-item m-0" role="presentation">
                        <button
                            class="btn btn-sm btn-icon btn-light me-3"
                            :class="[
                                gridView
                                    ? 'btn-active-primary active'
                                    : 'border border-active-primary border-gray-300',
                            ]"
                            @click.prevent="toggleGridView(true)"
                        >
                            <span class="svg-icon svg-icon-primary svg-icon-2">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </button>
                    </li>

                    <li class="nav-item m-0" role="presentation">
                        <button
                            class="btn btn-sm btn-icon btn-light"
                            :class="[
                                gridView
                                    ? 'border border-active-primary border-gray-300'
                                    : 'btn-active-primary active',
                            ]"
                            @click.prevent="toggleGridView(false)"
                        >
                            <span class="svg-icon svg-icon-primary svg-icon-2">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </button>
                    </li>
                </ul>
                <!--end::Tab nav-->
            </div>
            <!--end::Controls-->
        </div>
        <div class="row row-cols-1 row-cols-lg-3 g-9" v-if="gridView">
            <div v-if="!clusters?.data?.length" class="col-md-6 col-lg-12">
                <div
                    class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10"
                >
                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column pe-0 pe-sm-10">
                        <!--begin::Content-->
                        <span class="text-gray-600">
                            {{ __('No clusters created') }}
                        </span>
                        <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                    <!--end::Close-->
                </div>
                <!--end::Alert-->
            </div>
            <div
                v-for="(cluster, index) in clusters.data"
                class="col"
                :key="index"
            >
                <div class="card card-bordered text-gray-600">
                    <div class="card-header py-4">
                        <div class="card-title flex-column">
                            <h3
                                class="card-title card-label fw-bolder text-dark text-capitalize text-truncate"
                            >
                                {{ cluster.name }}
                            </h3>

                            <p class="fs-7 text-gray-600 my-0">
                                {{ __('Total Search volume') }} :
                                {{ cluster.keywords_sum_search_volume || 0 }}
                            </p>
                        </div>
                        <div class="mt-3" @click="openExpandModal(cluster)">
                            <i class="bi bi-arrows-angle-expand cursor-pointer"></i>
                        </div>
                    </div>
                    <div class="card-body card-scroll h-200px">
                        <p class="d-flex justify-content-between">
                            <b>{{ __('Keywords') }} ({{
                                    getFilteredKeywords(cluster.keywords)
                                        .length
                                }}/ {{ cluster.keywords.length }})</b>
                            <b>{{ __('Search Volume') }}</b>
                        </p>
                        <ul class="p-0">
                            <li
                                v-for="keyword in getFilteredKeywords(
                                    cluster.keywords
                                )"
                                :key="keyword.id"
                                class="d-flex justify-content-between fs-6"
                            >
                                <span>{{ keyword.keyword }}</span><span>{{ keyword.search_volume || 0}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="card-footer d-flex justify-content-between">
                        <div class="d-flex">&nbsp;</div>
                        <Link
                            href="#"
                            class="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_serp_beater_wizard_modal"
                            @click.stop="
                                openSerpBeaterModal('new', null, cluster.name)
                            "
                        >
                            <span>
                                <i class="bi bi-pencil-square"></i>
                            </span>
                            {{ __('Start SERP Beater') }}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-bordered list-card" v-else>
            <div class="card-body">
                <div
                    id="keyword_table_wrapper"
                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                    <div class="table-responsive">
                        <table
                            class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                            id="keyword_table"
                            aria-describedby="keyword-table"
                        >
                            <!--begin::Table head-->
                            <thead>
                                <!--begin::Table row-->
                                <tr
                                    class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                                >
                                    <th
                                        class="min-w-200px sorting cursor-pointer"
                                        tabindex="0"
                                        aria-controls="keyword_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Keyword"
                                        @click="sortTable('keyword')"
                                    >
                                        {{ __('Keyword') }}
                                    </th>
                                    <th
                                        class="min-w-200px sorting"
                                        tabindex="2"
                                        aria-controls="keyword_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Competition"
                                    >
                                        {{ __('Competition') }}
                                    </th>
                                    <th
                                        class="min-w-200px sorting cursor-pointer"
                                        tabindex="0"
                                        aria-controls="keyword_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Search Volume"
                                        @click="sortTable('search_volume')"
                                    >
                                        {{ __('Search Volume') }}
                                    </th>
                                    <th
                                        class="min-w-200px"
                                        tabindex="3"
                                        aria-controls="action"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="action"
                                    ></th>
                                </tr>
                                <!--end::Table row-->
                            </thead>
                            <!--end::Table head-->
                            <!--begin::Table body-->
                            <tbody class="fw-bold text-gray-600">
                                <tr
                                    v-for="(
                                        keywordResearchKeyword, index
                                    ) in keywordResearchKeywords?.data"
                                    :key="index"
                                >
                                    <td>
                                        <div class="d-flex align-items-center">
                                            {{ keywordResearchKeyword.keyword }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div
                                                class="d-flex justify-content-center text-gray-700 text-center"
                                            >
                                                <span
                                                    class="badge"
                                                    :class="
                                                        difficultyClass(
                                                            keywordResearchKeyword.competition
                                                        )
                                                    "
                                                    >{{
                                                        __(
                                                            keywordResearchKeyword.competition
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            {{
                                                keywordResearchKeyword.search_volume ||
                                                0
                                            }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div
                                                class="apexcharts-canvas d-flex align-items-center text-gray-700 w-150px mh-30px"
                                            >
                                                <vue-apex-charts
                                                    class="mt-6"
                                                    type="bar"
                                                    :options="chartOptions"
                                                    :series="
                                                        getMonthlySearchSeries(
                                                            keywordResearchKeyword.monthly_searches
                                                        )
                                                    "
                                                ></vue-apex-charts>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            class="align-items-center creat-seo-article-btn"
                                        >
                                            <Link
                                                href="#"
                                                class="btn btn-secondary border border-1 btn-sm"
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_serp_beater_wizard_modal"
                                                @click.stop="
                                                    openSerpBeaterModal(
                                                        'new',
                                                        null,
                                                        keywordResearchKeyword.keyword
                                                    )
                                                "
                                            >
                                                <span>
                                                    <i
                                                        class="bi bi-pencil-square"
                                                    ></i>
                                                </span>
                                                {{ __('Start SERP Beater') }}
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!--end::Table body-->
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-stack flex-wrap pt-10">
            <div class="d-flex flex-1 align-items-center">
                <select
                    class="form-select form-select-sm border-0 mw-70px me-4"
                    v-model="perPage"
                    @change="perPageOptionChanged"
                >
                    <option
                        v-for="option in perPageOptions"
                        :value="option"
                        :key="option"
                    >
                        {{ option }}
                    </option>
                </select>
                <div class="fs-6 fw-bold text-gray-700">
                    {{ paginationInfo }}
                </div>
            </div>
            <ul class="pagination">
                <li
                    v-for="(link, index) in pageLinks"
                    :key="index"
                    class="page-item"
                    :class="{
                        'prev previous': index === 0,
                        next: index === 14,
                        active: link.label == paginationMeta.currentPage,
                        'disabled': !link.url
                    }"
                >
                    <template v-if="link.url">
                        <Link :href="link.url" class="page-link">
                            <span v-if="index === 0">
                                <i class="previous"></i>
                            </span>
                            <span v-else-if="index === Object.keys(pageLinks).length - 1">
                                <i class="next"></i>
                            </span>
                            <span v-else>
                                {{ link.label }}
                            </span>
                        </Link>
                    </template>
                    <template v-else>
                        <span class="page-link">
                            <span v-if="index === 0">
                                <i class="previous"></i>
                            </span>
                            <span v-else-if="index === Object.keys(pageLinks).length - 1">
                                <i class="next"></i>
                            </span>
                            <span v-else>
                                {{ link.label }}
                            </span>
                        </span>
                    </template>
                </li>
            </ul>
        </div>

        <KeywordResearchExpandModal
            :cluster="selectedCluster"
        ></KeywordResearchExpandModal>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {router as Inertia} from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';
import { debounce } from 'lodash';
import KeywordResearchExpandModal from '@/Modals/KeywordResearchExpandModal.vue';
import VueApexCharts from 'vue3-apexcharts';
import { Modal } from 'bootstrap';

export default defineComponent({
    props: {
        keywordResearch: {
            type: Object,
            required: true
        },
        clusters: {
            type: Object,
            required: true
        },
        keywordResearchKeywords: {
            type: Object,
            required: true
        }
    },

    components: {
        AppLayout,
        Link,
        KeywordResearchExpandModal,
        VueApexCharts,
    },

    data() {
        return {
            gridView: true,
            perPage: null,
            sortBY: null,
            sortType: 'desc',
            search: '',
            gridPerPageOptions: [9, 30, 60, 90],
            listPerPageOptions: [10, 25, 50, 100, 500],
            selectedCluster: {},
            chartOptions: {
                chart: {
                    id: 'basic-bar',
                    toolbar: {
                        show: false,
                    },
                    // Set padding for chart
                    parentHeightOffset: 0, // remove offset
                    offsetY: -10, // adjust the vertical position
                    offsetX: 0,
                },
                tooltip: {
                    show: false, // Hide tooltip
                },
                legend: {
                    show: false, // Hide legend
                },
                dataLabels: {
                    enabled: false, // This line disables the data labels
                },
                xaxis: {
                    categories: [
                        this.__('January'),
                        this.__('February'),
                        this.__('March'),
                        this.__('April'),
                        this.__('May'),
                        this.__('June'),
                        this.__('July'),
                        this.__('August'),
                        this.__('September'),
                        this.__('October'),
                        this.__('November'),
                        this.__('December'),
                    ],
                    labels: {
                        show: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                },
                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },
        };
    },

    async mounted() {
        await this.setFilters();
    },

    methods: {
        openSerpBeaterModal(action, uuid = null, keyword) {
            emitter.emit('opened-serp-beater-wizard-modal', {
                action: action,
                uuid: uuid,
                keyword: keyword,
            });
        },
        openExpandModal(cluster) {
            this.selectedCluster = cluster;

            // Ensure that the DOM updates with the new selectedCluster data before opening the modal
            this.$nextTick(() => {
                let modal = new Modal(
                    document.getElementById('kt_modal_keyword_research_expand')
                );
                modal.show();
            });
        },
        toggleGridView(isGridView) {
            this.gridView = isGridView;
            this.perPage = this.gridView
                ? this.gridPerPageOptions[0]
                : this.listPerPageOptions[0];

            let urlParameters = { ...this.queryParameters, ...{ page: 1 } };

            let url = route('keyword-research.show', urlParameters);

            if (isGridView) {
                Inertia.visit(url, {
                    only: ['clusters'],
                });
            } else {
                Inertia.visit(url, {
                    only: ['keywordResearchKeywords'],
                });
            }
        },
        async setFilters() {
            let urlParams = new URLSearchParams(window.location.search);
            let defaultPerPage = this.gridView
                ? this.gridPerPageOptions[0]
                : this.listPerPageOptions[0];

            this.gridView = isNaN(parseInt(urlParams.get('gird_view')))
                ? true
                : Boolean(parseInt(urlParams.get('gird_view')));
            this.perPage = isNaN(parseInt(urlParams.get('per_page')))
                ? defaultPerPage
                : urlParams.get('per_page');
            this.sortBY = urlParams.get('sort_by') || null;
            this.sortType = ['asc', 'desc'].includes(urlParams.get('sort_type'))
                ? urlParams.get('sort_type')
                : 'desc';
            this.search = urlParams.get('q') || '';
        },
        perPageOptionChanged() {
            this.filterRecords();
        },
        sortByChanged() {
            this.sortRecords();
        },
        changeSortType() {
            this.sortType = 'desc' == this.sortType ? 'asc' : 'desc';
            this.sortRecords();
        },
        sortRecords() {
            this.filterRecords();
        },
        searchChanged: debounce(function () {
            if (!this.isGridView) {
                this.filterRecords();
            }
        }, 1000),
        filterRecords() {
            let url = route('keyword-research.show', this.queryParameters);

            Inertia.visit(url, {
                only: ['clusters', 'keywordResearchKeywords'],
            });
        },
        getFilteredKeywords(keywords) {
            let search = this.search;

            return keywords.filter((keyword) =>
                keyword.keyword.includes(search)
            );
        },
        sortTable(sortType) {
            this.sortType = 'desc' == this.sortType ? 'asc' : 'desc';
            this.sortBY = sortType;

            this.filterRecords();
        },
        getMonthlySearchSeries(monthlySearches) {
            if (!Array.isArray(monthlySearches)) return [];

            const sortedData = monthlySearches.sort(
                (a, b) => a.month - b.month
            );
            const seriesData = sortedData.map((item) => item.search_volume);

            return [
                {
                    name: this.__('Monthly Searches'),
                    data: seriesData,
                },
            ];
        },
        difficultyClass(difficulty) {
            let difficultyClass = 'badge-light-white';
            switch (true) {
                case difficulty === 'LOW':
                    difficultyClass = 'badge-light-success';
                    break;
                case difficulty === 'MEDIUM':
                    difficultyClass = 'badge-light-warning';
                    break;
                case difficulty === 'HIGH':
                    difficultyClass = 'badge-light-danger';
                    break;
            }
            return difficultyClass;
        },
    },

    computed: {
        perPageOptions() {
            return this.gridView
                ? this.gridPerPageOptions
                : this.listPerPageOptions;
        },
        queryParameters() {
            return {
                keywordResearch: this.keywordResearch.uuid,
                gird_view: this.gridView,
                page: this.paginationMeta.currentPage || 1,
                per_page: this.perPage,
                sort_by: this.sortBY,
                sort_type: this.sortType,
                q: this.search,
            };
        },
        pageLinks() {
            return this.gridView
                ? this.clusters?.links
                : this.keywordResearchKeywords?.links;
        },
        paginationMeta() {
            return {
                currentPage: this.gridView
                    ? this.clusters?.current_page
                    : this.keywordResearchKeywords?.current_page,
                pageFrom: this.gridView
                    ? this.clusters?.from
                    : this.keywordResearchKeywords?.from,
                pageTo: this.gridView
                    ? this.clusters?.to
                    : this.keywordResearchKeywords?.to,
                total: this.gridView
                    ? this.clusters?.total
                    : this.keywordResearchKeywords?.total,
            };
        },
        paginationInfo() {
            return (
                this.__('Showing') +
                ' ' +
                this.pageFrom +
                ' ' +
                this.__('to') +
                ' ' +
                this.pageTo +
                ' ' +
                this.__('of') +
                ' ' +
                this.paginationMeta.total +
                ' ' +
                this.__('entries')
            );
        },
        pageFrom() {
            return this.paginationMeta?.pageFrom || 0;
        },
        pageTo() {
            return this.paginationMeta?.pageTo || 0;
        },
    },
});
</script>

<style lang="scss">
.search-icon {
    transform: translate(0px, -50%);
    left: 15px;

    path {
        fill: #7e8299;
    }
}
</style>
