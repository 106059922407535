<template>
    <div>
        <div class="d-flex justify-content-between align-items-center border-top content-block-options mb-2">
            <!-- Word count and regenerate button -->
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center option" v-tooltip.html="__('Select your desired content type.')">
                    <label :for="'content-type-' + uniqueId" class="me-2">
                        <font-awesome-icon class="me-1" :icon="['far', 'paragraph']" />
                    </label>
                    <select class="form-select form-select-sm ps-1" v-model="contentData.content_type" @change="handleDropdownChange('dropdownType')" :id="'content-type-' + uniqueId" ref="content_typeSelect">
                        <option value="paragraph">{{ __('Paragraph') }}</option>
                        <option value="bullet-short">{{ __('Bullet Points (short)') }}</option>
                        <option value="enumeration-short">{{ __('Enumeration (short)') }}</option>
                        <option value="bullet-explanation">{{ __('Bullet Points (with explanation)') }}</option>
                        <option value="enumeration-explanation">{{ __('Enumeration (with explanation)') }}</option>
                        <option value="bullet-text">{{ __('Bullet Points + Text') }}</option>
                        <option value="enumeration-text">{{ __('Enumeration + Text') }}</option>
                        <option value="table-short">{{ __('Table (short)') }}</option>
                        <option value="table-explanation">{{ __('Table (with explanation)') }}</option>
                        <option value="table-text">{{ __('Table + Text') }}</option>
                    </select>
                </div>
            </div>
            <!-- Dropdowns for Content Type, Paragraph Length, and Number of Sections -->
            <div class="d-flex option-actions">
                <div class="d-flex align-items-center option" v-tooltip.html="__('Select your desired content size.')">
                    <label :for="'paragraph-length-' + uniqueId" class="me-2">
                        <font-awesome-icon class="me-1" :icon="['far', 'arrow-up-right-and-arrow-down-left-from-center']" />
                    </label>
                    <select class="form-select form-select-sm ps-1" v-model="contentData.paragraph_length" @change="handleDropdownChange('dropdownLength')" :id="'paragraph-length-' + uniqueId" ref="paragraph_lengthSelect">
                        <option value="50-100">{{ __('50-100 words') }}</option>
                        <option value="100-150">{{ __('100-150 words') }}</option>
                        <option value="150-200">{{ __('150-200 words') }}</option>
                        <option value="200-250">{{ __('200-250 words') }}</option>
                    </select>
                </div>
                <div class="d-flex align-items-center option" v-tooltip.html="__('Specify how many sections you would like to have.')">
                    <label :for="'num-sections-' + uniqueId" class="ms-2 me-1">
                        <font-awesome-icon :icon="['far', 'pipe-section']" />
                    </label>
                    <div class="d-flex align-items-center">
                        <span class="d-flex align-items-center fs-7 ms-2 me-1">{{ contentData.num_sections }}</span>
                        <div class="d-flex flex-column num-controls">
                            <div class="up">
                                <font-awesome-icon :icon="['far', 'angle-up']" @click="increaseSections" class="arrow-button"/>
                            </div>
                            <div class="down">
                                <font-awesome-icon :icon="['far', 'angle-down']" @click="decreaseSections" class="arrow-button"/>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-sm pe-0" @click.stop="regenerateDraft">
                    <i class="bi bi-arrow-repeat"></i>
                </button>
            </div>
        </div>
        <!-- dynamic content -->
        <div v-if="!loading" class="draft-content pb-4" v-html="parsedContent" contenteditable="true" @blur="updateContent($event.target.innerText)"></div>
        <div v-else class="d-flex align-items-center justify-content-center bg-lighten p-10">
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">{{ __('Loading...') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import MarkdownIt from 'markdown-it';

export default {
    props: {
        listName: {
            type: String,
            default: null,
        },
        h2Index: {
            type: Number,
            default: null,
        },
        h3Index: {
            type: Number,
            default: null,
        },
        isSubheading: {
            type: Boolean,
            default: false,
        },
        headingData: Object,
    },

    data() {
        return {
            loading: true,
            markdownIt: new MarkdownIt(),
            uniqueId: Math.random().toString(36).substring(2, 15),
            defaultContentData: {
                primary_keyword: '',
                secondary_keywords: [],
                paragraph_length: '100-150',
                num_sections: 1,
                content_type: 'paragraph',
            },
            localContentData: null,
        };
    },

    emits: ['generating-draft'],

    setup(props) {
        const articleWizardStore = useArticleWizardStore();
        return { articleWizardStore };
    },

    watch: {
        headingData: {
            deep: true,
            immediate: true,
            handler(newValue) {
                if (!newValue) {
                    this.resetContentData();
                }
            },
        },
        'articleWizardStore.getArticleDraftContent': {
            deep: true,
            immediate: true,
            handler() {
                this.syncWithStore();
                if (this.localContentData?.content) {
                    this.loading = false;
                }
            },
        },
    },

    created() {
        // Customize the table rendering
        this.markdownIt.renderer.rules.table_open = function () {
            return '<table class="table">\n';
        };
        this.markdownIt.renderer.rules.thead_open = function () {
            return '<thead class="thead">\n';
        };
        this.markdownIt.renderer.rules.tbody_open = function () {
            return '<tbody class="tbody">\n';
        };

        emitter.on('finish-generation', (data) => {
            if (
                data.listName === this.listName &&
                data.h2Index === this.h2Index &&
                data.h3Index === this.h3Index &&
                data.isSubheading === this.isSubheading
            ) {
                this.loading = false;
            }
        });

        this.syncWithStore();
        if (this.localContentData?.content) {
            this.loading = false;
        }
    },

    methods: {
        syncWithStore() {
            const storeContent = this.articleWizardStore.getArticleDraftContent[this.listName]?.[this.h2Index];

            if (this.isSubheading && storeContent?.sub_headings?.[this.h3Index]) {
                this.localContentData = { ...this.defaultContentData, ...storeContent.sub_headings[this.h3Index] };
            } else {
                this.localContentData = { ...this.defaultContentData, ...storeContent };
            }

            if (this.localContentData?.content) {
                this.loading = false;
            }
        },

        resetContentData() {
            this.syncWithStore();
        },

        updateContent(newContent) {
            if (this.localContentData) {
                this.localContentData.content = newContent;
                this.articleWizardStore.updateArticleDraftContent(
                    { ...this.localContentData },
                    this.listName,
                    this.h2Index,
                    this.h3Index
                );
            }
        },

        regenerateDraft() {
            this.loading = true;
            this.$emit(
                'start-generating-draft',
                this.listName,
                this.h2Index,
                this.h3Index,
                this.isSubheading
            );
        },

        countWords(text) {
            if (!text) return '';
            text = text.trim();
            const words = text.split(/\s+/);
            return words.length;
        },

        handleDropdownChange(dropdownRef) {
            const dropdown = this.$refs[dropdownRef];
            if (dropdown && dropdown[0] && dropdown[0]._dropdown) {
                dropdown[0]._dropdown.hide();
            }
            this.updateDraftData();
        },

        increaseSections() {
            if (this.localContentData) {
                this.localContentData.num_sections += 1;
                this.updateDraftData();
            }
        },

        decreaseSections() {
            if (this.localContentData && this.localContentData.num_sections > 1) {
                this.localContentData.num_sections -= 1;
                this.updateDraftData();
            }
        },

        updateDraftData() {
            if (this.localContentData) {
                const writingPointOptions = {
                    primary_keyword: this.localContentData.primary_keyword,
                    secondary_keywords: this.localContentData.secondary_keywords,
                    paragraph_length: this.localContentData.paragraph_length,
                    num_sections: this.localContentData.num_sections,
                    content_type: this.localContentData.content_type,
                };

                // Update the content in the article draft content
                this.articleWizardStore.updateArticleDraftContent(
                    this.localContentData,
                    this.listName,
                    this.h2Index,
                    this.h3Index
                );

                // Update writingPoints in the store
                if (this.isSubheading) {
                    Object.assign(
                        this.articleWizardStore.aiGeneratedWritingPoints[this.h2Index].heading_type_3[this.h3Index],
                        writingPointOptions
                    );
                } else {
                    Object.assign(
                        this.articleWizardStore.aiGeneratedWritingPoints[this.h2Index],
                        writingPointOptions
                    );
                }
            }
        },
    },

    computed: {
        contentData() {
            return this.localContentData || this.defaultContentData;
        },
        content() {
            return this.contentData ? this.contentData.content : '';
        },
        wordCount() {
            return this.countWords(this.content);
        },
        parsedContent() {
            return this.markdownIt.render(this.content);
        },
    },
};
</script>

<style lang="scss">
.select-item-box {
    .content-block-options {
        label, input {
            position: relative;
            width: auto;
            height: auto;
            visibility: visible;
        }

        select {
            border: 0;
            cursor: pointer;
        }

        input {
            border: 0;
            box-shadow: none;

            &.num-sections {
                width: 45px;
                /* Ensure that the arrows are always visible */
                -webkit-appearance: none; /* Safari */
                -moz-appearance: textfield; /* Firefox */
                appearance: textfield; /* Default */
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &::-moz-inner-spin-button,
            &::-moz-outer-spin-button {
                -moz-appearance: none;
                margin: 0;
            }

            &::-ms-expand {
                display: none;
            }
        }
    }
}

.draft-content {
    ul, ol {
        li {
            margin: 0;
            padding: 0;

            &:hover {
                background: none;
                cursor: auto;
            }
        }
    }

    table {
        th, td {
            padding: 0.25rem 0.25rem;
        }

        th {
            font-weight: bold;
        }
    }
}

.word-count {
    display: inline-block;
    margin-right: 10px;
}
</style>
