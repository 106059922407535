<template>
    <div
        class="brand-cta-block tint-block d-block d-md-flex justify-content-between align-items-center mb-5"
    >
        <div class="cta-text">
            <h3>{{ __('Brand tone') }}</h3>
            <p class="mb-md-0">
                {{
                    __(`Providing us with information about your tone is pivotal to
                getting great results with Creaitor.ai`)
                }}
            </p>
        </div>
        <a
            href="#"
            class="btn btn-outline-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_create_brand_identity"
        >
            {{ __('Add') }}
        </a>
    </div>

    <CreateBrandIdentity
        @created="$emit('brand-identity-created')"
    ></CreateBrandIdentity>
</template>

<script>
import { defineComponent } from 'vue';
import CreateBrandIdentity from '@/Pages/BrandIdentity/Create.vue';

export default defineComponent({
    components: {
        CreateBrandIdentity,
    },
});
</script>
