<template>
    <div class="w-100">
        <div class="mb-5">
            <!--begin::Title-->
            <h2 class="mb-3">{{ __('Generated Outline') }}</h2>
        </div>
        <!--end::Heading-->
        <div class="step-body">
            <!--begin::Input group-->
            <div
                class="fv-row mb-15 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid select-item-box generated-outline-blk"
                data-kt-buttons="true"
                data-kt-initialized="1"
            >
                <div class="row">
                    <div
                        class="d-flex align-items-start outline-group col-6 py-2"
                        v-for="(outlineState, index) in articleWizardStore.getParsedUrlOutline"
                        :key="index"
                    >
                        <OutlineItem
                            :outlineItem="outlineState"
                            :index="index"
                        ></OutlineItem>
                    </div>
                </div>
            </div>
            <!--end::Input group-->
        </div>
    </div>
</template>

<script>
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import OutlineItem from '@/Layouts/Article/Partials/Wizard/FromUrl/Partials/OutlineItem.vue';
import TopRankedFaqItems from '@/Layouts/Article/Partials/Wizard/SerpBeater/Partials/TopRankedFaqItems.vue';
import { useToast } from 'vue-toastification';

export default {
    components: {
        OutlineItem,
        TopRankedFaqItems,
    },

    setup() {
        const toast = useToast();
        const articleWizardStore = useArticleWizardStore();

        return {
            articleWizardStore,
            toast,
        };
    },
};
</script>
