<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_image_generator"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-fullscreen">
            <!--begin::Modal content-->
            <div class="modal-content">
                <!--begin::Modal header-->
                <div class="modal-header bg-white">
                    <!--begin::Heading-->
                    <h2>{{ __('AI Image Generator') }}</h2>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body m-5 d-flex px-0 mx-0">
                    <div class="d-flex">
                        <Prompt
                            :user="user"
                            :ai-assistant-templates="aiAssistantTemplates"
                            :ai-assistant-template-uuid="uuid"
                            :prompt-title="promptTitle"
                            :loading="loading"
                            :fields="fields"
                            @prompt-change="onPromptChange"
                            @outputs-change="(ev) => (options.outputs = ev)"
                            @quality-change="(ev) => (options.quality = ev)"
                            @characteristic-change="onCharacteristicChange"
                            @compose="compose($event)"
                        >
                        </Prompt>
                    </div>
                    <Result
                        :result-title="resultTitle"
                        :images="images"
                        :loading="loading"
                        :in-modal="true"
                    >
                    </Result>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import NProgress from 'nprogress';
import Multiselect from '@vueform/multiselect';
import Language from '@/Layouts/SettingElements/Language.vue';
import Prompt from '@/Layouts/ImageGenerator/Partials/Prompt.vue';
import Result from '@/Layouts/ImageGenerator/Partials/Result.vue';
import { useToast } from 'vue-toastification';
import { Modal } from 'bootstrap';
import trialLimitExhausted from '@/Mixins/trialLimitExhausted';

export default {
    mixins: [trialLimitExhausted],
    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast };
    },
    props: {
        user: Object,
    },
    components: {
        Multiselect,
        NProgress,
        Prompt,
        Result,
        Language,
    },
    data() {
        return {
            promptTitle: this.__('Image Generator'),
            promptInfo: '',
            resultTitle: this.__('Choose from the results'),
            aiAssistantTemplates: {},
            uuid: '',
            description: '',
            name: '',
            fields: [
                {
                    layout: 'description',
                    key: 'SZxmfJWMwfFimjBn',
                    attributes: {
                        placeholder: {
                            de: 'Beschreibung',
                            en: 'Description',
                            fr: 'Description',
                            it: 'Descrizione',
                        },
                        title: {
                            de: 'Welches Bild möchtest du erstellen?',
                            en: 'Which image you wanna create?',
                            fr: 'Quelle image voulez-vous créer?',
                            it: 'Quale immagine vuoi creare?',
                        },
                    },
                },
            ],
            prompts: {},
            options: {
                outputs: 1,
                quality: 'standard',
            },
            lang:
                this.$page.props.user.user_info.prompt_settings &&
                this.$page.props.user.user_info.prompt_settings.targetLang !==
                    undefined
                    ? this.$page.props.user.user_info.prompt_settings.targetLang
                    : 'en',
            loading: false,
            images: [],
            modal: {},
            document: {
                id: '',
            },
            availableImageEndpoints: {
                generate: '/api/images/generate',
                edit: '/api/images/edit',
                variate: '/api/images/variate',
            },
            variateImage: null,
            imageEditDetails: {
                uploadedImage: null,
                maskImage: null,
                prompt: '',
                n: 4,
            },
        };
    },
    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_image_generator'),
            {
                backdrop: this.userHasCurrentProject ? 'dynamic' : 'static',
            }
        );

        let vm = this;

        emitter.on('document-insert', () => {
            this.modal.hide();
        });
        emitter.on('document-image-insert', () => {
            this.modal.hide();
        });
        emitter.on('document-loaded', function (document) {
            vm.document.id = document.id;
        });

        emitter.on('image-variate-uploaded', function (file) {
            vm.variateImage = file;
        });

        emitter.on('image-edit-description-changed', function (description) {
            vm.imageEditDetails.prompt = description;
        });
        emitter.on('image-edit-uploaded', function (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                var base64data = reader.result;
                vm.imageEditDetails.uploadedImage = base64data;
            };
        });
    },
    methods: {
        onPromptChange(ev) {
            this.prompts[ev.component] = ev.value;
        },

        onCharacteristicChange(ev) {
            this.options[ev.component] = ev.value;
        },
        compose(action) {
            NProgress.start();
            this.loading = true;
            let vm = this;
            let apiEndpoint = this.getApiEndpoint(action);

            if (!apiEndpoint) {
                this.toast.error(this.__('Unable to find API endpoint'));
                return false;
            }

            if (this.isValidationFailed(action)) {
                this.loading = false;
                return false;
            }

            // if (this.userTeamHasEnoughCredits || this.userHasUnlimitedUsage) {
            if (this.getUserImageCredits >= 4) {
                // 4 is the default number of output generation.
                let data = this.getRequestData(action);

                // Fake respond progress
                setTimeout(() => NProgress.set(0.4), 500);
                setTimeout(() => NProgress.inc(), 1500);
                setTimeout(() => NProgress.inc(), 3000);

                axios
                    .post(apiEndpoint, data)
                    .then((response) => {
                        vm.loading = false;
                        vm.images = response.data;
                        NProgress.done();
                        Inertia.reload({ only: ['user'] });
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        vm.toast.error(error.response.data.message);
                        if (
                            error.response.data.error_code ==
                            'trial_credit_limit_exhausted'
                        ) {
                            vm.modal.hide();
                            vm.showTrialLimitExhaustedPopUp();
                        }
                    });
            } else {
                NProgress.done();
                this.loading = false;
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
            }
        },
        getApiEndpoint(method) {
            return this.availableImageEndpoints[method];
        },
        isValidationFailed(method) {
            if ('generate' == method) {
                if (!this.prompts.description) {
                    this.toast.error(
                        this.__('Please provide image description')
                    );

                    return true;
                }
            }
            if ('edit' == method) {
                if (!this.imageEditDetails.prompt) {
                    this.toast.error(
                        this.__('Please provide image description')
                    );

                    return true;
                }
            }
            if ('variate' == method) {
                if (!this.variateImage?.file) {
                    this.toast.error(
                        this.__('Please provide image to variate')
                    );

                    return true;
                }
            }

            return false;
        },
        getRequestData(method) {
            if ('generate' == method) {
                return {
                    prompts: this.prompts,
                    options: this.options,
                };
            }

            if ('edit' == method) {
                let canvas = document.getElementById('image-edit-canvas');
                const base64 = canvas.toDataURL({
                    format: 'png',
                    enableRetinaScaling: true,
                });

                return {
                    prompt: this.imageEditDetails.prompt,
                    n: this.imageEditDetails.n,
                    uploaded_image: this.imageEditDetails.uploadedImage,
                    mask_image: base64,
                };
            }

            if ('variate' == method) {
                let formData = new FormData();
                formData.append('variate_image', this.variateImage.file);
                formData.append(
                    'options',
                    JSON.stringify({
                        n: 4,
                    })
                );

                return formData;
            }
        },
    },
    computed: {
        getUserImageCredits() {
            let imageAddon =
                this.user?.current_team?.subscription_plan_addon_credits?.filter(
                    (addon) => 'image' === addon?.subscription_plan_addon?.code
                );
            let addonCredits = 0;

            // Check if the user has an image add-on with valid credits.
            if (
                imageAddon.length &&
                imageAddon.constructor.toString().indexOf('Array') > 0 &&
                'number' === typeof imageAddon[0]?.amount &&
                imageAddon[0]?.amount > 0
            ) {
                addonCredits = imageAddon[0]?.amount;
            }

            let teamPlanCredits =
                this.user?.current_team?.team_plan_credits?.image_credits || 0;
            let teamBonusCredits =
                this.user?.current_team?.team_bonus_credits?.image_credits || 0;

            return addonCredits + teamPlanCredits + teamBonusCredits;
        },

        // userTeamHasSubscription() {
        //   return this.$page.props.user.current_team.subscriptions.length > 0 || this.$page.props.user.on_trial || this.$page.props.user.current_team.lifetime_subscriptions.length > 0;
        // },
        // userTeamHasEnoughCredits() {
        //   return this.$page.props.user.current_team.team_plan_credits.character_credits > 0 || this.$page.props.user.current_team.team_bonus_credits.character_credits > 0
        // },
        // userHasUnlimitedUsage() {
        //   let user = this.$page.props.user;
        //   let plans = this.$page.props.plans;
        //   let unlimitedSubscription = user.current_team.subscriptions.find(subscription => subscription.paddle_plan === parseInt(plans.professional.monthly) || subscription.paddle_plan === parseInt(plans.professional.yearly));

        //   return (user.current_team.lifetime_subscriptions.length > 0  && (!this.planBasedLtd || (this.$page.props.plan && this.$page.props.plan.name === 'Professional'))) || !!unlimitedSubscription;
        // },
        // planBasedLtd() {
        //   return this.user.current_team.lifetime_subscriptions.filter((subscription) => {
        //     return subscription.plan;
        //   }).length > 0
        // }
    },
};
</script>

<style lang="scss">
#kt_modal_image_generator .modal-dialog .modal-header {
    z-index: 1;
}
</style>
