<template>
    <app-layout :title="__('Home')" :user="$attrs.user">
        <template #header>
            <h2 class="h4 font-weight-bold">
                {{ __('Home') }}
            </h2>
        </template>
        <div class="">
            <div class="row gx-5 gx-xl-10 py-2">
                <div class="col-md-12">

                    <!--begin::Brand CTA block-->
                    <BrandToneCard
                        v-if="!$page.props.hasBrandIdentity"
                        @brand-identity-created="refreshData"
                    ></BrandToneCard>
                    <!--end::Brand CTA block-->

                    <!--begin::Chatgpt block-->
                    <ChatCard :user="$attrs.user"></ChatCard>
                    <!--end::Chatgpt block-->

                    <!--begin::Tools block-->
                    <ToolsCard :user="$attrs.user"></ToolsCard>
                    <!--end::Tools block-->

                    <!--begin::History block-->
                    <GenerationHistoryCard></GenerationHistoryCard>
                    <!--end::History block-->
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import BrandToneCard from './Partials/BrandToneCard.vue';
import ChatCard from './Partials/ChatCard.vue';
import ToolsCard from './Partials/ToolsCard.vue';
import GenerationHistoryCard from './Partials/GenerationHistoryCard.vue';
import { useOnboardProcessStore } from '@/Stores/OnboardProcessStore';
import {router as Inertia} from '@inertiajs/vue3';

export default defineComponent({
    components: {
        AppLayout,
        BrandToneCard,
        ChatCard,
        ToolsCard,
        GenerationHistoryCard,
    },

    setup() {
        // Get onboardProcess store
        const onboardProcessStore = useOnboardProcessStore();

        // Make it available inside methods
        return {
            onboardProcessStore,
        };
    },

    data() {
        return {
            tones: this.$page.props?.tones || [],
        };
    },

    mounted() {
        this.setTones();
    },

    computed: {
        userName() {
            return this.$attrs.user.name || '';
        },
    },

    methods: {
        setTones() {
            this.onboardProcessStore.setAvailableTones(this.tones);
        },
        resetStore() {
            this.onboardProcessStore.resetState();
            this.setTones();
        },
        refreshData() {
            this.resetStore();

            Inertia.reload({
                preserveScroll: true,
                preserveState: true,
                only: ['hasBrandIdentity'],
            });
        },
    },
});
</script>
