<template>
    <div v-if="isMobileDevice" class="mt-10">
        <h2>{{ __('Switch to desktop') }}</h2>
        <p>
            {{ __('The editor is only available on desktop at the moment.') }}
        </p>
    </div>
    <div v-else class="mt-10">
        <Description
            :placeholder="
                __(
                    'An Impressionist oil painting of sunflowers in a purple vase'
                )
            "
            :title="__('Description about the edit')"
            @change="descriptionChanged"
            ref="imageEditDescription"
        ></Description>

        <div class="input-file">
            <input
                type="file"
                id="input-file"
                class="input-file-input d-none"
                name="input-file"
                @change="handleFileUpload"
            />
            <label for="input-file" class="input-file-label btn btn-primary">
                {{ __('Upload') }}</label
            >
        </div>
        <div>
            <canvas
                ref="canvas"
                :width="canvasWidth"
                :height="canvasHeight"
                @mousedown="startErasing"
                @mouseup="stopErasing"
                @mousemove="eraseFromCanvas"
                id="image-edit-canvas"
                class="mt-10 canvas-cursor-erasor"
                :class="[!isInitialFileUpload ? 'border' : '']"
            ></canvas>
        </div>
        <div v-if="!isInitialFileUpload" class="tool-box-container">
            <button @click="toggleErasor" class="btn">
                <span class="icon-btn">
                    <i v-if="isErasorEnabled" class="bi bi-eraser-fill"></i>
                    <i v-else class="bi bi-eraser"></i>
                </span>
                {{ __('Erase') }}
            </button>
            <button
                @click="undo"
                class="btn"
                :class="[historyIndexPointer > -1 ? '' : 'disabled']"
            >
                <span class="icon-btn">
                    <i class="bi bi-arrow-counterclockwise"></i>
                </span>
                {{ __('Undo') }}
            </button>
            <button
                @click="redo"
                class="btn"
                :class="[
                    this.historyIndexPointer < this.history.length - 1
                        ? ''
                        : 'disabled',
                ]"
            >
                <span class="icon-btn">
                    <i class="bi bi-arrow-clockwise"></i>
                </span>
                {{ __('Redo') }}
            </button>
            <div v-if="isErasorEnabled">
                <input
                    type="range"
                    class="form-range"
                    id="customRange1"
                    v-model="eraseSize"
                    min="5"
                    max="30"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Description from '@/Components/Fields/Description.vue';

export default {
    components: {
        Description,
    },
    data() {
        return {
            image: null,
            canvasWidth: 400,
            canvasHeight: 400,
            context: null,
            isErasing: false,
            history: [],
            historyIndexPointer: -1,
            isInitialFileUpload: true,
            eraseSize: 20,
            isErasorEnabled: false,
        };
    },
    methods: {
        descriptionChanged() {
            emitter.emit(
                'image-edit-description-changed',
                this.$refs.imageEditDescription.text
            );
        },
        handleFileUpload(event) {
            if (!this.isInitialFileUpload) {
                this.rewriteHistory();
            }

            this.isInitialFileUpload = false;
            const file = event.target.files[0];
            emitter.emit('image-edit-uploaded', file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            this.clearCanvas();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    this.image = img;
                    this.drawOnCanvas();
                };
                img.src = event.target.result;
            };
        },
        drawOnCanvas() {
            if (!this.image) return;
            this.context.drawImage(
                this.image,
                0,
                0,
                this.canvasWidth,
                this.canvasHeight
            );
        },
        startErasing(event) {
            if (!this.isErasorEnabled) {
                return false;
            }
            this.rewriteHistory();
            this.isErasing = true;
            this.eraseFromCanvas(event);
        },
        stopErasing() {
            this.isErasing = false;
        },
        eraseFromCanvas(event) {
            if (!this.isErasing) return;
            const x = event.offsetX;
            const y = event.offsetY;
            // set composite operation to 'destination-out' to erase pixels
            this.context.globalCompositeOperation = 'destination-out';
            this.context.beginPath();
            this.context.arc(x, y, this.eraseSize, 0, 2 * Math.PI);
            this.context.fill();
            // reset composite operation to default
            this.context.globalCompositeOperation = 'source-over';
        },
        clearCanvas() {
            this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
            this.context.beginPath();
            this.context.globalCompositeOperation = 'source-over';
            this.context.globalAlpha = 1;
        },
        rewriteHistory() {
            this.history.push(
                this.context.getImageData(
                    0,
                    0,
                    this.context.canvas.width,
                    this.context.canvas.height
                )
            );
            this.historyIndexPointer++;
            this.history.splice(
                this.historyIndexPointer + 1,
                this.history.length - this.historyIndexPointer + 1
            );
        },
        undo() {
            if (this.historyIndexPointer == this.history.length - 1) {
                this.history.push(
                    this.context.getImageData(
                        0,
                        0,
                        this.context.canvas.width,
                        this.context.canvas.height
                    )
                );
            }
            if (this.historyIndexPointer >= 0) {
                const previousState = this.history[this.historyIndexPointer--];
                this.context.putImageData(previousState, 0, 0);
            }
        },
        redo() {
            if (this.historyIndexPointer < this.history.length - 1) {
                const previousState = this.history[++this.historyIndexPointer];
                this.context.putImageData(previousState, 0, 0);
            }
        },
        toggleErasor() {
            this.isErasorEnabled = !this.isErasorEnabled;
            let mousePointer = this.isErasorEnabled ? 'crosshair' : 'default';
            this.setCanvasMousePointer(mousePointer);
        },
        setCanvasMousePointer(pointer) {
            this.$refs.canvas.style.cursor = pointer;
        },
    },
    computed: {
        isMobileDevice() {
            return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        },
    },
    mounted() {
        this.context = this.$refs.canvas.getContext('2d');
        this.drawOnCanvas();
    },
};
</script>

<style lang="scss">
.icon-btn {
    i {
        color: #009ef7 !important;
        font-size: 23px;
    }
}

.tool-box-container {
    width: 400px;
}
</style>
