<template>
    <!--begin::NoUiSlider-->
    <div
        ref="pipeSlider"
        class="noUi-target w-100 noUi-sm slider pip-slider mb-20"
    ></div>
    <!--end::NoUiSlider-->
</template>

<script>
import { defineComponent } from 'vue';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

export default defineComponent({
    components: {
        noUiSlider,
    },

    props: {
        sliderSettings: {
            type: Object,
            default: {},
        },
    },

    emits: ['output-change'],

    data() {
        return {
            slider: {},
            output: 50,
            sliderRandomId: Math.floor(Math.random() * 1000000),
        };
    },

    mounted() {
        let vm = this;

        this.slider = noUiSlider.create(
            this.$refs.pipeSlider,
            this.getSliderSettings()
        );

        this.slider.on('slide', function (ev) {
            vm.output = ev[0];
            vm.$emit('output-change', ev[0]);
        });
    },
    methods: {
        getSliderSettings() {
            let vm = this;

            return {
                connect: true,
                density: 4,
                format: {
                    from: function (value) {
                        return value;
                    },
                    to: function (value) {
                        return Math.floor(Number(value));
                    },
                },
                pips: {
                    format: {
                        to: function (value) {
                            let labels = vm.sliderSettings?.pips?.labels;

                            if (labels) {
                                for (let key in labels) {
                                    if (key == value) {
                                        return labels[key];
                                    }
                                }
                            }

                            return value;
                        },
                    },
                    mode: 'values',
                    values: [1, 50, 100],
                },
                range: {
                    min: 1,
                    max: 100,
                },
                start: this.sliderSettings?.start,
                step: 1,
            };
        },
    },
});
</script>

<style lang="scss">
.pip-slider.slider {
    height: 10px;

    .noUi-connects {
        background: #0095e8;
    }

    .noUi-target.noUi-horizontal .noUi-handle {
        width: 24px;
        height: 24px;
        top: -4.5px;
        border-radius: 50%;
        outline: 0;
    }

    .noUi-target .noUi-handle {
        background-color: #fff;
        border: 1px solid var(--bs-gray-200);
        box-shadow: var(--bs-box-shadow-sm);
    }
}
</style>
