<template>
    <button class="btn d-flex align-items-center" :class="buttonClasses">
        <span class="d-flex align-items-center w-35px h-35px"
              :class="{ 'me-2': buttonText }">
            <ApplicationMark
                v-show="!loading"
                cssClasses="ms-1"
                width="30"
                height="30"
            ></ApplicationMark>
            <lottie-player
                v-show="loading"
                ref="lottiePlayer"
                src="/media/animation/loading/creaitor_loading.json"
                cssClasses="w-35px h-35px"
            ></lottie-player>
        </span>
        <span class="text-center">
            {{ buttonText }}
        </span>
    </button>
</template>

<script>
import LottiePlayer from '@/Components/LottiePlayer/LottiePlayer.vue';
import ApplicationMark from "../../Jetstream/ApplicationMark.vue";

export default {
    components: {
        ApplicationMark,
        LottiePlayer,
    },

    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        buttonText: {
            type: String,
        },
        buttonClasses: {
            type: String,
            default: 'btn-primary',
        },
    },
};
</script>
