<template>
    <app-layout :title="__('Create Team')" :user="$attrs.user">
        <template #header>
            <h2 class="h4 font-weight-bold">
                {{ __('Create Team') }}
            </h2>
        </template>

        <create-team-form />
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import CreateTeamForm from '@/Pages/Teams/Partials/CreateTeamForm.vue';

export default defineComponent({
    components: {
        AppLayout,
        CreateTeamForm,
    },
});
</script>
