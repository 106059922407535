<template>
    <app-layout
        :title="__('AI Assistant Selection')"
        :user="$attrs.user"
        kt-toolbar-height="55px"
        kt-toolbar-height-tablet-and-mobile="55px"
    >
        <template #header>
            <h2 class="h4 font-weight-bold">
                {{ __('AI Assistant Selection') }}
            </h2>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('AI Assistant Selection') }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{ __('Please choose your AI Assistant') }}
                </div>
            </div>
        </div>
        <div class="card card-bordered mb-7">
            <!--begin::Card body-->
            <div class="card-body">
                <!--begin::Compact form-->
                <div class="d-flex row align-items-center">
                    <!--begin::Input group-->
                    <div class="col-12 col-md-6 position-relative mb-4 mb-md-0">
                        <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                        <span
                            class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="currentColor"
                                ></rect>
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                        <input
                            v-model="search"
                            type="text"
                            class="form-control form-control-solid ps-10"
                            name="search"
                            :placeholder="__('Search')"
                        />
                    </div>
                    <!--end::Input group-->
                    <div class="col-12 col-md-6">
                        <!--begin::Select-->
                        <Multiselect
                            v-model="selectedCategory"
                            :options="selectTemplateCategoryOptions"
                            :searchable="true"
                            trackBy="name"
                            label="name"
                            class="px-1 py-2"
                            :placeholder="__('Category')"
                        >
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <div class="d-flex">
                                        <div
                                            class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90"
                                            :style="{
                                                backgroundColor: value.color,
                                            }"
                                        >
                                            <img
                                                :src="'/storage/' + value.icon"
                                            />
                                        </div>
                                        <div
                                            class="d-flex align-items-center ms-2"
                                        >
                                            {{ value.name[$page.props.locale] }}
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <div class="d-flex">
                                    <div
                                        class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90"
                                        :style="{
                                            backgroundColor: option.color,
                                        }"
                                    >
                                        <img :src="'/storage/' + option.icon" />
                                    </div>
                                    <div class="d-flex align-items-center ms-2">
                                        {{ option.name[$page.props.locale] }}
                                    </div>
                                </div>
                            </template>
                        </Multiselect>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="aiAssistantTemplates.length > 0">
            <div class="row gx-5 gx-xl-10 mt-10">
                <div class="col-12">
                    <h2 class="text-gray-600">{{ __('Favorites') }}</h2>
                </div>
            </div>
            <div
                v-if="favoriteTemplates.length > 0"
                class="row gx-5 gx-xl-10 mb-10"
            >
                <div
                    v-for="(template, index) in favoriteTemplates"
                    @click="onClickTemplate(template.uuid)"
                    class="col-md-4 d-flex flex-row align-items-stretch"
                >
                    <div
                        class="card card-flush card-bordered card-ai-assistant w-100 hoverable my-5"
                    >
                        <div class="card-header flex-row">
                            <div class="d-flex flex-column">
                                <div
                                    class="d-flex flex-center w-60px h-60px rounded-circle bg-opacity-90 mt-10 mb-5"
                                    :style="{
                                        backgroundColor:
                                            template
                                                .ai_assistant_template_category
                                                .color,
                                    }"
                                >
                                    <img
                                        :src="
                                            '/storage/' +
                                            template.template_icon_path
                                        "
                                    />
                                </div>
                                <h3
                                    class="card-title card-label fw-bolder text-dark"
                                >
                                    {{ template.name[$page.props.locale] }}
                                </h3>
                            </div>
                            <div class="d-flex pt-10">
                                <a
                                    href="#"
                                    @click.stop.prevent="
                                        onClickFavorite(template.uuid)
                                    "
                                    :class="{
                                        'is-favorite': template.is_favorite,
                                    }"
                                    class="favorite"
                                >
                                    <span class="svg-icon svg-icon-3 favorite">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M13.0079 2.6L15.7079 7.2L21.0079 8.4C21.9079 8.6 22.3079 9.7 21.7079 10.4L18.1079 14.4L18.6079 19.8C18.7079 20.7 17.7079 21.4 16.9079 21L12.0079 18.8L7.10785 21C6.20785 21.4 5.30786 20.7 5.40786 19.8L5.90786 14.4L2.30785 10.4C1.70785 9.7 2.00786 8.6 3.00786 8.4L8.30785 7.2L11.0079 2.6C11.3079 1.8 12.5079 1.8 13.0079 2.6Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="card-body text-gray-700 py-5">
                            {{ template.description[$page.props.locale] }}
                        </div>
                        <div class="card-footer">
                            <span class="text-gray-400 mt-1 fw-bold fs-8"
                                ><i class="bi bi-tag-fill"></i>
                                {{
                                    template.ai_assistant_template_category
                                        .name[$page.props.locale]
                                }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row gx-5 gx-xl-10 mb-10">
                <div class="col-12 d-flex justify-content-center">
                    <span class="text-muted fs-6">{{
                        __('You have not selected any favorites.')
                    }}</span>
                </div>
            </div>
            <div class="row gx-5 gx-xl-10 mt-10">
                <div class="col-12">
                    <h2 class="text-gray-600">{{ __('All') }}</h2>
                </div>
                <div
                    v-for="(template, index) in filteredTemplates"
                    @click="onClickTemplate(template.uuid)"
                    class="col-md-4 d-flex flex-row align-items-stretch"
                >
                    <div
                        class="card card-flush card-bordered card-ai-assistant w-100 hoverable my-5"
                    >
                        <div class="card-header flex-row">
                            <div class="d-flex flex-column">
                                <div
                                    class="d-flex flex-center w-60px h-60px rounded-circle bg-opacity-90 mt-10 mb-5"
                                    :style="{
                                        backgroundColor:
                                            template
                                                .ai_assistant_template_category
                                                .color,
                                    }"
                                >
                                    <img
                                        :src="
                                            '/storage/' +
                                            template.template_icon_path
                                        "
                                    />
                                </div>
                                <h3
                                    class="card-title card-label fw-bolder text-dark"
                                >
                                    {{ template.name[$page.props.locale] }}
                                </h3>
                            </div>
                            <div class="d-flex pt-10">
                                <a
                                    href="#"
                                    @click.stop.prevent="
                                        onClickFavorite(template.uuid)
                                    "
                                    :class="{
                                        'is-favorite': template.is_favorite,
                                    }"
                                    class="favorite"
                                >
                                    <span class="svg-icon svg-icon-3 favorite">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M13.0079 2.6L15.7079 7.2L21.0079 8.4C21.9079 8.6 22.3079 9.7 21.7079 10.4L18.1079 14.4L18.6079 19.8C18.7079 20.7 17.7079 21.4 16.9079 21L12.0079 18.8L7.10785 21C6.20785 21.4 5.30786 20.7 5.40786 19.8L5.90786 14.4L2.30785 10.4C1.70785 9.7 2.00786 8.6 3.00786 8.4L8.30785 7.2L11.0079 2.6C11.3079 1.8 12.5079 1.8 13.0079 2.6Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="card-body text-gray-700 py-5">
                            {{ template.description[$page.props.locale] }}
                        </div>
                        <div class="card-footer">
                            <span class="text-gray-400 mt-1 fw-bold fs-8"
                                ><i class="bi bi-tag-fill"></i>
                                {{
                                    template.ai_assistant_template_category
                                        .name[$page.props.locale]
                                }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="h-100">
            <div class="row gx-5 gx-xl-10 h-100">
                <div
                    class="col-12 d-flex justify-content-center align-items-center"
                >
                    <CreaitorLoading
                        css-classes="w-100px h-100px"
                    ></CreaitorLoading>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Welcome from '@/Jetstream/Welcome.vue';
import Multiselect from '@vueform/multiselect';
import {router as Inertia} from '@inertiajs/vue3';
import CreaitorLoading from '@/Components/Loading/CreaitorLoading.vue';

export default defineComponent({
    components: {
        CreaitorLoading,
        AppLayout,
        Welcome,
        Multiselect,
    },
    props: {
        height: {
            type: Number,
            default: 400,
        },
        labelColor: {
            type: String,
            default: '#7E8299',
        },
        borderColor: {
            type: String,
            default: '#EFF2F5',
        },
        baseColor: {
            type: String,
            default: '#2bc5d3',
        },
        lightColor: {
            type: String,
            default: '#54d5aa75',
        },
    },

    data() {
        return {
            aiAssistantTemplateCategories: [],
            aiAssistantTemplates: [],
            favorites: [],
            selectedCategory: null,
            search: '',
        };
    },

    mounted() {
        this.fetchTemplateCategories();
        this.fetchTemplates();
    },

    methods: {
        fetchTemplateCategories() {
            axios
                .get('/api/ai-assistant-template-categories')
                .then((response) => {
                    this.aiAssistantTemplateCategories = response.data;
                });
        },
        fetchTemplates() {
            axios.get('/api/ai-assistant-templates').then((response) => {
                this.aiAssistantTemplates = response.data;
            });
        },
        onClickCategory(category) {
            this.selectedCategory = category;
        },
        onClickTemplate(uuid) {
            window.location.href = '/ai-assistant/' + uuid;
        },
        onClickFavorite(uuid) {
            // Find the template based on uuid instead of an index
            const template = this.aiAssistantTemplates.find(
                (t) => t.uuid === uuid
            );

            if (!template) {
                console.error(`No template found for UUID: ${uuid}`);
                return;
            }

            template.is_favorite = !template.is_favorite;

            if (template.is_favorite) {
                this.favorites.push(uuid);
            } else {
                const idx = this.favorites.indexOf(uuid);
                if (idx > -1) {
                    this.favorites.splice(idx, 1);
                }
            }

            const data = {
                user: {
                    ai_assistant_template_id: uuid,
                },
            };

            axios
                .put('/api/users/' + this.$page.props.user.id, data)
                .then((response) => {
                    // Handle any success actions here
                })
                .catch((error) => {
                    console.error('Error updating favorites:', error);
                    // Optionally revert the favorite state in case of error
                    template.is_favorite = !template.is_favorite;
                });
        },
    },

    computed: {
        remainingPercentages() {
            return this.totalCredits > 0
                ? Math.round((100 / this.totalCredits) * this.totalWordCount)
                : 0;
        },
        filteredTemplates() {
            let vm = this;

            let filteredResult = this.aiAssistantTemplates;

            if (this.selectedCategory !== null) {
                filteredResult = filteredResult.filter(
                    (template) =>
                        template.ai_assistant_template_category.machine_name ===
                        this.selectedCategory
                );
            }

            if (this.search !== '') {
                filteredResult = filteredResult.filter((template) =>
                    template.name[this.$page.props.locale]
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                );
            }

            return filteredResult;
        },
        templateCategories() {
            return this.aiAssistantTemplateCategories.filter(
                (category) => category.highlight
            );
        },
        selectTemplateCategoryOptions() {
            let entries = [];
            for (const [key, value] of Object.entries(
                this.aiAssistantTemplateCategories
            )) {
                entries.push({
                    value: value.machine_name,
                    name: value.name,
                    color: value.color,
                    icon: value.category_icon_path,
                });
            }
            return entries;
        },
        favoriteTemplates() {
            return this.aiAssistantTemplates.filter(
                (template) => template.is_favorite
            );
        },
    },
});
</script>

<style lang="scss">
.multiselect {
    border-color: #dee2e6;
    padding: 13px 0;
}
.nav-line-tabs {
    &.nav-line-tabs-2x {
        .nav-item {
            .nav-link {
                border: none;
                &.active,
                &:hover {
                    border: none;
                    .symbol-label {
                        background-color: #ffffff;
                    }
                    .triangle {
                        position: absolute;
                        bottom: 0;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 10px 10px 10px;
                        border-color: transparent transparent #f3f4f6
                            transparent;

                        @media (max-width: 605px) {
                            display: none;
                        }
                    }
                }
                .symbol-label {
                    background-color: #f3f4f6;
                }
            }
        }
    }
}
.card-ai-assistant {
    &:hover {
        box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.1) !important;
    }

    .favorite {
        .svg-icon svg path {
            fill: var(--bs-gray-300);
        }

        &.is-favorite,
        &:hover {
            .svg-icon svg path {
                fill: var(--bs-link-color);
            }
        }
    }
}
</style>
