<template>
    <app-layout
        :title="__('Wizard Selection')"
        :user="$attrs.user"
        kt-toolbar-height="55px"
        kt-toolbar-height-tablet-and-mobile="55px"
    >
        <template #header>
            <h2 class="h4 font-weight-bold">
                {{ __('Wizard Selection') }}
            </h2>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('Wizard Selection') }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{ __('Please choose your Wizard') }}
                </div>
            </div>
        </div>

        <div class="py-5">
            <div
                class="d-flex flex-column flex-md-row rounded border border-gray-300 p-10 bg-white"
            >
                <!--begin::tabs-->
                <ul
                    class="d-none nav nav-tabs nav-pills border-0 flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6"
                    role="tablist"
                >
                    <li class="nav-item w-md-250px me-0" role="presentation">
                        <a
                            class="nav-link text-start fw-bolder btn btn-active-light-primary active"
                            data-bs-toggle="tab"
                            href="#kt_vtab_pane_1"
                            aria-selected="true"
                            role="tab"
                            @show.bs.tab="handleTabShown"
                        >
                            {{ __('Article') }}</a
                        >
                    </li>
                    <li class="nav-item w-md-250px me-0" role="presentation">
                        <a
                            class="nav-link d-flex justify-content-between text-start fw-bolder btn btn-active-light-primary disabled"
                            data-bs-toggle="tab"
                            href="#kt_vtab_pane_2"
                            aria-selected="false"
                            tabindex="-1"
                            role="tab"
                        >
                            {{ __('Landingpage')
                            }}<span class="badge badge-light fs-8 ms-2">{{
                                __('Coming soon')
                            }}</span></a
                        >
                    </li>
                    <li class="nav-item w-md-250px" role="presentation">
                        <a
                            class="nav-link d-flex justify-content-between text-start fw-bolder btn btn-active-light-primary disabled"
                            data-bs-toggle="tab"
                            href="#kt_vtab_pane_3"
                            aria-selected="false"
                            tabindex="-1"
                            role="tab"
                        >
                            {{ __('Newsletter')
                            }}<span class="badge badge-light fs-8 ms-2">{{
                                __('Coming soon')
                            }}</span></a
                        >
                    </li>
                </ul>
                <!--end::tabs-->

                <!--begin::tab contet-->
                <div class="tab-content w-100" id="WizardTabContent">
                    <!--begin::tab Item-->
                    <div
                        class="tab-pane fade show active"
                        id="kt_vtab_pane_1"
                        role="tabpanel"
                    >
                        <div class="row gx-5 gx-xl-5">
                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_ai_article_assistant"
                                @click="setSelectedWizard('guided')"
                            >
                                <div
                                    class="card card-flush card-wizard border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="ribbon ribbon-top">
                                        <div class="ribbon-label bg-primary">
                                            {{ __('Recommended') }}
                                        </div>
                                    </div>
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-2">
                                            <div
                                                class="d-flex flex-center w-60px h-60px rounded-circle bg-light bg-opacity-90 mt-2 mb-6"
                                            >
                                                <div
                                                    class="d-flex justify-content-center w-30px h-30px"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 640 512"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            class="fa-primary"
                                                            d="M156.2 352H128V128l72-48c15.6-10.4 34-16 52.8-16c22.7 0 44.7 8.1 61.9 22.9l2.7 2.3-89.3 63.8c-21.6 15.4-26.6 45.4-11.2 67s45.4 26.6 67 11.2l57.2-40.8 13.1 12L496.4 332.6c5.5 5 9.6 11.1 12.1 17.5c6.6 16.6 3.7 36.2-9.2 50.3c-17.9 19.5-48.3 20.9-67.8 2.9l-7.8-7.1c-.9 10.1-5 20.1-12.4 28.2c-17.9 19.5-48.3 20.9-67.8 2.9l-17-15.6c-1.9 7.4-5.6 14.5-11.1 20.6c-17.9 19.6-48.2 21-67.8 3.1L156.2 352zM0 144c0-8.8 7.2-16 16-16H96V352c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V144zM64 336a16 16 0 1 0 -32 0 16 16 0 1 0 32 0z"
                                                        />
                                                        <path
                                                            class="fa-secondary"
                                                            d="M512 352V128h-1.5l-71-47.3C423.2 69.8 404.1 64 384.5 64c-20.7 0-40.9 6.5-57.7 18.5l-98.6 70.4c-21.6 15.4-26.6 45.4-11.2 67s45.4 26.6 67 11.2l57.2-40.8L496.4 332.6c6 5.5 10.3 12.2 12.8 19.4H512zm32 0c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V144c0-8.8-7.2-16-16-16H544V352zm48-32a16 16 0 1 1 0 32 16 16 0 1 1 0-32z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('Guided') }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'A guided wizard, which helps creating step by step a article wizard'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_one_click_article_generation"
                                @click="setSelectedWizard('one_click')"
                            >
                                <div
                                    class="card card-flush card-wizard border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <div
                                                class="d-flex flex-center w-60px h-60px rounded-circle bg-light bg-opacity-90 mt-2 mb-6"
                                            >
                                                <div
                                                    class="d-flex justify-content-center w-30px h-30px"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 384 512"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            class="fa-primary"
                                                            d="M176 192H0v32H192 384V192H208V0H176V192z"
                                                        />
                                                        <path
                                                            class="fa-secondary"
                                                            d="M0 192H176V0H160C71.6 0 0 71.6 0 160v32zm0 32V352c0 88.4 71.6 160 160 160h64c88.4 0 160-71.6 160-160V224H192 0zm384-32V160C384 71.6 312.4 0 224 0H208V192H384z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('One-Click') }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'Creating an Article just with one click'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                                @click="setSelectedWizard('from_image')"
                            >
                                <div
                                    class="card card-flush border border-0 bg-lighten border-secondary w-100 mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <div
                                                class="d-flex flex-center w-60px h-60px rounded-circle bg-light bg-opacity-90 mt-2 mb-6"
                                            >
                                                <div
                                                    class="d-flex justify-content-center w-30px h-30px"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            class="fa-primary"
                                                            d="M323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176z"
                                                        />
                                                        <path
                                                            class="fa-secondary"
                                                            d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('From Image')
                                                }}<span
                                                    class="badge badge-light fs-8 ms-2"
                                                    >{{
                                                        __('Coming soon')
                                                    }}</span
                                                >
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'Upload an image and an article post will be generated out of it'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_generate_article_from_url"
                                @click="setSelectedWizard('from_url')"
                            >
                                <div
                                    class="card card-flush card-wizard border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <div
                                                class="d-flex flex-center w-60px h-60px rounded-circle bg-light bg-opacity-90 mt-2 mb-6"
                                            >
                                                <div
                                                    class="d-flex justify-content-center w-30px h-30px"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 640 512"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            class="fa-primary"
                                                            d="M60.2 448.8c-56.5-56.5-56.5-148 0-204.5L172.5 132c56.5-56.5 148-56.5 204.5 0c50 50 56.5 128.8 15.4 186.3l-1.1 1.6c-10.3 14.4-30.3 17.7-44.6 7.4s-17.7-30.3-7.4-44.6l1.1-1.6c22.9-32.1 19.3-76-8.6-103.9c-31.5-31.5-82.5-31.5-114 0L105.5 289.5C74 321 74 372 105.5 403.5c27.9 27.9 71.8 31.5 103.8 8.6l1.6-1.1c14.4-10.3 34.4-6.9 44.6 7.4s6.9 34.4-7.4 44.6l-1.6 1.1C189 505.3 110.2 498.7 60.2 448.8z"
                                                        />
                                                        <path
                                                            class="fa-secondary"
                                                            d="M579.8 63.2c56.5 56.5 56.5 148 0 204.5L467.5 380c-56.5 56.5-148 56.5-204.5 0c-50-50-56.5-128.8-15.4-186.3l1.1-1.6c10.3-14.4 30.3-17.7 44.6-7.4s17.7 30.3 7.4 44.6l-1.1 1.6c-22.9 32.1-19.3 76 8.6 103.8c31.5 31.5 82.5 31.5 114 0L534.5 222.5c31.5-31.5 31.5-82.5 0-114C506.7 80.6 462.7 77 430.7 99.9l-1.6 1.1c-14.4 10.3-34.4 6.9-44.6-7.4s-6.9-34.4 7.4-44.6l1.6-1.1C451 6.7 529.8 13.3 579.8 63.2z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('From Url')
                                                }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'Just paste an URL and an article post will be generated'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::tab Item-->

                    <!--begin::tab Item-->
                    <div
                        class="tab-pane fade"
                        id="kt_vtab_pane_2"
                        role="tabpanel"
                    >
                        <div class="row gx-5 gx-xl-5">
                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                            >
                                <div
                                    class="card card-flush border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('One-Click') }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'Creating a landingpage just with one click'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_ai_article_assistant"
                            >
                                <div
                                    class="card card-flush border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('Guided') }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'A guided wizard, which helps creating step by step a landingpage wizard'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                            >
                                <div
                                    class="card card-flush card-wizard border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('From Image') }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'Upload an image and a landingpage will be generated out of it'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                            >
                                <div
                                    class="card card-flush card-wizard border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('From Url') }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'Just paste an URL and a landingpage will be generated'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::tab Item-->
                    <!--begin::tab Item-->
                    <div
                        class="tab-pane fade"
                        id="kt_vtab_pane_3"
                        role="tabpanel"
                    >
                        <div class="row gx-5 gx-xl-5">
                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                            >
                                <div
                                    class="card card-flush card-wizard border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('One-Click') }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'Creating a newsletter just with one click'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_ai_article_assistant"
                            >
                                <div
                                    class="card card-flush card-wizard border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('Guided') }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'A guided wizard, which helps creating step by step a newsletter wizard'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                            >
                                <div
                                    class="card card-flush card-wizard border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('From Image') }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'Upload an image and a newsletter will be generated out of it'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="col-md-6 d-flex flex-row align-items-stretch"
                            >
                                <div
                                    class="card card-flush card-wizard border border-1 border-secondary w-100 hoverable mb-5"
                                >
                                    <div class="card-body text-gray-700 py-5">
                                        <div class="d-flex flex-column mb-8">
                                            <h3
                                                class="card-title card-label text-dark"
                                            >
                                                {{ __('From Url') }}
                                            </h3>
                                        </div>
                                        <p>
                                            {{
                                                __(
                                                    'Just paste an URL and a newsletter will be generated'
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::tab Item-->
                </div>
                <!--end::tab contet-->
            </div>
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Welcome from '@/Jetstream/Welcome.vue';
import Multiselect from '@vueform/multiselect';
import {router as Inertia} from '@inertiajs/vue3';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';

export default defineComponent({
    components: {
        AppLayout,
        Welcome,
        Multiselect,
    },
    props: {
        height: {
            type: Number,
            default: 400,
        },
        labelColor: {
            type: String,
            default: '#7E8299',
        },
        borderColor: {
            type: String,
            default: '#EFF2F5',
        },
        baseColor: {
            type: String,
            default: '#2bc5d3',
        },
        lightColor: {
            type: String,
            default: '#54d5aa75',
        },
    },

    data() {
        return {
            aiAssistantTemplateCategories: [],
            aiAssistantTemplates: [],
            selectedCategory: null,
            search: '',
        };
    },

    mounted() {
        this.fetchTemplateCategories();
        this.fetchTemplates();
    },

    methods: {
        setSelectedWizard(articleType) {
            const store = useArticleWizardStore();
            store.setArticleWizardType(articleType);
        },
        handleTabShown() {
            const store = useArticleWizardStore();
            store.toggleCheckForOngoingArticle();
        },
        fetchTemplateCategories() {
            axios
                .get('/api/ai-assistant-template-categories')
                .then((response) => {
                    this.aiAssistantTemplateCategories = response.data;
                });
        },
        fetchTemplates() {
            axios.get('/api/ai-assistant-templates').then((response) => {
                this.aiAssistantTemplates = response.data;
            });
        },
        onClickCategory(category) {
            this.selectedCategory = category;
        },
        onClickTemplate(uuid) {
            window.location.href = '/ai-assistant/' + uuid;
        },
        onClickFavorite(uuid, index) {
            let vm = this;
            vm.aiAssistantTemplates[index].is_favorite =
                !vm.aiAssistantTemplates[index].is_favorite;
            let data = {
                user: {
                    ai_assistant_template_id: uuid,
                },
            };
            axios
                .put('/api/users/' + this.$page.props.user.id, data)
                .then((response) => {});
        },
    },

    computed: {
        remainingPercentages() {
            return this.totalCredits > 0
                ? Math.round((100 / this.totalCredits) * this.totalWordCount)
                : 0;
        },
        filteredTemplates() {
            let vm = this;

            let filteredResult = this.aiAssistantTemplates;

            if (this.selectedCategory !== null) {
                filteredResult = filteredResult.filter(
                    (template) =>
                        template.ai_assistant_template_category.machine_name ===
                        this.selectedCategory
                );
            }

            if (this.search !== '') {
                filteredResult = filteredResult.filter((template) =>
                    template.name[this.$page.props.locale]
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                );
            }

            return filteredResult;
        },
        templateCategories() {
            return this.aiAssistantTemplateCategories.filter(
                (category) => category.highlight
            );
        },
        selectTemplateCategoryOptions() {
            let entries = [];
            for (const [key, value] of Object.entries(
                this.aiAssistantTemplateCategories
            )) {
                entries.push({
                    value: value.machine_name,
                    name: value.name,
                    color: value.color,
                    icon: value.category_icon_path,
                });
            }
            return entries;
        },
    },
});
</script>

<style lang="scss">
.multiselect {
    border-color: #f5f8fa;
    padding: 13px 0;
}

.card-wizard {
    &:hover,
    &:focus {
        box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.1) !important;
    }
}

.nav-line-tabs {
    &.nav-line-tabs-2x {
        .nav-item {
            .nav-link {
                border: none;

                &.active,
                &:hover {
                    border: none;

                    .symbol-label {
                        background-color: #ffffff;
                    }

                    .triangle {
                        position: absolute;
                        bottom: 0;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 10px 10px 10px;
                        border-color: transparent transparent #f3f4f6
                            transparent;

                        @media (max-width: 605px) {
                            display: none;
                        }
                    }
                }

                .symbol-label {
                    background-color: #f3f4f6;
                }
            }
        }
    }
}

.card-ai-assistant {
    &:hover {
        box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.1) !important;
    }

    .favorite {
        .svg-icon svg path {
            fill: var(--bs-gray-300);
        }

        &.is-favorite,
        &:hover {
            .svg-icon svg path {
                fill: var(--bs-link-color);
            }
        }
    }
}

.fa-secondary {
    opacity: 0.4;
}
</style>
