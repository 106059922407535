<template>
    <BubbleMenu
        :editor="editorStore.getEditor"
        class="bubble-menu d-flex"
        :tippy-options="{
                duration: 100,
                maxWidth: 'none',
                zIndex: 998,
            }"
        :shouldShow="shouldShow()">
        <div v-if="!showAltTextInput" class="d-flex">
            <div
                class="btn-group rounded-0 border-end border-gray-300 pe-2 align-items-center"
                role="group"
            >
                <button
                    id="btn-text-group"
                    type="button"
                    class="btn dropdown-toggle d-flex border-0 bg-transparent align-items-center p-2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <div class="remix w-100 h-100">
                        <i class="fs-2 ri-image-2-line" />
                    </div>
                </button>
                <ul
                    class="dropdown-menu"
                    aria-labelledby="btn-text-group"
                >
                    <li>
                        <a
                            class="dropdown-item d-flex"
                            href="#"
                            @click.prevent="editAltText"
                        >
                            {{ __('Set alt text') }}
                        </a>
                    </li>
                    <li>
                        <a
                            class="dropdown-item d-flex"
                            href="#"
                            @click.prevent="toggleCaptionVisibility"
                        >
                            {{ __('Toggle caption') }}
                        </a>
                    </li>
                </ul>
            </div>
            <button
                class="btn d-flex bg-transparent align-items-center p-2"
                @click="alignImage('left')">
                <span class="icon d-flex align-items-center fs-7 ms-2">
                    <span class="remix w-100 h-100">
                        <i class="fs-2 ri-align-item-left-line" />
                    </span>
                </span>
                <span class="d-flex align-items-center fs-7 ms-2 mt-0">
                    {{ __('Left') }}
                </span>
            </button>
            <button
                class="btn d-flex bg-transparent align-items-center p-2"
                @click="alignImage('center')">
                <span class="icon d-flex align-items-center fs-7 ms-2">
                    <span class="remix w-100 h-100">
                        <i class="fs-2 ri-align-item-horizontal-center-line"></i>
                    </span>
                </span>
                <span class="d-flex align-items-center fs-7 ms-2 mt-0">
                    {{ __('Center') }}
                </span>
            </button>
            <button
                class="btn d-flex bg-transparent align-items-center p-2"
                @click="alignImage('right')">
                <span class="icon d-flex align-items-center fs-7 ms-2">
                    <span class="remix w-100 h-100">
                        <i class="fs-2 ri-align-item-right-line" />
                    </span>
                </span>
                <span class="d-flex align-items-center fs-7 ms-2 mt-0">
                    {{ __('Right') }}
                </span>
            </button>
        </div>
        <div v-else class="d-flex p-2">
            <input
                type="text"
                v-model="tempAltText"
                @keyup.enter="applyAltText"
                class="form-control form-control-sm focus-ring me-3"
                :placeholder="__('Enter alt text')"
            />
            <button @click="applyAltText" class="btn btn-sm btn-icon-success btn-shadow focus-ring bg-hover-light me-1 py-0 px-1">
                <i class="fs-1 ri-check-line p-0"></i>
            </button>
            <button @click="cancelAltText" class="btn btn-sm btn-icon-danger btn-shadow focus-ring bg-hover-light py-0 px-1">
                <i class="fs-1 ri-close-line p-0"></i>
            </button>
        </div>
    </BubbleMenu>
</template>
<script>
import { defineComponent } from 'vue';
import { BubbleMenu } from '@tiptap/vue-3';
import { useEditorStore } from "@/Stores/EditorStore";


export default defineComponent({

    components: {
        BubbleMenu,
    },

    setup() {
        const editorStore = useEditorStore();

        return {
            editorStore,
        };
    },

    data() {
        return {
            showAltTextInput: false,
            tempAltText: '',
        };
    },

    methods: {
        shouldShow() {
            return ({state}) => {
                const {selection} = state;

                return selection.node && selection.node.type.name === 'image';
            };
        },
        editAltText() {
            this.tempAltText = this.editorStore.getEditor.getAttributes('image').alt || '';
            this.showAltTextInput = true;
        },
        applyAltText() {
            const editor = this.editorStore.getEditor;
            if (!editor) return;

            editor.commands.setImageAltText(this.tempAltText);

            this.showAltTextInput = false;
        },
        cancelAltText() {
            this.showAltTextInput = false;
        },
        toggleCaptionVisibility() {
            const editor = this.editorStore.getEditor;
            if (!editor) return;

            editor.commands.toggleCaptionVisibility();
        },
        alignImage(direction) {
            const editor = this.editorStore.getEditor;
            if (!editor) return;

            editor.commands.setImageAlignment(direction);
        },
    }
});
</script>
<style scoped>
.focus-ring:focus {
    outline: 0;
    box-shadow: 0 0 0 var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}
</style>