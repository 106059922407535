<template>
    <div class="w-100 writing-points">
        <!--begin::Input group-->
        <div
            class="fv-row mb-2 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid select-item-box"
            data-kt-buttons="true"
            data-kt-initialized="1"
        >
            <div class="d-flex flex-column">
                <div class="my-2">
                    <div
                        class="h2-element-wrapper d-flex justify-content-between"
                    >
                        <div class="d-flex flex-column flex-1">
                            <div
                                class="d-flex flex-1 justify-content-between h2-element-box"
                            >
                                <button
                                    class="h2-element heading-button btn d-flex align-items-center p-0"
                                    @click="scrollToSection('introduction')"
                                >
                                    <h3>{{ __('Introduction') }}</h3>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="h2-element-wrapper d-flex justify-content-between"
                    >
                        <div class="d-flex flex-column flex-1">
                            <div
                                class="d-flex flex-1 justify-content-between h2-element-box"
                            >
                                <button
                                    class="h2-element heading-button btn d-flex align-items-center p-0"
                                    @click="scrollToSection(`heading-${h2Index}`)"
                                >
                                    <h3>{{ __('Headings') }}</h3>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        class="h2-element-wrapper d-flex justify-content-between"
                        v-for="(writingPoint, h2Index) in headingLists"
                        :key="h2Index"
                    >
                        <div class="d-flex flex-column flex-1">
                            <div
                                class="d-flex flex-1 justify-content-between h2-element-box"
                            >
                                <button
                                    class="h2-element heading-button btn d-flex align-items-center p-0"
                                    @click="scrollToSection(`heading-${h2Index}`)"
                                >
                                    <span class="badge badge-secondary me-2"
                                        >H2</span
                                    >
                                    <span class="heading-content">
                                        {{
                                            Object.keys(
                                                writingPoint.heading_type_2
                                            )[0]
                                        }}
                                    </span>
                                </button>
                            </div>

                            <div class="mt-2">
                                <div
                                    v-for="(
                                        heading3, h3Index
                                    ) in writingPoint.heading_type_3"
                                    :key="h3Index"
                                    class="h3-element-wrapper justify-content-sm-between"
                                >
                                    <button
                                        class="h3-element heading-button draggable-element btn d-flex align-items-center p-0"
                                        @click="scrollToSection(`heading-${h2Index}-${h3Index}`)"
                                    >
                                        <span class="badge badge-light me-2"
                                            >H3</span
                                        >
                                        <span class="heading-content">
                                            {{ Object.keys(heading3)[0] }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="h2-element-wrapper d-flex justify-content-between"
                    >
                        <div class="d-flex flex-column flex-1">
                            <div
                                class="d-flex flex-1 justify-content-between h2-element-box"
                            >
                                <button
                                    class="h2-element heading-button btn d-flex align-items-center p-0"
                                    @click="scrollToSection(`faq`)"
                                >
                                    <h3>
                                        {{ __('Frequently Asked Questions') }}
                                    </h3>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        class="h2-element-wrapper d-flex justify-content-between"
                        v-for="(writingPoint, h2Index) in faqLists"
                        :key="h2Index"
                    >
                        <div class="d-flex flex-column flex-1">
                            <div
                                class="d-flex flex-1 justify-content-between h2-element-box"
                            >
                                <button
                                    class="h2-element heading-button btn d-flex align-items-center p-0"
                                    @click="scrollToSection(`faq-${h2Index}`)"
                                >
                                    <span class="badge badge-secondary me-2"
                                        >H2</span
                                    >
                                    <span class="heading-content">
                                        {{
                                            Object.keys(
                                                writingPoint.heading_type_2
                                            )[0]
                                        }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        class="h2-element-wrapper d-flex justify-content-between"
                    >
                        <div class="d-flex flex-column flex-1">
                            <div
                                class="d-flex flex-1 justify-content-between h2-element-box"
                            >
                                <button
                                    class="h2-element heading-button btn d-flex align-items-center p-0"
                                    @click="scrollToSection('conclusion')"
                                >
                                    <h3>{{ __('Conclusion') }}</h3>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end::Input group-->
    </div>
</template>

<script>
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';

export default {
    setup() {
        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        // Make it available inside methods
        return { articleWizardStore };
    },

    computed: {
        headingLists() {
            return this.articleWizardStore.getAiGeneratedWritingPoints.filter(
                (writingPoints) => {
                    return writingPoints.type == 'heading_lists';
                }
            );
        },
        faqLists() {
            return this.articleWizardStore.getAiGeneratedWritingPoints.filter(
                (writingPoints) => {
                    return writingPoints.type == 'faq_lists';
                }
            );
        },
    },

    methods: {
        scrollToSection(sectionId) {
            console.log('Scrolling to:', `section-${sectionId}`); // Debug log
            const element = document.getElementById(`section-${sectionId}`);
            if (element) {
                console.log('Element found, scrolling...'); // Debug log
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            } else {
                console.log('Element not found'); // Debug log
            }
        }
    }
};
</script>
