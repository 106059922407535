<template>
    <div class="h3-element-wrapper d-flex justify-content-sm-between">
        <button class="h3-element heading-button draggable-element btn d-flex align-items-center p-0">
            <i class="arrow-icon bi bi-grip-vertical"></i>
            <span class="heading-content"
                  contenteditable="true"
                  @blur="updateContent">
                {{ content }}
            </span>
        </button>
        <span class="btn btn-sm btn-remove p-0">
            <i class="bi bi-trash" @click="remove"></i>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        content: String,
        isH2: Boolean,
        pointIndex: Number,
        h2Index: Number,
        h3Index: Number,
    },
    emits: ['update', 'remove'],
    methods: {
        updateContent(e) {
            this.$emit('update', {
                newValue: e.target.innerText,
                pointIndex: this.pointIndex,
                h2Index: this.h2Index,
                h3Index: this.h3Index
            });
        },
        remove(e) {
            this.$emit('remove', {
                newValue: e.target.innerText,
                pointIndex: this.pointIndex,
                h2Index: this.h2Index,
                h3Index: this.h3Index
            });
        },
    },
};
</script>
