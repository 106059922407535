<template>
    <div class="justify-content-sm-between content-block-options" :class="{'h2-element-wrapper': isH2, 'h3-element-wrapper': !isH2}">
        <div class="d-flex">
            <button class="heading-button btn d-flex align-items-center p-0" :class="{'h2-element': isH2, 'h3-element': !isH2}" @click="toggleCollapse">
                <i :class="{'bi-chevron-right': collapsed, 'bi-chevron-down': !collapsed}"></i>
                <span class="d-flex align-items-center badge me-2" :class="{'badge-secondary': isH2, 'badge-light': !isH2}">{{ headingType }}</span>
                <span class="heading-content">{{ heading }}</span>
            </button>
            <div class="d-flex">
                <div class="d-flex align-items-center">
                    <div class="dropdown" ref="dropdownPrimaryKeyword" v-tooltip.html="__('Define your primary keyword.')">
                        <button class="btn btn-sm d-flex justify-content-between align-items-center dropdown-toggle" type="button" :id="'primary-keyword-menu-' + uniqueId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <font-awesome-icon :icon="['fas', 'key-skeleton']" />
                        </button>
                        <ul class="dropdown-menu p-2" :aria-labelledby="'primary-keyword-menu-' + uniqueId">
                            <li><label :for="'primary-keyword-' + uniqueId">{{ __('Primary Keyword') }}</label></li>
                            <li><input class="form-control form-control-sm" type="text" v-model="headingData.primary_keyword" @focus="closeDropdown('dropdownPrimaryKeyword')" @change="updateHeadingData" :id="'primary-keyword-' + uniqueId" /></li>
                        </ul>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="dropdown content-block-options" ref="dropdownSecondaryKeyword" v-tooltip.html="__('Define your secondary keywords.')">
                        <button class="btn btn-sm d-flex justify-content-between align-items-center dropdown-toggle pe-0" type="button" :id="'secondary-keyword-' + uniqueId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <font-awesome-icon :icon="['fas', 'key-skeleton-left-right']" />
                        </button>
                        <ul class="dropdown-menu p-2" :aria-labelledby="'secondary-keyword-' + uniqueId">
                            <li><label :for="'secondary-keyword-' + uniqueId">{{ __('Secondary Keywords') }}</label></li>
                            <li>
                                <div class="tag-input">
                                    <input class="form-control form-control-sm mb-2" type="text" v-model="newSecondaryKeyword" @keyup.enter="addSecondaryKeyword" :id="'secondary-keyword-' + uniqueId" />
                                    <div v-for="(keyword, index) in headingData.secondary_keywords" :key="index" class="tag rounded px-3">
                                        {{ keyword }}
                                        <span class="tag-remove" @click="removeSecondaryKeyword(index, $event)">x</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between border-top">
            <div class="d-flex">
                <div class="d-flex align-items-center option" v-tooltip.html="__('Select your desired content type.')">
                    <label :for="'content-type-' + uniqueId" class="me-2">
                        <font-awesome-icon class="me-1" :icon="['far', 'paragraph']" />
                    </label>
                    <select class="form-select form-select-sm ps-1" v-model="headingData.content_type" @change="handleDropdownChange('dropdownType')" :id="'content-type-' + uniqueId" ref="content_typeSelect">
                        <option value="paragraph">{{ __('Paragraph') }}</option>
                        <option value="bullet-short">{{ __('Bullet Points (short)') }}</option>
                        <option value="enumeration-short">{{ __('Enumeration (short)') }}</option>
                        <option value="bullet-explanation">{{ __('Bullet Points (with explanation)') }}</option>
                        <option value="enumeration-explanation">{{ __('Enumeration (with explanation)') }}</option>
                        <option value="bullet-text">{{ __('Bullet Points + Text') }}</option>
                        <option value="enumeration-text">{{ __('Enumeration + Text') }}</option>
                        <option value="table-short">{{ __('Table (short)') }}</option>
                        <option value="table-explanation">{{ __('Table (with explanation)') }}</option>
                        <option value="table-text">{{ __('Table + Text') }}</option>
                    </select>
                </div>
            </div>
            <div class="d-flex">
                <div class="d-flex align-items-center option" v-tooltip.html="__('Select your desired content size.')">
                    <label :for="'paragraph-length-' + uniqueId" class="me-2">
                        <font-awesome-icon class="me-1" :icon="['far', 'arrow-up-right-and-arrow-down-left-from-center']" />
                    </label>
                    <select class="form-select form-select-sm ps-1" v-model="headingData.paragraph_length" @change="handleDropdownChange('dropdownLength')" :id="'paragraph-length-' + uniqueId" ref="paragraph_lengthSelect">
                        <option value="50-100">{{ __('50-100 words') }}</option>
                        <option value="100-150">{{ __('100-150 words') }}</option>
                        <option value="150-200">{{ __('150-200 words') }}</option>
                        <option value="200-250">{{ __('200-250 words') }}</option>
                    </select>
                </div>
                <div class="d-flex align-items-center option" v-tooltip.html="__('Specify how many sections you would like to have.')">
                    <label :for="'num-sections-' + uniqueId" class="ms-2 me-1">
                        <font-awesome-icon :icon="['far', 'pipe-section']" />
                    </label>
                    <div class="d-flex align-items-center">
                        <span class="d-flex align-items-center fs-7 ms-2 me-1">{{ headingData.num_sections }}</span>
                        <div class="d-flex flex-column num-controls">
                            <div class="up">
                                <font-awesome-icon :icon="['far', 'angle-up']" @click="increaseSections" class="arrow-button"/>
                            </div>
                            <div class="down">
                                <font-awesome-icon :icon="['far', 'angle-down']" @click="decreaseSections" class="arrow-button"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="!collapsed">
            <slot></slot> <!-- Slot for content `EditableContent` -->
        </div>
    </div>
</template>

<script>
import { useArticleWizardStore } from "@/Stores/ArticleWizardStore";

export default {
    props: {
        heading: String,
        headingType: String, // 'H2' or 'H3'
        isH2: Boolean,
        h2Index: Number,
        headingData: {
            type: Object,
            default: () => ({
                paragraph_length: '100-150',
                num_sections: 1,
                content_type: 'paragraph',
                primary_keyword: '',
                secondary_keywords: [],
            })
        }
    },
    setup() {
        const articleWizardStore = useArticleWizardStore();

        return { articleWizardStore };
    },
    data() {
        return {
            collapsed: false,
            uniqueId: Math.random().toString(36).substring(2, 15), // Generate unique ID for each instance
            newSecondaryKeyword: '', // New keyword input
        };
    },
    methods: {
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
        updateHeadingData() {
            this.$emit('update:headingData', this.headingData);
        },
        handleDropdownChange(dropdownRef) {
            this.updateHeadingData();
            const dropdown = this.$refs[dropdownRef];
            if (dropdown && dropdown._dropdown) {
                dropdown._dropdown.hide();
            }
        },
        closeDropdown(dropdownRef) {
            const dropdown = this.$refs[dropdownRef];
            if (dropdown && dropdown._dropdown) {
                dropdown._dropdown.hide();
            }
        },
        addSecondaryKeyword() {
            if (this.newSecondaryKeyword.trim() !== '') {
                this.headingData.secondary_keywords.push(this.newSecondaryKeyword.trim());
                this.newSecondaryKeyword = '';
                this.updateHeadingData();
            }
        },
        removeSecondaryKeyword(index, event) {
            event.stopPropagation(); // Prevent dropdown from closing
            this.headingData.secondary_keywords.splice(index, 1);
            this.updateHeadingData();
        },
        increaseSections() {
            this.headingData.num_sections += 1;
            this.updateHeadingData();
        },
        decreaseSections() {
            if (this.headingData.num_sections > 1) {
                this.headingData.num_sections -= 1;
                this.updateHeadingData();
            }
        },
        initializeDefaultValues() {
            if (!this.headingData.primary_keyword) {
                this.headingData.primary_keyword = this.articleWizardStore.getFocusKeyword;
            }
            if (!this.headingData.paragraph_length) {
                this.headingData.paragraph_length = '100-150';
            }
            if (!this.headingData.num_sections) {
                this.headingData.num_sections = 1;
            }
            if (!this.headingData.content_type) {
                this.headingData.content_type = 'paragraph';
            }
            if (!this.headingData.secondary_keywords) {
                this.headingData.secondary_keywords = [];
            }
        },
        initializeDropdowns() {
            import('bootstrap').then((bootstrap) => {
                if (this.$refs.dropdownPrimaryKeyword) {
                    new bootstrap.Dropdown(this.$refs.dropdownPrimaryKeyword);
                }
                if (this.$refs.dropdownSecondaryKeyword) {
                    new bootstrap.Dropdown(this.$refs.dropdownSecondaryKeyword);
                }
            });
        }
    },
    created() {
        this.initializeDefaultValues();
    },
    mounted() {
        this.$nextTick(() => {
            this.initializeDropdowns();
        });
    },
};
</script>

<style lang="scss">
@import 'resources/sass/app.scss';

.content-block-options {
    .option {
        label,
        select,
        .btn {
            color: $gray-600;
        }

        label, input {
            position: relative;
            width: auto;
            height: auto;
            visibility: visible;
        }

        select {
            border: 0;
            cursor: pointer;
        }

        input {
            border: 0;
            box-shadow: none;
        }

        .num-controls {
            width: 12px;
            height: 20px;
            position: relative;

            .up, .down {
                cursor: pointer;
                width: 12px;
                height: 10px;

                svg {
                    position: absolute;
                    width: 12px;
                    height: 12px;
                }
            }

            .up {
                svg {
                    top: 0;
                }
            }

            .down {
                svg {
                    bottom: 0;
                }
            }
        }

        &:hover {
            label, select, .btn {
                color: $gray-900;
            }
        }
    }

    .dropdown-menu {
        &.show {
            width: 220px;
        }
    }
}

.arrow-button {
    cursor: pointer;
    font-size: 1.2rem;
    color: $gray-600;

    &:hover {
        color: $gray-900;
    }
}

.tag-input {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tag {
    background-color: #e0e0e0;
    border-radius: 3px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
}

.tag-remove {
    margin-left: 5px;
    cursor: pointer;
    font-weight: bold;
}
</style>
