<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_delete"
        tabindex="-1"
        aria-hidden="true"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content bg-light-danger rounded">
                <!--begin::Modal header-->
                <div class="modal-header pb-0 border-0 justify-content-end">
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body scroll-y py-0">
                    <!--begin:Form-->
                    <!--begin::Alert-->
                    <div
                        class="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10"
                    >
                        <!--begin::Icon-->
                        <span
                            class="svg-icon svg-icon-5tx svg-icon-danger mb-5"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.3"
                                    x="2"
                                    y="2"
                                    width="20"
                                    height="20"
                                    rx="10"
                                    fill="currentColor"
                                ></rect>
                                <rect
                                    x="11"
                                    y="14"
                                    width="7"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-90 11 14)"
                                    fill="currentColor"
                                ></rect>
                                <rect
                                    x="11"
                                    y="17"
                                    width="2"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-90 11 17)"
                                    fill="currentColor"
                                ></rect>
                            </svg>
                        </span>
                        <!--end::Icon-->

                        <!--begin::Wrapper-->
                        <div class="text-center">
                            <!--begin::Title-->
                            <h1 class="fw-bolder mb-5">{{ title }}</h1>
                            <!--end::Title-->

                            <!--begin::Separator-->
                            <div
                                class="separator separator-dashed border-danger opacity-25 mb-5"
                            ></div>
                            <!--end::Separator-->

                            <!--begin::Content-->
                            <div class="mb-9 text-dark">
                                {{ description }}
                            </div>
                            <!--end::Content-->

                            <!--begin::Buttons-->
                            <div class="d-flex flex-center flex-wrap">
                                <a
                                    href="#"
                                    class="btn btn-outline btn-outline-danger btn-active-danger m-2"
                                    @click="closeModal"
                                    >{{ __('Cancel') }}</a
                                >
                                <a
                                    href="#"
                                    class="btn btn-danger m-2"
                                    @click="sendSubmission"
                                    >{{ __('Confirm') }}</a
                                >
                            </div>
                            <!--end::Buttons-->
                        </div>
                        <!--end::Wrapper-->
                    </div>
                    <!--end::Alert-->
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import { Modal } from 'bootstrap';
import { useToast } from 'vue-toastification';

export default {
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },

    data() {
        return {
            title: '',
            name: '',
            description: '',
            url: '',
            modal: {},
            loading: false,
            currentProjectId: this.$page.props.user.current_project_id,
        };
    },
    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_delete')
        );
        const vm = this;
        emitter.on('set-delete-candidate', (e) => {
            Object.keys(e).forEach((k) => {
                vm.$data[k] = e[k];
            });
            vm.modal.show();
        });
    },
    beforeUnmount() {
        emitter.off('set-delete-candidate');
    },
    methods: {
        sendSubmission() {
            this.loading = true;
            let vm = this;
            if (this.ids) {
                axios
                    .delete(`${this.url}`, { data: { ids: this.ids } })
                    .then((response) => {
                        vm.loading = false;
                        vm.callback();
                    })
                    .catch((error) => {
                        vm.toast.error(
                            this.__(error.response.data.message) ||
                                this.__('Something went wrong!')
                        );
                        vm.loading = false;
                    });
            } else {
                let id = this.uuid ? this.uuid : this.id;
                axios.delete(`${this.url}/${id}`).then((response) => {
                    vm.loading = false;
                    vm.callback();
                })
                .catch((error) => {
                        vm.toast.error(
                            this.__(error.response.data.message) ||
                                this.__('Something went wrong!')
                        );
                        vm.loading = false;
                    });
            }
            vm.closeModal();
        },
        closeModal() {
            this.url = '';
            this.ids = null;
            this.uuid = null;
            this.id = null;
            this.modal.hide();
            document.querySelectorAll('.modal-backdrop').forEach((backdrop) => {
                backdrop.remove();
            });
        },
    },
};
</script>
