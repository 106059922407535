<template>
    <bubble-menu
        class="bubble-menu d-flex"
        :tippy-options="{
                duration: 100,
                maxWidth: 'none',
                zIndex: 998,
            }"
        :editor="editorStore.getEditor"
        :shouldShow="shouldShow()"
    >
        <div
            class="btn rounded-0 border-end border-gray-300 p-2"
        >
            {{ selectedCharacters }}
        </div>
        <div
            class="btn-group rounded-0 border-end border-gray-300 align-items-center"
            role="group"
        >
            <button
                id="btn-text-group"
                type="button"
                class="btn dropdown-toggle d-flex border-0 bg-transparent align-items-center p-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <div class="remix w-100 h-100">
                    <i
                        :class="`fs-2 ri-text`"
                    />
                </div>
            </button>
            <ul
                class="dropdown-menu"
                aria-labelledby="btn-text-group"
            >
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="editorStore.getEditor
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()"
                    >
                    <span
                        class="d-flex d-flex icon border rounded"
                    >
                        <div
                            class="remix w-100 h-100"
                        >
                            <i class="fs-2 ri-bold"/>
                        </div>
                    </span>
                        <span
                            class="d-flex align-items-center w-20 ms-2"
                        >
                            {{ __('Bold') }}
                        </span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click="editorStore.getEditor
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()"
                    >
                        <span
                            class="d-flex d-flex icon border rounded"
                        >
                            <div
                                class="remix w-100 h-100"
                            >
                                <i class="fs-2 ri-italic"
                                />
                            </div>
                        </span>
                        <span
                            class="d-flex align-items-center w-20 ms-2"
                        >
                                {{ __('Italic') }}
                        </span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click="editorStore.getEditor
                            .chain()
                            .focus()
                            .toggleStrike()
                            .run()"
                    >
                        <span
                            class="d-flex d-flex icon border rounded"
                        >
                            <div
                                class="remix w-100 h-100"
                            >
                                <i class="fs-2 ri-strikethrough"
                                />
                            </div>
                        </span>
                        <span
                            class="d-flex align-items-center w-20 ms-2"
                        >
                            {{ __('Strike') }}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
        <button
            type="button"
            class="btn d-flex bg-transparent align-items-center p-2"
            @click.prevent="compose('paragraph')"
        >
            <span
                class="icon d-flex align-items-center fs-7 ms-2"
            >
                <div class="remix w-100 h-100">
                    <i class="fs-2 ri-align-left"
                    />
                </div>
            </span>
            <span
                class="d-flex align-items-center fs-7 ms-2 mt-0"
            >
                {{ __('Paragraph') }}
            </span>
        </button>
        <button
            type="button"
            class="btn d-flex bg-transparent align-items-center p-2"
            @click.prevent="compose('improve')"
        >
            <span
                class="icon d-flex align-items-center fs-7 ms-2"
            >
                <div class="remix w-100 h-100">
                    <i
                        class="fs-2 ri-sort-asc"
                    />
                </div>
            </span>
            <span
                class="d-flex align-items-center fs-7 ms-2 mt-0"
            >
                {{ __('Improve') }}
            </span>
        </button>
        <button
            type="button"
            class="btn d-flex bg-transparent align-items-center p-2"
            @click.prevent="compose('rewrite')"
        >
            <span
                class="icon d-flex align-items-center fs-7 ms-2"
            >
                <div class="remix w-100 h-100">
                    <i
                        class="fs-2 ri-text-wrap"
                    />
                </div>
            </span>
            <span
                class="d-flex align-items-center fs-7 ms-2 mt-0"
            >
                {{ __('Rewrite') }}
            </span>
        </button>
        <button
            type="button"
            class="btn d-flex bg-transparent align-items-center p-2"
            @click.prevent="compose('expand')"
        >
            <span
                class="svg-icon svg-icon-5 align-items-center me-0 ms-2"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                >
                    <path
                        d="M295 183c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l135-135V168c0 13.3 10.7 24 24 24s24-10.7 24-24V24c0-13.3-10.7-24-24-24H344c-13.3 0-24 10.7-24 24s10.7 24 24 24h86.1L295 183zM217 329c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L48 430.1V344c0-13.3-10.7-24-24-24s-24 10.7-24 24V488c0 13.3 10.7 24 24 24H168c13.3 0 24-10.7 24-24s-10.7-24-24-24H81.9L217 329z"
                    />
                </svg>
            </span>
            <span
                class="d-flex align-items-center fs-7 ms-2 mt-0"
            >
                {{ __('Expand') }}
            </span>
        </button>
        <button
            type="button"
            class="btn d-flex bg-transparent align-items-center p-2"
            @click.prevent="compose('shorten')"
        >
            <span
                class="svg-icon svg-icon-5 align-items-center me-0 ms-2"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                >
                    <path
                        d="M489 57c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-135 135V72c0-13.3-10.7-24-24-24s-24 10.7-24 24V216c0 13.3 10.7 24 24 24H440c13.3 0 24-10.7 24-24s-10.7-24-24-24H353.9L489 57zM23 455c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l135-135V440c0 13.3 10.7 24 24 24s24-10.7 24-24V296c0-13.3-10.7-24-24-24H72c-13.3 0-24 10.7-24 24s10.7 24 24 24h86.1L23 455z"
                    />
                </svg>
            </span>
            <span
                class="d-flex align-items-center fs-7 ms-2 mt-0"
            >
                {{ __('Shorten') }}
            </span>
        </button>
        <!--
        <BubbleImageMenu :editor="editorStore.getEditor" v-if="nodeIsResizableImage"
                         @change-image-meta="changeImageMeta"></BubbleImageMenu>-->
    </bubble-menu>
</template>
<script>
import {defineComponent} from 'vue';
import {BubbleMenu, Editor, EditorContent, FloatingMenu} from '@tiptap/vue-3';
import {useEditorStore} from '@/Stores/EditorStore';
import {isTextSelection} from '@tiptap/core';
import BubbleImageMenu from "@/Layouts/Document/Editor/BubbleImageMenu.vue";


export default defineComponent({

    components: {
        BubbleMenu,
        BubbleImageMenu
    },

    data() {
        return {
            forceShowBubbleMenu: false,
            forceHideBubbleMenu: false,
        };
    },

    emits: ['compose'],

    setup() {
        const editorStore = useEditorStore();

        return {
            editorStore,
        };
    },

    computed: {
        selectedCharacters() {
            return this.editorStore?.getSelectedCharacters || 0;
        },
        /*nodeIsResizableImage() {
            return this.editorStore.getEditor.isNodeResizableImage();
        },*/
    },

    methods: {
        shouldShow() {
            return ({editor, view, state, oldState, from, to}) => {
                // only show the bubble menu for images and links
                const {doc, selection} = state;
                const {empty} = selection;

                if (this.forceHideBubbleMenu) {
                    this.forceHideBubbleMenu = false;
                    return false;
                }

                if (this.forceShowBubbleMenu) {
                    return true;
                }

                // Sometime check for `empty` is not enough.
                // Doubleclick an empty paragraph returns a node size of 2.
                // So we check also for an empty text size.
                const isEmptyTextBlock =
                    !doc.textBetween(from, to).length &&
                    isTextSelection(state.selection);

                if (!view.hasFocus() || empty || isEmptyTextBlock) {
                    return false;
                }

                if (selection.node && selection.node.type.name === 'image') {
                    return false;
                }

                return true;
            };
        },

        compose(type) {
            try {
                this.$emit('compose', type);
            } catch (e) {
                console.error(e);

            }
        },

        changeImageMeta(meta) {
            this.editorStore.getEditor.changeImageMeta(meta);
        },
    },

});
</script>