<template>
    <div class="w-100" v-if="articleWizardStore?.getAiGeneratedWritingPoints?.length">
        <!--begin::Input group-->
        <div class="fv-row mb-2 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid select-item-box" data-kt-buttons="true" data-kt-initialized="1">
            <div class="d-flex flex-column">
                <div class="my-2 p-4">
                    <div class="d-flex align-items-center mb-3">
                        <span class="badge badge-dark me-2">H1</span>
                        <span class="text-gray-800 fs-3 me-2">
                            {{ articleWizardStore.getArticleTitle }}
                        </span>
                    </div>

                    <div :id="`section-introduction`">
                        <DraftSection :list-name="'introduction'" :h2-index="0" :is-subheading="false" @start-generating-draft="startRegeneration" @update:headingData="updateHeadingData('introduction', 0)">
                        </DraftSection>
                    </div>

                    <div class="h2-element-wrapper d-flex justify-content-between" v-for="(writingPoint, h2Index) in headingLists" :key="h2Index" :id="`section-heading-${h2Index}`">
                        <div class="d-flex flex-column flex-1">
                            <div class="d-flex flex-1 justify-content-between h2-element-box">
                                <div class="h2-element heading-button btn d-flex align-items-center p-0" @click="writingPoint.collapsed = !writingPoint.collapsed">
                                    <i class="arrow-icon bi" :class="{
                                            'bi-chevron-right': writingPoint.collapsed,
                                            'bi-chevron-down': !writingPoint.collapsed,
                                        }"></i>

                                    <span class="badge badge-secondary me-2">H2</span>
                                    <span class="heading-content">
                                        {{ Object.keys(writingPoint.heading_type_2)[0] }}
                                    </span>
                                </div>
                                <div class="d-flex align-items-center text-gray-700 fs-7 me-2">
                                    <font-awesome-icon class="me-2" :icon="['far', 'file-lines']" />
                                    {{ getWordCount(writingPoint) }}
                                </div>
                            </div>

                            <div class="mt-2" v-show="!writingPoint.collapsed">
                                <div>
                                    <DraftSection
                                        :list-name="'heading_lists'"
                                        :h2-index="h2Index" :is-subheading="false" @start-generating-draft="startRegeneration" @finish-regeneration="finishGeneration" @update:headingData="updateHeadingData('heading_lists', h2Index)">
                                    </DraftSection>
                                </div>

                                <div v-for="(heading3, h3Index) in writingPoint.heading_type_3" :key="h3Index" :id="`section-heading-${h2Index}-${h3Index}`" class="h3-element-wrapper justify-content-sm-between">
                                    <div class="h3-element heading-button btn d-flex align-items-center justify-content-between p-0" @click="heading3.collapsed = !heading3.collapsed">
                                        <div class="d-flex">
                                            <i class="arrow-icon bi" :class="{
                                                'bi-chevron-right': heading3.collapsed,
                                                'bi-chevron-down': !heading3.collapsed,
                                            }"></i>
                                            <span class="badge badge-light me-2">H3</span>
                                            <span class="heading-content">
                                                {{ Object.keys(heading3)[0] }}
                                            </span>
                                        </div>
                                        <div class="d-flex align-items-center text-gray-700 fs-7 me-2">
                                            <font-awesome-icon class="me-2" :icon="['far', 'file-lines']" />
                                            {{ getWordCount(heading3) }}
                                        </div>
                                    </div>

                                    <div class="mt-2" v-show="!heading3.collapsed">
                                        <div>
                                            <DraftSection :list-name="'heading_lists'" :h2-index="h2Index" :is-subheading="true" :h3-index="h3Index" @start-generating-draft="startRegeneration" @finish-generation="finishGeneration" @update:headingData="updateHeadingData('heading_lists', h2Index, h3Index)">
                                            </DraftSection>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="h2-element-wrapper d-flex justify-content-between" :id="`section-faq`">
                        <div class="d-flex flex-column flex-1">
                            <div class="d-flex flex-1 justify-content-between h2-element-box">
                                <button class="h2-element heading-button btn d-flex align-items-center p-0">
                                    <h3>{{ __('Frequently Asked Questions') }}</h3>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="h2-element-wrapper d-flex justify-content-between" v-for="(writingPoint, h2Index) in faqLists" :key="h2Index">
                        <div class="d-flex flex-column flex-1">
                            <div class="d-flex flex-1 justify-content-between h2-element-box">
                                <div class="h2-element heading-button btn d-flex align-items-center p-0" @click="writingPoint.collapsed = !writingPoint.collapsed">
                                    <i class="arrow-icon bi" :class="{
                                            'bi-chevron-right': writingPoint.collapsed,
                                            'bi-chevron-down': !writingPoint.collapsed,
                                        }"></i>

                                    <span class="badge badge-secondary me-2">H2</span>
                                    <span class="heading-content">
                                        {{ Object.keys(writingPoint.heading_type_2)[0] }}
                                    </span>
                                </div>
                            </div>

                            <div class="mt-2" v-show="!writingPoint.collapsed">
                                <div>
                                    <DraftSection :list-name="'faq_lists'" :h2-index="h2Index" :is-subheading="false" @start-generating-draft="startRegeneration" @update:headingData="updateHeadingData('faq_lists', h2Index)">
                                    </DraftSection>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="h2-element-wrapper d-flex justify-content-between" :id="`section-conclusion`">
                        <div class="d-flex flex-column flex-1">
                            <div class="d-flex flex-1 justify-content-between h2-element-box">
                                <button class="h2-element heading-button btn d-flex align-items-center p-0">
                                    <h3>{{ __('Conclusion') }}</h3>
                                </button>
                            </div>
                        </div>
                    </div>

                    <DraftSection :list-name="'conclusion'" :h2-index="0" :is-subheading="false" @start-generating-draft="startRegeneration" @update:headingData="updateHeadingData('conclusion', 0)">
                    </DraftSection>
                </div>
            </div>
        </div>
        <!--end::Input group-->
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import DraftSection from '@/Layouts/Article/Partials/Wizard/Guided/Partials/DraftSection.vue';

export default {
    props: {
        formality: String,
        lang: String,
    },

    components: {
        DraftSection,
    },

    setup() {
        const articleWizardStore = useArticleWizardStore();
        const toast = useToast();
        return { articleWizardStore, toast };
    },

    data() {
        return {
            finishedInitialLoading: false,
            renderLastComponent: false,
        };
    },

    computed: {
        headingLists() {
            return this.articleWizardStore.getAiGeneratedWritingPoints.filter(
                (writingPoints) => writingPoints.type == 'heading_lists'
            );
        },
        faqLists() {
            return this.articleWizardStore.getAiGeneratedWritingPoints.filter(
                (writingPoints) => writingPoints.type == 'faq_lists'
            );
        },
    },

    watch: {
        'articleWizardStore.currentStep': {
            handler(newVal) {
                let activeStep = this.articleWizardStore.getSerpBeater.uuid
                    ? 5
                    : 4;
                if (newVal === activeStep) {
                    this.fetchContentPieces();
                }
            },
            immediate: true,
        },
    },

    methods: {
        getWordCount(writingPoint) {
            if (!writingPoint) return 0;

            let content;
            if (writingPoint.heading_type_2) {
                // For H2 headings
                content = this.articleWizardStore.getArticleDraftContent.heading_lists?.find(
                    (item) => item.heading === Object.keys(writingPoint.heading_type_2)[0]
                )?.content;
            } else {
                // For H3 headings
                const h2Content = this.articleWizardStore.getArticleDraftContent.heading_lists?.find(
                    (item) => item.sub_headings?.some(
                        (sub) => sub.heading === Object.keys(writingPoint)[0]
                    )
                );

                content = h2Content?.sub_headings?.find(
                    (sub) => sub.heading === Object.keys(writingPoint)[0]
                )?.content;
            }

            if (!content) return 0;

            // Remove markdown formatting and count words
            const cleanText = content
                .replace(/[#*`_~]/g, '') // Remove markdown symbols
                .replace(/\n/g, ' ')     // Replace newlines with spaces
                .trim();                 // Remove extra whitespace

            return cleanText.split(/\s+/).length;
        },

        updateHeadingData(listName, h2Index, h3Index = null) {
            const draftSection = this.$refs[`draft-section-${listName}-${h2Index}-${h3Index !== null ? h3Index : ''}`];
            if (draftSection) {
                draftSection.updateDraftData();
            }
        },

        async fetchContentPieces() {
            // Only clear if there's no existing content
            if (!this.articleWizardStore.getArticleDraftContent.introduction.length &&
                !this.articleWizardStore.getArticleDraftContent.heading_lists.length) {
                this.articleWizardStore.clearArticleDraftContentSection();
            }

            // If there's existing content, don't fetch again
            if (this.articleWizardStore.getArticleDraftContent.introduction.length ||
                this.articleWizardStore.getArticleDraftContent.heading_lists.length) {
                return;
            }

            await this.fetchArticleDraftContents('add', { content: '' }, 'introduction');

            for (let i = 0; i < this.headingLists.length; i++) {
                await this.fetchArticleDraftContents('add', this.headingLists[i], 'heading_lists', i);

                if (this.headingLists[i].heading_type_3) {
                    for (let j = 0; j < this.headingLists[i].heading_type_3.length; j++) {
                        await this.fetchArticleDraftContents(
                            'add',
                            this.headingLists[i].heading_type_3[j],
                            'heading_lists',
                            i,
                            j
                        );
                    }
                }
            }

            // Only fetch FAQs and conclusion if we have headings
            if (this.headingLists.length > 0) {
                for (let i = 0; i < this.faqLists.length; i++) {
                    await this.fetchArticleDraftContents('add', this.faqLists[i], 'faq_lists', i);
                }

                await this.fetchArticleDraftContents('add', { content: '' }, 'conclusion');
            }
        },

        async fetchArticleDraftContents(action, writingPoint, type, h2Index = null, h3Index = null) {
            this.$emit('generating-draft', { loading: true, type: type });

            let requestUrl = '/api/article/ai-assistant/generate-draft-content';

            let writingPointOptions = {
                primary_keyword: writingPoint.primary_keyword || '',
                secondary_keywords: writingPoint.secondary_keywords || [],
                paragraph_length: writingPoint.paragraph_length || '100-150',
                num_sections: writingPoint.num_sections || 1,
                content_type: writingPoint.content_type || 'paragraph'
            };

            let payload = {
                article_title: this.articleWizardStore.getArticleTitle,
                lang: this.lang,
                formality: this.formality,
                section_heading: null,
                writing_points: null,
                previous_content: this.getTrimmedContent(this.articleWizardStore.getArticleDraftContent['heading_lists']),
            };

            payload = { ...payload, ...writingPointOptions };

            if (type === 'introduction' || type === 'conclusion') {
                payload.section_heading = type === 'introduction' ? 'Introduction' : 'Conclusion';
                payload.writing_points = type === 'introduction' ? ['An introduction'] : ['A conclusion'];
                h2Index = 0;

                requestUrl = type === 'introduction' ? '/api/article/ai-assistant/generate-introduction' : '/api/article/ai-assistant/generate-conclusion';
            } else {
                payload.section_heading = h3Index != null ? Object.keys(writingPoint)[0] : Object.keys(writingPoint.heading_type_2)[0];
                payload.writing_points = h3Index != null ? Object.values(writingPoint)[0] : Object.values(writingPoint.heading_type_2)[0];
            }

            let responseText = '';

            await axios
                .post(requestUrl, payload)
                .then(async (response) => {
                    responseText = response.data.draft_contents[0].text;
                })
                .catch((error) => {
                    this.toast.error(this.__('Some error occurred'));
                });

            writingPoint.content = responseText;
            writingPoint = { ...writingPoint, ...writingPointOptions };

            if (action === 'add') {
                this.addArticleDraftContent(writingPoint, type, h2Index, h3Index);
            } else if (action === 'update') {
                this.updateArticleDraftContent(writingPoint, type, h2Index, h3Index);
            }

            emitter.emit('finish-generation', {
                listName: type,
                h2Index: h2Index,
                h3Index: h3Index,
                isSubheading: h3Index != null,
            });

            this.$emit('generating-draft', { loading: false, type: type });
            writingPoint.loadingDraftPoints = false;
        },

        async startRegeneration(listName, h2Index, h3Index, isSubheading) {
            if (isSubheading) {
                await this.fetchArticleDraftContents('update', this.headingLists[h2Index].heading_type_3[h3Index], listName, h2Index, h3Index);
            } else {
                await this.fetchArticleDraftContents('update', this.headingLists[h2Index], listName, h2Index);
            }

            emitter.emit('finish-generation', listName, h2Index, h3Index, isSubheading);
        },

        finishGeneration() {
            this.$emit('regeneration-finished');
        },

        addArticleDraftContent(writingPoint, type, h2Index, h3Index) {
            if (type === 'introduction' || type === 'conclusion') {
                let heading = type === 'introduction' ? this.__('Introduction') : this.__('Conclusion');
                this.articleWizardStore.addToArticleDraftContent(
                    {
                        heading: heading,
                        content: writingPoint.content,
                        paragraph_length: writingPoint.paragraph_length,
                        num_sections: writingPoint.num_sections,
                        content_type: writingPoint.content_type,
                        primary_keyword: writingPoint.primary_keyword,
                        secondary_keywords: writingPoint.secondary_keywords,
                    },
                    type,
                    0
                );
            } else {
                let heading = h3Index != null ? Object.keys(writingPoint)[0] : Object.keys(writingPoint.heading_type_2)[0];
                this.articleWizardStore.addToArticleDraftContent(
                    {
                        heading: heading,
                        content: writingPoint.content,
                        paragraph_length: writingPoint.paragraph_length,
                        num_sections: writingPoint.num_sections,
                        content_type: writingPoint.content_type,
                        primary_keyword: writingPoint.primary_keyword,
                        secondary_keywords: writingPoint.secondary_keywords,
                    },
                    type,
                    h2Index,
                    h3Index
                );
            }
        },

        updateArticleDraftContent(writingPoint, type, h2Index, h3Index) {
            if (type === 'introduction' || type === 'conclusion') {
                let heading = type === 'introduction' ? this.__('Introduction') : this.__('Conclusion');
                this.articleWizardStore.updateArticleDraftContent(
                    {
                        heading: heading,
                        content: writingPoint.content,
                        paragraph_length: writingPoint.paragraph_length,
                        num_sections: writingPoint.num_sections,
                        content_type: writingPoint.content_type,
                        primary_keyword: writingPoint.primary_keyword,
                        secondary_keywords: writingPoint.secondary_keywords,
                    },
                    type,
                    0
                );
            } else {
                let heading = h3Index != null ? Object.keys(writingPoint)[0] : Object.keys(writingPoint.heading_type_2)[0];
                this.articleWizardStore.updateArticleDraftContent(
                    {
                        heading: heading,
                        content: writingPoint.content,
                        paragraph_length: writingPoint.paragraph_length,
                        num_sections: writingPoint.num_sections,
                        content_type: writingPoint.content_type,
                        primary_keyword: writingPoint.primary_keyword,
                        secondary_keywords: writingPoint.secondary_keywords,
                    },
                    type,
                    h2Index,
                    h3Index
                );
            }
        },

        getTrimmedContent(contentArray) {
            if (!contentArray || !Array.isArray(contentArray)) return '';

            let content = '';
            contentArray.forEach((data) => {
                content += `## ${data.heading}\n${data.content}\n`;
                data.sub_headings.forEach((sub_data) => {
                    content += `### ${sub_data.heading}\n${sub_data.content}\n`;
                });
            });

            return content;
        },
    },
};
</script>

<style lang="scss">
.word-count {
    display: inline-block;
    margin-right: 10px;
}
</style>
