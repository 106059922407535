<template>
    <div>
        <div v-if="userPermissions.canAddTeamMembers && canAddTeamMembers">
            <jet-section-border />

            <!-- Add Team Member -->
            <jet-form-section @submitted="addTeamMember">
                <template #title>
                    {{ __('Add Team Member') }}
                </template>

                <template #description>
                    {{
                        __(
                            'Add a new team member to your team, allowing them to collaborate with you.'
                        )
                    }}
                </template>

                <template #form>
                    <jet-action-message
                        :on="addTeamMemberForm.recentlySuccessful"
                    >
                        {{ __('Added') }}.
                    </jet-action-message>

                    <div class="mb-3">
                        {{
                            __(
                                'Please provide the email address of the person you would like to add to this team. The email address must be associated with an existing account.'
                            )
                        }}
                    </div>

                    <!-- Member Email -->
                    <div class="w-75">
                        <div class="form-group">
                            <jet-label for="email" value="Email" />
                            <jet-input
                                id="name"
                                type="text"
                                v-model="addTeamMemberForm.email"
                                :class="{
                                    'is-invalid':
                                        addTeamMemberForm.errors.email,
                                }"
                            />
                            <jet-input-error
                                :message="addTeamMemberForm.errors.email"
                            />
                        </div>
                    </div>

                    <!-- Role -->
                    <div class="my-3 w-75" v-if="availableRoles.length > 0">
                        <jet-label for="roles" value="Role" />

                        <input
                            type="hidden"
                            :class="{
                                'is-invalid': addTeamMemberForm.errors.role,
                            }"
                        />
                        <jet-input-error
                            :message="addTeamMemberForm.errors.role"
                        />

                        <div class="list-group">
                            <a
                                href="#"
                                class="list-group-item list-group-item-action"
                                :class="{
                                    'text-black-50':
                                        addTeamMemberForm.role &&
                                        addTeamMemberForm.role != role.key,
                                }"
                                @click.prevent="
                                    addTeamMemberForm.role = role.key
                                "
                                v-for="(role, i) in availableRoles"
                                :key="role.key"
                            >
                                <div>
                                    <span
                                        :class="{
                                            'font-weight-bold':
                                                addTeamMemberForm.role ==
                                                role.key,
                                        }"
                                    >
                                        {{ role.name }}
                                    </span>

                                    <svg
                                        v-if="
                                            addTeamMemberForm.role == role.key
                                        "
                                        class="ms-1 text-success font-weight-light"
                                        width="20"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                    </svg>
                                </div>

                                <!-- Role Description -->
                                <div class="mt-2">
                                    {{ role.description }}
                                </div>
                            </a>
                        </div>
                    </div>
                </template>

                <template #actions>
                    <jet-button
                        :class="{
                            'text-white-50': addTeamMemberForm.processing,
                        }"
                        :disabled="addTeamMemberForm.processing"
                    >
                        <div
                            v-show="addTeamMemberForm.processing"
                            class="spinner-border spinner-border-sm"
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>

                        {{ __('Add') }}
                    </jet-button>
                </template>
            </jet-form-section>
        </div>

        <div
            v-if="
                team.team_invitations.length > 0 &&
                userPermissions.canAddTeamMembers
            "
        >
            <jet-section-border />

            <!-- Team Member Invitations -->
            <jet-action-section>
                <template #title>
                    {{ __('Pending Team Invitations') }}
                </template>

                <template #description>
                    {{
                        __(
                            'These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.'
                        )
                    }}
                </template>

                <!-- Pending Team Member Invitation List -->
                <template #content>
                    <div
                        class="d-flex justify-content-between"
                        v-for="invitation in team.team_invitations"
                        :key="invitation.id"
                    >
                        <div>{{ invitation.email }}</div>

                        <div>
                            <!-- Cancel Team Invitation -->
                            <button
                                class="btn btn-link text-danger"
                                @click="cancelTeamInvitation(invitation)"
                                v-if="userPermissions.canRemoveTeamMembers"
                            >
                                {{ __('Cancel') }}
                            </button>
                        </div>
                    </div>
                </template>
            </jet-action-section>
        </div>

        <div v-if="team.users.length > 0">
            <jet-section-border />

            <!-- Manage Team Members -->
            <jet-action-section>
                <template #title>
                    {{ __('Team Members') }}
                </template>

                <template #description>
                    {{ __('All of the people that are part of this team.') }}
                </template>

                <!-- Team Member List -->
                <template #content>
                    <div
                        class="d-flex justify-content-between mb-3"
                        v-for="user in team.users"
                        :key="user.id"
                    >
                        <div
                            class="d-flex justify-content-start align-items-center"
                        >
                            <div class="pr-3 me-4">
                                <img
                                    width="32"
                                    class="rounded-circle"
                                    :src="user.profile_photo_url"
                                    :alt="user.name"
                                />
                            </div>
                            <span>{{ user.name }}</span>
                        </div>

                        <div class="d-flex">
                            <!-- Manage Team Member Role -->
                            <button
                                class="btn btn-sm btn-light btn-active-primary me-2"
                                v-if="
                                    userPermissions.canAddTeamMembers &&
                                    availableRoles.length > 0
                                "
                                @click="manageRole(user)"
                            >
                                {{ displayableRole(user.membership.role) }}
                            </button>

                            <div
                                class="btn btn-sm text-secondary text-muted cursor-default me-2"
                                v-else-if="availableRoles.length > 0"
                            >
                                {{ displayableRole(user.membership.role) }}
                            </div>

                            <!-- Leave Team -->
                            <button
                                class="btn btn-sm btn-light-danger"
                                @click="confirmLeavingTeam"
                                v-if="$page.props.user.id === user.id"
                            >
                                {{ __('Leave') }}
                            </button>

                            <!-- Remove Team Member -->
                            <button
                                class="btn btn-sm btn-light-danger"
                                @click="confirmTeamMemberRemoval(user)"
                                v-if="userPermissions.canRemoveTeamMembers"
                            >
                                {{ __('Remove') }}
                            </button>
                        </div>
                    </div>
                </template>
            </jet-action-section>
        </div>

        <!-- Role Management Modal -->
        <jet-dialog-modal id="currentlyManagingRoleModal">
            <template #title>
                {{ __('Manage Role') }}
            </template>

            <template #content>
                <div class="list-group" v-if="managingRoleFor">
                    <a
                        href="#"
                        class="list-group-item list-group-item-action"
                        :class="{
                            'text-black-50':
                                updateRoleForm.role &&
                                updateRoleForm.role != role.key,
                        }"
                        @click.prevent="updateRoleForm.role = role.key"
                        v-for="(role, i) in availableRoles"
                        :key="role.key"
                    >
                        <div>
                            <span
                                :class="{
                                    'font-weight-bold':
                                        updateRoleForm.role == role.key,
                                }"
                            >
                                {{ role.name }}
                            </span>

                            <svg
                                v-if="updateRoleForm.role == role.key"
                                class="ms-1 text-success font-weight-light"
                                width="20"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                            </svg>
                        </div>

                        <!-- Role Description -->
                        <div class="mt-2">
                            {{ role.description }}
                        </div>
                    </a>
                </div>
            </template>

            <template #footer>
                <jet-secondary-button data-bs-dismiss="modal">
                    {{ __('Cancel') }}
                </jet-secondary-button>

                <jet-button
                    class="ms-2"
                    @click="updateRole"
                    :class="{ 'text-black-50': updateRoleForm.processing }"
                    :disabled="updateRoleForm.processing"
                >
                    <div
                        v-show="updateRoleForm.processing"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    >
                        <span class="visually-hidden">Loading...</span>
                    </div>

                    {{ __('Save') }}
                </jet-button>
            </template>
        </jet-dialog-modal>

        <!-- Leave Team Confirmation Modal -->
        <jet-confirmation-modal id="confirmingLeavingTeamModal">
            <template #title>
                {{ __('Leave Team') }}
            </template>

            <template #content>
                {{ __('Are you sure you would like to leave this team?') }}
            </template>

            <template #footer>
                <jet-secondary-button data-bs-dismiss="modal">
                    {{ __('Cancel') }}
                </jet-secondary-button>

                <jet-danger-button
                    class="ms-2"
                    @click="leaveTeam"
                    :class="{ 'text-white-50': leaveTeamForm.processing }"
                    :disabled="leaveTeamForm.processing"
                >
                    <div
                        v-show="leaveTeamForm.processing"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    >
                        <span class="visually-hidden">Loading...</span>
                    </div>

                    {{ __('Leave') }}
                </jet-danger-button>
            </template>
        </jet-confirmation-modal>

        <!-- Remove Team Member Confirmation Modal -->
        <jet-confirmation-modal id="teamMemberBeingRemovedModal">
            <template #title>
                {{ __('Remove Team Member') }}
            </template>

            <template #content>
                {{
                    __(
                        'Are you sure you would like to remove this person from the team?'
                    )
                }}
            </template>

            <template #footer>
                <jet-secondary-button data-bs-dismiss="modal">
                    {{ __('Cancel') }}
                </jet-secondary-button>

                <jet-danger-button
                    class="ms-2"
                    @click="removeTeamMember"
                    :class="{
                        'text-white-50': removeTeamMemberForm.processing,
                    }"
                    :disabled="removeTeamMemberForm.processing"
                >
                    <div
                        v-show="removeTeamMemberForm.processing"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    >
                        <span class="visually-hidden">Loading...</span>
                    </div>

                    {{ __('Remove') }}
                </jet-danger-button>
            </template>
        </jet-confirmation-modal>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useForm } from '@inertiajs/vue3';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetActionSection from '@/Jetstream/ActionSection.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetDialogModal from '@/Jetstream/DialogModal.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue';
import JetSectionBorder from '@/Jetstream/SectionBorder.vue';
import { Modal } from 'bootstrap';

export default defineComponent({
    components: {
        JetActionMessage,
        JetActionSection,
        JetButton,
        JetConfirmationModal,
        JetDangerButton,
        JetDialogModal,
        JetFormSection,
        JetInput,
        JetInputError,
        JetLabel,
        JetSecondaryButton,
        JetSectionBorder,
    },

    props: ['team', 'availableRoles', 'userPermissions'],

    setup() {
        const addTeamMemberForm = useForm({
            email: '',
            role: null,
        });

        const updateRoleForm = useForm({
            role: null,
        });

        const leaveTeamForm = useForm({});
        const removeTeamMemberForm = useForm({});

        return {
            addTeamMemberForm,
            updateRoleForm,
            leaveTeamForm,
            removeTeamMemberForm,
        };
    },

    data() {
        return {
            currentlyManagingRole: false,
            managingRoleFor: null,
            confirmingLeavingTeam: false,
            teamMemberBeingRemoved: null,
            modal: null,
        };
    },

    computed: {
        maxMembers() {
            return this.team?.max_members || 0;
        },
        extraSeats() {
            return this.team?.extra_seats || 0;
        },
        totalSeats() {
            return this.maxMembers + this.extraSeats;
        },
        pendingInvitations() {
            return this.team?.team_invitations?.length || 0;
        },
        occupiedSeats() {
            return this.team?.users?.length || 0;
        },
        availableSeats() {
            return this.occupiedSeats + this.pendingInvitations;
        },
        canAddTeamMembers() {
            return this.totalSeats > this.availableSeats;
        },
    },

    methods: {
        addTeamMember() {
            this.addTeamMemberForm.post(
                route('team-members.store', this.team),
                {
                    errorBag: 'addTeamMember',
                    preserveScroll: true,
                    onSuccess: () => this.addTeamMemberForm.reset(),
                }
            );
        },

        cancelTeamInvitation(invitation) {
            this.$inertia.delete(
                route('team-invitations.destroy', invitation),
                {
                    preserveScroll: true,
                }
            );
        },

        manageRole(teamMember) {
            this.managingRoleFor = teamMember;
            this.updateRoleForm.role = teamMember.membership.role;
            this.currentlyManagingRole = true;

            let el = document.querySelector('#currentlyManagingRoleModal');
            this.modal = new Modal(el);
            this.modal.toggle();
        },

        updateRole() {
            this.updateRoleForm.put(
                route('team-members.update', [this.team, this.managingRoleFor]),
                {
                    preserveScroll: true,
                    onSuccess: () => (this.currentlyManagingRole = false),
                }
            );
        },

        confirmLeavingTeam() {
            this.confirmingLeavingTeam = true;

            let el = document.querySelector('#confirmingLeavingTeamModal');
            this.modal = new Modal(el);
            this.modal.toggle();
        },

        leaveTeam() {
            this.leaveTeamForm
                .delete(
                    route('team-members.destroy', [this.team, this.$page.user])
                )
                .then(() => {
                    this.modal.toggle();
                });
        },

        confirmTeamMemberRemoval(teamMember) {
            this.teamMemberBeingRemoved = teamMember;

            let el = document.querySelector('#teamMemberBeingRemovedModal');
            this.modal = new Modal(el);
            this.modal.toggle();
        },

        removeTeamMember() {
            this.removeTeamMemberForm.delete(
                route('team-members.destroy', [
                    this.team,
                    this.teamMemberBeingRemoved,
                ]),
                {
                    preserveScroll: true,
                    preserveState: true,
                }
            );
            this.teamMemberBeingRemoved = null;
            this.modal.toggle();
        },

        displayableRole(role) {
            return this.availableRoles.find((r) => r.key === role).name;
        },
    },
});
</script>
