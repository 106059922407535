<template>
    <!--begin::Items-->
    <div v-if="!loading" class="scroll-y mh-325px my-5 px-8">
        <div v-if="notifications.length === 0">
            {{ __('No notifications yet') }}
        </div>
        <!--begin::Item-->
        <div
            v-for="notification in notifications"
            :key="notification.id"
            class="d-flex flex-stack py-4"
        >
            <!--begin::Section-->
            <div class="d-flex align-items-center flex-1">
                <!--begin::Symbol-->
                <div class="symbol symbol-35px me-4">
                    <DynamicIcons
                        :icon="notification.data.symbol"
                    ></DynamicIcons>
                    <span
                        v-if="!notification.read_at"
                        class="notification-new-mark bullet bullet-dot bg-success position-absolute top-0 end-0"
                    ></span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="d-flex flex-column mb-0 me-4 flex-1">
                    <div class="d-flex justify-content-between">
                        <a
                            href="#"
                            class="fs-6 text-gray-800 text-hover-primary"
                            @click.prevent="showNotificationModal(notification)"
                            :class="notification.read_at ? '' : 'fw-bolder'"
                        >
                            <label class="cursor-pointer">
                                {{ getTitle(notification.data.title) }}
                            </label>
                        </a>
                    </div>
                    <div class="text-gray-400 fs-7">
                        {{ getSubtitle(notification.data.sub_title) }}
                    </div>
                    <div class="d-flex mt-2">
                        <!--begin::Label-->
                        <span class="badge badge-light fs-8">{{
                            age(notification.created_at)
                        }}</span>
                        <!--end::Label-->
                    </div>
                </div>
                <!--end::Title-->
            </div>
            <!--end::Section-->
        </div>
        <!--end::Item-->
    </div>
    <!--end::Items-->

    <!--begin::View more-->
    <div class="py-3 text-center border-top inner-clickable-link">
        <Link
            href="/notifications"
            class="btn btn-color-gray-600 btn-active-color-primary inner-clickable-link"
        >
            {{ __('View all notifications') }}
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
            <span class="svg-icon svg-icon-5">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <rect
                        opacity="0.5"
                        x="18"
                        y="13"
                        width="13"
                        height="2"
                        rx="1"
                        transform="rotate(-180 18 13)"
                        fill="black"
                    />
                    <path
                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                        fill="black"
                    />
                </svg>
            </span>
            <!--end::Svg Icon-->
        </Link>
    </div>
    <!--end::View more-->
</template>

<script>
import DynamicIcons from '@/Components/Icons/DynamicIcons.vue';
import {router as Inertia} from '@inertiajs/vue3';
import moment from 'moment';
import { Link } from '@inertiajs/vue3';

export default {
    components: {
        DynamicIcons,
        Link,
    },
    props: {
        user: Object,
        type: {
            type: String,
        },
    },
    data() {
        return {
            loading: false,
            language: this.user?.user_info?.language
                ? this.user.user_info.language
                : 'en',
            notifications: [],
        };
    },
    beforeMount() {
        moment.locale(this.language);
    },
    mounted() {
        let vm = this;

        window.Echo.private('creaitor-notifications').listen(
            'BroadcastAdminNotification',
            (e) => {
                this.getNotifications();
            }
        );

        this.getNotifications();

        emitter.on('new-user-notification', function () {
            vm.getNotifications();
        });
    },
    methods: {
        getTitle(title) {
            try {
                return title[this.language]
                    ? title[this.language]
                    : this.__('No title');
            } catch (e) {
                return title ? title : this.__('No title');
            }
        },
        getSubtitle(subtitle) {
            try {
                return subtitle[this.language]
                    ? subtitle[this.language]
                    : this.__('No title');
            } catch (e) {
                return subtitle ? subtitle : this.__('No subtitle');
            }
        },
        age(created) {
            return created ? moment(created).fromNow() : this.__('now');
        },
        showNotificationModal(notification) {
            if (notification.data?.view_url) {
                Inertia.visit(notification.data.view_url);
                return false;
            }

            Inertia.reload({ only: ['user'] });
            emitter.emit('new-user-notification');
            emitter.emit('open-notification-detail-modal', notification.id);
        },
        getNotifications() {
            let vm = this;

            axios
                .get(`/api/notifications/?range-from=year&per-page=5`)
                .then((response) => {
                    vm.notifications = response.data.data;
                })
                .catch((error) => {
                    vm.notifications = [];
                });
        },
    },
};
</script>
<style lang="scss">
.notification-new-mark {
    margin-right: 4px;
    margin-top: 4px;
}
</style>
