<template>
    <!--begin::Modal - Edit Brand Identity-->
    <div
        class="modal fade"
        id="kt_modal_create_brand_identity"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="kt_modal_create_brand_identity"
        aria-hidden="true"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header pb-0 border-0 justify-content-end">
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                    <!--begin:Form-->
                    <form
                        id="kt_modal_create_brand_identity_form"
                        class="form"
                        action="#"
                        @submit.prevent="sendSubmission"
                    >
                        <!--begin::Heading-->
                        <div class="text-center">
                            <!--begin::Title-->
                            <h1>
                                {{ __('Create Brand Identity') }}
                            </h1>
                            <!--end::Title-->
                        </div>
                        <!--end::Heading-->

                        <BrandIdentity></BrandIdentity>

                        <!--begin::Actions-->
                        <div class="text-center">
                            <button
                                type="reset"
                                id="kt_modal_create_brand_identity_cancel"
                                class="btn btn-light me-3"
                                @click="closeModal"
                            >
                                {{ __('Cancel') }}
                            </button>
                            <button
                                type="submit"
                                id="kt_modal_create_brand_identity_submit"
                                class="btn btn-primary"
                            >
                                <span v-if="!loading" class="indicator-label">
                                    {{ __('Create') }}
                                </span>
                                <span v-else class="indicator-progress d-block">
                                    {{ __('Please wait...') }}
                                    <span
                                        class="spinner-border spinner-border-sm align-middle ms-2"
                                    >
                                    </span>
                                </span>
                            </button>
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end:Form-->
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { useOnboardProcessStore } from '@/Stores/OnboardProcessStore';
import { useToast } from 'vue-toastification';
import BrandIdentity from '@/Pages/Onboard/Partials/BrandIdentity.vue';
import { Modal } from 'bootstrap';

export default {
    components: {
        BrandIdentity,
    },

    emits: ['created'],

    setup() {
        // Get onboardProcess store
        const onboardProcessStore = useOnboardProcessStore();

        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return {
            onboardProcessStore,
            toast,
        };
    },

    data() {
        return {
            brandIdentityCreateModal: {},
            loading: false,
        };
    },

    mounted() {
        this.initilizeModal();
    },

    methods: {
        initilizeModal() {
            let modalElement = document.getElementById(
                'kt_modal_create_brand_identity'
            );
            this.brandIdentityCreateModal = new Modal(modalElement);
        },
        sendSubmission() {
            let vm = this;
            this.loading = true;

            axios
                .post(
                    route('brand-identities.store'),
                    this.onboardProcessStore.getBrandIdentity
                )
                .then((response) => {
                    vm.$emit('created');
                    vm.toast.success(vm.__('Brand voice created'));
                    vm.closeModal();
                })
                .catch((error) => {
                    let errorMessage =
                        error.response?.data?.message ||
                        vm.__('Some error occurred');

                    if (422 == error.response.status) {
                        for (let key in error.response.data.errors) {
                            try {
                                vm.onboardProcessStore.setFormErrors(
                                    `brand_voice.${key}`,
                                    error.response.data.errors[key]
                                );
                            } catch (error) {
                                console.error(
                                    `Error while assigning form error property : ${key}`
                                );
                            }
                        }
                    }

                    vm.toast.error(errorMessage);
                })
                .then(() => {
                    vm.loading = false;
                });
        },
        closeModal() {
            this.brandIdentityCreateModal.hide();
        },
    },
};
</script>
<style lang="scss">
#kt_modal_create_brand_identity_form {
    .multiselect {
        padding: 7px 0;
    }
    .pip-slider.slider {
        height: 10px;
        margin: 0 auto;
        width: 85% !important;
    }
    h2 {
        display: none;
    }
    .noUi-handle {
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        background: #fff;
        cursor: default;
        box-shadow:
            inset 0 0 1px #fff,
            inset 0 1px 7px #ebebeb,
            0 3px 6px -3px #bbb;
        width: 34px;
        height: 28px;
        right: -17px;
        top: -12px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            height: 14px;
            width: 1px;
            background: #e8e7e6;
            left: 14px;
            top: 6px;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            height: 14px;
            width: 1px;
            background: #e8e7e6;
            left: 17px;
            top: 6px;
        }
    }
}
</style>
