<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_buy_more_keyword_research_credits"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered modal-md">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header pb-0 border-0 justify-content-end">
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body pt-0 pb-15 px-5 px-xl-20">
                    <!--begin::Heading-->
                    <div class="mb-5 text-center">
                        <h1 class="mb-3">
                            {{ __('Choose a credit package') }}
                        </h1>
                    </div>

                    <div v-if="!loading" class="d-flex flex-column">
                        <!--begin::Plans-->
                        <div class="d-flex flex-column">
                            <!--begin::Row-->
                            <div class="row mt-10">
                                <!--begin::Col-->
                                <div class="col-lg-12 mb-10 mb-lg-0">
                                    <!--begin::Tabs-->
                                    <div class="nav flex-column">
                                        <!--begin::Tab link-->
                                        <div
                                            class="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary custom-bonus-credit d-flex flex-stack text-start p-6 mb-6"
                                            :class="{
                                                active:
                                                    option.is_default ||
                                                    index === 0,
                                            }"
                                            @click="
                                                selectPlan(option.product_id)
                                            "
                                            data-bs-toggle="tab"
                                            v-for="(
                                                option, index
                                            ) in availableCreditOptions"
                                            :key="index"
                                        >
                                            <!--end::Description-->

                                            <div
                                                class="d-flex align-items-center me-2"
                                            >
                                                <!--begin::Radio-->
                                                <div
                                                    class="form-check form-check-custom form-check-solid form-check-success me-6"
                                                >
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="credit-plan"
                                                        :id="`product-${index}`"
                                                        v-if="
                                                            option.is_default ||
                                                            index === 0
                                                        "
                                                        checked="checked"
                                                        :value="
                                                            option.price.net
                                                        "
                                                    />
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="credit-plan"
                                                        :id="`product-${index}`"
                                                        v-else
                                                        :value="
                                                            option.price.net
                                                        "
                                                    />
                                                </div>
                                                <!--end::Radio-->
                                                <!--begin::Info-->
                                                <label
                                                    class="flex-grow-1 custom-bonus-credit-label"
                                                    :for="`product-${index}`"
                                                >
                                                    <span
                                                        class="fs-3 fw-bolder m-0"
                                                    >
                                                        {{
                                                            __(
                                                                option.product_title
                                                            )
                                                        }}
                                                    </span>
                                                </label>
                                                <!--end::Info-->
                                            </div>
                                            <!--end::Description-->
                                            <!--begin::Price-->
                                            <div class="ms-5">
                                                <span class="mb-2">{{
                                                    getCurrencySymbol(
                                                        option.currency
                                                    ) || '$'
                                                }}</span>
                                                <span class="fs-3x fw-bolder">{{
                                                    option.price.net
                                                }}</span>
                                            </div>
                                            <!--end::Price-->
                                        </div>
                                        <!--end::Tab link-->
                                    </div>
                                    <!--end::Tabs-->
                                </div>
                                <!--end::Col-->
                            </div>
                            <!--end::Row-->
                        </div>
                        <!--end::Plans-->

                        <!--begin::Actions-->
                        <div class="d-flex flex-center flex-row-fluid pt-6">
                            <button
                                type="reset"
                                class="btn btn-light me-3"
                                data-bs-dismiss="modal"
                            >
                                {{ __('Cancel') }}
                            </button>
                            <button
                                @click="buyMoreCredits"
                                type="submit"
                                class="btn btn-primary"
                            >
                                {{ __('Buy credits') }}
                            </button>
                        </div>
                        <!--end::Actions-->
                    </div>
                    <div v-else class="d-flex flex-column">
                        <div
                            class="d-flex align-items-center justify-content-center p-5"
                        >
                            <div
                                class="spinner-border spinner-border text-primary"
                                role="status"
                            >
                                <span class="visually-hidden">{{
                                    __('Loading...')
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import { useToast } from 'vue-toastification';
import { defineComponent } from 'vue';
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Modal } from 'bootstrap';

export default defineComponent({
    setup() {
        let vm = this;
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast };
    },
    props: {
        user: Object,
    },
    data() {
        return {
            availableCreditOptions: [],
            products: this.getPaddleProducts('keyword-research-credits'),
            productId: null,
            currentProductId: null,
            billableId: this.user.current_team_id,
            billableType: 'team',
            modal: {},
            loading: false,
            purchaseToken: null,
        };
    },
    watch: {
        process: function (val) {
            console.log('changed');
            console.log(val);
        },
    },
    mounted() {
        this.fetchProducts();

        let vm = this;
        emitter.on('open-buy-more-keyword-research-credits', function (data) {
            vm.modal.show();
        });

        this.modal = new Modal(
            document.getElementById(
                'kt_modal_buy_more_keyword_research_credits'
            )
        );
    },
    beforeUnmount() {
        // eslint-disable-next-line no-undef
        emitter.off('open-buy-more-keyword-research-credits');
    },
    methods: {
        /**
         * Get the Paddle products for the given product type.
         */
        async fetchProducts() {
            this.loading = true;

            try {
                // Fetching products price
                const productsResponse = await axios.post(
                    `/api/prices/get-products`,
                    {
                        product_ids: Object.values(this.products),
                    }
                );

                this.availableCreditOptions = productsResponse.data;
                this.productId = this.availableCreditOptions[0].product_id;
            } catch (error) {
                console.error('Failed to fetch product details:', error);
            }

            this.loading = false;
        },

        /**
         * Get the currency symbol for the given currency.
         */
        getCurrencySymbol(currency) {
            return getSymbolFromCurrency(currency) || '$';
        },

        /**
         * Subscribe to the given plan.
         */
        buyMoreCredits() {
            let vm = this;
            Paddle.Spinner.show();

            let optedCreditOption = vm.availableCreditOptions.filter(
                (option) => option && option.product_id === vm.productId
            );

            if (0 === optedCreditOption.length) {
                Paddle.Spinner.hide();
                return false;
            }

            window.history.pushState(
                {},
                document.title,
                window.location.pathname + '?credit_plan=' + vm.productId
            );

            if (this.$page.props.user.current_team.subscriptions.length > 0) {
                this.request('POST', '/api/get-more-credits-payment-link', {
                    product_id: vm.productId,
                }).then((response) => {
                    if (response.data.status === 'success') {
                        vm.request('POST', '/api/update-team-bonus-credits', {
                            team_id: vm.billableId,
                            product_id: vm.productId,
                            credit_type: 'keyword_research_credits',
                            amount: response.data.amount,
                            currency: response.data.currency,
                            token: response.data.token,
                        }).then((response) => {
                            vm.reloadData();
                            vm.modal.hide();
                            Paddle.Spinner.hide();
                            emitter.emit('update-credits');
                            emitter.emit(
                                'buy-more-keyword-research-credits-successful-finished'
                            );
                        });
                    } else {
                        alert(
                            vm.__(
                                'Something went wrong. Please try again later.'
                            )
                        );
                        vm.reloadData();
                        vm.modal.hide();
                    }
                });
            } else {
                let vm = this;

                if (this.$page.props.paddle_sandbox) {
                    // eslint-disable-next-line no-undef
                    Paddle.Environment.set('sandbox');
                }
                // eslint-disable-next-line no-undef
                Paddle.Update({
                    eventCallback: function (event) {
                        switch (event.name) {
                            case 'checkout.completed':
                                vm.paddleCreditPurchaseCheckoutCompleted(event);
                                break;
                            case 'checkout.closed':
                                vm.paddleCreditPurchaseCheckoutClosed();
                                break;
                        }
                    },
                });
                this.request(
                    'POST',
                    '/api/get-more-credits-without-subscription-payment-link',
                    {
                        product_id: vm.productId,
                    }
                ).then((response) => {
                    vm.purchaseToken = response.data?.token;
                    // eslint-disable-next-line no-undef
                    Paddle.Checkout.open({
                        items: response.data.checkout.items,
                        customer: response.data.checkout.customer,
                        settings: {
                            allowLogout: false,
                            displayMode: 'overlay',
                        },
                    });
                });
            }
        },

        paddleCreditPurchaseCheckoutCompleted(event) {
            let vm = this;
            vm.$page.props.state = 'pending';

            window.history.pushState(
                {},
                document.title,
                window.location.pathname
            );

            vm.request('POST', '/spark/pending-checkout', {
                checkout_id: event.data.id,
            }).then(() => {
                vm.request('POST', '/api/update-team-bonus-credits', {
                    team_id: vm.billableId,
                    product_id: vm.productId,
                    credit_type: 'keyword_research_credits',
                    token: vm.purchaseToken,
                }).then(() => {
                    vm.reloadData();
                    vm.modal.hide();
                    // eslint-disable-next-line no-undef
                    Paddle.Spinner.hide();
                    // eslint-disable-next-line no-undef
                    Paddle.Checkout.close();
                    // eslint-disable-next-line no-undef
                    emitter.emit('update-credits');
                    // eslint-disable-next-line no-undef
                    emitter.emit(
                        'buy-more-keyword-research-credits-successful-finished'
                    );
                });
            });
        },

        paddleCreditPurchaseCheckoutClosed() {
            window.history.pushState(
                {},
                document.title,
                window.location.pathname
            );
        },

        /**
         * Make an outgoing request to the Laravel application.
         */
        request(method, url, data = {}) {
            let vm = this;
            this.errors = [];

            data.billableType = this.billableType.toString();
            data.billableId = this.billableId.toString();

            return axios
                .request({
                    url: url,
                    method: method,
                    data: data,
                })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    Paddle.Spinner.hide();

                    if (error.response.status == 422) {
                        this.errors = _.flatMap(error.response.data.errors);
                    } else {
                        this.errors = [this.__(error?.response?.data?.error)];
                    }

                    vm.toast.error(this.errors[this.errors.length - 1]);
                });
        },

        /**
         * Start periodically reloading the page's data.
         */
        startReloadingData() {
            setTimeout(() => {
                this.reloadData();
            }, 2000);
        },

        /**
         * Reload the page's data, while maintaining any current state.
         */
        reloadData() {
            return this.$inertia.reload({
                onSuccess: () => {
                    if (this.$page.props.state == 'pending') {
                        this.startReloadingData();
                    }

                    if (this.selectingNewPlan) {
                        this.selectingNewPlan = false;
                    }

                    Paddle.Spinner.hide();
                },
            });
        },

        selectPlan(plan) {
            this.productId = plan;
        },
    },
});
</script>
