<template>
    <div class="w-100">
        <!--begin::Heading-->
        <ul
            class="nav nav-tabs article-title-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-5"
        >
            <li class="nav-item">
                <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_top_ranked_outlines"
                >
                    <h2 class="mb-0">{{ __('Top Ranked Outlines') }}</h2>
                </a>
            </li>
            <li
                class="nav-item d-none"
                v-if="articleWizardStore.getTopRankedOutlines.length"
            >
                <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_faq"
                >
                    <h2 class="mb-0">{{ __('FAQs') }}</h2>
                </a>
            </li>
        </ul>

        <div class="tab-content" id="serpBeaterWizardTabContents">
            <div
                class="tab-pane fade active show"
                id="kt_tab_pane_top_ranked_outlines"
                role="tabpanel"
            >
                <div>
                    <div
                        class="fv-row mb-15 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        data-kt-buttons="true"
                        data-kt-initialized="1"
                    >
                        <ul
                            class="nav nav-tabs competitor-rank-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-8"
                        >
                            <li class="nav-item">
                                <a
                                    class="nav-link active"
                                    data-bs-toggle="tab"
                                    href="#competitor_1_10"
                                >
                                    <h5 class="mb-0">1-10</h5>
                                </a>
                            </li>
                            <li class="nav-item" v-if="articleWizardStore.getTopRankedOutlines.length > 10">
                                <a
                                    class="nav-link"
                                    data-bs-toggle="tab"
                                    href="#competitor_11_20"
                                >
                                    <h5 class="mb-0">11-20</h5>
                                </a>
                            </li>
                            <li class="nav-item" v-if="articleWizardStore.getTopRankedOutlines.length > 20">
                                <a
                                    class="nav-link"
                                    data-bs-toggle="tab"
                                    href="#competitor_21_30"
                                >
                                    <h5 class="mb-0">21-30</h5>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="serp-beater-competitors-tab-contents">
                            <!-- Tab for competitors 1-10 -->
                            <div class="tab-pane fade active show" id="competitor_1_10" role="tabpanel">
                                <div class="d-flex flex-column select-item-box">
                                    <div class="row">
                                        <div
                                            class="d-flex align-items-start outline-group col-6 py-2"
                                            v-for="(outline, index) in filteredOutlines(1, 10)"
                                            :key="outline.id"
                                        >
                                            <CompetitorOutlineItem
                                                :outlineItem="outline"
                                                :index="index"
                                                :currentRankRangeStart="1"
                                                :currentRankRangeEnd="10"
                                            ></CompetitorOutlineItem>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Tab for competitors 11-20 -->
                            <div class="tab-pane fade" id="competitor_11_20" role="tabpanel"  v-if="articleWizardStore.getTopRankedOutlines.length > 10">
                                <div class="d-flex flex-column select-item-box">
                                    <div class="row">
                                        <div
                                            class="d-flex align-items-start outline-group col-6 py-2"
                                            v-for="(outline, index) in filteredOutlines(11, 20)"
                                            :key="outline.id"
                                        >
                                            <CompetitorOutlineItem
                                                :outlineItem="outline"
                                                :index="index"
                                                :currentRankRangeStart="11"
                                                :currentRankRangeEnd="20"
                                            ></CompetitorOutlineItem>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Tab for competitors 21-30 -->
                            <div class="tab-pane fade" id="competitor_21_30" role="tabpanel"  v-if="articleWizardStore.getTopRankedOutlines.length > 20">
                                <div class="d-flex flex-column select-item-box">
                                    <div class="row">
                                        <div
                                            class="d-flex align-items-start outline-group col-6 py-2"
                                            v-for="(outline, index) in filteredOutlines(21, 30)"
                                            :key="outline.id"
                                        >
                                            <CompetitorOutlineItem
                                                :outlineItem="outline"
                                                :index="index"
                                                :currentRankRangeStart="21"
                                                :currentRankRangeEnd="30"
                                            ></CompetitorOutlineItem>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</template>

<script>
import { onMounted, onUnmounted, getCurrentInstance, computed, watch, ref } from 'vue';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import CompetitorOutlineItem from '@/Layouts/Article/Partials/Wizard/SerpBeater/Partials/CompetitorOutlineItem.vue';
import TopRankedFaqItems from '@/Layouts/Article/Partials/Wizard/SerpBeater/Partials/TopRankedFaqItems.vue';
import { useToast } from 'vue-toastification';

export default {
    components: {
        CompetitorOutlineItem,
        TopRankedFaqItems,
    },

    setup(props) {
        const instance = getCurrentInstance();
        const toast = useToast();
        const articleWizardStore = useArticleWizardStore();

        const outlines = ref(articleWizardStore.getTopRankedOutlines);
        const hasFetchedOutlines = ref(false);
        const intervalIds = ref([]);
        const currentCount = ref(2); // start with 2 outlines
        const outlinesState = ref([]);
        const selectedSerpBeaterCompetitorsIds = ref([]);

        const initializeState = () => {
            articleWizardStore.setTopRankedOutlines([]);
            selectedSerpBeaterCompetitorsIds.value = articleWizardStore.getSelectedSerpBeaterCompetitorsId;

            outlinesState.value = articleWizardStore.serpBeaterCompetitors
                .filter(competitor => selectedSerpBeaterCompetitorsIds.value.includes(competitor.id))
                .map(competitor => ({
                    id: competitor.id,
                    rank: competitor.rank_group,
                    isLoading: true,
                    data: null,
                    error: false,
                }));
            articleWizardStore.setTopRankedOutlines(outlinesState);
        };

        initializeState(currentCount.value);

        const fetchCompetitorStatus = async (competitor) => {
            try {
                const response = await axios.get(`/api/serp-beater-competitors/status/${competitor.id}`);
                const status = response.data.processing_status;

                if (status === 'completed') {
                    // Fetch the outline for this competitor
                    const outlineResponse = await axios.get(`/api/serp-beater-competitors/outline/${competitor.id}`);
                    const outlineData = outlineResponse.data.outline; // Assuming this is an array

                    // Update the specific competitor's outline data based on rank
                    outlinesState.value = outlinesState.value.map(outline => {
                        if (outline.rank === competitor.rank_group) {
                            if (outlineData) {
                                return { ...outline, data: outlineData[0], isLoading: false, error: false };
                            } else {
                                // Set isLoading to false even if outlineData is null
                                return { ...outline, data: {}, isLoading: false, error: true };
                            }
                        }
                        return outline;
                    });
                    articleWizardStore.setTopRankedOutlines(outlinesState);
                } else {
                    // Recheck after a delay
                    const intervalId = setTimeout(() => fetchCompetitorStatus(competitor), 5000);
                    intervalIds.value.push(intervalId);
                }
            } catch (error) {
                console.error('Error fetching competitor status:', error);
            }
        };

        const startPollingCompetitors = () => {
            articleWizardStore.serpBeaterCompetitors
                .filter(competitor => selectedSerpBeaterCompetitorsIds.value.includes(competitor.id))
                .forEach(competitor => {
                    fetchCompetitorStatus(competitor);
                });
        };

        const articleTitleHasChanged = () => {
            const currentTitle = articleWizardStore.getArticleTitle;
            const previousTitle = articleWizardStore.getPreviousArticleTitle;
            return currentTitle !== previousTitle;
        };

        const filteredOutlines = (start, end) => {
            return articleWizardStore.getTopRankedOutlines.filter(outline => {
                return outline.rank >= start && outline.rank <= end;
            });
        };

        onMounted(() => {
            initializeState();
            startPollingCompetitors();
        });

        onUnmounted(() => {
            intervalIds.value.forEach(clearTimeout);
        });

        watch(() => articleWizardStore.getTopRankedOutlines, (newOutlines) => {
            outlines.value = newOutlines;
        }, { immediate: true });

        watch(
            () => articleWizardStore.currentStep,
            (currentStep, prevStep) => {
                if (articleWizardStore.getIsModalOpen && currentStep === 3) {
                    if (
                        articleWizardStore.getTopRankedOutlines.length === 0 ||
                        articleTitleHasChanged()
                    ) {
                        articleWizardStore.setHeadingLists([]);
                        initializeState(2);
                        startPollingCompetitors();
                        articleWizardStore.setPreviousArticleTitle(
                            articleWizardStore.getArticleTitle
                        );
                    } else {
                        outlinesState.value =
                            articleWizardStore.getTopRankedOutlines.map(
                                (outline) => ({
                                    isLoading: false,
                                    data: outline[0],
                                })
                            );
                    }
                }
            },
            { immediate: true }
        );


        return {
            articleWizardStore,
            toast,
            outlines,
            hasFetchedOutlines,
            filteredOutlines,
        };
    },
};
</script>
