<template>
    <div class="fv-row mb-10 fv-plugins-icon-container">
        <!--begin::Label-->
        <label class="fs-6 fw-bold mb-2">{{ title }}</label>
        <!--end::Label-->
        <textarea
            ref="textarea"
            class="form-control"
            aria-label="With textarea"
            v-model="text"
            :placeholder="placeholder"
            @input="changeText"
            :style="textareaStyle"
            :maxlength="fieldMaxLength"
        >
        </textarea>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        inheritedValue: {
            type: String,
        },
        maxLength: {
            default: 1000,
        },
    },

    data() {
        return {
            text: '',
            textareaStyle: '',
        };
    },

    watch: {
        inheritedValue: function (value) {
            this.text = value;
        },
    },

    mounted() {
        this.text = this.inheritedValue;
        let vm = this;
        this.$emit('value-change', {
            component: 'description',
            value: vm.text,
        });
    },

    methods: {
        changeText() {
            this.$emit('value-change', {
                component: 'negative-prompt',
                value: this.text,
            });
            this.$refs.textarea.style.cssText = 'height:auto;';
            this.$refs.textarea.style.cssText =
                'height:' + this.$refs.textarea.scrollHeight + 'px';
        },
    },

    computed: {
        fieldMaxLength() {
            return Number(this.maxLength) > 0 ? Number(this.maxLength) : 10000;
        },
    },
};
</script>
