<template>
    <!--begin::Wrapper-->
    <div class="d-flex flex-column justify-content-between w-100">
        <div class="d-flex flex-column">
            <!--begin::Heading-->
            <div class="mb-5">
                <!--begin::Title-->
                <h2 class="text-primary">
                    {{ __('Tell us the topic you want to write about') }}
                </h2>
                <p class="text-muted">
                    {{
                        __(
                            'Giving a brief description of your context will help us in generating great outputs'
                        )
                    }}
                </p>
            </div>
            <!--end::Heading-->
            <!--begin::Input group-->
            <div
                v-if="!articleWizardStore.getSerpBeaterMode"
                class="fv-row mb-15 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
            >
                <textarea
                    class="form-control"
                    v-model="articleWizardStore.context"
                ></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col-6" v-if="showFocusKeyword">
                <!--begin::Input group-->
                <div class="d-flex flex-column mb-8 fv-row">
                    <!--begin::Label-->
                    <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                        <span class="required">{{ __('Keyword') }}</span>
                        <i
                            class="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title="{{ __('Specify a project name for future usage and reference') }}"
                        ></i>
                    </label>
                    <!--end::Label-->
                    <GoogleSearchSuggester
                        :initialValue="articleWizardStore.getFocusKeyword"
                        @suggestion-selected="onSuggestionSelected"
                    ></GoogleSearchSuggester>
                </div>
                <!--end::Input group-->
            </div>
            <div class="col-6" v-if="showSearchEngine">
                <!--begin::Input group-->
                <div class="d-flex flex-column mb-8">
                    <label class="fs-6 fw-bold mb-2">{{
                        __('Search engine')
                    }}</label>
                    <Multiselect
                        v-model="articleWizardStore.searchEngine"
                        :options="selectEngineOptions"
                        :searchable="true"
                        :no-options-text="__('Loading...')"
                        trackBy="name"
                        label="name"
                        @focusin="fetchLocations"
                        @select="onChangeSearchEngine"
                        class="form-select form-control-solid d-flex px-1 py-2"
                    >
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <div class="d-flex">
                                    <div class="d-flex align-items-center ms-2">
                                        {{ value.name }} |
                                        {{ value.countryIsoCode }} | Google
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <div class="d-flex flex-grow-1">
                                <div
                                    class="d-flex justify-content-between flex-grow-1"
                                >
                                    <div class="d-flex align-items-center ms-2">
                                        {{ option.name }} |
                                        {{ option.countryIsoCode }} | Google
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Multiselect>
                </div>
                <!--end::Input group-->
            </div>
        </div>

        <div class="d-flex flex-column mt-20">
            <p>
                {{ __('Choose your desired language') }}
            </p>
            <Language
                :allowed-languages="allowedLanguages"
                :lang="lang"
                @language-change="onLanguageChange"
                @formality-change="onFormalityChange"
            >
            </Language>
        </div>
    </div>
    <!--end::Wrapper-->
</template>

<script>
import Multiselect from '@vueform/multiselect';
import Language from '@/Layouts/SettingElements/Language.vue';
import {router as Inertia} from '@inertiajs/vue3';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import GoogleSearchSuggester from "@/Components/GoogleSearchSuggester/GoogleSearchSuggester.vue";

export default {
    setup() {
        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        return { articleWizardStore };
    },

    props: {
        contextText: String,
        showFocusKeyword: {
            type: Boolean,
            default: false,
        },
        showSearchEngine: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        GoogleSearchSuggester,
        Multiselect,
        Language,
    },

    data() {
        const currentProject = this.$page.props.user.current_project;
        const promptSettings = currentProject ? currentProject.prompt_settings : {};

        return {
            searchEngineOptions: {},
            currentSupportedLanguages: [],
            allowedLanguages: [],
            lang: promptSettings ? promptSettings.targetLang : 'en',
            formality: promptSettings ? promptSettings.formality : 'default',
            promptSettingsAccordionOpen: promptSettings ? promptSettings.promptSettingsAccordionOpen : true,
            languageDisabled: true,
        };
    },

    mounted() {
        this.articleWizardStore.setLanguage(this.lang);
        this.fetchSupportedLanguages();
        this.fetchLocations();
    },

    computed: {
        selectEngineOptions() {
            let entries = [];
            for (const [key, value] of Object.entries(
                this.searchEngineOptions
            )) {
                entries.push({
                    value: value.location_code,
                    name: value.location_name,
                    countryIsoCode: value.country_iso_code,
                });
            }
            return entries;
        },
        promptSettings() {
            return {
                targetLang: this.lang,
                formality: this.formality,
                promptSettingsAccordionOpen: this.promptSettingsAccordionOpen,
            };
        },
    },

    methods: {
        onSuggestionSelected(suggestion) {
            this.articleWizardStore.setFocusKeyword(suggestion);
        },
        fetchSupportedLanguages() {
            let vm = this;
            axios
                .get('/api/languages/language-codes')
                .then((response) => {
                    vm.currentSupportedLanguages = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchLocations() {
            let vm = this;
            axios
                .get('/api/locations')
                .then((response) => {
                    vm.searchEngineOptions = response.data
                        .map((location) => {
                            // Translate language names
                            location.location_name = vm.__(
                                location.location_name
                            );
                            return location;
                        })
                        .filter((location) => {
                            return location.languages.some((language) => {
                                return vm.currentSupportedLanguages.includes(
                                    language.language_code
                                );
                            });
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onChangeSearchEngine() {
            this.fetchLocationLanguages();
            this.articleContextSearchEngineOrLanguageChanged();
        },
        fetchLocationLanguages() {
            let vm = this;
            axios
                .get(
                    `/api/location-languages/${this.articleWizardStore.getSearchEngine}`
                )
                .then((response) => {
                    vm.allowedLanguages = response.data.filter((language) => {
                        return vm.currentSupportedLanguages.includes(language);
                    });

                    vm.lang = response.data[0];
                    vm.articleWizardStore.setLanguage(response.data[0]);
                    vm.languageDisabled = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onLanguageChange(ev) {
            this.lang = ev;
            this.$emit('language-change', ev);
            this.articleContextSearchEngineOrLanguageChanged();

            this.changePromptSettings();
        },
        onFormalityChange(ev) {
            this.$emit('formality-change', ev);

            this.changePromptSettings();
        },
        changePromptSettings() {
            let data = {
                prompt_settings: this.promptSettings,
            };

            axios
                .put(
                    '/api/user-infos/' + this.$page.props.user.user_info.id,
                    data
                )
                .then((response) => {
                    Inertia.reload({ only: ['user'] });
                });
        },
        articleContextSearchEngineOrLanguageChanged() {
            this.$emit('article-context-search-engine-or-language-changed', {
                engine: this.articleWizardStore.getSearchEngine,
                language: this.articleWizardStore.getLanguage,
            });
        },
    },
};
</script>
