import { Node, mergeAttributes } from '@tiptap/core';
import { Image as TipTapImage } from '@tiptap/extension-image';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import ImageViewComponent from './Image.vue';

const Image = TipTapImage.extend({
    name: 'image',

    // Inherit all standard Image attributes and add custom ones
    addAttributes() {
        return {
            ...this.parent?.(),
            src: { default: null },
            alt: { default: null },
            title: { default: null },
            width: { default: 'auto' },
            height: { default: 'auto' },
            float: { default: null },
            caption: { default: null },
            captionVisible: { default: false },
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),
            setImageAltText: (altText) => ({ chain }) => {
                return chain().command(({ tr, state }) => {
                    const { selection } = state;
                    const node = selection.node;

                    if (node && node.type.name === 'image') {
                        const attrs = { ...node.attrs, alt: altText };
                        const nodePos = selection.$anchor.pos;
                        tr.setNodeMarkup(nodePos, null, attrs);
                        return true;
                    }
                    return false;
                }).run();
            },
            toggleCaptionVisibility: () => ({ chain }) => {
                return chain().command(({ tr, state }) => {
                    const { selection } = state;
                    const node = selection.node;

                    if (node && node.type.name === 'image') {
                        const attrs = { ...node.attrs, captionVisible: !node.attrs.captionVisible };
                        const nodePos = selection.$anchor.pos;
                        tr.setNodeMarkup(nodePos, null, attrs);
                        return true;
                    }
                    return false;
                }).run();
            },
            setImageAlignment: (alignment) => ({ chain }) => {
                return chain().command(({ tr, state }) => {
                    const { selection } = state;
                    const node = selection.node;

                    if (node && node.type.name === 'image') {
                        const attrs = { ...node.attrs, float: alignment };
                        const nodePos = selection.$anchor.pos;
                        tr.setNodeMarkup(nodePos, null, attrs);
                        return true;
                    }
                    return false;
                }).run();
            },
        }
    },

    // The group and draggable settings can remain the same as the base Image extension
    group: 'block',
    draggable: true,

    // Define how the node should be parsed from HTML
    parseHTML() {
        return [
            {
                tag: 'img',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'figure',
            mergeAttributes(HTMLAttributes, { class: `image float-${HTMLAttributes.float}` }), // Adjust class according to alignment
            ['img', HTMLAttributes],
            HTMLAttributes.caption && HTMLAttributes.captionVisible ? ['figcaption', {}, HTMLAttributes.caption] : '',
        ];
    },

    addNodeView() {
        return VueNodeViewRenderer(ImageViewComponent);
    },

})

export default Image
