<template>
    <div
        ref="lottieContainer"
        :class="cssClasses"
        :style="containerStyle"
    ></div>
</template>

<script>
import lottie from 'lottie-web';

export default {
    props: {
        src: {
            type: String,
            required: true,
        },
        width: {
            type: [String, Number],
            default: 400,
        },
        height: {
            type: [String, Number],
            default: 400,
        },
        cssClasses: {
            type: String,
            default: 'w-200px h-200px',
        },
        loop: {
            type: Boolean,
            default: true,
        },
        autoplay: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            lottieInstance: null,
        };
    },
    computed: {
        containerStyle() {
            return {
                width: `${this.width}px`,
                height: `${this.height}px`,
            };
        },
    },
    mounted() {
        this.initializeAnimation();
    },
    methods: {
        initializeAnimation() {
            this.lottieInstance = lottie.loadAnimation({
                container: this.$refs.lottieContainer,
                renderer: 'svg',
                loop: this.loop,
                autoplay: this.autoplay,
                path: this.src,
            });
        },
        destroyAnimation() {
            if (this.lottieInstance) {
                this.lottieInstance.destroy();
                this.lottieInstance = null;
            }
        },
        start() {
            if (this.lottieInstance) {
                this.lottieInstance.play();
            }
        },
        stop() {
            if (this.lottieInstance) {
                this.lottieInstance.stop();
            }
        },
    },
    beforeUnmount() {
        if (this.lottieInstance) {
            this.lottieInstance.destroy();
            this.lottieInstance = null;
        }
    },
};
</script>

<style scoped></style>
