<template>
    <app-layout
        :title="__('Editor')"
        :view="__('Documents')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Documents') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('Documents') }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{
                        __(
                            'Here you can find all the documents you have created.'
                        )
                    }}
                </div>
            </div>
        </div>
        <DocumentsTable></DocumentsTable>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import DocumentsTable from '@/Components/Documents/DocumentsTable.vue';

export default defineComponent({
    components: {
        Link,
        AppLayout,
        DocumentsTable,
    },
});
</script>

<style lang="scss"></style>
