<template>
    <NodeViewWrapper @mouseenter="mouseEnter" @mouseleave="mouseLeave" @click="toggleSelection">
        <figure :class="`image-wrapper float-${node.attrs.float} ${isHovered ? 'hover' : ''} ${isImageSelected ? 'selected' : ''}`" ref="figure">
            <img :src="node.attrs.src" :alt="node.attrs.alt" ref="image" :style="{ width: node.attrs.width, height: node.attrs.height }"/>
            <figcaption
                contenteditable="true"
                ref="caption"
                :class="{
                    'figcaption-visible': node.attrs.captionVisible,
                    'figcaption-hidden': !node.attrs.captionVisible
                }"
                @input="updateCaption"
                :data-placeholder="__('Enter image caption')"
            ></figcaption>
            <div v-if="isImageSelected" class="resize-handle top-left" @mousedown="initResize"></div>
            <div v-if="isImageSelected" class="resize-handle top-right" @mousedown="initResize"></div>
            <div v-if="isImageSelected" class="resize-handle bottom-left" @mousedown="initResize"></div>
            <div v-if="isImageSelected" class="resize-handle bottom-right" @mousedown="initResize"></div>
        </figure>
    </NodeViewWrapper>
</template>

<script>
import { NodeViewWrapper } from '@tiptap/vue-3';

export default {
    components: {
        NodeViewWrapper,
    },
    props: ['node', 'updateAttributes', 'editor', 'getPos'],
    data() {
        return {
            isImageSelected: false,
            isHovered: false,
        };
    },
    methods: {
        toggleSelection() {
            this.isImageSelected = !this.isImageSelected;
        },
        mouseEnter() {
            this.isHovered = true;
        },
        mouseLeave() {
            this.isHovered = false;
        },
        initResize(event) {
            if (!event.target.classList.contains('resize-handle')) return;

            event.preventDefault();

            const startWidth = parseInt(document.defaultView.getComputedStyle(this.$refs.image).width, 10);
            const startHeight = parseInt(document.defaultView.getComputedStyle(this.$refs.image).height, 10);
            const aspectRatio = startWidth / startHeight;

            const startX = event.pageX;
            const startY = event.pageY;

            // Determine which direction we are resizing (only works for bottom-right corner in this setup)
            let isResizingHorizontally = event.target.classList.contains('bottom-right') || event.target.classList.contains('top-right');
            let isResizingVertically = event.target.classList.contains('bottom-right') || event.target.classList.contains('bottom-left');

            const doDrag = (dragEvent) => {
                dragEvent.preventDefault();

                const diffX = dragEvent.pageX - startX;
                const diffY = dragEvent.pageY - startY;

                let newWidth, newHeight;

                if (isResizingHorizontally && isResizingVertically) {
                    // If dragging the bottom-right corner, maintain the aspect ratio.
                    if (Math.abs(diffX) > Math.abs(diffY)) {
                        newWidth = Math.max(startWidth + diffX, 10); // Prevent the image from being too small.
                        newHeight = newWidth / aspectRatio;
                    } else {
                        newHeight = Math.max(startHeight + diffY, 10); // Prevent the image from being too small.
                        newWidth = newHeight * aspectRatio;
                    }
                } else if (isResizingHorizontally) {
                    // For horizontal resizing (if you implement handles for this), maintain the aspect ratio.
                    newWidth = Math.max(startWidth + diffX, 10);
                    newHeight = newWidth / aspectRatio;
                } else if (isResizingVertically) {
                    // For vertical resizing (if you implement handles for this), maintain the aspect ratio.
                    newHeight = Math.max(startHeight + diffY, 10);
                    newWidth = newHeight * aspectRatio;
                }

                this.updateAttributes({
                    width: `${newWidth}px`,
                    height: `${newHeight}px`,
                });
            };

            const stopDrag = () => {
                document.documentElement.removeEventListener('mousemove', doDrag, false);
                document.documentElement.removeEventListener('mouseup', stopDrag, false);
            };

            document.documentElement.addEventListener('mousemove', doDrag, false);
            document.documentElement.addEventListener('mouseup', stopDrag, false);
        },
        updateCaption(event) {
            const newCaption = event.target.innerText;
            const { src, alt } = this.node.attrs;

            this.updateAttributes({ src, alt, caption: newCaption });
        },
    },
};

</script>

<style lang="scss">
.image-wrapper {
    position: relative;
    display: inline-block;
    outline: transparent solid 4px;
    transition: outline-color 200ms ease;

    &:hover:not(.selected) {
        outline-color: var(--bs-yellow);
    }

    &.float-left {
        float: left;
        margin-right: 20px;
    }

    &.float-center img {
        display: block;
        margin: 0 auto;
    }

    &.float-right {
        float: right;
        margin-left: 20px;
    }

    .resize-handle {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: var(--bs-primary);
        border: 1px solid var(--bs-white);
        display: none; /* Initially hidden */
    }

    &.selected {
        outline-color: var(--bs-primary);

        .resize-handle {
            display: block; /* Show handles when selected */
        }
    }

    figcaption {
        background-color: var(--bs-light);
        caption-side: bottom;
        color: var(--bs-gray-700);
        font-size: .75em;
        outline-offset: -1px;
        outline: var(--bs-focus-ring-color);
        padding: .6em;
        word-break: break-word;
        text-align: center;
        transition: opacity 0.3s ease;
        pointer-events: none;
    }

    .figcaption-visible {
        opacity: 1;
        pointer-events: all;
        display: block;
    }

    .figcaption-hidden {
        opacity: 0;
        display: none;
    }

    .top-left {
        top: -8px;
        left: -8px;
        cursor: nwse-resize;
    }

    .top-right {
        top: -8px;
        right: -8px;
        cursor: nesw-resize;
    }

    .bottom-left {
        bottom: -8px;
        left: -8px;
        cursor: nesw-resize;
    }

    .bottom-right {
        bottom: -8px;
        right: -8px;
        cursor: nwse-resize;
    }
}
</style>
