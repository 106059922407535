import posthog from "posthog-js";

export default {
    install(app) {
        posthog.init("phc_hgTJvE4QfJBC9bvhV0uSwluEKYDWPYBob48HnpAbgvw", {
            api_host: "https://app.posthog.com",
        });

        // Set the initialized PostHog instance on the global properties
        app.config.globalProperties.$posthog = posthog;
    },
};
