<template>
    <draggable
        class="dragArea list-group outline-elements w-100"
        v-if="generatedUnselectedHeadings.length > 0"
        :list="generatedUnselectedHeadings"
        :group="{ name: 'outlineHeadings', pull: 'clone', put: false }"
    >
        <div
            v-for="(heading, index) in generatedUnselectedHeadings"
            :key="index"
            class="list-group-item"
            @click="addToHeadingList(heading)"
        >
            <div class="h3-element draggable-element">
                <span class="badge badge-light me-2">H3</span>
                <span>{{ heading.name }}</span>
            </div>
        </div>
    </draggable>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';

export default {
    props: {
        h2HeadingId: String,
    },

    components: {
        draggable: VueDraggableNext,
    },

    setup() {
        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        // Make it available inside methods
        return { articleWizardStore };
    },

    methods: {
        addToHeadingList(heading) {
            this.articleWizardStore.addToH3HeadingList(this.h2HeadingId, heading);
            this.$emit('rebuild-selected-article-outline');
        },
    },

    computed: {
        generatedHeadings() {
            return (
                this.articleWizardStore.getAiGeneratedH3Ideas?.[
                    this.h2HeadingId
                ]?.map((heading, index) => {
                    return {
                        name: heading,
                        id: `generated-h3-${
                            this.h2HeadingId
                        }-${Date.now()}-${index}`,
                        collapsed: true,
                    };
                }) || []
            );
        },
        generatedUnselectedHeadings() {
            try {
                let vm = this;

                let currentSelectedHeadings =
                    this.articleWizardStore.getHeadingLists.find(
                        (headingList) => headingList.id === vm.h2HeadingId
                    ).subHeadings;

                if (0 === currentSelectedHeadings.length) {
                    return this.generatedHeadings;
                }

                const subHeadingsNotInSubHeadingList =
                    this.generatedHeadings.filter((generatedHeading) => {
                        return !currentSelectedHeadings.find(
                            (currentSelectedHeading) =>
                                currentSelectedHeading.id ===
                                generatedHeading.id
                        );
                    });

                return subHeadingsNotInSubHeadingList;
            } catch (error) {
                return this.generatedHeadings;
            }
        },
    },
};
</script>
