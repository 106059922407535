<template>
    <draggable
        class="dragArea outline-elements w-100 p-2"
        :list="topRankedOutlineFaqsItems"
        :group="{ name: 'faqsIdeas', pull: 'clone', put: false }"
    >
        <div
            v-for="element in topRankedOutlineFaqsItems"
            :key="element.id"
            class="list-group-item py-1"
            :class="{ 'added-item': isAddedH2(element) }"
        >
            <div
                class="h2-element d-flex draggable-element p2 ms-0 mb-1"
                @click="addToCurrentFocusedH2Heading(element)"
            >
                <div class="d-flex align-items-baseline">
                    <span
                        class="arrow-icon text-muted bi bi-grip-vertical"
                    ></span>
                    <span class="badge badge-secondary me-2">H2</span>
                </div>
                <div class="d-flex flex-1">
                    <span class="headline">{{ element.question }}</span>
                </div>
                <div class="d-flex add-action">
                    <span class="arrow-left me-1"
                        ><i class="bi bi-arrow-left"></i
                    ></span>
                </div>
            </div>
        </div>
    </draggable>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import { useToast } from 'vue-toastification';

export default {
    setup() {
        const toast = useToast();
        // Get article wizard store
        const articleWizardStore = useArticleWizardStore();

        return {
            toast,
            articleWizardStore,
        };
    },

    components: {
        draggable: VueDraggableNext,
    },

    computed: {
        topRankedOutlineFaqsItems() {
            return this.articleWizardStore.getTopRankedOutlineFaqs.map(
                (faqItem, index) => {
                    return {
                        question: faqItem,
                        id: `topranked-faq-${index}`,
                    };
                }
            );
        },
    },

    methods: {
        isAddedH2(h2Element) {
            return this.articleWizardStore.getSelectedFaqList.some(
                (heading) => heading.id === h2Element.id
            );
        },
        addToCurrentFocusedH2Heading(faqItem) {
            const clonedFaqItem = JSON.parse(JSON.stringify(faqItem));
            this.articleWizardStore.addToSelectedFaqList(clonedFaqItem);
        },
    },
};
</script>

<style lang="scss">
.outline-elements {
    .list-group-item {
        border-radius: 0;

        &:hover,
        &:focus {
            background-color: rgba(54, 153, 255, 0.1);
        }
    }
}

.added-item {
    > div > div > .headline {
        color: var(--bs-gray-400);
    }
}
</style>
