<template>
    <div class="level-one-body">
        <div
            class="level-one-item cursor-pointer"
            :class="{ active: currentProjectId === project.id }"
            href="#"
            v-for="project in projects"
            :key="project.id"
            @click="switchToProject(project.id)"
        >
            {{ project.name }}
            <span>
                {{ getBrandIdentityFromProject(project)?.brand_name || '' }}
            </span>
        </div>
        <div class="level-one-sticky">
            <a
                href="#"
                class="d-flex align-items-center"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_new_project"
            >
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10 4.16663V15.8333"
                        stroke="#019EF7"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M4.16669 10H15.8334"
                        stroke="#019EF7"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                {{ 'Create a new project' }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        team: {
            type: Object,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: {},
        },
        projects: {
            type: Array,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: [],
        },
        brandIdentities: {
            type: Array,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: [],
        },
        currentProjectId: {
            type: Number,
            default: 0,
        },
    },

    methods: {
        switchToProject(projectId) {
            if (this.currentProjectId === projectId) {
                return false;
            }

            let data = {
                user: {
                    current_project_id: projectId,
                },
            };
            // eslint-disable-next-line no-undef
            axios
                .put('/api/users/' + this.$page.props.user.id, data)
                .then(() => {
                    this.$emit('switch-team', this.team.id);
                });
        },

        getBrandIdentityFromProject(project) {
            let projectBrandIdentityId = project.brand_identity_id;

            return (
                this.brandIdentities?.filter(
                    (brandIdentity) =>
                        brandIdentity.id === projectBrandIdentityId
                )[0] || null
            );
        },
    },
};
</script>