<template>
    <div class="d-flex mb-3">
        <span class="badge badge-dark">H1</span>
        <span class="text-gray-800 fs-3 ms-2">{{ title }}</span>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
    },
};
</script>
