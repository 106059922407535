import { defineStore } from 'pinia';

export const useDocumentChatStore = defineStore('documentChat', {
    state: () => ({
        loading: false,
        loadedFromDocument: false,
        uuid: '',
        documentId: '',
        currentQuestion: '',
    }),
    getters: {
        isLoading: (state) => state.loading,
        isLoadedFromDocument: (state) => state.loadedFromDocument,
        getUuid: (state) => state.uuid,
        getDocumentId: (state) => state.documentId,
        getCurrentQuestion: (state) => state.currentQuestion,
    },
    actions: {
        setLoading(value) {
            this.loading = value;
        },
        setLoadedFromDocument(value) {
            this.loadedFromDocument = value;
        },
        setUuid(value) {
            this.uuid = value;
        },
        setDocumentId(value) {
            this.id = value;
        },
        setCurrentQuestion(value) {
            this.currentQuestion = value;
        },
    },
});
