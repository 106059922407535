<template>
    <div class="accordion" id="seo_check_accordion">
        <SeoCheckAccordion v-for="(accordionItem, index) in renderedAccordionItems"
                           :key="accordionItem.id"
                           :accordion-item="accordionItem"
                           :index="index" />
    </div>
</template>

<script>
/*import nlp from "compromise";*/
import {defineComponent} from "vue";
import {useToast} from "vue-toastification";
import { useDocumentStore } from '@/Stores/DocumentStore';
import { useEditorStore } from '@/Stores/EditorStore';
import SeoCheckAccordion from './SeoCheckAccordion.vue';

export default defineComponent({
    setup() {
        // Get document store
        const documentStore = useDocumentStore();

        // Get editor store
        const editorStore = useEditorStore();

        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { documentStore, editorStore, toast };
    },
    name: 'SeoCheck',
    components: {
        SeoCheckAccordion,
    },
    props: {
        content: {
            type: String,
            required: true,
        },
        suggestions: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editor: this.editorStore.getEditor,
            containingKeywords: [],
            accordionItems: [],
        };
    },

    mounted() {
        this.updateAccordionItems();
    },

    computed: {
        editorContent() {
            return this.editor && this.editor.view && this.editor.view.state && this.editor.view.state.doc
                ? this.editor.view.state.doc.textContent
                : '';
        },

        keyTermsUsageLabel() {
            return this.__(
                'Current Key Terms usage is :currentUsage.<br><b>Suggested is</b>: :suggestedUsageMin - :suggestedUsageMax',
                {
                    currentUsage: this.getKeyTermsUsage,
                    suggestedUsageMin: this.suggestions?.keyTermsAndKeywordDensity?.keyTermUsage?.min || 0,
                    suggestedUsageMax: this.suggestions?.keyTermsAndKeywordDensity?.keyTermUsage?.max || 0
                }
            );
        },

        getKeyTermsUsage() {
            const editorContent = this.documentStore.getContent;

            // Check if editorContent is valid
            if (!editorContent || !editorContent.content) {
                return 0;
            }

            let totalCount = 0;
            const keyTerms = [this.documentStore.getKeyword];

            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    if (node.type === 'text' && node.text) {
                        keyTerms.forEach((keyTerm) => {
                            if (keyTerm) {
                                const regex = new RegExp('\\b' + keyTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '\\b', 'gi');
                                const matches = node.text.match(regex);
                                if (matches) {
                                    totalCount += matches.length;
                                }
                            }
                        });
                    } else if (node.content) {
                        traverseContent(node.content);
                    }
                });
            };

            traverseContent(editorContent.content);

            return totalCount;
        },

        keywordFrequencyLabel() {
            let minKeywordFrequency =
                this.suggestions?.keyTermsAndKeywordDensity?.keywordFrequency
                    ?.min ?? 1;
            let maxKeywordFrequency =
                this.suggestions?.keyTermsAndKeywordDensity?.keywordFrequency
                    ?.max ?? 2;

            minKeywordFrequency = Number(minKeywordFrequency.toFixed(2));
            maxKeywordFrequency = Number(maxKeywordFrequency.toFixed(2));

            return this.__(
                'Highest frequency keywords is :currentFrequency%.<br><b>Suggested is</b>: :keywordFrequencySuggestedMin% - :keywordFrequencySuggestedMax%',
                {
                    currentFrequency:
                        Math.round(this.getKeywordsFrequency * 100) / 100,
                    keywordFrequencySuggestedMin: minKeywordFrequency,
                    keywordFrequencySuggestedMax: maxKeywordFrequency,
                }
            );
        },

        getKeywordsFrequency() {
            const editorContent = this.documentStore.getContent;

            // Validate that editorContent is properly defined
            if (!editorContent || !editorContent.content) {
                return 0;
            }

            // Retrieve key terms from the document store
            const keyword = this.documentStore.getKeyword;

            // Initialize counter for total keyword occurrences
            let keywordOccurrences = 0;
            let totalWordCount = 0;

            // Function that recursively traverses content nodes
            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    // Check if node is a text node and contains text
                    if (node.type === 'text' && node.text) {
                        // Increment total word count
                        totalWordCount += node.text.split(/\s+/).filter(word => word.trim().length > 0).length;

                        // Search for keyword occurrences if keyword is defined
                        if (keyword) {
                            const regex = new RegExp('\\b' + keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '\\b', 'gi');
                            const matches = node.text.match(regex);
                            // If matches are found, increment the occurrences count
                            if (matches) {
                                keywordOccurrences += matches.length;
                            }
                        }
                    } else if (node.content) {
                        // If node has further nested content, continue traversal recursively
                        traverseContent(node.content);
                    }
                });
            };

            // Start traversal with document content
            traverseContent(editorContent.content);

            // Calculate keyword density as a percentage, if total word count is greater than zero
            const keywordDensity = totalWordCount > 0 ? (keywordOccurrences / totalWordCount) * 100 : 0;

            // Return the keyword density
            return keywordDensity;
        },

        totalWordCountLabel() {
            return this.__(
                'Current total word count is :totalWordCount.<br><b>Suggested is</b>: :totalWordCountSuggestedMin - :totalWordCountSuggestedMax',
                {
                    totalWordCount: this.getTotalWordCount,
                    totalWordCountSuggestedMin: this.suggestions?.contentDepth?.totalWordCount?.min || 0,
                    totalWordCountSuggestedMax: this.suggestions?.contentDepth?.totalWordCount?.max || 0
                }
            );
        },

        getTotalWordCount() {
            const editorContent = this.documentStore.getContent;

            // Function to extract text from the content
            const extractText = (nodes) => {
                return nodes.map(node => {
                    if (node.type === 'text') {
                        return node.text;
                    } else if (node.content) {
                        return extractText(node.content);
                    }
                    return '';
                }).join(' ');
            };

            // Extract text and calculate word count
            const text = editorContent && editorContent.content ? extractText(editorContent.content) : '';
            const totalWordCount = text.split(' ').filter(word => word.trim().length > 0).length;

            return totalWordCount;
        },

        headingsWordCountLabel() {
            return this.__(
                'Current word count of all headings is :headingsWordCount.<br><b>Suggested is</b>: :headingsWordCountSuggestedMin - :headingsWordCountSuggestedMax',
                {
                    headingsWordCount: this.getHeadingsWordCount,
                    headingsWordCountSuggestedMin: this.suggestions?.contentDepth?.headingsWordCount?.min || 0,
                    headingsWordCountSuggestedMax: this.suggestions?.contentDepth?.headingsWordCount?.max || 0
                }
            );
        },

        getHeadingsWordCount() {
            const editorContent = this.documentStore.getContent;

            // Check if editorContent is valid
            if (!editorContent || !editorContent.content) {
                return 0;
            }

            let totalCount = 0; // Declare the totalCount variable here.

            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    // Check if the node is a heading
                    if (node.type === 'heading' && node.content) {
                        // Traverse the content of the heading node
                        node.content.forEach((contentNode) => {
                            // Check if the content node is a text node
                            if (contentNode.type === 'text' && contentNode.text) {
                                // Split the text by whitespace to get individual words, filter out empty strings
                                const wordCount = contentNode.text.split(/\s+/).filter(word => word.trim().length > 0).length;
                                // Add the word count of this text node to the total count
                                totalCount += wordCount;
                            }
                        });
                    } else if (node.content) {
                        // If the node has nested content (e.g. a different type of node containing text)
                        // Continue traversal recursively
                        traverseContent(node.content);
                    }
                });
            };

            // Begin the traversal with the top-level nodes
            traverseContent(editorContent.content);

            // After the traversal is complete, the totalCount will contain the sum of all words in headings
            return totalCount;
        },

        paragraphsWordCountLabel() {
            return this.__(
                'Current word count of all paragraphs is :paragraphsWordCount.<br><b>Suggested is</b>: :paragraphsWordCountSuggestedMin - :paragraphsWordCountSuggestedMax',
                {
                    paragraphsWordCount: this.getPargraphsWordCount,
                    paragraphsWordCountSuggestedMin: this.suggestions?.contentDepth?.paragraphsWordCount?.min || 0,
                    paragraphsWordCountSuggestedMax: this.suggestions?.contentDepth?.paragraphsWordCount?.max || 0
                }
            );
        },

        getPargraphsWordCount() {
            const editorContent = this.documentStore.getContent;

            // Check if editorContent is valid
            if (!editorContent || !editorContent.content) {
                return 0;
            }

            let totalCount = 0; // Declare the totalCount variable here.

            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    // Check if the node is a paragraph
                    if (node.type === 'paragraph' && node.content) {
                        // Traverse the content of the paragraph node
                        node.content.forEach((contentNode) => {
                            // Check if the content node is a text node
                            if (contentNode.type === 'text' && contentNode.text) {
                                // Split the text by whitespace to get individual words, filter out empty strings
                                const wordCount = contentNode.text.split(/\s+/).filter(word => word.trim().length > 0).length;
                                // Add the word count of this text node to the total count
                                totalCount += wordCount;
                            }
                        });
                    } else if (node.content) {
                        // If the node has nested content (e.g. a different type of node containing text)
                        // Continue traversal recursively
                        traverseContent(node.content);
                    }
                });
            };

            // Begin the traversal with the top-level nodes
            traverseContent(editorContent.content);

            // After the traversal is complete, the totalCount will contain the sum of all words in paragraphs
            return totalCount;
        },

        boldWordCountLabel() {
            return this.__(
                'Current bold word count is :boldWordCount.<br><b>Suggested is</b>: :boldWordCountSuggestedMin - :boldWordCountSuggestedMax',
                {
                    boldWordCount: this.getBoldWordCount,
                    boldWordCountSuggestedMin: this.suggestions?.contentDepth?.boldWordCount?.min || 0,
                    boldWordCountSuggestedMax: this.suggestions?.contentDepth?.boldWordCount?.max || 0
                }
            );
        },

        getBoldWordCount() {
            const editorContent = this.documentStore.getContent;
            // Check if editorContent is valid
            if (!editorContent || !editorContent.content) {
                return 0;
            }
            let totalCount = 0;
            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    // Check if the node is a paragraph
                    if (node.type === 'paragraph' && node.content) {
                        // Traverse the content of the paragraph node
                        node.content.forEach((contentNode) => {
                            // Check if the content node is a text node and is bold
                            if (contentNode.type === 'text' && contentNode.text) {
                                // Check if the word is bold
                                const isBold = contentNode.marks && contentNode.marks.some(mark => mark.type === 'bold');
                                if (isBold) {
                                    // Split the text by whitespace to get individual words, filter out empty strings
                                    const wordCount = contentNode.text.split(/\s+/).filter(word => word.trim().length > 0).length;
                                    // Add the word count of this bold text node to the total count
                                    totalCount += wordCount;
                                }
                            }
                        });
                    } else if (node.content) {
                        // If the node has nested content (e.g. a different type of node containing text)
                        // Continue traversal recursively
                        traverseContent(node.content);
                    }
                });
            };
            // Begin the traversal with the top-level nodes
            traverseContent(editorContent.content);
            // After the traversal is complete, the totalCount will contain the sum of all words in paragraphs that are bold
            return totalCount;
        },

        imagesCountLabel() {
            return this.__(
                'Current number of images is :imagesCount.<br><b>Suggested is</b>: :imagesCountSuggestedMin - :imagesCountSuggestedMax',
                {
                    imagesCount: this.getImagesCount,
                    imagesCountSuggestedMin: this.suggestions?.contentDepth?.imagesCount?.min || 0,
                    imagesCountSuggestedMax: this.suggestions?.contentDepth?.imagesCount?.max || 0
                }
            );
        },

        getImagesCount() {
            const editorContent = this.documentStore.getContent;
            // Check if editorContent is valid
            if (!editorContent || !editorContent.content) {
                return 0;
            }
            let totalCount = 0;
            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    // Check if the node is an image
                    if (node.type === 'image') {
                        // Increment the total count
                        totalCount++;
                    } else if (node.content) {
                        // If the node has nested content (e.g. a different type of node containing text)
                        // Continue traversal recursively
                        traverseContent(node.content);
                    }
                });
            };
            // Begin the traversal with the top-level nodes
            traverseContent(editorContent.content);
            // After the traversal is complete, the totalCount will contain the sum of all images
            return totalCount;
        },

        imagesAltTextLabel() {
            const imagesAltTextCount = this.getImagesAltTextCount;
            const imagesCount = 7;
            return this.__(
                'Alt text is present in :imagesAltTextCount out of :imagesCount images',
                {
                    imagesAltTextCount: imagesAltTextCount,
                    imagesCount: imagesCount,
                }
            );
        },

        getImagesAltTextCount() {
            const editorContent = this.documentStore.getContent;
            // Check if editorContent is valid
            if (!editorContent || !editorContent.content) {
                return 0;
            }
            let totalCount = 0;
            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    // Check if the node is an image
                    if (node.type === 'image') {
                        // Check if the image has an alt text
                        if (node.attrs && node.attrs.alt) {
                            // Increment the total count
                            totalCount++;
                        }
                    } else if (node.content) {
                        // If the node has nested content (e.g. a different type of node containing text)
                        // Continue traversal recursively
                        traverseContent(node.content);
                    }
                });
            };
            // Begin the traversal with the top-level nodes
            traverseContent(editorContent.content);
            // After the traversal is complete, the totalCount will contain the sum of all images with alt text
            return totalCount;
        },

        headingsCountLabel() {
            return this.__(
                'Current number of headings is :headingsCount.<br><b>Suggested is</b>: :headingsCountSuggestedMin - :headingsCountSuggestedMax',
                {
                    headingsCount: this.getHeadingsCount,
                    headingsCountSuggestedMin: this.suggestions?.contentDepth?.headingsCount?.min || 0,
                    headingsCountSuggestedMax: this.suggestions?.contentDepth?.headingsCount?.max || 0
                }
            );
        },

        getHeadingsCount() {
            const editorContent = this.documentStore.getContent;
            // Check if editorContent is valid
            if (!editorContent || !editorContent.content) {
                return 0;
            }
            let totalCount = 0;
            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    // Check if the node is a heading
                    if (node.type === 'heading') {
                        // Increment the total count
                        totalCount++;
                    } else if (node.content) {
                        // If the node has nested content (e.g. a different type of node containing text)
                        // Continue traversal recursively
                        traverseContent(node.content);
                    }
                });
            };
            // Begin the traversal with the top-level nodes
            traverseContent(editorContent.content);
            // After the traversal is complete, the totalCount will contain the sum of all headings
            return totalCount;
        },

        h1CountLabel() {
            return this.__(
                'Exactly one H1 should be present in content.<br><b>Current is</b>: :h1Count.',
                {
                    h1Count: this.getH1Count,
                }
            );
        },

        getH1Count() {
            const editorContent = this.documentStore.getContent;
            // Check if editorContent is valid
            if (!editorContent || !editorContent.content) {
                return 0;
            }
            let totalCount = 0;
            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    // Check if the node is a heading
                    if (node.type === 'heading' && node.attrs && node.attrs.level === 1) {
                        // Increment the total count
                        totalCount++;
                    } else if (node.content) {
                        // If the node has nested content (e.g. a different type of node containing text)
                        // Continue traversal recursively
                        traverseContent(node.content);
                    }
                });
            };
            // Begin the traversal with the top-level nodes
            traverseContent(editorContent.content);
            // After the traversal is complete, the totalCount will contain the sum of all headings
            return totalCount;
        },

        h1KeywordLabel() {
            return this.__(
                'Primary Keyword should be present in H1.',
            );
        },

        getH1Keyword() {
            const editorContent = this.documentStore.getContent;
            const keyword = this.documentStore.getKeyword;

            // Check if editorContent and keyword are valid
            if (!editorContent || !editorContent.content || !keyword) {
                return false;
            }

            let containsKeyword = false;
            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    // Check if the node is a heading
                    if (node.type === 'heading' && node.attrs && node.attrs.level === 1) {
                        // Traverse the content of the heading node
                        node.content.forEach((contentNode) => {
                            // Check if the content node is a text node
                            if (contentNode.type === 'text' && contentNode.text) {
                                // Normalize the text by converting to lowercase and replacing non-alphanumeric characters with spaces
                                const textNormalized = contentNode.text.toLowerCase().replace(/[\W_]+/g, ' ');
                                const keywordNormalized = keyword.toLowerCase().replace(/[\W_]+/g, ' ');
                                // Check if the normalized text contains the normalized keyword
                                containsKeyword = textNormalized.includes(keywordNormalized);
                            }
                        });
                    } else if (node.content) {
                        // If the node has nested content (e.g., a different type of node containing text)
                        // Continue traversal recursively
                        traverseContent(node.content);
                    }
                });
            };

            // Begin the traversal with the top-level nodes
            traverseContent(editorContent.content);

            // After the traversal is complete, the containsKeyword will contain the result
            return containsKeyword;
        },

        h1MetaTitleLabel() {
            return this.__(
                'H1 heading should be different from the meta title.',
            );
        },

        getH1MetaTitle() {
            const editorContent = this.documentStore.getContent;
            const metaTitle = this.documentStore.getMetaContent.title;

            // Check if meta Title is not same as H1
            if (metaTitle && metaTitle.length > 0) {
                // Check if editorContent is valid
                if (!editorContent || !editorContent.content) {
                    return false;
                }
                let diffFromMetaTitle = false;
                const traverseContent = (nodes) => {
                    nodes.forEach((node) => {
                        // Check if the node is a heading
                        if (node.type === 'heading' && node.attrs && node.attrs.level === 1) {
                            // Traverse the content of the heading node
                            node.content.forEach((contentNode) => {
                                // Check if the content node is a text node
                                if (contentNode.type === 'text' && contentNode.text) {
                                    // Check if the words array contains the keyword
                                    diffFromMetaTitle = contentNode.text !== metaTitle;
                                }
                            });
                        } else if (node.content) {
                            // If the node has nested content (e.g. a different type of node containing text)
                            // Continue traversal recursively
                            traverseContent(node.content);
                        }
                    });
                };
                // Begin the traversal with the top-level nodes
                traverseContent(editorContent.content);
                // After the traversal is complete, the containsKeyword will contain the result
                return diffFromMetaTitle;
            }
            return true;
        },

        h1LengthLabel() {
            return this.__(
                'Current H1 length is :h1Length char(s).<br><b>Suggested is</b>: :h1LengthSuggestedMin - :h1LengthSuggestedMax char(s)',
                {
                    h1Length: this.getH1Length,
                    h1LengthSuggestedMin: this.suggestions?.h1Heading?.length?.min || 0,
                    h1LengthSuggestedMax: this.suggestions?.h1Heading?.length?.max || 0
                }
            );
        },

        getH1Length() {
            const editorContent = this.documentStore.getContent;
            if (!editorContent || !editorContent.content) {
                return 0;
            }
            let h1Length = 0;
            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    if (node.type === 'heading' && node.attrs && node.attrs.level === 1) {
                        node.content.forEach((contentNode) => {
                            if (contentNode.type === 'text' && contentNode.text) {
                                // Length of the text in the node is added to the h1Length
                                h1Length += contentNode.text.length;
                            }
                        });
                    } else if (node.content) {
                        traverseContent(node.content);
                    }
                });
            };
            traverseContent(editorContent.content);
            return h1Length;
        },

        h2H6QuestionsLabel() {
            return this.__(
                'At least one question should be present in H2-H6 headings.'
            );
        },

        getH2H6Questions() {
            const editorContent = this.documentStore.getContent;

            if (!editorContent || !editorContent.content) {
                return false;
            }
            let containsQuestion = false;
            const traverseContent = (nodes) => {
                // If we found a question in a previous node, stop checking the rest
                if (containsQuestion) { return; }

                nodes.forEach((node) => {
                    if (containsQuestion) { return; } // <- this line stops the loop when a question found in node content
                    // Check if the node is a heading
                    if (node.type === 'heading' && node.attrs && node.attrs.level > 1) {
                        node.content.forEach((contentNode) => {
                            if (contentNode.type === 'text' && contentNode.text) {
                                containsQuestion = contentNode.text.includes('?');
                            }
                        });
                    } else if (node.content) {
                        traverseContent(node.content);
                    }
                });
            };
            traverseContent(editorContent.content);
            return containsQuestion;
        },

        h2H6PrimaryKeywordLabel() {
            return this.__(
                'Primary keyword should be present in H2-H6 headings.',
            );
        },

        getH2H6PrimaryKeyword() {
            const editorContent = this.documentStore.getContent;
            const keyTerms = [this.documentStore.getKeyword];

            if (!editorContent || !editorContent.content || !keyTerms) {
                return false;
            }
            let containsKeyword = false;
            const traverseContent = (nodes) => {
                // If we found a keyword in a previous node, stop checking the rest
                if (containsKeyword) { return; }

                nodes.forEach((node) => {
                    if (containsKeyword) { return; } // <- this line stops the loop when a keyword found in node content

                    if (node.type === 'heading' && node.attrs && node.attrs.level > 1) {
                        node.content.forEach((contentNode) => {
                            if (contentNode.type === 'text' && contentNode.text) {
                                keyTerms.forEach((keyTerm) => {
                                    if (keyTerm) {
                                        const regex = new RegExp('\\b' + keyTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '\\b', 'gi');
                                        const matches = contentNode.text.match(regex);
                                        if (matches) {
                                            containsKeyword = true;
                                        }
                                    }
                                });
                            }
                        });
                    } else if (node.content) {
                        traverseContent(node.content);
                    }
                });
            };
            traverseContent(editorContent.content);
            return containsKeyword;
        },
        /*
        const keyTerms = [this.documentStore.getKeyword];

            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    if (node.type === 'text' && node.text) {
                        keyTerms.forEach((keyTerm) => {
                            if (keyTerm) {
                                const regex = new RegExp('\\b' + keyTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '\\b', 'gi');
                                const matches = node.text.match(regex);
                                if (matches) {
                                    totalCount += matches.length;
                                }
                            }
                        });
                    } else if (node.content) {
                        traverseContent(node.content);
                    }
                });
            };
        * */

        metaTitleLengthLabel() {
            return this.__(
                'Current meta title length is :metaTitleLength char(s).<br><b>Suggested is</b>: :metaTitleLengthSuggestedMin - :metaTitleLengthSuggestedMax char(s)',
                {
                    metaTitleLength: this.getMetaTitleLength,
                    metaTitleLengthSuggestedMin: this.suggestions?.metaTags?.metaTitleLength?.min || 0,
                    metaTitleLengthSuggestedMax: this.suggestions?.metaTags?.metaTitleLength?.max || 0
                }
            );
        },

        getMetaTitleLength() {
            return this.documentStore.getMetaContent.title?.length || 0;
        },

        metaKeywordsTitleLabel() {
            return this.__(
                'Primary keyword should be present in the meta title',
            );
        },

        getMetaKeywordsTitle() {
            const metaTitle = this.documentStore.getMetaContent.title;
            const keyword = this.documentStore.getKeyword;

            // Check if metaTitle and keyword are valid
            if (!metaTitle || !keyword) {
                return false;
            }

            // Normalize by converting to lowercase and replacing non-alphanumeric characters with spaces
            const titleNormalized = metaTitle.toLowerCase().replace(/[\W_]+/g, ' ');
            const keywordNormalized = keyword.toLowerCase().replace(/[\W_]+/g, ' ');

            // Check if the normalized keyword phrase is present in the normalized title
            const containsKeyword = titleNormalized.includes(keywordNormalized);

            // Return the result
            return containsKeyword;
        },

        metaDescriptionLengthLabel() {
            return this.__(
                'Current meta description length is :metaDescriptionLength char(s).<br><b>Suggested is</b>: :metaDescriptionLengthSuggestedMin - :metaDescriptionLengthSuggestedMax char(s)',
                {
                    metaDescriptionLength: this.getMetaDescriptionLength,
                    metaDescriptionLengthSuggestedMin: this.suggestions?.metaTags?.metaDescriptionLength?.min || 0,
                    metaDescriptionLengthSuggestedMax: this.suggestions?.metaTags?.metaDescriptionLength?.max || 0
                }
            );
        },

        getMetaDescriptionLength() {
            const metaDescription = this.documentStore.getMetaContent.description;
            return metaDescription ? metaDescription.length : 0;
        },

        metaKeywordsDescriptionLabel() {
            return this.__(
                'Primary/Secondary keyword(s) should be present in the meta description',
            );
        },

        getMetaKeywordsDescription() {
            const metaDescription = this.documentStore.getMetaContent.description;
            const primary_keyword = this.documentStore.getKeyword;

            // Check if metaDescription and primary_keyword are valid
            if (!metaDescription || !primary_keyword) {
                return false;
            }

            // Normalize by converting to lowercase and replacing non-alphanumeric characters with spaces
            const descriptionNormalized = metaDescription.toLowerCase().replace(/[\W_]+/g, ' ');
            const keywordNormalized = primary_keyword.toLowerCase().replace(/[\W_]+/g, ' ');

            // Check if the normalized keyword phrase is present in the normalized description
            const containsKeyword = descriptionNormalized.includes(keywordNormalized);

            // Return the result
            return containsKeyword;
        },

        featureSnippetLabel() {
            return this.__(
                'Featured snippet should be used in one of the heading (other than H1) with a paragraph beneath it containing 35-50 words',
            );
        },

        getFeatureSnippet() {
            const editorContent = this.documentStore.getContent;
            // Check if editorContent is valid
            if (!editorContent || !editorContent.content) {
              return false;
            }

            let containsFeatureSnippet = false;

            // check if content beneath a heading (other than H1) has 35-50 words
            const checkContentLength = (nodes) => {
              nodes.forEach((node) => {
                // Check if the node is a paragraph
                if (node.type === 'paragraph' && node.content) {
                  // Traverse the content of the paragraph node
                  node.content.forEach((contentNode) => {
                    // Check if the content node is a text node
                    if (contentNode.type === 'text' && contentNode.text) {
                      // Split the text by whitespace to get individual words, filter out empty strings
                      const wordCount = contentNode.text.split(/\s+/).filter(word => word.trim().length > 0).length;
                      // Check if the word count is between 35-50
                      if (wordCount >= 35 && wordCount <= 50) {
                        containsFeatureSnippet = true;
                      }
                    }
                  });
                } else if (node.content) {
                  // If the node has nested content (e.g. a different type of node containing text)
                  // Continue traversal recursively
                  checkContentLength(node.content);
                }
              });
            };

            // check if a heading (other than H1) has a featured snippet
            const traverseContent = (nodes) => {
              nodes.forEach((node) => {
                // Check if the node is a heading
                if (node.type === 'heading' && node.attrs && node.attrs.level > 1) {
                  // Traverse the content of the heading node
                  node.content.forEach((contentNode) => {
                    // Check if the content node is a text node
                    if (contentNode.type === 'text' && contentNode.text) {
                      // Check if the text contains a question mark
                      if (contentNode.text.includes('?')) {
                        checkContentLength(node.content);
                      }
                    }
                  });
                } else if (node.content) {
                  // If the node has nested content (e.g. a different type of node containing text)
                  // Continue traversal recursively
                  traverseContent(node.content);
                }
              });
            };

            // Begin the traversal with the top-level nodes
            traverseContent(editorContent.content);

            return containsFeatureSnippet;
        },

        outboundLinkCountLabel() {
            return this.__(
                'Current outbound link count is :outboundLinkCount.<br><b>Suggested is</b>: :outboundLinkCountSuggestedMin - :outboundLinkCountSuggestedMax',
                {
                    outboundLinkCount: this.getOutboundLinkCount,
                    outboundLinkCountSuggestedMin: this.suggestions?.links?.outboundLinkCount?.min || 0,
                    outboundLinkCountSuggestedMax: this.suggestions?.links?.outboundLinkCount?.max || 0
                }
            );
        },

        getOutboundLinkCount(content) {
            const editorContent = this.documentStore.getContent;
            // Check if editorContent is valid
            if (!editorContent || !editorContent.content) {
                return false;
            }

            let totalCount = 0;

            const traverseContent = (nodes) => {
                nodes.forEach((node) => {
                    // Node might be a text node with marks
                    if (node.marks) {
                        node.marks.forEach((mark) => {
                            if (mark.type === 'link') {
                                totalCount++;
                            }
                        });
                    }
                    // Node has nested content, traverse recursively
                    else if (node.content) {
                        traverseContent(node.content);
                    }
                });
            };

            // Begin the traversal with the top-level nodes
            traverseContent(editorContent.content);

            return totalCount;
        },

        urlLengthLabel() {
            return this.__(
                'Current URL length is :urlLength char(s).<br><b>Suggested is</b>: 1 - :urlLengthSuggestedMax char(s)',
                {
                    urlLength: this.getUrlLength,
                    urlLengthSuggestedMax: this.suggestions?.url?.urlLength?.max || 0
                }
            );
        },

        getUrlLength() {
            const url = this.documentStore.getMetaContent.slug;
            return url ? url.length : 0;
        },

        urlPrimaryKeywordLabel() {
            return this.__(
                'Primary keyword should be present in the URL permalink',
            );
        },

        getUrlPrimaryKeyword() {
            // Get the slug from the URL and the keyword
            const url = this.documentStore.getMetaContent.slug;
            const keyword = this.documentStore.getKeyword;

            // Check if url and keyword are valid
            if (!url || !keyword) {
                return false;
            }

            // Normalize both the URL and the keyword to ensure a consistent comparison
            // Replace hyphens and underscores in the URL with spaces to match the format of the keyword
            const normalizedUrl = url.replace(/[-_]+/g, ' ').toLowerCase();
            const normalizedKeyword = keyword.toLowerCase();

            // Check if the normalized URL contains the keyword
            const containsKeyword = normalizedUrl.includes(normalizedKeyword);

            // Return the result
            return containsKeyword;
        },

        renderedAccordionItems() {
            return this.accordionItems.map(item => {
                return {
                    ...item,
                };
            });
        },

    },


    methods: {
        updateSeoChecks() {
            // Update accordionItems data based on the new content
            this.updateAccordionItems();

            this.checkContainsKeyTerms(this.editor);

            // Force a re-render of the component by creating a new reference for accordionItems
            this.accordionItems = [...this.accordionItems];
        },

        updateAccordionItems() {
            this.accordionItems = [
                {
                    id: 'keyTermsKeywordDensity',
                    title: this.__('Key terms & Keyword Density'),
                    checks: [
                        {
                            label: this.keyTermsUsageLabel,
                            type: this.checkBetween(
                                this.getKeyTermsUsage,
                                this.suggestions?.keyTermsAndKeywordDensity?.keyTermUsage?.min || 0,
                                this.suggestions?.keyTermsAndKeywordDensity?.keyTermUsage?.max || 0,
                            ),
                        },
                        /*
                        // TODO: Implement logic for this check
                        {
                            label: this.__('Use at least 1 from the following Key Terms to increase SEO score'),
                            type: keyTermsSuggested,
                        },*/
                        {
                            label: this.keywordFrequencyLabel,
                            type: this.checkBetween(
                                this.getKeywordsFrequency,
                                this.suggestions?.keyTermsAndKeywordDensity
                                    ?.keywordFrequency?.min ?? 1,
                                this.suggestions?.keyTermsAndKeywordDensity
                                    ?.keywordFrequency?.max ?? 2
                            ),
                        },
                        /*
                        // TODO: Implement logic for this check
                        {
                          label: this.__('Presence of all primary and secondary keywords should be between :pagePercentage% and :pagesPercentage%', {
                            pagePercentage: pagePercentage,
                            pagesPercentage: pagesPercentage,
                          }),
                          type:,
                        },*/
                    ],
                },
                {
                    id: 'contentDepth',
                    title: this.__('Content Depth'),
                    checks: [
                        {
                            label: this.totalWordCountLabel,
                            type: this.checkBetween(
                                this.getTotalWordCount,
                                this.suggestions?.contentDepth?.totalWordCount?.min || 0,
                                this.suggestions?.contentDepth?.totalWordCount?.max || 0,
                            ),
                        },
                        {
                            label: this.headingsWordCountLabel,
                            type: this.checkBetween(
                                this.getHeadingsWordCount,
                                this.suggestions?.contentDepth?.headingsWordCount?.min || 0,
                                this.suggestions?.contentDepth?.headingsWordCount?.max || 0,
                            ),
                        },
                        {
                            label: this.paragraphsWordCountLabel,
                            type: this.checkBetween(
                                this.getPargraphsWordCount,
                                this.suggestions?.contentDepth?.paragraphsWordCount?.min || 0,
                                this.suggestions?.contentDepth?.paragraphsWordCount?.max || 0,
                            ),
                        },
                        {
                            label: this.boldWordCountLabel,
                            type: this.checkBetween(
                                this.getBoldWordCount,
                                this.suggestions?.contentDepth?.boldWordCount?.min || 0,
                                this.suggestions?.contentDepth?.boldWordCount?.max || 0,
                            )
                        },
                        {
                            label: this.imagesCountLabel,
                            type: this.checkBetween(
                                this.getImagesCount,
                                this.suggestions?.contentDepth?.imagesCount?.min || 0,
                                this.suggestions?.contentDepth?.imagesCount?.max || 0,
                            ),
                        },
                        {
                            label: this.imagesAltTextLabel,
                            type: this.checkBetween(
                                this.getImagesAltTextCount,
                                this.suggestions?.contentDepth?.imagesCount?.min || 0,
                                this.suggestions?.contentDepth?.imagesCount?.max || 0,
                            ),
                        },
                        {
                            label: this.headingsCountLabel,
                            type: this.checkBetween(
                                this.getHeadingsCount,
                                this.suggestions?.contentDepth?.headingsCount?.min || 0,
                                this.suggestions?.contentDepth?.headingsCount?.max || 0,
                            ),
                        },
                    ],
                },
                {
                    id: 'h1Heading',
                    title: this.__('H1 Heading'),
                    checks: [
                        {
                            label: this.h1CountLabel,
                            type: this.checkEqual(
                                this.getH1Count,
                                1,
                            ),
                        },
                        {
                            label: this.h1KeywordLabel,
                            type: this.checkGreaterThanOrEqual(
                                this.getH1Keyword,
                                1,
                            ),
                        },
                        {
                            label: this.h1MetaTitleLabel,
                            type: this.checkBoolean(
                                this.getH1MetaTitle
                            )
                        },
                        {
                            label: this.h1LengthLabel,
                            type: this.checkBetween(
                                this.getH1Length,
                                this.suggestions?.h1Heading?.length?.min || 0,
                                this.suggestions?.h1Heading?.length?.max || 0,
                            ),
                        },
                    ],
                },
                {
                    id: 'h2H6Heading',
                    title: this.__('H2-H6 Heading'),
                    checks: [
                        /*{
                            label: this.h2H6KeywordsLabel,
                            type: this.checkEqual(
                                this.getH2H6Keywords,
                                this.suggestions?.h2H6Heading?.h2H6Keywords || 0,
                            ),
                        },*/
                        {
                            label: this.h2H6QuestionsLabel,
                            type: this.checkBoolean(
                                this.getH2H6Questions
                            ),
                        },
                        {
                            label: this.h2H6PrimaryKeywordLabel,
                            type: this.checkBoolean(
                                this.getH2H6PrimaryKeyword
                            ),
                        },
                    ],
                },
                {
                    id: 'metaTags',
                    title: this.__('Meta Tags'),
                    checks: [
                        {
                            label: this.metaTitleLengthLabel,
                            type: this.checkBetween(
                                this.getMetaTitleLength,
                                this.suggestions?.metaTags?.metaTitleLength?.min || 0,
                                this.suggestions?.metaTags?.metaTitleLength?.max || 0,
                            ),
                        },
                        {
                            label: this.metaKeywordsTitleLabel,
                            type: this.checkGreaterThanOrEqual(
                                this.getMetaKeywordsTitle,
                                1,
                            ),
                        },
                        {
                            label: this.metaDescriptionLengthLabel,
                            type: this.checkBetween(
                                this.getMetaDescriptionLength,
                                this.suggestions?.metaTags?.metaDescriptionLength?.min || 0,
                                this.suggestions?.metaTags?.metaDescriptionLength?.max || 0,
                            ),
                        },
                        {
                            label: this.metaKeywordsDescriptionLabel,
                            type: this.checkGreaterThanOrEqual(
                                this.getMetaKeywordsDescription,
                                1,
                            ),
                        },
                    ],
                },
                {
                    id: 'featuredSnippet',
                    title: this.__('Featured Snippet'),
                    checks: [
                        {
                            label: this.featureSnippetLabel,
                            type: this.checkBoolean(this.getFeatureSnippet),
                        },
                    ],
                },
                {
                    id: 'links',
                    title: this.__('Links'),
                    checks: [
                        {
                            label: this.outboundLinkCountLabel,
                            type: this.checkBetween(
                                this.getOutboundLinkCount,
                                this.suggestions?.links?.outboundLinkCount.min || 0,
                                this.suggestions?.links?.outboundLinkCount.max || 0,
                            ),
                        },
                        /*
                        // TODO: Implmenet logic for internal links
                        {
                            label: this.__(
                                'Use the following links to increase SEO score'
                            ),
                            type: 'good',
                        },*/
                    ],
                },
                {
                    id: 'url',
                    title: this.__('URL'),
                    checks: [
                        {
                            label: this.urlLengthLabel,
                            type: this.checkSmallerThan(
                                this.getUrlLength,
                                this.suggestions?.url?.urlLength.max || 0,
                            ),
                        },
                        {
                            label: this.urlPrimaryKeywordLabel,
                            type: this.checkBoolean(
                                this.getUrlPrimaryKeyword,
                            ),
                        },
                    ],
                },
            ];
        },

        getKeywords(editor) {
            if (!editor) {
                return [];
            }

            const keywords = [];
            const keyTerms = [this.documentStore.getKeyword];

            editor.view.state.doc.descendants((node, pos) => {
                if (node.isText) {
                    keyTerms.forEach((keyTerm) => {
                        if (keyTerm) {
                            const regex = new RegExp(
                                '\\b' +
                                    keyTerm.replace(
                                        /[-\/\\^$*+?.()|[\]{}]/g,
                                        '\\$&'
                                    ) +
                                    '\\b',
                                'gi'
                            );
                            const matches = node.textContent.match(regex);
                            if (matches) {
                                keywords.push(keyTerm);
                            }
                        }
                    });
                }
            });

            return keywords;
        },

        checkBoolean(value) {
            if (value) {
                return 'good';
            }
            return 'major';
        },

        checkGreaterThan(value, threshold) {
            const majorThreshold = threshold * 0.4;
            if (value <= threshold - majorThreshold) {
                return 'major';
            }
            if (value <= threshold) {
                return 'minor';
            }
            return 'good';
        },

        checkGreaterThanOrEqual(value, threshold) {
            const majorThreshold = threshold * 0.4;
            if (value < threshold - majorThreshold) {
                return 'major';
            }
            if (value < threshold) {
                return 'minor';
            }
            return 'good';
        },

        checkSmallerThan(value, threshold) {
            const majorThreshold = threshold * 0.4;
            if (value >= threshold + majorThreshold) {
                return 'major';
            }
            if (value >= threshold) {
                return 'minor';
            }
            return 'good';
        },

        checkBetween(value, lowerBound, upperBound) {
            const avg = (lowerBound + upperBound) / 2;
            const majorThreshold = avg * 0.4;

            if (
                value < lowerBound - majorThreshold ||
                value > upperBound + majorThreshold
            ) {
                return 'major';
            }
            if (value < lowerBound || value > upperBound) {
                return 'minor';
            }
            return 'good';
        },
        checkEqual(value, target) {
            if (value === target) {
                return 'good';
            }
            return 'major';
        },

        checkStringDifferentiation(value, target, returnValue) {
            const difference = Math.abs(value.length - target.length);
            if (difference === 0) {
                return returnValue;
            }
            return 'good';
        },

        checkContainsKeyTerms(editor) {
            if (!editor) {
                console.log('no editor');
                return 'major';
            }
            const keyTerms = [this.documentStore.getKeyword];
            const keyTermsFound = {};

            editor.view.state.doc.descendants((node, pos) => {
                if (node.isText) {
                    keyTerms.forEach((keyTerm) => {
                        if (keyTerm) {
                            const regex = new RegExp(
                                '\\b' +
                                    keyTerm.replace(
                                        /[-\/\\^$*+?.()|[\]{}]/g,
                                        '\\$&'
                                    ) +
                                    '\\b',
                                'gi'
                            );
                            const matches = node.textContent.match(regex);
                            if (matches) {
                                const lowerCaseKeyTerm = keyTerm.toLowerCase();
                                keyTermsFound[lowerCaseKeyTerm] =
                                    (keyTermsFound[lowerCaseKeyTerm] || 0) +
                                    matches.length;
                            }
                        }
                    });
                }
            });

            const keyTermsFoundCount = Object.values(keyTermsFound).reduce(
                (sum, count) => sum + count,
                0
            );

            if (keyTermsFoundCount > 0) {
                return 'good';
            }

            return 'major';
        },

        checkKeywordFrequency(editor, threshold) {
            if (!editor) {
                console.log('no editor');
                return 'major';
            }
            const keyTerms = [this.documentStore.getKeyword];
            const keyTermsFound = {};

            editor.view.state.doc.descendants((node, pos) => {
                if (node.isText) {
                    keyTerms.forEach((keyTerm) => {
                        if (keyTerm) {
                            const regex = new RegExp(
                                '\\b' +
                                    keyTerm.replace(
                                        /[-\/\\^$*+?.()|[\]{}]/g,
                                        '\\$&'
                                    ) +
                                    '\\b',
                                'gi'
                            );
                            const matches = node.textContent.match(regex);
                            if (matches) {
                                const lowerCaseKeyTerm = keyTerm.toLowerCase();
                                keyTermsFound[lowerCaseKeyTerm] =
                                    (keyTermsFound[lowerCaseKeyTerm] || 0) +
                                    matches.length;
                            }
                        }
                    });
                }
            });

            const keyTermsFoundCount = Object.values(keyTermsFound).reduce(
                (sum, count) => sum + count,
                0
            );

            if (keyTermsFoundCount > threshold) {
                return 'good';
            }

            return 'major';
        },
    },
    watch: {
        'documentStore.getContent': {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.updateSeoChecks();
                }
            },
            deep: true,
        },
        'documentStore.getMetaContent': {
            handler(newValue, oldValue) {
                this.updateSeoChecks();
            },
            deep: true,
        },
    },
});
</script>

<style scoped></style>
