<template>
    <div
        class="modal fade"
        id="kt_modal_project_edit_modal"
        tabindex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-700px">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header pb-0 border-0 justify-content-end">
                    <!--begin::Close-->
                    <div
                        v-if="!userHasCurrentProject"
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                    <!--begin:Form-->
                    <form
                        id="kt_modal_new_project_form"
                        class="form"
                        action="#"
                        @submit.prevent="updateProject"
                    >
                        <!--begin::Heading-->
                        <div class="mb-13 text-center">
                            <!--begin::Title-->
                            <h1 class="mb-3">{{ __('Edit Project') }}</h1>
                            <!--end::Title-->
                        </div>
                        <!--end::Heading-->
                        <!--begin::Input group-->
                        <div class="d-flex flex-column mb-8 fv-row">
                            <!--begin::Label-->
                            <label
                                class="d-flex align-items-center fs-6 fw-bold mb-2"
                            >
                                <span class="required">{{
                                    __('Project Title')
                                }}</span>
                                <i
                                    class="fas fa-exclamation-circle ms-2 fs-7"
                                    data-bs-toggle="tooltip"
                                    title="{{ __('Specify a project name for future usage and reference') }}"
                                ></i>
                            </label>
                            <!--end::Label-->
                            <input
                                type="text"
                                class="form-control form-control-solid"
                                :placeholder="__('Enter Project Title')"
                                name="project_title"
                                v-model="project.name"
                            />
                        </div>
                        <!--end::Input group-->
                        <!--begin::Input group-->
                        <div class="d-flex flex-column mb-8">
                            <label class="fs-6 fw-bold mb-2">{{
                                __('Project Description')
                            }}</label>
                            <textarea
                                class="form-control form-control-solid"
                                rows="3"
                                name="description"
                                :placeholder="__('Type Project Details')"
                                v-model="project.description"
                            ></textarea>
                        </div>
                        <!--end::Input group-->
                        <!--begin::Input group-->
                        <div class="d-flex flex-column mb-8">
                            <div
                                class="d-flex flex-column flex-sm-row align-items-sm-center position-relative my-1 py-2"
                            >
                                <!--begin::Label-->
                                <div class="col-2 fs-7 text-gray-700 fw-bolder">
                                    {{ __('Brand Identity') }}:
                                </div>
                                <!--end::Label-->
                                <div class="col-10">
                                    <BrandIdentitySelection
                                        @on-brand-identity-change="
                                            brandIdentityChanged
                                        "
                                        :brandIdentityId="
                                            project?.brand_identity_id
                                        "
                                    >
                                    </BrandIdentitySelection>
                                </div>
                            </div>

                            <div
                                class="d-flex flex-column flex-sm-row align-items-sm-center position-relative my-1 py-2 mt-0 pt-0"
                            >
                                <!--begin::Label-->
                                <div
                                    class="col-2 fs-7 text-gray-700 fw-bolder"
                                ></div>
                                <!--end::Label-->
                                <div class="col-10">
                                    <div class="help-block mb-5">
                                        <span class="text-muted fs-7">
                                            <a href="/brand-identities">{{
                                                __('Manage Brand Identities')
                                            }}</a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <Creativity
                                @creativity-change="creativityChanged"
                                :defaultCreativity="
                                    project?.prompt_settings?.creativity
                                "
                            ></Creativity>

                            <Outputs
                                @outputs-change="outputsChanged"
                                :defaultOutputs="
                                    project?.prompt_settings?.outputs
                                "
                            >
                            </Outputs>

                            <!--                            <ToneOfVoice @tone-of-voice-change="toneOfVoiceChanged"
                                :defaultToneOfVoice="project?.prompt_settings?.toneOfVoice"></ToneOfVoice>

                            <TargetAudience @target-audience-change="targetAudienceChanged"
                                :defaultTargetAudience="project?.prompt_settings?.targetAudience"></TargetAudience>-->

                            <div
                                class="d-flex flex-column flex-sm-row align-items-sm-center py-2"
                            >
                                <label
                                    class="col-2 fs-7 text-gray-700 fw-bolder"
                                    >{{ __('Language') }}</label
                                >
                                <div class="col-10">
                                    <Language
                                        :allowed-languages="allowedLanguages"
                                        :lang="
                                            project?.prompt_settings
                                                ?.targetLang || 'en'
                                        "
                                        @language-change="onLanguageChange"
                                        @formality-change="onFormalityChange"
                                    >
                                    </Language>
                                </div>
                            </div>
                        </div>
                        <!--end::Input group-->
                        <!--begin::Actions-->
                        <div class="text-center">
                            <button
                                v-if="!userHasCurrentProject"
                                class="btn btn-light me-3"
                                data-bs-dismiss="modal"
                            >
                                {{ __('Cancel') }}
                            </button>
                            <button type="submit" class="btn btn-primary">
                                <span v-if="!loading" class="indicator-label">{{
                                    __('Save')
                                }}</span>
                                <span v-else class="indicator-progress d-block"
                                    >{{ __('Please wait...') }}
                                    <span
                                        class="spinner-border spinner-border-sm align-middle ms-2"
                                    ></span
                                ></span>
                            </button>
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end:Form-->
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import Outputs from '@/Layouts/SettingElements/Outputs.vue';
import ToneOfVoice from '@/Layouts/SettingElements/ToneOfVoice.vue';
import TargetAudience from '@/Layouts/SettingElements/TargetAudience.vue';
import Creativity from '@/Layouts/SettingElements/Creativity.vue';
import Multiselect from '@vueform/multiselect';
import BrandIdentitySelection from '@/Components/Fields/BrandIdentitySelection.vue';
import Language from '@/Layouts/SettingElements/Language.vue';
import { Modal } from 'bootstrap';
import {router as Inertia} from '@inertiajs/vue3';

export default {
    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods
        return {
            toast,
        };
    },

    props: {
        user: Object,
        project: Object,
    },

    emits: ['close'],

    components: {
        Multiselect,
        Outputs,
        ToneOfVoice,
        TargetAudience,
        Creativity,
        Language,
        BrandIdentitySelection,
    },

    data() {
        return {
            modal: {},
            loading: false,
            allowedLanguages: [],
            projectSettings: {},
        };
    },

    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_project_edit_modal')
        );
        this.projectSettings = this.project;
    },

    watch: {
        project() {
            this.setDefaultProjectPrompts();
        },
    },

    methods: {
        setDefaultProjectPrompts() {
            this.projectSettings = this.project;

            if (this.project?.prompt_settings !== null) {
                return false;
            }

            let defaultProjectPrompts = {
                targetLang: 'en',
                formality: 'default',
                creativity: 0.7,
                outputs: 3,
                toneOfVoice: '',
                targetAudience: '',
            };

            this.project.prompt_settings = defaultProjectPrompts;
        },
        onLanguageChange(event) {
            this.project.prompt_settings.targetLang = event;
        },
        onFormalityChange(event) {
            this.project.prompt_settings.formality = event;
        },
        creativityChanged(event) {
            this.project.prompt_settings.creativity = event;
        },
        outputsChanged(event) {
            this.project.prompt_settings.outputs = event;
        },
        toneOfVoiceChanged(event) {
            this.project.prompt_settings.toneOfVoice = event;
        },
        targetAudienceChanged(event) {
            this.project.prompt_settings.targetAudience = event;
        },
        brandIdentityChanged(event) {
            this.project.brand_identity_id = event;
        },
        userHasCurrentProject() {
            return this.user.current_project_id !== null;
        },
        updateProject() {
            let payload = {
                project: this.project,
            };
            let vm = this;
            this.loading = true;

            axios
                .put(`/api/projects/${this.project.id}`, payload)
                .then((response) => {
                    vm.loading = false;
                    Inertia.reload({ only: ['user'] });
                    vm.$emit('close');
                })
                .catch((error) => {
                    vm.toast.error(vm.__('Some error occured'));
                    vm.loading = false;
                });
        },
    },
};
</script>
