<template>
    <div class="loader-overlay">
        <div class="loader"></div>
        <span style="color: white; margin-left: 10px">{{ loadingText }}</span>
    </div>
</template>

<script>
export default {
    props: {
        loadingText: {
            type: String,
            default: 'Loading...',
        },
    },
    data() {},
    mounted() {},
};
</script>

<style scoped>
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
