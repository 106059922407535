<template>
    <div class="modal fade" id="linkModal" ref="linkModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="linkModalLabel" aria-hidden="true" v-if="isVisible">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header pb-0 border-0">
                    <h5 class="modal-title" id="linkModalLabel">{{ __('Edit Link') }}</h5>
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                        :aria-label="__('Close')"
                        @click="close"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="saveLink">
                        <div class="mb-3">
                            <label for="hrefInput" class="form-label">{{ __('URL') }}</label>
                            <input type="url" class="form-control" id="hrefInput" v-model="editorStore.currentLink.href" :placeholder="__('Enter URL')">
                        </div>
                        <div class="mb-3 p-0 form-check">
                            <div class="form-check form-switch form-check-custom form-check-solid me-10">
                                <input class="form-check-input h-20px w-30px" type="checkbox"  v-model="editorStore.currentLink.target" id="targetCheck"/>
                                <label class="form-check-label fs-5" for="targetCheck">
                                    {{ __('Open in new window') }}
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer pt-0 border-0">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close">{{ __('Cancel') }}</button>
                    <button type="button" class="btn btn-primary" @click="saveLink">{{ __('Save') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import { useEditorStore } from '@/Stores/EditorStore';

export default defineComponent({
    props: {
        isVisible: Boolean,
    },

    emits: ['updateLink', 'close'],

    setup() {
        const editorStore = useEditorStore();

        return {
            editorStore,
        };
    },

    data() {
        return {
            modal: null,
        };
    },

    watch: {
        isVisible(newVal) {
            this.$nextTick(() => {
                if (newVal) {
                    this.modal = new Modal(this.$refs.linkModal);
                    this.show();
                } else if (this.modal) {
                    this.hide();
                }
            });
        },
    },

    methods: {
        saveLink() {
            this.$emit('updateLink', {
                href: this.editorStore.getCurrentLinkHref,
                target: this.editorStore.getCurrentLinkTarget ? '_blank' : ''
            });
            this.close();
        },
        show() {
            this.modal?.show();
        },
        hide() {
            this.modal?.hide();
        },
        close() {
            this.hide();
            this.reset();
            this.$emit('close');
        },
        reset() {
            this.href = '';
            this.target = false;
        }
    }
});
</script>

