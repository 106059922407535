import { defineStore } from 'pinia';

function getDefaultState() {
    return {
        loading: false,
        currentStep: 0,
        accountType: {
            account_type: null,
        },
        accountInfo: {
            team_size: null,
            business_name: null,
            position: null,
            industry: null,
            content_creation_amount: null,
            business_description: null,
            website_url: null,
        },
        brandIdentity: {
            brand_name: null,
            purpose: null,
            description: null,
            target_audience: null,
            tone: [],
            emotional_tone: 50,
            vocabulary_level: 50,
            humor_intensity: 50,
            directness: 50,
            personalization: 50,
            descriptiveness: 50,
            language_style: 50,
            content_type: 50,
            passivity: 50,
            include_phrases: null,
            avoid_phrases: null,
            brand_examples: null,
            sentence_structure: null,
            lexical_choices: [],
            primary_topic_categories: [],
        },
        availableTones: [],
        formErrors: {
            account_info: {
                team_size: [],
                business_name: [],
                position: [],
                industry: [],
                content_creation_amount: [],
                content_creation_team: [],
                business_description: [],
                website_url: [],
            },
            brand_voice: {
                brand_name: [],
                purpose: [],
                description: [],
                tone: [],
            },
        },
        brandIdentityContents: {},
        brandIdentityDetectionTookTooLong: false,
    };
}

export const useOnboardProcessStore = defineStore('onboardProcess', {
    state: () => {
        return getDefaultState();
    },
    getters: {
        isLoading: (state) => state.loading,
        getCurrentStep: (state) => state.currentStep,
        getAccountType: (state) => state.accountType,
        getAccountInfo: (state) => state.accountInfo,
        getBrandIdentity: (state) => state.brandIdentity,
        getAvailableTones: (state) => state.availableTones,
        getFormErrors: (state) => state.formErrors,
        getBrandIdentityContents: (state) => state.brandIdentityContents,
        getBrandIdentityDetectionTookTooLong: (state) =>
            state.brandIdentityDetectionTookTooLong,
    },
    actions: {
        setLoading(value) {
            this.loading = value;
        },
        setCurrentStep(value) {
            this.currentStep = value;
        },
        incrementCurrentStep() {
            this.currentStep += 1;
        },
        decrementCurrentStep() {
            if (this.currentStep === 0) {
                return;
            }
            this.currentStep -= 1;
        },
        setAccountType(propertyName, value) {
            updateNestedProperty(this.accountType, propertyName, value);
        },
        setAccountInfo(propertyName, value) {
            updateNestedProperty(this.accountInfo, propertyName, value);
        },
        setBrandIdentity(propertyName, value) {
            updateNestedProperty(this.brandIdentity, propertyName, value);
        },
        setAvailableTones(value) {
            this.availableTones = value;
        },
        setFormErrors(propertyName, value, append = false) {
            updateNestedProperty(this.formErrors, propertyName, value, append);
        },
        setBrandIdentityContents(value) {
            this.brandIdentityContents = value;
        },
        setBrandIdentityDetectionTookTooLong(value) {
            this.brandIdentityDetectionTookTooLong = value;
        },
        resetState() {
            Object.assign(this.$state, getDefaultState());
        },
    },
});

// Define a utility function to update nested properties
function updateNestedProperty(target, propertyName, value, append = false) {
    // Split the property name by dots to handle nested properties
    const pathParts = propertyName.split('.');

    // Initialize a reference to the current level of the target object
    let currentLevel = target;

    // Traverse through the property path, creating nested objects if necessary
    for (let i = 0; i < pathParts.length - 1; i++) {
        const key = pathParts[i];
        if (!currentLevel[key] || typeof currentLevel[key] !== 'object') {
            throw new Error(`Nested property "${key}" does not exist.`);
        }
        currentLevel = currentLevel[key];
    }

    // Check if the final property exists
    const finalKey = pathParts[pathParts.length - 1];
    if (!currentLevel.hasOwnProperty(finalKey)) {
        throw new Error(`Property "${finalKey}" does not exist.`);
    }

    if (append && Array.isArray(currentLevel[finalKey])) {
        currentLevel[finalKey].push(value);
    }

    // Set the value at the final property
    currentLevel[finalKey] = value;
}
