import { defineStore } from 'pinia';
import { useEditorStore } from '@/Stores/EditorStore';
import { useDocumentChatStore } from '@/Stores/DocumentChatStore';

function getDefaultState() {
    return {
        loading: false,
        id: '',
        uuid: '',
        documentName: '',
        powerEditorData: {},
        content: {},
        savedContent: '',
        metaContent: {
            title: '',
            description: '',
            domain: '',
            slug: '',
        },
        keyword: '',
        keywords: [],
        chatGptConversationUuid: '',
        savedChatGptConversationUuid: '',
        promptSettings: {
            creativity: 0.75,
            outputs: 3,
            toneOfVoice: '',
            targetAudience: '',
            targetLang: 'en',
            formality: 'default',
        },
        serpBeater: {},
    };
}

export const useDocumentStore = defineStore('document', {
    state: () => {
        return getDefaultState();
    },
    getters: {
        isLoading: (state) => state.loading,
        getId: (state) => state.id,
        getUuid: (state) => state.uuid,
        getDocumentName: (state) => state.documentName,
        getPowerEditorData: (state) => state.powerEditorData,
        getContent: (state) => state.content,
        getSavedContent: (state) => state.savedContent,
        getMetaContent: (state) => state.metaContent,
        getKeyword: (state) => state.keyword,
        getChatGptConversationUuid: (state) => state.chatGptConversationUuid,
        getSavedChatGptConversationUuid: (state) =>
            state.savedChatGptConversationUuid,
        getKeywords: (state) => state.keywords,
        getPromptSettings: (state) => state.promptSettings,
        getTargetLang: (state) => state.promptSettings.targetLang,
        getFormality: (state) => state.promptSettings.formality,
        getToneOfVoice: (state) => state.promptSettings.toneOfVoice,
        getTargetAudience: (state) => state.promptSettings.targetAudience,
        getCreativity: (state) => state.promptSettings.creativity,
        getOutputs: (state) => state.promptSettings.outputs,
        getSerpBeater: (state) => state.serpBeater,
    },
    actions: {
        setLoading(value) {
            this.loading = value;
        },
        setId(value) {
            this.id = value;
        },
        setUuid(value) {
            this.uuid = value;
        },
        setDocumentName(value) {
            this.documentName = value;
        },
        setPowerEditorData(value) {
            this.powerEditorData = value;
        },
        setContent(value) {
            this.content = value;
        },
        setSavedContent(value) {
            this.savedContent = value;
        },
        setMetaContent(value) {
            this.metaContent = value;
        },
        setKeyword(value) {
            this.keyword = value;
        },
        setChatGptConversationUuid(value) {
            this.chatGptConversationUuid = value;
        },
        setSavedChatGptConversationUuid(value) {
            this.savedChatGptConversationUuid = value;
        },
        setKeywords(value) {
            this.keywords = value;
        },
        updateMetaContent(newMetaContent) {
            this.metaContent = { ...this.metaContent, ...newMetaContent };
        },
        updateMetaTitle(value) {
            this.metaContent.title = value;
        },
        updateMetaDescription(value) {
            this.metaContent.description = value;
        },
        updateMetaDomain(value) {
            this.metaContent.domain = value;
        },
        updateMetaSlug(value) {
            this.metaContent.slug = value;
        },
        updatePromptSettings(newSettings) {
            this.promptSettings = { ...this.promptSettings, ...newSettings };
        },
        updateTargetLang(newTargetLanguage) {
            this.promptSettings.targetLang = newTargetLanguage;
        },
        updateFormality(newFormality) {
            this.promptSettings.formality = newFormality;
        },
        updateToneOfVoice(newToneOfVoice) {
            this.promptSettings.toneOfVoice = newToneOfVoice;
        },
        updateTargetAudience(newTargetAudience) {
            this.promptSettings.targetAudience = newTargetAudience;
        },
        updateCreativity(newCreativity) {
            this.promptSettings.creativity = newCreativity;
        },
        updateOutputs(newOutputs) {
            this.promptSettings.outputs = newOutputs;
        },
        setSerpBeater(value) {
            this.serpBeater = value;
        },
        sanitizeContent(content) {
            if (!content || content.type !== 'doc' || !Array.isArray(content.content)) {
                // Invalid content structure, return default content
                return { type: 'doc', content: [{ type: 'paragraph', content: [{ type: 'text', text: '\u00A0' }] }] };
            }

            // Recursive function to sanitize nodes
            const sanitizeNodes = (nodes) => {
                return nodes.map(node => {
                    if (node.type === 'text') {
                        // Ensure text nodes have valid text (non-empty)
                        return { ...node, text: node.text || '\u00A0' }; // Replace empty string with a non-breaking space
                    } else if (node.content) {
                        // Recursively sanitize child nodes
                        return { ...node, content: sanitizeNodes(node.content) };
                    }
                    return node;
                });
            };

            // Sanitize content nodes
            content.content = sanitizeNodes(content.content);
            return content;
        },
        async fetchDocument(documentId) {
            this.setLoading(true);
            try {
                const documentData = await fetch(`/api/documents/${this.uuid}`).then(res => res.json());
                this.setId(documentData.id);
                this.setUuid(documentData.uuid);
                this.setDocumentName(documentData.name);
                const sanitizedContent = this.sanitizeContent(documentData.content);
                this.setPowerEditorData(sanitizedContent);
                const editorStore = useEditorStore();
                editorStore.setEditorContent(sanitizedContent);
                this.setContent(editorStore.getEditor.getJSON());
                if (documentData.meta) {
                    this.setMetaContent(documentData.meta);
                }
                this.setKeyword(documentData.keyword);
                this.setKeywords(documentData.keywords);
                this.setChatGptConversationUuid(documentData.chatGptConversationUuid);
                this.setSavedChatGptConversationUuid(documentData.savedChatGptConversationUuid);
                this.promptSettings = { ...this.promptSettings, ...documentData.promptSettings };
                const documentChatStore = useDocumentChatStore();
                documentChatStore.setLoadedFromDocument(true);
                documentChatStore.setUuid(
                    documentData.chat_gpt_conversation_uuid
                );
                documentChatStore.setDocumentId(documentData.id);

                if (documentData.serp_beater) {
                    this.setSerpBeater(documentData.serp_beater);

                    if (!documentData.meta) {
                        emitter.emit('generate-meta-tags');
                    }
                }
            } catch (error) {
                console.error("Failed to fetch document:", error);
                // Handle error state appropriately
            } finally {
                this.setLoading(false);
            }
        },
        resetState() {
            Object.assign(this.$state, getDefaultState());
        },
    },
});
