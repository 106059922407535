<template>
    <div class="autocomplete-dropdown" v-click-outside="closeDropdown">
        <input
            type="text"
            class="form-control py-4"
            :placeholder="__('Enter Keyword')"
            v-model="searchQuery"
            @input="onInput"
            @keydown.down.prevent="onArrowDown"
            @keydown.up.prevent="onArrowUp"
            @keydown.enter.prevent="onEnter"
            @keydown.tab="onTab">
        <ul v-if="suggestions.length" class="dropdown-menu show">
            <li v-for="(suggestion, index) in suggestions"
                :key="index" class="dropdown-item fs-6"
                :class="{ 'highlighted': index === highlightedIndex }"
                @click="selectSuggestion(suggestion)"
                @mouseover="highlightSuggestion(index)">
                {{ suggestion }}
            </li>
        </ul>
    </div>
</template>

<script>
import ClickOutsideDirective from '@/Directives/click-outside.js';

export default {
    directives: {
        'click-outside': ClickOutsideDirective
    },
    props: {
        initialValue: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            searchQuery: this.initialValue,
            suggestions: [],
            highlightedIndex: -1,
            lastRequestId: 0
        };
    },
    watch: {
        searchQuery() {
            this.highlightedIndex = -1;
        },
        initialValue() {
            this.searchQuery = this.initialValue;
        }
    },
    methods: {
        async onInput() {
            if (!this.searchQuery) {
                this.suggestions = [];
                return;
            }

            const requestId = ++this.lastRequestId; // Increment and store the current request ID
            const callbackName = 'autocompleteCallback' + requestId;

            window[callbackName] = (data) => {
                if (requestId === this.lastRequestId) { // Check if this response matches the latest request
                    this.suggestions = data[1];
                }
                delete window[callbackName]; // Clean up the global function
            };

            const script = document.createElement('script');
            script.src = `https://www.google.com/complete/search?output=search&client=chrome&q=${encodeURIComponent(this.searchQuery)}&gl=undefined&cp=2&callback=${callbackName}`;
            document.body.appendChild(script);
        },
        selectSuggestion(suggestion) {
            this.searchQuery = suggestion;
            this.suggestions = [];
            this.$emit('suggestion-selected', suggestion);
        },
        closeDropdown() {
            this.suggestions = [];
            this.$emit('suggestion-selected', this.searchQuery);
        },
        highlightSuggestion(index) {
            this.highlightedIndex = index;
        },
        onArrowDown() {
            if (this.suggestions.length > 0) {
                this.highlightedIndex = (this.highlightedIndex + 1) % this.suggestions.length;
                console.log('Arrow Down:', this.highlightedIndex);
                this.scrollToHighlighted();
            }
        },
        onArrowUp() {
            if (this.suggestions.length > 0) {
                if (this.highlightedIndex === 0) {
                    this.highlightedIndex = this.suggestions.length - 1;
                } else {
                    this.highlightedIndex = (this.highlightedIndex - 1) % this.suggestions.length;
                }
                console.log('Arrow Up:', this.highlightedIndex);
                this.scrollToHighlighted();
            }
        },
        onEnter() {
            if (this.highlightedIndex !== -1) {
                this.selectSuggestion(this.suggestions[this.highlightedIndex]);
            } else if (this.searchQuery) {
                this.$emit('suggestion-selected', this.searchQuery);
                this.suggestions = []; // Close the dropdown menu
            }
        },
        onTab() {
            this.closeDropdown();
            this.$emit('suggestion-selected', this.searchQuery);
        },
        scrollToHighlighted() {
            this.$nextTick(() => {
                const container = this.$el.querySelector('.dropdown-menu');
                const item = container.querySelector('.highlighted');
                if (item) {
                    container.scrollTop = item.offsetTop - container.offsetTop;
                }
            });
        }
    }
};
</script>

<style lang="scss">
.autocomplete-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-menu.show {
    display: block;
    position: absolute;
    width: 100%;
    max-height: 200px; /* Adjust the maximum height as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: hidden; /* Hides horizontal scrollbar */


    /* Optional: Style the scrollbar (for webkit browsers) */
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #e4e6ef;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #dadbe5;
    }

    .dropdown-item {
        cursor: pointer;

        &:hover,
        &.highlighted {
            background-color: #f8f9fa; /* Or any other color */
        }
    }
}
</style>
