<template>
    <div v-show="showSmartBar" class="smart-bar-container">
        <div class="smart-bar">
            <div
                class="smart-bar-content d-flex justify-content-between fs-2 p-5"
            >
                <div class="d-flex"></div>
                <div class="d-flex flex-column flex-center">
                    <div class="d-flex justify-content-center">
                        <span class="d-flex align-items-center fw-bolder">{{
                            __('60% Easter Saving Promotion!')
                        }}</span>
                        <span
                            class="d-flex d-none d-sm-flex align-items-center fw-light fs-3"
                            >&nbsp;|
                            {{ __('A special offer for new customers') }}</span
                        >
                    </div>
                    <div class="d-flex flex-row fw-bolder mb-0">
                        <div class="countdown-text d-flex">
                            {{ __('Ends in') }}
                        </div>
                        <div class="countdown d-flex flex-row">
                            <span v-if="days != 0" class="days"
                                >&nbsp;{{ days }}d</span
                            >
                            <span v-if="hours != 0" class="hours"
                                >&nbsp;{{ hours }}h</span
                            >
                            <span class="minutes">&nbsp;{{ minutes }}m</span>
                            <span class="seconds">&nbsp;{{ seconds }}s</span>...
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        @click="closeSmartBar"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                ></rect>
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                ></rect>
                            </svg> </span
                        ><!--end::Svg Icon-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';

export default {
    setup() {
        // Get user store
        const userStore = useUserStore();
        // Get team store
        const teamStore = useTeamStore();
        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        // Make it available inside methods
        return {
            userStore,
            teamStore,
            subscriptionStore,
        };
    },

    data() {
        return {
            eventTime: moment('2022-04-20 00:00').unix(),
            currentTime: moment().unix(),
            diffTime: 0,
            duration: 0,
            interval: 1000,
            closed: false,
        };
    },

    mounted() {
        this.userStore.setUser(this.user);
        this.subscriptionStore.setPlans(this.getPlans() || {});
    
        let smartBarCookie = this.getCookie('smart-bar');

        if (this.subscriptionStore.hasSubscription) {
            this.closeSmartBar();
        }

        if (this.getCookie('promo')) {
            this.eventTime = this.getCookie('promo');
        } else {
            /* Switch to Spring Begin */
            //let promoEnd = moment().startOf('hour').subtract(1, 'hours').unix();
            let promoEnd = moment('2022-04-20 00:00').unix();

            this.setCookie('promo', promoEnd, 30);
            this.eventTime = promoEnd;
        }

        this.diffTime = this.eventTime - this.currentTime;
        this.duration = moment.duration(this.diffTime * 1000, 'milliseconds');

        // if time to countdown
        if (this.diffTime > 0) {
            let vm = this;

            setInterval(function () {
                vm.duration = moment.duration(
                    vm.duration.asMilliseconds() - vm.interval,
                    'milliseconds'
                );
                emitter.emit('update-promo-duration', {
                    duration: vm.duration,
                });
            }, this.interval);
        }
    },
    methods: {
        closeSmartBar() {
            this.setCookie('smart-bar', 'false', 30);
            this.closed = true;
            emitter.emit('close-smart-bar');
        },

        setCookie(name, value, days) {
            let expires;
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = '; expires=' + date.toGMTString();
            } else {
                expires = '';
            }
            document.cookie =
                name +
                '=' +
                value +
                expires +
                '; path=/; domain=.creaitor.ai; Secure; SameSite=None';
        },

        getCookie(c_name) {
            if (document.cookie.length > 0) {
                let c_start = document.cookie.indexOf(c_name + '=');
                if (c_start != -1) {
                    c_start = c_start + c_name.length + 1;
                    let c_end = document.cookie.indexOf(';', c_start);
                    if (c_end == -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return null;
        },
    },

    computed: {
        days() {
            return moment.duration(this.duration).days();
        },
        hours() {
            return moment.duration(this.duration).hours();
        },
        minutes() {
            return moment.duration(this.duration).minutes();
        },
        seconds() {
            return moment.duration(this.duration).seconds();
        },
        showSmartBar() {
            return this.duration > 0 && !this.closed;
        },
    },
};
</script>

<style scoped></style>
