<template>
    <div class="d-flex flex-column flex-root bg-white">
        <!--begin::Authentication - Multi-steps-->
        <div
            class="d-flex flex-column flex-lg-row flex-column-fluid"
            data-kt-stepper="true"
        >
            <Prompt
                :user="user"
                :ai-assistant-templates="aiAssistantTemplates"
                :ai-assistant-template-uuid="uuid"
                :prompt-title="promptTitle"
                :loading="loading"
                :fields="fields"
                @prompt-change="onPromptChange"
                @outputs-change="(ev) => (options.outputs = ev)"
                @quality-change="(ev) => (options.quality = ev)"
                @characteristic-change="onCharacteristicChange"
                @characteristic-remove="onCharacteristicRemove"
                @compose="compose"
            >
            </Prompt>

            <Result
                :result-title="resultTitle"
                :images="images"
                :loading="loading"
            >
            </Result>
        </div>
        <!--end::Authentication - Multi-steps-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import NProgress from 'nprogress';
import Toolbar from '@/Layouts/Toolbar/Toolbar.vue';
import Prompt from '@/Layouts/ImageGenerator/Partials/Prompt.vue';
import Result from '@/Layouts/ImageGenerator/Partials/Result.vue';
import trialLimitExhausted from '@/Mixins/trialLimitExhausted';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';

export default defineComponent({
    mixins: [trialLimitExhausted],
    setup() {
        // Get toast interface
        const toast = useToast();

        // Get user store
        const userStore = useUserStore();
        // Get team store
        const teamStore = useTeamStore();
        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        // Make it available inside methods
        return {
            toast,
            userStore,
            teamStore,
            subscriptionStore,
        };
    },
    components: {
        NProgress,
        Toolbar,
        Prompt,
        Result,
    },

    props: {
        user: Object,
    },

    data() {
        return {
            promptTitle: this.__('Image Generator'),
            promptInfo: '',
            resultTitle: this.__('Choose from the results'),
            aiAssistantTemplates: {},
            uuid: '',
            description: '',
            name: '',
            fields: [
                {
                    layout: 'description',
                    key: 'SZxmfJWMwfFimjBn',
                    attributes: {
                        placeholder: {
                            de: 'Beschreibung',
                            en: 'Description',
                            fr: 'Description',
                            it: 'Descrizione',
                            es: 'Descripción',
                        },
                        title: {
                            de: 'Welches Bild möchtest du erstellen?',
                            en: 'Which image you wanna create?',
                            fr: 'Quelle image voulez-vous créer?',
                            it: 'Quale immagine vuoi creare?',
                            es: '¿Qué imagen quieres crear?',
                        },
                    },
                },
                {
                    layout: 'negative-prompt',
                    key: 'xJzvJWMwfFimjxK',
                    attributes: {
                        placeholder: {
                            de: 'Ausschluss',
                            en: 'Exclusion',
                            fr: 'Exclusion',
                            it: 'Esclusione',
                            es: 'Exclusión',
                        },
                        title: {
                            de: 'Was soll auf dem Bild nicht sein?',
                            en: 'What should not be on the image?',
                            fr: "Qu'est-ce qui ne devrait pas être sur l'image?",
                            it: 'Cosa non dovrebbe essere sull\'immagine?',
                            es: '¿Qué no debería estar en la imagen?',
                        },
                    },
                },
            ],
            prompts: {},
            options: {
                outputs: 1,
                quality: 'standard',
            },
            lang:
                this.$page.props.user.user_info.prompt_settings &&
                this.$page.props.user.user_info.prompt_settings.targetLang !==
                    undefined
                    ? this.$page.props.user.user_info.prompt_settings.targetLang
                    : 'en',
            loading: false,
            images: [],
        };
    },

    mounted() {
        this.userStore.setUser(this.user);
        this.subscriptionStore.setPlans(this.getPlans() || {});
    },

    methods: {
        onPromptChange(ev) {
            this.prompts[ev.component] = ev.value;
        },

        onCharacteristicChange(ev) {
            this.options[ev.component] = ev.value;
        },

        onCharacteristicRemove(ev) {
            delete this.options[ev.component];
        },

        compose() {
            NProgress.start();
            this.loading = true;
            let vm = this;
            if (
                this.teamStore.hasCharacterCredits ||
                this.subscriptionStore.hasUnlimitedUsage
            ) {
                let data = {
                    prompts: this.prompts,
                    options: this.options,
                };

                // Fake respond progress
                setTimeout(() => NProgress.set(0.4), 500);
                setTimeout(() => NProgress.inc(), 1500);
                setTimeout(() => NProgress.inc(), 3000);

                axios
                    .post('/api/images/generate', data)
                    .then((response) => {
                        vm.loading = false;
                        vm.images = response.data;
                        NProgress.done();
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        vm.toast.error(error.response.data.message);

                        if (
                            error.response.data.error_code ==
                            'trial_credit_limit_exhausted'
                        ) {
                            vm.showTrialLimitExhaustedPopUp();
                        }
                    });
            } else {
                NProgress.done();
                this.loading = false;
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
            }
        },
    },
});
</script>
