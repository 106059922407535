<template>
    <app-layout
        :title="__('Image Generator')"
        :view="view"
        :user="$attrs.user"
        wrapper-class="wrapper-fullscreen"
        main-class="main-fullscreen"
        kt-toolbar-height="1px"
        kt-toolbar-height-tablet-and-mobile="1px"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Image Generator') }}
            </h1>
        </template>

        <ImageGeneratorLayout :user="$attrs.user"> </ImageGeneratorLayout>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import ImageGeneratorLayout from '@/Layouts/ImageGenerator/ImageGeneratorLayout.vue';

export default defineComponent({
    components: {
        AppLayout,
        ImageGeneratorLayout,
    },

    data() {
        return {
            view: '',
        };
    },

    mounted() {
        let vm = this;
        emitter.on('change-template-name', function (name) {
            vm.view = name;
        });
    },

    beforeUnmount() {
        emitter.off('change-template-name');
    },
});
</script>
