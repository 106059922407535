<template>
    <app-layout
        :title="__('SERP Beater')"
        :view="__('List')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('SERP Beater') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('SERP Beater') }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{ __('Here you can find the list of your analyses.') }}
                </div>
            </div>
        </div>

        <div class="row mb-10">
            <div class="col-sm-12 col-md-4">
                <CreditInfoCard
                    :is-loading="isSerpBeaterLoading"
                    :total="totalSerpBeaters"
                >
                    {{ __('Total SERP Beater articles') }}
                </CreditInfoCard>
            </div>
            <div class="col-sm-12 col-md-4">
                <CreditInfoCard
                    :is-loading="isSerpBeaterLoading"
                    :total="totalSerpBeaterCompetitors"
                >
                    {{ __('Total analyzed competitors') }}
                </CreditInfoCard>
            </div>
            <div class="col-sm-12 col-md-4">
                <CreditsCard
                    :is-loading="isCreditsLoading"
                    :total-credits="totalCredits"
                    @update-credits="creditsUpdated"
                    buy-more-credits-modal="buy-more-serp-beater-credits"
                ></CreditsCard>
            </div>
        </div>

        <SerpBeatersTable :total-credits="totalCredits"></SerpBeatersTable>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import SerpBeatersTable from '@/Components/SerpBeater/SerpBeatersTable.vue';
import CreditInfoCard from '../../Components/Cards/CreditInfoCard.vue';
import CreditsCard from '../../Components/Cards/CreditsCard.vue';
import Swal from 'sweetalert2';

export default defineComponent({
    components: {
        AppLayout,
        SerpBeatersTable,
        CreditInfoCard,
        CreditsCard,
    },

    data() {
        return {
            isCreditsLoading: false,
            totalSerpBeaters: 0,
            isSerpBeaterLoading: false,
            totalSerpBeaterCompetitors: 0,
        };
    },

    computed: {
        totalCredits() {
            let teamPlanCredits =
                this.$attrs.user?.current_team?.team_plan_credits
                    ?.serp_beater_credits || 0;
            let teamBonusCredits =
                this.$attrs.user?.current_team?.team_bonus_credits
                    ?.serp_beater_credits || 0;

            return teamPlanCredits + teamBonusCredits;
        },
    },

    mounted() {
        this.fetchSerpBeaterCompetitorCount();
    },

    methods: {
        fetchSerpBeaterCompetitorCount() {
            this.isSerpBeaterLoading = true;
            axios
                .get(route('get-serp-beater-count'))
                .then((response) => {
                    this.totalSerpBeaterCompetitors =
                        response.data.serp_beater_competitor_count;
                    this.totalSerpBeaters = response.data.serp_beater_count;
                    this.isSerpBeaterLoading = false;
                })
                .finally(() => {
                    this.isSerpBeaterLoading = false;
                });
        },

        creditsUpdated() {
            console.log('credits updated');
            Swal.fire({
                text: this.__('Your credits have been added to your account.'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: this.__('Ok'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
            });
        },
    },
});
</script>

<style lang="scss">
.action-dropdown-menu {
    .menu-sub-dropdown {
        top: 51px;
        right: 20px;
    }
}
</style>
