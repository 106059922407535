<template>
    <div>
        <div class="container">
            <div class="tex-center">
                <h1 v-if="title" class="mt-2">{{ title }}</h1>
                <h1 v-else class="text-muted mt-2">{{ __('No title') }}</h1>
            </div>
            <div class="tex-center">
                <h3 v-if="subtitle" class="text-muted fs-5">{{ subtitle }}</h3>
                <h3 v-else class="text-muted fs-5">{{ __('No subtitle') }}</h3>
            </div>
            <div class="col">
                <div class="pt-10 fs-4">
                    <p v-if="description" v-html="description"></p>
                    <p v-else>{{ __('No description') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DynamicIcons from '@/Components/Icons/DynamicIcons.vue';
import { Link } from '@inertiajs/vue3';

export default {
    components: {
        DynamicIcons,
    },

    props: {
        notificationDetails: {
            data: {},
            notification: {},
        },
    },

    data() {
        return {
            language: this.$page?.props?.user?.user_info?.language
                ? this.$page.props.user.user_info.language
                : 'en',
        };
    },

    computed: {
        title() {
            return this.notificationDetails?.data?.title[this.language]
                ? this.notificationDetails.data.title[this.language]
                : this.__('(No title)');
        },
        subtitle() {
            return this.notificationDetails?.data?.sub_title[this.language]
                ? this.notificationDetails.data.sub_title[this.language]
                : this.__('(No Subtitle)');
        },
        description() {
            return this.notificationDetails?.data?.description[this.language];
        },
    },
};
</script>
