<template>
    <app-layout
        title="Open Form"
        :view="view"
        :user="$attrs.user"
        wrapper-class="wrapper-fullscreen"
        main-class="main-fullscreen"
        kt-toolbar-height="1px"
        kt-toolbar-height-tablet-and-mobile="1px"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Open Form') }}
            </h1>
        </template>

        <OpenFormLayout :user="$attrs.user"> </OpenFormLayout>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import OpenFormLayout from '@/Layouts/OpenForm/OpenFormLayout.vue';

export default defineComponent({
    components: {
        AppLayout,
        OpenFormLayout,
    },

    data() {
        return {
            view: 'Open Form',
        };
    },
});
</script>
