<template>
    <app-layout
        :title="__('Document')"
        :view="view"
        :user="$attrs.user"
        wrapper-class="wrapper-fullscreen "
        main-class="main-fullscreen"
        kt-toolbar-height="55px"
        kt-toolbar-height-tablet-and-mobile="55px"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Document') }}
            </h1>
        </template>

        <DocumentLayout :user="$attrs.user"> </DocumentLayout>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import DocumentLayout from '@/Layouts/Document/DocumentLayout.vue';

export default defineComponent({
    components: {
        AppLayout,
        DocumentLayout,
    },

    data() {
        return {
            view: this.__('Editor'),
        };
    },

    mounted() {
        let vm = this;
        emitter.on('change-template-name', function (name) {
            vm.view = name;
        });
    },

    beforeUnmount() {
        emitter.off('change-template-name');
    },
});
</script>
