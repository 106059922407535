import DOMPurify from 'dompurify';
import { createPopper } from '@popperjs/core';

export default {
    beforeMount(el, binding) {
        let popperInstance = null;
        let tooltipElement = null;
        let arrowElement = null;
        let hideTimeout = null;
        let isHoveringTooltip = false;

        const clearHideTimeout = () => {
            if (hideTimeout) {
                clearTimeout(hideTimeout);
                hideTimeout = null;
            }
        };

        const hide = () => {
            clearHideTimeout();
            hideTimeout = setTimeout(() => {
                if (!isHoveringTooltip) {
                    if (tooltipElement) {
                        tooltipElement.removeAttribute('data-show');
                        document.body.removeChild(tooltipElement);
                        tooltipElement = null;
                    }
                    if (popperInstance) {
                        popperInstance.destroy();
                        popperInstance = null;
                    }
                }
            }, 100); // Reduced delay before hiding the tooltip
        };

        const show = () => {
            clearHideTimeout();

            if (tooltipElement) {
                return;
            }

            if (!binding.value) {
                return;
            }

            tooltipElement = document.createElement('div');
            arrowElement = document.createElement('div');
            tooltipElement.className = 'tooltip';
            arrowElement.className = 'tooltip-arrow';
            arrowElement.setAttribute('data-popper-arrow', '');
            tooltipElement.appendChild(arrowElement);

            let content = binding.value;

            if (typeof content === 'object' && content.html) {
                content = content.html;
            }

            const tooltipInner = document.createElement('div');
            tooltipInner.className = 'tooltip-inner';
            if (binding.modifiers.html) {
                tooltipInner.innerHTML = DOMPurify.sanitize(content);
            } else {
                tooltipInner.textContent = content;
            }
            tooltipElement.appendChild(tooltipInner);

            document.body.appendChild(tooltipElement);

            let highestZIndex = 0;
            let parent = el;
            while (parent) {
                const zIndex = window.getComputedStyle(parent).zIndex;
                if (zIndex !== 'auto') {
                    highestZIndex = Math.max(highestZIndex, parseInt(zIndex, 10));
                }
                parent = parent.parentElement;
            }
            tooltipElement.style.zIndex = highestZIndex + 1;

            popperInstance = createPopper(el, tooltipElement, {
                placement: 'top',
                modifiers: [
                    { name: 'arrow', options: { element: arrowElement } },
                    { name: 'offset', options: { offset: [0, 8] } },
                ],
            });

            tooltipElement.setAttribute('data-show', '');

            tooltipElement.addEventListener('mouseenter', () => {
                isHoveringTooltip = true;
                clearHideTimeout();
            });
            tooltipElement.addEventListener('mouseleave', () => {
                isHoveringTooltip = false;
                hide();
            });
        };

        el.addEventListener('mouseenter', show);
        el.addEventListener('focus', show);
        el.addEventListener('mouseleave', hide);
        el.addEventListener('blur', hide);

        el._onCleanup = () => {
            clearHideTimeout();
            if (tooltipElement) {
                tooltipElement.removeEventListener('mouseenter', () => {
                    isHoveringTooltip = true;
                });
                tooltipElement.removeEventListener('mouseleave', () => {
                    isHoveringTooltip = false;
                });
                document.body.removeChild(tooltipElement);
                tooltipElement = null;
            }
            if (popperInstance) {
                popperInstance.destroy();
                popperInstance = null;
            }
            el.removeEventListener('mouseenter', show);
            el.removeEventListener('focus', show);
            el.removeEventListener('mouseleave', hide);
            el.removeEventListener('blur', hide);
        };
    },

    unmounted(el) {
        if (el._onCleanup) {
            el._onCleanup();
        }
    },
};
