<template>
    <div>
        <Multiselect
            v-model="selectedBrandIdentity"
            :placeholder="__('Brand Voice Selection')"
            trackBy="brand_name"
            :close-on-select="true"
            :searchable="true"
            :valueProp="type"
            :mode="mode"
            label="brand_name"
            :infinite="true"
            :limit="10"
            :clear-on-search="true"
            :class="{ 'is-invalid': false }"
            :options="brandIdentities"
            class="px-1 py-2"
            @change="brandIdentityChanged"
        >
        </Multiselect>
    </div>
</template>
<script>
import Multiselect from '@vueform/multiselect';
import { useToast } from 'vue-toastification';
export default {
    components: {
        Multiselect,
    },

    props: {
        brandIdentityId: {
            default: null,
            type: [Number, String, Array],
        },
        mode: {
            default: 'single',
            type: String,
        },
        type: {
            default: 'id',
            type: String,
        },
    },

    emits: ['on-brand-identity-change'],

    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods
        return { toast };
    },

    data() {
        return {
            brandIdentities: [],
            selectedBrandIdentity: this.brandIdentityId || null,
        };
    },

    async mounted() {
        await this.fetchBrandIdentity();
    },

    watch: {
        brandIdentityId(newBrandIdentityId) {
            this.selectedBrandIdentity = newBrandIdentityId;
        },
    },

    methods: {
        brandIdentityChanged(value, select) {
            this.$emit('on-brand-identity-change', value);
        },
        async fetchBrandIdentity() {
            let vm = this;
            let payload = {
                items: 'id,uuid,brand_name',
            };

            await axios
                .get(route('brand-identities.get', payload))
                .then(async (response) => {
                    this.brandIdentities = response.data.brand_identities || [];
                })
                .catch((error) => {
                    vm.toast.error(
                        error.response.data.message ||
                            vm.__('Some error occurred')
                    );
                });
        },
    },
};
</script>
