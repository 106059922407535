<template>
    <!--begin::Wrapper-->
    <div class="w-100">
        <!--begin::Heading-->
        <div class="pb-10 pb-lg-15">
            <!--begin::Title-->
            <h2 class="fw-bolder text-dark">
                {{ __('Account Info') }}
            </h2>
            <!--end::Title-->
        </div>
        <!--end::Heading-->

        <!--begin::Input group-->
        <div
            v-if="
                onboardProcessStore.getAccountType.account_type === 'business'
            "
            class="mb-10 fv-row"
        >
            <!--begin::Label-->
            <label class="d-flex align-items-center form-label mb-3 required">
                {{ __('Company') }}
                <i
                    class="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    :title="__('Provide your company name')"
                ></i>
            </label>
            <!--end::Label-->
            <!--begin::Input-->
            <input
                type="text"
                v-model="businessName"
                class="form-control form-control-lg"
                :class="{ 'is-invalid': isInvalid('business_name') }"
                name="business_name"
                :placeholder="__('Company')"
            />
            <!--end::Input-->
            <FormError
                :message="
                    onboardProcessStore.getFormErrors?.account_info
                        ?.business_name
                "
            ></FormError>
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <div class="mb-10 fv-row">
            <!--begin::Label-->
            <label class="d-flex align-items-center form-label mb-3 required">
                {{ __('Specify Team Size') }}
                <i
                    class="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    :title="
                        __(
                            'Provide your team size to help us setup your billing'
                        )
                    "
                ></i>
            </label>
            <!--end::Label-->
            <!--begin::Row-->
            <!--begin::Input-->
            <div
                class="row mb-2"
                data-kt-buttons="true"
                data-kt-initialized="1"
                :class="{ 'is-invalid': isInvalid('team_size') }"
            >
                <!--begin::Col-->
                <div class="col" v-for="teamSize in teamSizes" :key="teamSize">
                    <!--begin::Option-->
                    <label
                        class="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-1 p-md-4"
                        :class="{
                            active:
                                teamSize ===
                                onboardProcessStore.getAccountInfo?.team_size,
                        }"
                        @click="setTeamSize(teamSize)"
                    >
                        <input
                            type="radio"
                            class="btn-check"
                            name="account_team_size"
                            value="1-1"
                        />
                        <span class="fw-bold fs-5 fs-mob-1">{{
                            teamSize
                        }}</span>
                    </label>
                    <!--end::Option-->
                </div>
                <!--end::Col-->
            </div>
            <!--end::Input-->

            <FormError
                :message="
                    onboardProcessStore.getFormErrors?.account_info?.team_size
                "
            ></FormError>
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <div class="fv-row mb-10">
            <!--begin::Label-->
            <label class="form-label required">
                {{
                    __(
                        'How much content (article or social media posts, etc.) do you create per month?'
                    )
                }}
            </label>
            <!--end::Label-->
            <!--begin::Input-->
            <div
                class="row mb-2"
                data-kt-buttons="true"
                data-kt-initialized="1"
                :class="{ 'is-invalid': isInvalid('content_creation_amount') }"
            >
                <!--begin::Col-->
                <div
                    class="col"
                    v-for="contentCreationAmount in contentCreationAmounts"
                    :key="contentCreationAmount"
                >
                    <!--begin::Option-->
                    <label
                        class="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-1 p-md-4"
                        :class="{
                            active:
                                contentCreationAmount ===
                                onboardProcessStore.getAccountInfo
                                    ?.content_creation_amount,
                        }"
                        @click="setContentCreationAmount(contentCreationAmount)"
                    >
                        <input
                            type="radio"
                            class="btn-check"
                            name="account_team_size"
                            value="1-1"
                        />
                        <span class="fw-bold fs-5 fs-mob-1">
                            {{ contentCreationAmount }}
                        </span>
                    </label>
                    <!--end::Option-->
                </div>
                <!--end::Col-->
            </div>
            <!--end::Input-->

            <FormError
                :message="
                    onboardProcessStore.getFormErrors?.account_info
                        ?.content_creation_amount
                "
            ></FormError>
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <div class="mb-10 fv-row">
            <!--begin::Label-->
            <label
                class="d-flex align-items-center form-label mb-3"
                :class="{
                    required:
                        onboardProcessStore.getAccountType.account_type ===
                        'business',
                }"
            >
                {{ __('Website') }}
                <i
                    class="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    :title="__('Provide your website')"
                ></i>
            </label>
            <!--end::Label-->
            <!--begin::Input-->
            <div class="input-group mb-5">
                <span class="input-group-text" id="basic-addon3">https://</span>
                <input
                    type="text"
                    class="form-control form-control-lg"
                    :class="{
                        'is-invalid':
                            isInvalid('website_url') &&
                            onboardProcessStore.getAccountType.account_type ===
                                'business',
                    }"
                    name="website_url"
                    :placeholder="__('Website')"
                    v-model="websiteUrl"
                />
            </div>

            <div class="help-block mt-2" :class="isInvalid('website_url')">
                <span class="text-muted">
                    {{
                        __(
                            'We will try to detect your brand identity from the website provided.'
                        )
                    }}
                </span>
            </div>
            <!--end::Input-->
            <FormError
                :message="
                    onboardProcessStore.getFormErrors?.account_info?.website_url
                "
            ></FormError>
        </div>
        <!--end::Input group-->
    </div>
    <!--end::Wrapper-->
</template>

<script>
import { defineComponent } from 'vue';
import { useOnboardProcessStore } from '@/Stores/OnboardProcessStore';
import FormError from '@/Components/Form/Error.vue';

export default defineComponent({
    components: {
        FormError,
    },

    setup() {
        // Get onboardProcess store
        const onboardProcessStore = useOnboardProcessStore();

        // Make it available inside methods
        return {
            onboardProcessStore,
        };
    },

    data() {
        return {
            teamSizes: ['1', '2-10', '10-50', '50+'],
            contentCreationAmounts: [
                this.__('0-5'),
                this.__('6-10'),
                this.__('11-20'),
                this.__('21-50'),
                this.__('51-100'),
                this.__('100+'),
            ],
        };
    },

    computed: {
        businessName: {
            get() {
                return this.onboardProcessStore.getAccountInfo?.business_name;
            },
            set(value) {
                this.resetFormErrors('business_name');
                this.onboardProcessStore.setAccountInfo('business_name', value);
            },
        },
        websiteUrl: {
            get() {
                return this.onboardProcessStore.getAccountInfo?.website_url?.replace(
                    /^https?:\/\//,
                    ''
                );
            },
            set(value) {
                this.resetFormErrors('website_url');

                let fullUrl =
                    value.startsWith('http://') || value.startsWith('https://')
                        ? value
                        : `https://${value}`;
                this.onboardProcessStore.setAccountInfo('website_url', fullUrl);
            },
        },
    },

    methods: {
        setTeamSize(teamSize) {
            this.resetFormErrors('team_size');
            this.onboardProcessStore.setAccountInfo('team_size', teamSize);
        },
        setContentCreationAmount(contentCreationAmount) {
            this.resetFormErrors('content_creation_amount');
            this.onboardProcessStore.setAccountInfo(
                'content_creation_amount',
                contentCreationAmount
            );
        },
        isInvalid(field) {
            return (
                this.onboardProcessStore.getFormErrors?.account_info[field]
                    .length > 0
            );
        },
        resetFormErrors(field) {
            this.onboardProcessStore.setFormErrors(`account_info.${field}`, []);
        },
    },
});
</script>

<style scoped>
.fs-mob-1 {
    @media (max-width: 767px) {
        font-size: 10px !important;
    }
}
</style>
