<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_projects"
        tabindex="-1"
        aria-hidden="true"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header pb-0 border-0 justify-content-end">
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                    <!--begin::Heading-->
                    <div
                        class="d-flex align-items-center justify-content-between mb-13 mt-5"
                    >
                        <!--begin::Title-->
                        <h1 class="mb-3">{{ __('Projects') }}</h1>
                        <!--end::Title-->
                        <button
                            class="btn btn-sm btn-success"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_new_project"
                        >
                            {{ __('New') }}
                        </button>
                    </div>
                    <!--end::Heading-->
                    <!--begin:Form-->
                    <form
                        id="kt_modal_projects_form"
                        class="form"
                        action="#"
                        @submit.prevent="sendSubmission"
                    >
                        <!--begin:Option-->
                        <label
                            v-for="(project, index) in projects"
                            class="d-flex flex-stack mb-5 cursor-pointer"
                        >
                            <!--begin:Label-->
                            <span class="d-flex align-items-center w-100 me-2">
                                <!--begin:Info-->
                                <span class="d-flex flex-column w-100">
                                    <span
                                        class="fw-bolder py-3 fs-6"
                                        v-show="!project.edit"
                                        @click="toggleEdit(this, project)"
                                        >{{ project.name }}</span
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input"
                                        :ref="`project-name-field-${project.id}`"
                                        v-model="project.name"
                                        v-show="project.edit"
                                        @blur="saveEdit(index, project)"
                                    />
                                    <span class="fs-7 text-muted">{{
                                        project.description
                                    }}</span>
                                </span>
                                <!--end:Info-->
                            </span>
                            <!--end:Label-->
                            <!--begin:Input-->
                            <span
                                class="form-check form-check-custom form-check-solid"
                            >
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="current_project_id"
                                    v-model="currentProjectId"
                                    :value="project.id"
                                />
                                <span
                                    @click="openProjectEditModal(project)"
                                    class="edit-icon-span"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                        />
                                    </svg>
                                </span>
                            </span>
                            <!--end:Input-->
                        </label>
                        <!--end::Option-->
                        <!--begin::Actions-->
                        <div class="d-flex justify-content-between mt-10">
                            <div class="d-flex">
                                <button
                                    id="kt_modal_projects_delete"
                                    class="btn btn-danger me-3"
                                    @click.prevent="projectDelete"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_delete"
                                >
                                    {{ __('Delete') }}
                                </button>
                            </div>
                            <div class="d-flex">
                                <button
                                    type="reset"
                                    id="kt_modal_projects_cancel"
                                    class="btn btn-light me-3"
                                    @click="closeModal"
                                >
                                    {{ __('Cancel') }}
                                </button>

                                <button
                                    type="submit"
                                    id="kt_modal_projects_submit"
                                    class="btn btn-primary"
                                >
                                    <span
                                        v-if="!loading"
                                        class="indicator-label"
                                        >{{ __('Save') }}</span
                                    >
                                    <span
                                        v-else
                                        class="indicator-progress d-block"
                                        >{{ __('Please wait...') }}
                                        <span
                                            class="spinner-border spinner-border-sm align-middle ms-2"
                                        ></span
                                    ></span>
                                </button>
                            </div>
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end:Form-->
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
    <ProjectEditModal
        :user="user"
        :project="selectedProject"
        @close="closeProjectEditModal"
    ></ProjectEditModal>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import ProjectEditModal from '@/Modals/ProjectEditModal.vue';
import { Modal } from 'bootstrap';

export default {
    props: {
        user: Object,
    },

    components: {
        ProjectEditModal,
    },

    data() {
        return {
            name: '',
            description: '',
            modal: {},
            projectEditModal: {},
            projectsUrl: '/api/projects',
            loading: false,
            currentProjectId: this.user.current_project_id,
            selectedProject: {},
        };
    },
    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_projects')
        );
        this.projectEditModal = new Modal(
            document.getElementById('kt_modal_project_edit_modal')
        );
    },
    methods: {
        updateCurrentProjectId() {
            this.currentProjectId = this.user.current_project_id;
        },
        projectDelete() {
            emitter.emit('set-delete-candidate', {
                uuid: this.currentProjectId,
                url: this.projectsUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this project? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
        },
        deleteCallback(id) {
            const newProjectList = this.$page.props.user.projects.filter(
                (p) => p.id !== id
            );
            if (newProjectList.length) {
                this.currentProjectId = newProjectList[0].id;
                this.$page.props.user.current_project_id =
                    this.currentProjectId;
                this.sendSubmission(); // Switch active project and reload project list this way! There were many breaks with other tries :-)
            }
        },
        sendSubmission() {
            this.loading = true;
            let data = {
                user: {
                    current_project_id: this.currentProjectId,
                },
            };
            axios
                .put('/api/users/' + this.$page.props.user.id, data)
                .then((response) => {
                    this.loading = false;
                    this.closeModal();
                    Inertia.visit('/dashboard', { only: ['user'] });
                });
        },
        toggleEdit: function (ev, project) {
            project.edit = !project.edit;
        },
        saveEdit: function (ev, project) {
            let data = {
                project: project,
            };
            axios.put('/api/projects/' + project.id, data).then((response) => {
                Inertia.reload();
            });
            //save your changes
            this.toggleEdit(ev, project);
        },
        closeModal() {
            this.modal.hide();
        },
        openProjectEditModal(project) {
            this.selectedProject = project;
            this.projectEditModal.show();
        },
        closeProjectEditModal() {
            this.projectEditModal.hide();
        },
    },
    watch: {
        user: function () {
            this.currentProjectId = this.user.current_project_id;
        },
    },
    computed: {
        projects() {
            return this.user.projects.map(function (project) {
                project.edit = false;
                return project;
            });
        },
    },
};
</script>
