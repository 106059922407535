<template>
    <button
        :class="[
            {
                ['is-active']: isActive(),
            },
            `editor__button d-flex position-relative border-0 bg-transparent align-items-center p-2`,
        ]"
        @click="handleClick($event, item)"
        :title="title"
        :ref="item.name"
    >
        <div class="remix fs-2">
            <i :class="`fs-3 ri-${icon}`"></i>
        </div>
        <component
            v-if="dropdownComponent"
            :is="dropdownComponent"
            :editor="editor"
            v-model:isActive="dropdownIsActive"
            :currentMenu="$refs[item.name]"
            @updateActiveStatus="dropdownIsActive = $event"
        />
    </button>
</template>

<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';
import TableDropdown from './TableDropdown.vue';

export default {
    components: {
        TableDropdown,
    },
    props: {
        editor: {
            type: Object,
            required: true,
        },

        icon: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        item: {
            type: Object,
            required: true,
        },

        dropdownComponent: {
            type: String,
            default: null,
        },

        action: {
            type: Function,
            required: true,
        },

        currentMenu: {
            type: Object,
            default: () => null,
        },

        isActive: {
            type: Function,
            default: () => {},
        },
    },

    data() {
        return {
            remixiconUrl,
            dropdownIsActive: false,
        };
    },

    methods: {
        handleClick(ev, item) {
            if (this.dropdownComponent) {
                if (!this.dropdownIsActive) {
                    this.dropdownIsActive = true;
                } else {
                    let el = this.$refs[item.name];
                    let button = ev.target.closest('button');

                    if (
                        !el.contains(button) ||
                        el === button ||
                        !button.classList.contains('dropdown-toggle')
                    ) {
                        this.dropdownIsActive = false;
                    }
                }
            } else {
                this.action();
            }
        },
    },
};
</script>

<style lang="scss">
.editor__button {
    width: 32px;
    height: 32px;
    @media (max-width: 540px) {
        width: 10%;
    }
    svg {
        color: #9094a4;
        box-sizing: content-box;

        &:hover {
            color: #181c32;
        }

        path,
        use {
            fill: currentColor;
        }
    }
    &:hover,
    &.is-active {
        .remix {
            i {
                color: #181c32;
            }
        }
    }
}
</style>
