export default {
    mounted(el, binding) {
        el.clickOutsideEvent = function (event) {
            // Check if click was outside the el and its children
            if (!(el === event.target || el.contains(event.target))) {
                // Call the provided method
                binding.value(event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
};
