<template>
    <Head :title="__('Register')" />
    <div class="d-flex flex-column flex-root">
        <!--begin::Authentication - Signup Free Trial-->
        <div class="d-flex flex-column flex-xl-row flex-column-fluid">
            <!--begin::Aside-->
            <div class="d-flex flex-column flex-center flex-lg-row-fluid">
                <!--begin::Content-->
                <div class="d-flex align-items-start flex-column p-5 p-lg-15">
                    <!--begin::Logo-->
                    <a href="/" class="mb-15">
                        <jet-authentication-card-logo
                            mark-width="64"
                            mark-height="64"
                            logo-text-classes="p-3 text-black"
                        />
                    </a>
                    <!--end::Logo-->
                    <!--begin::Title-->
                    <h1 class="text-dark fs-2x mb-3">{{ __('Welcome') }}</h1>
                    <!--end::Title-->
                    <!--begin::Description-->
                    <div
                        class="fw-bold fs-4 text-gray-600 mb-10"
                        v-html="
                            __(
                                'Start writing your article post, creating topic ideas,<br />Facebook Ads and more.'
                            )
                        "
                    ></div>
                    <!--begin::Description-->
                    <div class="d-flex align-items-center">
                        <img
                            src="/media/logos/bosw_2023_innovation.png"
                            class="w-100px me-4"
                            alt="Best of Swiss Web 2023 Innovation"
                        />
                        <img
                            src="/media/logos/bosw_2023_productivity.png"
                            class="w-100px me-4"
                            alt="Best of Swiss Web 2023 Innovation"
                        />
                        <img
                            src="/media/logos/bosa_bronze.png"
                            class="w-150px"
                            alt="Best of Swiss App 2022 Innovation"
                        />
                    </div>
                </div>
                <!--end::Content-->
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div
                class="flex-row-fluid d-flex flex-center justfiy-content-xl-first p-10"
            >
                <!--begin::Wrapper-->
                <div
                    class="d-flex flex-center p-15 shadow-sm bg-white rounded w-100 w-md-550px mx-auto ms-xl-20"
                >
                    <!--begin::Form-->
                    <form
                        v-if="token && showForm"
                        class="form"
                        novalidate="novalidate"
                        @submit.prevent="submit"
                    >
                        <!--begin::Heading-->
                        <div class="text-center mb-10">
                            <!--begin::Title-->
                            <h1
                                class="text-dark mb-3"
                                v-html="__('7 Days Free Trial')"
                            ></h1>
                            <!--end::Title-->
                            <!--begin::Link-->
                            <div
                                class="text-gray-400 fw-bold fs-4"
                                v-html="__('Register for free.')"
                            ></div>
                            <!--end::Link-->
                        </div>
                        <div class="col-md-12 social-media-btn-group mt-10">
                            <a
                                href="/login/google"
                                class="btn btn-sm btn-primary w-200 mb-5 google-login"
                            >
                                <span
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.1"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 32 32"
                                    >
                                        <path
                                            d="M16.319 13.713v5.487h9.075c-0.369 2.356-2.744 6.9-9.075 6.9-5.463 0-9.919-4.525-9.919-10.1s4.456-10.1 9.919-10.1c3.106 0 5.188 1.325 6.375 2.469l4.344-4.181c-2.788-2.612-6.4-4.188-10.719-4.188-8.844 0-16 7.156-16 16s7.156 16 16 16c9.231 0 15.363-6.494 15.363-15.631 0-1.050-0.113-1.85-0.25-2.65l-15.113-0.006z"
                                        /></svg></span
                                ><span>{{ __('Login with Google') }}</span>
                            </a>
                            <a
                                href="/login/facebook"
                                class="btn btn-sm btn-primary w-200 mb-5 facebook-login"
                            >
                                <span
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.1"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 32 32"
                                    >
                                        <path
                                            d="M19 6h5v-6h-5c-3.86 0-7 3.14-7 7v3h-4v6h4v16h6v-16h5l1-6h-6v-3c0-0.542 0.458-1 1-1z"
                                        /></svg></span
                                ><span>{{ __('Login with Facebook') }}</span>
                            </a>
                            <a
                                href="/login/linkedin"
                                class="btn btn-sm btn-primary w-200 mb-5 linkedin-login"
                            >
                                <span
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.1"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 32 32"
                                    >
                                        <path
                                            d="M12 12h5.535v2.837h0.079c0.77-1.381 2.655-2.837 5.464-2.837 5.842 0 6.922 3.637 6.922 8.367v9.633h-5.769v-8.54c0-2.037-0.042-4.657-3.001-4.657-3.005 0-3.463 2.218-3.463 4.509v8.688h-5.767v-18z"
                                        />
                                        <path d="M2 12h6v18h-6v-18z" />
                                        <path
                                            d="M8 7c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"
                                        /></svg></span
                                ><span>{{ __('Login with Linkedin') }}</span>
                            </a>
                            <a
                                href="/login/twitter"
                                class="btn btn-sm btn-primary w-200 mb-5 twitter-login"
                            >
                                <span
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1.1"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 32 32"
                                    >
                                        <path
                                            d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z"
                                        /></svg></span
                                ><span>{{ __('Login with Twitter') }}</span>
                            </a>
                        </div>
                        <div class="separator separator-content my-14">
                            <span
                                class="w-125px text-gray-500 fw-semibold fs-7"
                                >{{ __('Or with email') }}</span
                            >
                        </div>
                        <!--begin::Heading-->
                        <jet-validation-errors class="mb-3" />
                        <!--begin::Input group-->
                        <div class="fv-row mb-10">
                            <label
                                class="form-label fw-bolder text-dark fs-6"
                                >{{ __('Name') }}</label
                            >
                            <input
                                v-model="form.name"
                                class="form-control form-control-solid"
                                type="text"
                                placeholder=""
                                name="name"
                                autocomplete="off"
                                required
                            />
                        </div>
                        <!--begin::Input group-->
                        <div class="fv-row mb-10">
                            <label
                                class="form-label fw-bolder text-dark fs-6"
                                >{{ __('Email') }}</label
                            >
                            <input
                                v-model="form.email"
                                class="form-control form-control-solid"
                                type="email"
                                placeholder=""
                                name="email"
                                autocomplete="off"
                                required
                            />
                        </div>
                        <!--end::Input group-->
                        <!--begin::Input group-->
                        <div class="mb-7 fv-row" data-kt-password-meter="true">
                            <!--begin::Wrapper-->
                            <div class="mb-1">
                                <!--begin::Label-->
                                <label
                                    class="form-label fw-bolder text-dark fs-6"
                                    >{{ __('Password') }}</label
                                >
                                <!--end::Label-->
                                <!--begin::Input wrapper-->
                                <div class="position-relative mb-3">
                                    <input
                                        v-model="form.password"
                                        @input="computeScore"
                                        class="form-control form-control-solid"
                                        :type="form.passwordType"
                                        placeholder=""
                                        name="password"
                                        autocomplete="off"
                                    />
                                    <div
                                        class="d-flex translate-middle top-50 end-0 me-n2 position-absolute translate-middle top-50 end-0 me-n2"
                                    >
                                        <span
                                            @click="form.passwordType = 'text'"
                                            :class="{
                                                'd-none':
                                                    form.passwordType ===
                                                    'text',
                                            }"
                                            class="btn btn-sm btn-icon cursor-pointer"
                                            data-kt-password-meter-control="visibility"
                                        >
                                            <i class="bi bi-eye-slash fs-2"></i>
                                        </span>
                                        <span
                                            @click="
                                                form.passwordType = 'password'
                                            "
                                            :class="{
                                                'd-none':
                                                    form.passwordType ===
                                                    'password',
                                            }"
                                            class="btn btn-sm btn-icon cursor-pointer"
                                            data-kt-password-meter-control="visibility"
                                        >
                                            <i class="bi bi-eye fs-2"></i>
                                        </span>
                                    </div>
                                </div>
                                <!--end::Input wrapper-->
                                <!--begin::Meter-->
                                <div
                                    class="d-flex align-items-center mb-3"
                                    data-kt-password-meter-control="highlight"
                                >
                                    <div
                                        :class="{ active: checkSteps > 0 }"
                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                    ></div>
                                    <div
                                        :class="{ active: checkSteps > 1 }"
                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                    ></div>
                                    <div
                                        :class="{ active: checkSteps > 2 }"
                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                    ></div>
                                    <div
                                        :class="{ active: checkSteps > 3 }"
                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
                                    ></div>
                                </div>
                                <!--end::Meter-->
                            </div>
                            <!--end::Wrapper-->
                            <!--begin::Hint-->
                            <div class="text-muted">
                                {{
                                    __(
                                        'Use 8 or more characters with a mix of letters, numbers &amp; symbols.'
                                    )
                                }}
                            </div>
                            <!--end::Hint-->
                        </div>
                        <!--end::Input group=-->
                        <!--begin::Row-->
                        <div class="fv-row mb-10">
                            <label
                                class="form-label fw-bolder text-dark fs-6"
                                >{{ __('Confirm Password') }}</label
                            >
                            <input
                                v-model="form.password_confirmation"
                                class="form-control form-control-solid"
                                type="password"
                                placeholder=""
                                name="confirm-password"
                                autocomplete="off"
                            />
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <!--            <div class="fv-row mb-10">
              <label class="form-label fw-bolder text-dark fs-6">{{ __('Coupon Code') }}</label>
              <input v-model="form.couponCode" class="form-control form-control-solid" type="text" placeholder=""
                name="coupon-code" autocomplete="off" />
            </div>-->
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div
                            class="fv-row mb-2"
                            v-if="
                                $page.props.jetstream
                                    .hasTermsAndPrivacyPolicyFeature
                            "
                        >
                            <div
                                class="form-check form-check-custom form-check-solid form-check-inline mb-5"
                            >
                                <jet-checkbox
                                    name="terms"
                                    id="terms"
                                    v-model:checked="form.terms"
                                />
                                <label
                                    class="custom-control-label ms-2"
                                    for="terms"
                                >
                                    <span
                                        v-html="
                                            __(
                                                'I agree to the :terms_of_service and :privacy_policy',
                                                {
                                                    terms_of_service:
                                                        '<a href=\'https://www.creaitor.ai/terms\' target=\'_blank\' class=\'fw-bolder\'>' +
                                                        __('Terms of Service') +
                                                        '</a>',
                                                    privacy_policy:
                                                        '<a href=\'https://www.creaitor.ai/privacy-policy\' target=\'_blank\' class=\'fw-bolder\'>' +
                                                        __('Privacy Policy') +
                                                        '</a>',
                                                }
                                            )
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div class="fv-row mb-10">
                            <div
                                class="form-check form-check-custom form-check-solid form-check-inline mb-5"
                            >
                                <jet-checkbox
                                    name="marketing"
                                    id="marketing"
                                    v-model:checked="form.marketing"
                                />
                                <label
                                    class="custom-control-label ms-2"
                                    for="marketing"
                                >
                                    {{
                                        __(
                                            'I would like to be informed about news and offers'
                                        )
                                    }}
                                </label>
                            </div>
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div class="text-center pb-lg-0 pb-8">
                            <button
                                type="submit"
                                id="kt_free_trial_submit"
                                class="btn btn-lg btn-primary fw-bolder w-100"
                            >
                                <span class="indicator-label">{{
                                    __('Create an Account')
                                }}</span>
                                <span
                                    v-show="form.processing"
                                    class="indicator-progress"
                                    >{{ __('Please wait...') }}
                                    <span
                                        class="spinner-border spinner-border-sm align-middle ms-2"
                                    ></span
                                ></span>
                            </button>
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div class="text-center mt-4 pb-lg-0 pb-8">
                            <Link
                                :href="route('login')"
                                class="text-muted me-3 text-decoration-none"
                            >
                                {{ __('Already registered?') }}
                            </Link>
                        </div>
                    </form>
                    <!--end::Form-->
                    <div v-else class="d-flex align-baseline justify-center">
                        <vue-turnstile
                            site-key="0x4AAAAAAANZ-D2PLUclm5-z"
                            v-model="token"
                        />
                    </div>
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Right Content-->
        </div>
        <!--end::Authentication - Signup Free Trial-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/ApplicationLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import VueTurnstile from 'vue-turnstile';
import { Head, Link } from '@inertiajs/vue3';

export default defineComponent({
    components: {
        Head,
        JetAuthenticationCard,
        JetAuthenticationCardLogo,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        JetValidationErrors,
        VueTurnstile,
        Link,
    },

    data() {
        let urlParams = new URLSearchParams(window.location.search);
        const utmData = this.getCookie('utmData')
            ? JSON.parse(this.getCookie('utmData'))
            : {};
        const affiliateData = this.getCookie('affiliateData')
            ? JSON.parse(this.getCookie('affiliateData'))
            : {};
        const previousPageURL = this.getCookie('previous_page_url');

        return {
            form: this.$inertia.form({
                name: '',
                email: '',
                password: '',
                passwordType: 'password',
                password_confirmation: '',
                couponCode: '',
                language: navigator.language,
                terms: false,
                marketing: false,
                referral_id: urlParams.get('ref') || '',
                previous_page_url: previousPageURL || null,
                utm_source: utmData.utm_source || null,
                utm_medium: utmData.utm_medium || null,
                utm_campaign: utmData.utm_campaign || null,
                utm_term: utmData.utm_term || null,
                utm_content: utmData.utm_content || null,
                gclid: null,
                ref: affiliateData.ref || null,
                acceptTerms: false,
                optInMarketing: false,
                gaClientId: null,
                gaSessionId: null,
                cookieString: null,
                invitation: null,
                errors: {},
            }),
            checkSteps: 0,
            showForm: false,
            token: '',
        };
    },

    watch: {
        token(newToken) {
            if (newToken) {
                // The token is present, meaning CAPTCHA was completed
                setTimeout(() => {
                    this.showForm = true; // Show the form after 2 seconds
                }, 2000);
            }
        },

        'form.password': function () {
            this.computeScore();
        },
        'form.password_confirmation': function () {
            this.computeScore();
        },
    },

    mounted() {
        Intercom('boot', {
            app_id: INTERCOM_APP_ID,
        });
    },

    methods: {
        submit() {
            this.form.gaClientId = this.getGaClientId();
            this.form.gaSessionId = this.getGaSessionId();
            this.form.cookieString = this.getCookieString();
            this.form.gclid = this.getGclidFromCookie();
            this.form.invitation = this.getInvitation();

            this.form.post(this.route('register'), {
                onFinish: () =>
                    this.form.reset('password', 'password_confirmation'),
            });
        },

        getInvitation() {
            let urlParams = new URLSearchParams(window.location.search);

            return urlParams.get('invitation');
        },

        submitRedeem() {
            this.form.gaClientId = this.getGaClientId();
            this.form.gaSessionId = this.getGaSessionId();
            this.form.cookieString = this.getCookieString();

            this.form.post(this.route('register-lifetime'), {});
        },

        getGaClientId() {
            let gaClientId = this.getCookie('_ga');

            if (gaClientId) {
                gaClientId = gaClientId.split('.').slice(-2).join('.');
            }

            return gaClientId;
        },

        getGaSessionId: function () {
            const cookieValue = this.getCookie('_ga_EWRGSZNZEQ');
            if (cookieValue && cookieValue !== 'deleted') {
                const match = cookieValue.match(/GS1\.1\.(\d+)/);
                return match ? match[1] : null;
            }

            return '';
        },

        getGclidFromCookie() {
            return this.getCookie('gclid');
        },

        getCookie(c_name) {
            if (document.cookie.length > 0) {
                let c_start = document.cookie.indexOf(c_name + '=');
                if (c_start != -1) {
                    c_start = c_start + c_name.length + 1;
                    let c_end = document.cookie.indexOf(';', c_start);
                    if (c_end == -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return null;
        },

        getCookieString() {
            return document.cookie;
        },

        checkLowercase() {
            return /[a-z]/.test(this.form.password) ? true : false; // 20 score
        },

        checkUppercase() {
            return /[A-Z]/.test(this.form.password) ? true : false; // 20 score
        },

        minimumEightSigns() {
            return this.form.password.length > 7 ? true : false;
        },

        minimumOneSpecialCharacter() {
            return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
                this.form.password
            )
                ? true
                : false;
        },

        passwordMatch() {
            return this.form.password === this.form.password_confirmation;
        },

        computeScore() {
            let count = 0;

            if (this.checkLowercase()) {
                count++;
            }
            if (this.checkUppercase()) {
                count++;
            }
            if (this.minimumEightSigns()) {
                count++;
            }
            if (this.minimumOneSpecialCharacter()) {
                count++;
            }

            this.checkSteps = count;
        },
    },

    computed: {
        errors() {
            return this.$page.props.lifetimeErrors;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    },
});
</script>
