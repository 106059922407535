<template>
    <div>
        <h1 class="intro-heading text-center">{{ __('Creaitor Chat') }}</h1>
        <div class="row g-5 mt-10 intro-content">
            <div class="col-lg-4">
                <div class="intro-title text-center">
                    <span class="actions-icon">
                        <span class="icon-xl">
                            <svg
                                stroke="currentColor"
                                fill="none"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="h-6 w-6"
                                width="100%"
                                height="100%"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="12" cy="12" r="5"></circle>
                                <line x1="12" y1="1" x2="12" y2="3"></line>
                                <line x1="12" y1="21" x2="12" y2="23"></line>
                                <line
                                    x1="4.22"
                                    y1="4.22"
                                    x2="5.64"
                                    y2="5.64"
                                ></line>
                                <line
                                    x1="18.36"
                                    y1="18.36"
                                    x2="19.78"
                                    y2="19.78"
                                ></line>
                                <line x1="1" y1="12" x2="3" y2="12"></line>
                                <line x1="21" y1="12" x2="23" y2="12"></line>
                                <line
                                    x1="4.22"
                                    y1="19.78"
                                    x2="5.64"
                                    y2="18.36"
                                ></line>
                                <line
                                    x1="18.36"
                                    y1="5.64"
                                    x2="19.78"
                                    y2="4.22"
                                ></line>
                            </svg>
                        </span>
                    </span>
                    <h3 class="text-center">{{ __('Examples') }}</h3>
                </div>

                <div
                    class="card intro-card card-bordered mb-5 cursor-pointer"
                    v-for="example in chatExamples"
                    :key="example"
                    @click="$emit('single-example-chat-selected', example)"
                >
                    <div class="card-body">
                        {{ example }}
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="intro-title text-center">
                    <span class="actions-icon">
                        <span class="icon-xl">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                                class="h-6 w-6"
                                width="100%"
                                height="100%"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                                ></path>
                            </svg>
                        </span>
                    </span>
                    <h3 class="text-center">{{ __('Capabilities') }}</h3>
                </div>

                <div class="card intro-card card-bordered mb-5">
                    <div class="card-body">
                        {{
                            __(
                                'Remembers what user said earlier in the conversation'
                            )
                        }}
                    </div>
                </div>

                <div class="card intro-card card-bordered mb-5">
                    <div class="card-body">
                        {{ __('Allows user to provide follow-up corrections') }}
                    </div>
                </div>

                <div class="card intro-card card-bordered mb-5">
                    <div class="card-body">
                        {{ __('Trained to decline inappropriate requests') }}
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="intro-title text-center">
                    <span class="actions-icon">
                        <span class="icon-xl">
                            <svg
                                stroke="currentColor"
                                fill="none"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="h-6 w-6"
                                width="100%"
                                height="100%"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                                ></path>
                                <line x1="12" y1="9" x2="12" y2="13"></line>
                                <line x1="12" y1="17" x2="12.01" y2="17"></line>
                            </svg>
                        </span>
                    </span>
                    <h3 class="text-center">{{ __('Limitations') }}</h3>
                </div>

                <div class="card intro-card card-bordered mb-5">
                    <div class="card-body">
                        {{
                            __(
                                'May occasionally generate incorrect information'
                            )
                        }}
                    </div>
                </div>

                <div class="card intro-card card-bordered mb-5">
                    <div class="card-body">
                        {{
                            __(
                                'May occasionally produce harmful instructions or biased content'
                            )
                        }}
                    </div>
                </div>

                <div class="card intro-card card-bordered mb-5">
                    <div class="card-body">
                        {{
                            __(
                                'Limited knowledge of world and events after 2023'
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            chatExamples: [
                this.__('Explain quantum computing in simple terms'),
                this.__('Got any creative ideas for a 10 year old’s birthday?'),
                this.__('How do I make an HTTP request in Javascript?'),
            ],
        };
    },
};
</script>

<style lang="scss">
.intro-heading {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
}

.intro-title {
    margin-bottom: 1rem;

    h3 {
        margin-top: 1rem;
    }

    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
}

.intro-content {
    margin-bottom: 100px;
}

.intro-card {
    border-radius: 8px;
    overflow: hidden;
    background-color: #f4f4f4;

    .card-body {
        padding: 1rem;
        text-align: center;
    }
}

.icon-xl {
    svg {
        width: 100%;
        max-width: 24px;
        max-height: 24px;
        object-fit: contain;
    }
}
</style>
