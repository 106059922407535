<template>
    <div class="fv-row mb-10 fv-plugins-icon-container">
        <!--begin::Label-->
        <label class="fs-6 fw-bold mb-2">{{ title }}</label>
        <!--end::Label-->
        <input
            class="form-control"
            v-model="keyword"
            :placeholder="placeholder"
            @input="
                $emit('value-change', { component: 'keyword', value: keyword })
            "
        />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        inheritedValue: {
            type: String,
        },
    },

    data() {
        return {
            keyword: '',
        };
    },

    watch: {
        inheritedValue: function (value) {
            this.keyword = value;
        },
    },

    mounted() {
        this.keyword = this.inheritedValue;
        let vm = this;
        this.$emit('value-change', { component: 'keyword', value: vm.keyword });
    },
};
</script>
