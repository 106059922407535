<template>
    <app-layout
        :title="__('Notifications')"
        :view="__('All')"
        :user="$attrs.user"
        :main-class="mainClass"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Notifications') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('Notifications') }}</h1>
            </div>
        </div>
        <div class="card card-flush card-bordered">
            <!--begin::Card body-->
            <div class="card-body">
                <div class="d-flex flex-column flex-column-fluid">
                    <div class="card">
                        <!--begin::Card head-->
                        <div
                            class="card-header card-header-stretch"
                            v-if="false"
                        >
                            <!--begin::Title-->
                            <div class="card-title d-flex align-items-center">
                                <i
                                    class="ki-duotone ki-calendar-8 fs-1 text-primary me-3 lh-0"
                                >
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                    <span class="path3"></span>
                                    <span class="path4"></span>
                                    <span class="path5"></span>
                                    <span class="path6"></span>
                                </i>

                                <h3 class="fw-bold m-0 text-gray-800">
                                    {{ currentFullDate }}
                                </h3>
                            </div>
                            <!--end::Title-->

                            <!--begin::Toolbar-->
                            <div class="card-toolbar m-0">
                                <!--begin::Tab nav-->
                                <ul
                                    class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold"
                                    role="tablist"
                                >
                                    <li
                                        class="nav-item"
                                        role="presentation"
                                        @click="tabClicked('today')"
                                    >
                                        <a
                                            id="kt_activity_today_tab"
                                            class="nav-link justify-content-center text-active-gray-800 active"
                                            data-bs-toggle="tab"
                                            role="tab"
                                            href="#kt_activity_today"
                                            aria-selected="true"
                                        >
                                            {{ __('Today') }}
                                        </a>
                                    </li>
                                    <li
                                        class="nav-item"
                                        role="presentation"
                                        @click="tabClicked('week')"
                                    >
                                        <a
                                            id="kt_activity_week_tab"
                                            class="nav-link justify-content-center text-active-gray-800"
                                            data-bs-toggle="tab"
                                            role="tab"
                                            href="#kt_activity_week"
                                            aria-selected="false"
                                            tabindex="-1"
                                        >
                                            {{ __('Week') }}
                                        </a>
                                    </li>
                                    <li
                                        class="nav-item"
                                        role="presentation"
                                        @click="tabClicked('month')"
                                    >
                                        <a
                                            id="kt_activity_month_tab"
                                            class="nav-link justify-content-center text-active-gray-800"
                                            data-bs-toggle="tab"
                                            role="tab"
                                            href="#kt_activity_month"
                                            aria-selected="false"
                                            tabindex="-1"
                                        >
                                            {{ __('Month') }}
                                        </a>
                                    </li>
                                    <li
                                        class="nav-item"
                                        role="presentation"
                                        @click="tabClicked('year')"
                                    >
                                        <a
                                            id="kt_activity_year_tab"
                                            class="nav-link justify-content-center text-active-gray-800 text-hover-gray-800"
                                            data-bs-toggle="tab"
                                            role="tab"
                                            href="#kt_activity_year"
                                            aria-selected="false"
                                            tabindex="-1"
                                        >
                                            {{ currentYear }}
                                        </a>
                                    </li>
                                </ul>
                                <!--end::Tab nav-->
                            </div>
                            <!--end::Toolbar-->
                        </div>
                        <!--end::Card head-->

                        <!--begin::Card body-->
                        <div class="card-body">
                            <!--begin::Tab Content-->
                            <div class="tab-content">
                                <!--begin::Tab panel-->
                                <div
                                    id="kt_activity_today"
                                    class="card-body p-0 tab-pane fade"
                                    :class="[
                                        'today' === currentTab
                                            ? 'active show'
                                            : '',
                                    ]"
                                    role="tabpanel"
                                    v-if="false"
                                    aria-labelledby="kt_activity_today_tab"
                                >
                                    <NotificationTabPanel
                                        :notifications="notifications"
                                        :pageLinks="pageLinks"
                                        :isLoading="isLoading"
                                        :pageDetails="pageDetails"
                                        @pagination-clicked-event="
                                            getAllNotifications($event)
                                        "
                                    >
                                    </NotificationTabPanel>
                                </div>
                                <!--end::Tab panel-->

                                <!--begin::Tab panel-->
                                <div
                                    id="kt_activity_week"
                                    class="card-body p-0 tab-pane fade"
                                    :class="[
                                        'week' === currentTab
                                            ? 'active show'
                                            : '',
                                    ]"
                                    role="tabpanel"
                                    v-if="false"
                                    aria-labelledby="kt_activity_week_tab"
                                >
                                    <NotificationTabPanel
                                        :notifications="notifications"
                                        :pageLinks="pageLinks"
                                        :isLoading="isLoading"
                                        :pageDetails="pageDetails"
                                        @pagination-clicked-event="
                                            getAllNotifications($event)
                                        "
                                    >
                                    </NotificationTabPanel>
                                </div>
                                <!--end::Tab panel-->

                                <!--begin::Tab panel-->
                                <div
                                    id="kt_activity_month"
                                    class="card-body p-0 tab-pane fade"
                                    :class="[
                                        'month' === currentTab
                                            ? 'active show'
                                            : '',
                                    ]"
                                    role="tabpanel"
                                    v-if="false"
                                    aria-labelledby="kt_activity_month_tab"
                                >
                                    <NotificationTabPanel
                                        :notifications="notifications"
                                        :pageLinks="pageLinks"
                                        :isLoading="isLoading"
                                        :pageDetails="pageDetails"
                                        @pagination-clicked-event="
                                            getAllNotifications($event)
                                        "
                                    >
                                    </NotificationTabPanel>
                                </div>
                                <!--end::Tab panel-->

                                <!--begin::Tab panel-->
                                <div
                                    id="kt_activity_year"
                                    class="card-body p-0 tab-pane fade"
                                    :class="[
                                        'year' === currentTab
                                            ? 'active show'
                                            : '',
                                    ]"
                                    role="tabpanel"
                                    aria-labelledby="kt_activity_year_tab"
                                >
                                    <NotificationTabPanel
                                        :notifications="notifications"
                                        :pageLinks="pageLinks"
                                        :isLoading="isLoading"
                                        :pageDetails="pageDetails"
                                        @pagination-clicked-event="
                                            getAllNotifications($event)
                                        "
                                    >
                                    </NotificationTabPanel>
                                </div>
                                <!--end::Tab panel-->
                            </div>
                            <!--end::Tab Content-->
                        </div>
                        <!--end::Card body-->
                    </div>
                </div>
            </div>
            <!--end::Card body-->
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import NotificationTabPanel from '@/Layouts/Notification/NotificationTabPanel.vue';
import moment from 'moment';

export default {
    components: {
        AppLayout,
        NotificationTabPanel,
    },

    data() {
        return {
            mainClass: 'container w-lg-50 my-5',
            nextPageUrl: '',
            notifications: [],
            pageLinks: {},
            pageDetails: {
                currentPage: 1,
                from: 1,
                to: 1,
                total: 1,
            },
            notificationBaseUrl: '/api/notifications/',
            isLoading: true,
            currentTab: 'year',
            availableTabs: [
                // 'today',
                // 'week',
                // 'month',
                'year',
            ],
        };
    },
    mounted() {
        let vm = this;
        let language = this.$page?.props?.user?.user_info?.language;
        language = language ? language : 'en';
        moment.locale(language);
        this.getAllNotifications(
            `${this.notificationBaseUrl}?range-from=${this.currentTab}`
        );

        window.Echo.private('creaitor-notifications').listen(
            'BroadcastAdminNotification',
            (e) => {
                this.tabClicked(this.currentTab);
            }
        );

        emitter.on('new-user-notification', function () {
            vm.tabClicked(vm.currentTab);
        });
    },
    computed: {
        currentFullDate() {
            return moment().format('ll');
        },
        currentYear() {
            return moment().format('YYYY');
        },
    },
    methods: {
        tabClicked(tab) {
            if (!this.availableTabs.includes(tab)) {
                return false;
            }

            this.currentTab = tab;
            this.getAllNotifications(
                `${this.notificationBaseUrl}?range-from=${tab}`
            );
        },
        getAllNotifications(notificationBaseUrl) {
            let vm = this;
            this.isLoading = true;
            this.notifications = [];
            axios.get(notificationBaseUrl).then((response) => {
                vm.nextPageUrl = response.data.next_page_url;
                vm.notifications = response.data.data;
                vm.pageLinks = response.data.links;
                vm.pageDetails.currentPage = response.data.current_page;
                vm.pageDetails.from = response.data.from;
                vm.pageDetails.to = response.data.to;
                vm.pageDetails.total = response.data.total;
                vm.isLoading = false;
            });
        },
    },
};
</script>
