<template>
    <div class="container">
        <div class="row align-items-start  search-image-block">
            <div class="mb-10">
                <select class="form-select" v-model="imageProvider">
                    <option>{{ __('Please select a provider') }}</option>
                    <option :value="provider.slug" v-for="(provider, index) in providers" :key="index">
                        {{ provider.label }}
                    </option>
                </select>
            </div>

            <div class="mb-10">
                <input type="text" class="form-control" :placeholder="__('Search key word')" v-model="search" />
            </div>

            <div class="mb-0 text-end">
                <button type="button" class="btn btn-primary" :class="[isLoading ? 'disabled' : '']" @click="searchImage">
                    <span v-if="!isLoading">{{ __('Search') }}</span>
                    <span v-else>{{ __('Loading..') }}</span>
                </button>
            </div>
        </div>
        <div class="row align-items-start mt-10 search-result-block" v-if="images.length">
            <img class="thumb-images" :src="image.thumb" v-for="(image, index) in images" :key="index"
                :alt="`search-result-image-${index}`" @click="$emit('image-ready', image.original)">
        </div>
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';

export default {

    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast }
    },

    data() {
        return {
            isLoading: false,
            imageProvider: '',
            search: '',
            perPage: 4,
            images: [],
            providers: [
                {
                    slug: 'pexels',
                    label: this.__('Pexels'),
                },
                {
                    slug: 'unsplash',
                    label: this.__('Unsplash'),
                },
            ],
        }
    },

    methods: {
        searchImage() {

            if (!this.isValidSubmission()) {
                return false;
            }

            let vm = this;
            this.isLoading = true;

            let data = {
                image_provider: this.imageProvider,
                query: this.search,
                per_page: this.perPage,
            };

            axios.get(`/api/image/search`, { params: data })
                .then(response => {
                    vm.images = response.data;
                    vm.isLoading = false;
                })
                .catch(error => {
                    vm.toast.error(vm.__('Some error occured'));
                    vm.isLoading = false;
                });
        },
        isValidSubmission() {
            let isValid = true;

            if (!this.search) {
                this.toast.error(this.__('Please enter something to search'));
                isValid = false;
            }

            if (!this.imageProvider) {
                this.toast.error(this.__('Please select an image provider'));
                isValid = false;
            }

            return isValid;
        },
    },
}
</script>

<style lang="scss">
.thumb-images {
    width: 25%;
    height: 100px;
}
</style>