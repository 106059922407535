import { defineStore } from 'pinia';
import { useTeamStore } from '@/Stores/TeamStore';
import { useUserStore } from '@/Stores/UserStore';
export const useSubscriptionStore = defineStore('subscription', {
    state: () => {
        return getDefaultState();
    },
    getters: {
        getCurrentPlan: (state) => {
            return state?.currentPlan;
        },
        getPlans: (state) => {
            return state?.plans;
        },
        getPlanIds: (state) => {
            return (plan) => state.getPlans[plan];
        },
        getUser: () => {
            const user = useUserStore();

            return user?.getUser;
        },
        currentTeam: () => {
            const team = useTeamStore();

            return team?.getCurrentTeam;
        },
        getSubscriptions: (state) => {
            return state?.currentTeam?.subscriptions || [];
        },
        getLifetimeSubscriptions: (state) => {
            return state?.currentTeam?.lifetime_subscriptions || [];
        },
        planBasedLtd: (state) => {
            return (
                state.getLifetimeSubscriptions.filter(
                    (subscription) => subscription.plan
                )?.length > 0
            );
        },
        planBasedLtdStandard: (state) => {
            return (
                state.getLifetimeSubscriptions.filter(
                    (subscription) => subscription.plan === 'standard'
                )?.length > 0
            );
        },
        planBasedLtdProfessional: (state) => {
            return (
                state.getLifetimeSubscriptions.filter(
                    (subscription) => subscription.plan === 'professional'
                )?.length > 0
            );
        },
        isUserOnTrial: (state) => {
            return state.getUser?.on_trial || false;
        },
        hasRecurringSubscription: (state) => {
            return state.getSubscriptions?.length > 0;
        },
        hasLifetimeSubscription: (state) => {
            return state.getLifetimeSubscriptions?.length > 0;
        },
        hasSubscription: (state) => {
            return (
                state.hasRecurringSubscription ||
                state.isUserOnTrial ||
                state.hasLifetimeSubscription
            );
        },
        hasNoSubscription: (state) => {
            let hasActiveSubscriptionWithStatus =
                state.hasSubscriptionWithStatuses([
                    'active',
                    'past_due',
                    'trialing',
                ]);

            return (
                !hasActiveSubscriptionWithStatus &&
                !state.hasLifetimeSubscription
            );
        },
        hasSubscriptionWithStatuses: (state) => {
            return (statuses) => {
                return state.getSubscriptions.some((subscription) =>
                    statuses.includes(subscription.paddle_status)
                );
            };
        },
        // Check if the user has a normal standard or professional subscription
        hasNormalSubscription: (state) => {
            let plans = state.getPlans;

            return state.getSubscriptions?.some((subscription) => {
                const paddlePlanId = subscription.paddle_plan;
                const professionalPlans = plans.professional;
                const standardPlans = plans.standard;

                return (
                    professionalPlans.includes(paddlePlanId) ||
                    standardPlans.includes(paddlePlanId)
                );
            });
        },
        hasUnlimitedUsage: (state) => {
            // Check if the user has a special lifetime subscription based on plan name or having more than one lifetime subscription
            let hasLifetimeSubscription = state.getLifetimeSubscriptions.some(
                (subscription) => {
                    // Check if the plan name is "professional" or "standard"
                    if (
                        subscription.plan &&
                        ['professional', 'standard'].includes(
                            subscription.plan.toLowerCase()
                        )
                    ) {
                        return true;
                    } else if (
                        subscription.plan &&
                        ['basic'].includes(subscription.plan.toLowerCase())
                    ) {
                        // Check if the plan name is "basic"
                        return false;
                    }

                    // Check if the user has more than one lifetime subscription
                    return state.hasLifetimeSubscription;
                }
            );

            // Return true if the user has a normal subscription or a special lifetime subscription
            return state.hasNormalSubscription || hasLifetimeSubscription;
        },
        getAddons: (state) => {
            return state.getSubscriptions[0]?.addons || [];
        },
        hasAddons: (state) => {
            return state.getAddons?.length > 0;
        },
        hasBasicPlan: (state) => {
            if (state.hasRecurringSubscription) {
                return state.getSubscriptions.some(
                    (subscription) =>
                        state
                            .getPlanIds('basic')
                            .includes(subscription.paddle_plan.toString()) &&
                        ['active', 'trialing'].includes(
                            subscription.paddle_status
                        )
                );
            }
            return false;
        },
        hasStandardPlan: (state) => {
            if (state.hasRecurringSubscription) {
                return state.getSubscriptions.some(
                    (subscription) =>
                        state
                            .getPlanIds('standard')
                            .includes(subscription.paddle_plan.toString()) &&
                        ['active', 'trialing'].includes(
                            subscription.paddle_status
                        )
                );
            }
            return false;
        },
        hasProfessionalPlan: (state) => {
            if (state.hasRecurringSubscription) {
                return state.getSubscriptions.some(
                    (subscription) =>
                        state
                            .getPlanIds('professional')
                            .includes(subscription.paddle_plan.toString()) &&
                        ['active', 'trialing'].includes(
                            subscription.paddle_status
                        )
                );
            }
            return false;
        },
    },
    actions: {
        setCurrentPlan(value) {
            this.currentPlan = value;
        },
        setPlans(value) {
            this.plans = value;
        },
    },
});

function getDefaultState() {
    return {
        currentPlan: null,
        plans: null,
    };
}
