<template>
    <div class="container">
        <div class="row align-items-start mt-0">
            <input type="file" id="input-file" class="input-file-input d-none" name="input-file"
                @change="handleFileUpload" />
            <label for="input-file" class="input-file-label btn btn-primary"> {{ __("Upload") }}</label>
        </div>
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';

export default {

    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast }
    },

    methods: {
        async handleFileUpload(event) {

            let file = event.target.files[0];
            let vm = this;

            this.getFileAsBase64(file)
                .then(base64String => {
                    vm.$emit('image-ready', base64String);
                })
                .catch(error => {
                    vm.toast.error(vm.__('Some error occured'));
                });
        },
        getFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    const base64String = reader.result;
                    resolve(base64String);
                };
                reader.onerror = reject;
            });
        },
    },
}
</script>