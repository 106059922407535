<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_add_image_generator_to_plan"
        tabindex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <!--begin::Modal content-->
            <div class="modal-content modal-rounded">
                <!--begin::Modal header-->
                <div class="modal-header py-7 d-flex justify-content-between">
                    <!--begin::Modal title-->
                    <h2>{{ __('Addons') }}</h2>
                    <!--end::Modal title-->
                    <!--begin::Close-->
                    <a
                        href="#"
                        @click="closeModal()"
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-kt-modal-action-type="close"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </a>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body scroll-y m-5">
                    <!--begin::Stepper-->
                    <div
                        :class="{ first: step === 1, last: step === 2 }"
                        class="stepper stepper-links d-flex flex-column gap-5 first"
                        id="add_addon_stepper"
                        data-kt-stepper="true"
                    >
                        <!--begin::Nav-->
                        <div class="stepper-nav justify-content-center py-2">
                            <!--begin::Step 1-->
                            <div
                                :class="{
                                    current: step === 1,
                                    pending: step !== 1,
                                }"
                                class="stepper-item"
                            >
                                <h3 class="stepper-title">
                                    {{ __('Add Addon') }}
                                </h3>
                            </div>
                            <!--end::Step 1-->
                            <!--begin::Step 2-->
                            <div
                                :class="{
                                    current: step === 2,
                                    pending: step !== 2,
                                }"
                                class="stepper-item"
                            >
                                <h3 class="stepper-title">
                                    {{ __('Confirm') }}
                                </h3>
                            </div>
                            <!--end::Step 2-->
                            <!--begin::Step 3-->
                            <div
                                :class="{
                                    current: step === 3,
                                    pending: step !== 3,
                                }"
                                class="stepper-item"
                            >
                                <h3 class="stepper-title">
                                    {{ __('Completed') }}
                                </h3>
                            </div>
                            <!--end::Step 3-->
                        </div>
                        <!--end::Nav-->
                        <!--begin::Form-->
                        <div
                            class="mx-auto w-100 mw-600px pt-15 pb-10 fv-plugins-bootstrap5 fv-plugins-framework"
                            id="add_addon_stepper_form"
                        >
                            <!--begin::Step 1-->
                            <div
                                :class="{
                                    current: step === 1,
                                    pending: step !== 1,
                                }"
                                data-kt-stepper-element="content"
                            >
                                <!--begin::Wrapper-->
                                <div class="w-100">
                                    <!--begin::Heading-->
                                    <div class="mb-13">
                                        <!--begin::Title-->
                                        <h2 class="mb-3">{{ __('Addon') }}</h2>
                                        <!--end::Title-->
                                        <!--begin::Description-->
                                        <div
                                            class="text-muted fw-semibold fs-5"
                                        >
                                            {{ __('Add addons to your plan') }}
                                        </div>
                                        <!--end::Description-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Input group-->
                                    <div
                                        class="fv-row mb-15 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                                        data-kt-buttons="true"
                                        data-kt-initialized="1"
                                    >
                                        <!--begin::Option-->
                                        <label
                                            class="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 mb-6 active"
                                        >
                                            <!--begin::Input-->
                                            <input
                                                class="btn-check"
                                                type="radio"
                                                checked="checked"
                                                name="offer_type"
                                                value="1"
                                            />
                                            <!--end::Input-->
                                            <!--begin::Label-->
                                            <span
                                                class="d-flex w-100 justify-content-between"
                                            >
                                                <!--begin::Icon-->
                                                <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg-->
                                                <span
                                                    class="svg-icon svg-icon-3hx"
                                                >
                                                    <svg
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 18 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            opacity="0.3"
                                                            d="M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z"
                                                            fill="currentColor"
                                                        ></path>
                                                        <path
                                                            d="M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon-->
                                                <!--end::Icon-->
                                                <!--begin::Info-->
                                                <span class="ms-4">
                                                    <span
                                                        class="fs-3 fw-bold text-gray-900 mb-2 d-block"
                                                        >{{
                                                            __(
                                                                'Image Generator'
                                                            )
                                                        }}</span
                                                    >
                                                    <span
                                                        class="fw-semibold fs-4 text-muted"
                                                        >{{
                                                            __(
                                                                'Create unlimited unique and loyalty free images'
                                                            )
                                                        }}</span
                                                    >
                                                </span>
                                                <!--end::Info-->
                                                <div class="ms-5">
                                                    <span class="mb-2">$</span
                                                    ><span
                                                        class="fs-3x fw-bolder"
                                                        data-kt-plan-price-month="19"
                                                        data-kt-plan-price-annual="199"
                                                        >19</span
                                                    ><span
                                                        class="fs-7 opacity-50"
                                                        >/
                                                        <span
                                                            data-kt-element="period"
                                                            >{{
                                                                __('Mo')
                                                            }}</span
                                                        ></span
                                                    >
                                                </div>
                                            </span>
                                            <!--end::Label-->
                                        </label>
                                        <!--end::Option-->
                                        <div
                                            class="fv-plugins-message-container invalid-feedback"
                                        ></div>
                                    </div>
                                    <!--end::Input group-->
                                </div>
                                <!--end::Wrapper-->
                            </div>
                            <!--end::Step 1-->
                            <!--begin::Step 2-->
                            <div
                                :class="{
                                    current: step === 2,
                                    pending: step !== 2,
                                }"
                                data-kt-stepper-element="content"
                            >
                                <!--begin::Wrapper-->
                                <div class="w-100">
                                    <!--begin::Heading-->
                                    <div class="pb-10 pb-lg-12">
                                        <!--begin::Title-->
                                        <h1 class="fw-bold text-dark">
                                            {{ __('Confirmation') }}
                                        </h1>
                                        <!--end::Title-->
                                        <!--begin::Description-->
                                        <div
                                            class="text-muted fw-semibold fs-6"
                                        >
                                            {{
                                                __(
                                                    'The following product will be added as addon on top of your current subscription.'
                                                )
                                            }}
                                        </div>
                                        <!--end::Description-->
                                    </div>
                                    <!--end::Heading-->
                                    <div class="d-flex">
                                        <div class="table-responsive w-100">
                                            <table
                                                class="table table-striped gy-7 gs-7"
                                            >
                                                <thead>
                                                    <tr
                                                        class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200"
                                                    >
                                                        <th class="w-75">
                                                            {{ __('Addons') }}
                                                        </th>
                                                        <th>{{ __('Qty') }}</th>
                                                        <th class="text-right">
                                                            {{ __('Price') }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {{
                                                                __(
                                                                    'Image Generator'
                                                                )
                                                            }}
                                                        </td>
                                                        <td>{{ __('1') }}</td>
                                                        <td class="text-right">
                                                            $19/{{
                                                                __('Month')
                                                            }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!--end::Wrapper-->
                            </div>
                            <!--end::Step 2-->
                            <!--begin::Step 3-->
                            <div
                                :class="{
                                    current: step === 3,
                                    pending: step !== 3,
                                }"
                                data-kt-stepper-element="content"
                            >
                                <!--begin::Wrapper-->
                                <div class="w-100">
                                    <!--begin::Heading-->
                                    <div class="pb-12 text-center">
                                        <!--begin::Title-->
                                        <h1 class="fw-bold text-dark">
                                            {{ __('Successful added!') }}
                                        </h1>
                                        <!--end::Title-->
                                        <!--begin::Description-->
                                        <div
                                            class="fw-semibold text-muted fs-4"
                                        >
                                            {{
                                                __(
                                                    'Thank you very much, you are ready to use it now.'
                                                )
                                            }}
                                        </div>
                                        <!--end::Description-->
                                    </div>
                                    <!--end::Heading-->
                                    <!--begin::Actions-->
                                    <div class="d-flex flex-center pb-20">
                                        <button
                                            type="button"
                                            class="btn btn-lg btn-primary"
                                            @click="
                                                modal.hide();
                                                step = 1;
                                            "
                                        >
                                            {{ __('Close') }}
                                        </button>
                                    </div>
                                    <!--end::Actions-->
                                </div>
                            </div>
                            <!--end::Step 3-->
                            <!--begin::Actions-->
                            <div class="d-flex flex-stack pt-10">
                                <!--begin::Wrapper-->
                                <div class="me-2">
                                    <button
                                        type="button"
                                        class="btn btn-lg btn-light-primary me-3"
                                        @click="previousStep"
                                        data-kt-stepper-action="previous"
                                    >
                                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr063.svg-->
                                        <span class="svg-icon svg-icon-3 me-1">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x="6"
                                                    y="11"
                                                    width="13"
                                                    height="2"
                                                    rx="1"
                                                    fill="currentColor"
                                                ></rect>
                                                <path
                                                    d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->{{ __('Back') }}
                                    </button>
                                </div>
                                <!--end::Wrapper-->
                                <!--begin::Wrapper-->
                                <div v-if="step !== 3">
                                    <button
                                        type="button"
                                        class="btn btn-lg btn-primary"
                                        @click.stop.prevent="addAddons"
                                        data-kt-stepper-action="submit"
                                    >
                                        <span
                                            v-if="loading === false"
                                            class="indicator-label"
                                            >{{ __('Submit') }}
                                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                                            <span
                                                v-if="loading === false"
                                                class="svg-icon svg-icon-3 ms-2 me-0"
                                            >
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        opacity="0.5"
                                                        x="18"
                                                        y="13"
                                                        width="13"
                                                        height="2"
                                                        rx="1"
                                                        transform="rotate(-180 18 13)"
                                                        fill="currentColor"
                                                    ></rect>
                                                    <path
                                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon--></span
                                        >
                                        <span
                                            v-if="loading === true"
                                            class="indicator-progress"
                                            >{{ __('Please wait...') }}
                                            <span
                                                class="spinner-border spinner-border-sm align-middle ms-2"
                                            ></span>
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-lg btn-primary"
                                        @click.stop.prevent="nextStep"
                                        data-kt-stepper-action="next"
                                    >
                                        {{ __('Continue') }}
                                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                                        <span
                                            class="svg-icon svg-icon-3 ms-1 me-0"
                                        >
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x="18"
                                                    y="13"
                                                    width="13"
                                                    height="2"
                                                    rx="1"
                                                    transform="rotate(-180 18 13)"
                                                    fill="currentColor"
                                                ></rect>
                                                <path
                                                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                    </button>
                                </div>
                                <!--end::Wrapper-->
                            </div>
                            <!--end::Actions-->
                        </div>
                        <!--end::Form-->
                    </div>
                    <!--end::Stepper-->
                </div>
                <!--begin::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import { Modal } from 'bootstrap';

export default {
    props: {
        user: Object,
    },
    data() {
        return {
            intention: 'subscribe',
            switcher: {
                monthly: true,
                yearly: false,
            },
            selectingNewPlan: false,
            plan: 'basic',
            currentPlan: null,
            billableId: this.user.current_team_id,
            billableType: 'team',
            modal: {},
            loading: false,
            promoDuration: 0,
            step: 1,
        };
    },
    mounted() {
        let vm = this;
        emitter.on('open-add-image-generator-to-plan', function (data) {
            vm.modal.show();
            vm.intention = data ? data.intention : 'subscribe';
            vm.currentPlan = data ? parseInt(data.currentPlan) : 23634;
        });

        emitter.on('update-promo-duration', function (ev) {
            vm.promoDuration = ev.duration;
        });

        this.modal = new Modal(
            document.getElementById('kt_modal_add_image_generator_to_plan'),
            {
                backdrop: 'dynamic',
            }
        );
    },
    methods: {
        nextStep() {
            this.step++;
        },
        previousStep() {
            this.step--;
        },
        addAddons() {
            this.loading = true;

            let vm = this;

            axios
                .post('/api/teams/add-image-generator-addon')
                .then((response) => {
                    vm.loading = false;
                    vm.$emit('update-plan', response.data);
                    vm.startReloadingData();
                })
                .catch((error) => {
                    vm.loading = false;
                    console.log(error);
                });

            this.step++;
        },

        /**
         * Start periodically reloading the page's data.
         */
        startReloadingData() {
            setTimeout(() => {
                this.reloadData();
            }, 2000);
        },

        /**
         * Reload the page's data, while maintaining any current state.
         */
        reloadData() {
            return this.$inertia.reload({
                onSuccess: () => {
                    if (this.$page.props.state == 'pending') {
                        this.startReloadingData();
                    }

                    if (this.selectingNewPlan) {
                        this.selectingNewPlan = false;
                    }

                    Paddle.Spinner.hide();
                },
            });
        },

        getCookie(c_name) {
            if (document.cookie.length > 0) {
                let c_start = document.cookie.indexOf(c_name + '=');
                if (c_start != -1) {
                    c_start = c_start + c_name.length + 1;
                    let c_end = document.cookie.indexOf(';', c_start);
                    if (c_end == -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return null;
        },

        closeModal() {
            this.modal.hide();
        },

        toggleSwitcher() {
            this.switcher.monthly = !this.switcher.monthly;
            this.switcher.yearly = !this.switcher.yearly;
        },

        selectPlan(plan) {
            this.plan = plan;
        },
    },
    computed: {
        currentPeriod() {
            return this.switcher.monthly ? 'monthly' : 'yearly';
        },
        paddlePlan() {
            let plan = this.showPromo ? this.plan + '_promo' : this.plan;

            return this.$page.props.plans[plan][this.currentPeriod];
        },
        showPromo() {
            return this.promoDuration > 0;
        },
    },
};
</script>
