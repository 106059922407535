<template>
    <div>
        <jet-section-border />

        <!-- Project Settings -->
        <jet-form-section @submitted="addProject">
            <template #title>
                {{ __('Project Settings') }}
            </template>

            <template #description>
                {{ __('Edit your project settings') }}
            </template>

            <template #form>
                <div class="mb-3">
                    {{ __('Edit your project settings.') }}
                </div>
                <div class="mb-3">
                    <a
                        href="#"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_projects"
                    >
                        {{ __('Edit Settings') }}
                    </a>
                </div>
            </template>
        </jet-form-section>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useForm } from '@inertiajs/vue3';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetSectionBorder from '@/Jetstream/SectionBorder.vue';

export default defineComponent({
    components: {
        JetFormSection,
        JetSectionBorder,
    },
    setup() {
        const form = useForm({
            // your form fields here
        });

        const addProject = () => {
            // your project adding logic here
            form.post(route('projects.store'), {
                errorBag: 'addProject',
                preserveScroll: true,
                onSuccess: () => form.reset(),
            });
        };

        return {
            form,
            addProject,
        };
    },
});
</script>
