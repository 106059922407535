<template>
    <draggable
        class="dragArea list-group outline-elements w-100"
        :list="generatedUnselectedFaqs"
        :group="{ name: 'faqsIdeas', pull: 'clone', put: false }"
    >
        <div
            v-for="faq in generatedUnselectedFaqs"
            :key="faq.id"
            class="list-group-item"
            @click.stop="addToSelectedFaqList(faq)"
        >
            <div class="h2-element draggable-element">
                <span class="badge badge-secondary me-2">H2</span>
                <span>{{ faq.question }}</span>
                <span class="arrow-up"><i class="bi bi-arrow-up"></i></span>
            </div>
        </div>
    </draggable>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';

export default {
    components: {
        draggable: VueDraggableNext,
    },

    setup() {
        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        // Make it available inside methods
        return { articleWizardStore };
    },

    methods: {
        addToSelectedFaqList(faq) {
            this.articleWizardStore.addToSelectedFaqList(faq);
            this.$emit('rebuild-selected-article-outline');
        },
    },

    computed: {
        generatedFaqs() {
            return (
                this.articleWizardStore?.getAiGeneratedFaqIdeas?.map(
                    (faq, index) => {
                        return {
                            question: faq,
                            id: `generated-faq-${Date.now()}-${index}`,
                        };
                    }
                ) || []
            );
        },
        generatedUnselectedFaqs() {
            try {
                let currentSelectedFaqs =
                    this.articleWizardStore.getSelectedFaqList;

                if (0 === currentSelectedFaqs.length) {
                    return this.generatedFaqs;
                }

                const faqsNotInSubHeadingList = this.generatedFaqs.filter(
                    (generatedFaq) => {
                        return !currentSelectedFaqs.find(
                            (currentSelectedFaq) =>
                                currentSelectedFaq.id === generatedFaq.id
                        );
                    }
                );

                return faqsNotInSubHeadingList;
            } catch (error) {
                return this.generatedFaqs;
            }
        },
    },
};
</script>

<style lang="scss">
.outline-elements {
    .h3-element {
        margin-left: 10px;

        span {
            &.arrow-up {
                float: right;

                i {
                    color: var(--bs-link-color);
                }
            }
        }
    }

    .list-group-item {
        border-radius: 0;

        &:hover,
        &:focus {
            background-color: rgba(54, 153, 255, 0.1);
        }
    }
}
</style>
