<template>
    <div class="card card-flush card-bordered pt-3 mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div class="card-header">
            <!--begin::Card title-->
            <div class="card-title">
                <h2>{{ __('Referrals') }}</h2>
            </div>
            <!--end::Card title-->
            <!--begin::Toolbar-->
            <div class="card-toolbar">
                <!--begin::Tab nav-->
                <ul
                    class="nav nav-stretch fs-5 fw-bold nav-line-tabs nav-line-tabs-2x border-transparent"
                    role="tablist"
                >
                    <li class="nav-item" role="presentation">
                        <a
                            class="nav-link text-active-primary active"
                            data-bs-toggle="tab"
                            role="tab"
                            @click="fetchReferrals(referralUrl, 'active')"
                            href="#kt_active_referrals"
                        >
                            {{ __('Active') }}
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a
                            class="nav-link text-active-primary"
                            data-bs-toggle="tab"
                            role="tab"
                            @click="fetchReferrals(referralUrl, 'pending')"
                            href="#kt_inactive_referrals"
                        >
                            {{ __('Pending') }}
                        </a>
                    </li>
                </ul>
                <!--end::Tab nav-->
            </div>
            <!--end::Toolbar-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-2">
            <!--begin::Tab Content-->
            <div class="tab-content">
                <!--begin::Tab panel-->
                <div
                    id="kt_active_referrals"
                    class="tab-pane fade show active"
                    role="tabpanel"
                >
                    <div v-if="!isLoading">
                        <!--begin::Table wrapper-->
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <table
                                class="table align-middle table-row-dashed fs-6 fw-bolder gs-0 gy-4 p-0 m-0"
                                aria-label="active-referral-table"
                            >
                                <!--begin::Thead-->
                                <thead
                                    class="border-bottom border-gray-200 fs-7 text-uppercase fw-bolder"
                                >
                                    <tr class="text-start text-gray-400">
                                        <th class="min-w-100px">
                                            {{ __('Email') }}
                                        </th>
                                        <th class="min-w-100px">
                                            {{ __('Credit Amount') }}
                                        </th>
                                        <th class="min-w-100px">
                                            {{ __('Remarks') }}
                                        </th>
                                    </tr>
                                </thead>
                                <!--end::Thead-->
                                <!--begin::Tbody-->
                                <tbody class="fs-6 fw-bold text-gray-600">
                                    <tr
                                        v-for="(referral, index) in referrals"
                                        :key="index"
                                    >
                                        <td>
                                            <a
                                                href="#"
                                                class="text-gray-600 text-hover-primary"
                                                >{{ referral.email }}</a
                                            >
                                        </td>
                                        <td>
                                            <a
                                                href="#"
                                                class="text-gray-600 text-hover-primary"
                                            >
                                                {{
                                                    referral.got_credits
                                                        ? '1,00,000'
                                                        : '0'
                                                }}
                                            </a>
                                        </td>
                                        <td>
                                            {{
                                                !referral.got_credits
                                                    ? __(
                                                          "User didn't subscribed to a plan"
                                                      )
                                                    : ''
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                                <!--end::Tbody-->
                            </table>
                            <!--end::Table-->
                        </div>
                        <!--end::Table wrapper-->
                        <div class="row">
                            <div
                                class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                            >
                                <div class="dataTables_length">
                                    <label>
                                        {{ __('Showing') }} {{ pageFrom }}
                                        {{ __('to') }} {{ pageTo }}
                                        {{ __('of') }} {{ totalPages }}
                                        {{ __('entries') }}
                                    </label>
                                </div>
                            </div>
                            <div
                                class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                            >
                                <div
                                    class="dataTables_paginate paging_simple_numbers"
                                >
                                    <ul class="pagination">
                                        <li
                                            v-for="(link, index) in pageLinks"
                                            class="page-item"
                                            :key="index"
                                            :class="{
                                                'prev previous': index === 0,
                                                next: index === 14,
                                                active:
                                                    link.label == currentPage,
                                            }"
                                        >
                                            <a
                                                href="#"
                                                @click.prevent="
                                                    fetchReferrals(link.url)
                                                "
                                                class="page-link"
                                            >
                                                <span v-if="index === 0"
                                                    ><i class="previous"></i
                                                ></span>
                                                <span
                                                    v-else-if="
                                                        index ===
                                                        Object.keys(pageLinks)
                                                            .length -
                                                            1
                                                    "
                                                    ><i class="next"></i
                                                ></span>
                                                <span v-else>{{
                                                    link.label
                                                }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="container">
                        <div class="col-md-12 p-10">
                            <div class="align-items-center text-center">
                                <div
                                    class="d-flex flex-column align-items-center text-center"
                                >
                                    <span
                                        class="spinner-border text-primary"
                                        role="status"
                                    ></span>
                                    <span
                                        class="text-gray-800 fs-6 fw-semibold mt-5"
                                        >{{ __('Loading...') }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Tab panel-->

                <!--begin::Tab panel-->
                <div
                    id="kt_inactive_referrals"
                    class="tab-pane fade show"
                    role="tabpanel"
                >
                    <div v-if="!isLoading">
                        <!--begin::Table wrapper-->
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <table
                                class="table align-middle table-row-dashed fs-6 fw-bolder gs-0 gy-4 p-0 m-0"
                                aria-label="inactive-referral-table"
                            >
                                <!--begin::Thead-->
                                <thead
                                    class="border-bottom border-gray-200 fs-7 text-uppercase fw-bolder"
                                >
                                    <tr class="text-start text-gray-400">
                                        <th class="min-w-100px">
                                            {{ __('Email') }}
                                        </th>
                                        <th class="min-w-100px">
                                            {{ __('Credit Amount') }}
                                        </th>
                                    </tr>
                                </thead>
                                <!--end::Thead-->
                                <!--begin::Tbody-->
                                <tbody class="fs-6 fw-bold text-gray-600">
                                    <tr
                                        v-for="(referral, index) in referrals"
                                        :key="index"
                                    >
                                        <td>
                                            <a
                                                href="#"
                                                class="text-gray-600 text-hover-primary"
                                                >{{ referral.email }}</a
                                            >
                                        </td>
                                        <td>
                                            <a
                                                href="#"
                                                class="text-gray-600 text-hover-primary"
                                                >0</a
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                                <!--end::Tbody-->
                            </table>
                            <!--end::Table-->
                        </div>
                        <!--end::Table wrapper-->
                        <div class="row">
                            <div
                                class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                            >
                                <div class="dataTables_length">
                                    <label>
                                        {{ __('Showing') }} {{ pageFrom }}
                                        {{ __('to') }} {{ pageTo }}
                                        {{ __('of') }} {{ totalPages }}
                                        {{ __('entries') }}
                                    </label>
                                </div>
                            </div>
                            <div
                                class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                            >
                                <div
                                    class="dataTables_paginate paging_simple_numbers"
                                >
                                    <ul class="pagination">
                                        <li
                                            v-for="(link, index) in pageLinks"
                                            class="page-item"
                                            :key="index"
                                            :class="{
                                                'prev previous': index === 0,
                                                next: index === 14,
                                                active:
                                                    link.label == currentPage,
                                            }"
                                        >
                                            <a
                                                href="#"
                                                @click.prevent="
                                                    fetchReferrals(
                                                        link.url,
                                                        'inactive'
                                                    )
                                                "
                                                class="page-link"
                                            >
                                                <span v-if="index === 0"
                                                    ><i class="previous"></i
                                                ></span>
                                                <span
                                                    v-else-if="
                                                        index ===
                                                        Object.keys(pageLinks)
                                                            .length -
                                                            1
                                                    "
                                                    ><i class="next"></i
                                                ></span>
                                                <span v-else>{{
                                                    link.label
                                                }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="container">
                        <div class="col-md-12 p-10">
                            <div class="align-items-center text-center">
                                <div
                                    class="d-flex flex-column align-items-center text-center"
                                >
                                    <span
                                        class="spinner-border text-primary"
                                        role="status"
                                    ></span>
                                    <span
                                        class="text-gray-800 fs-6 fw-semibold mt-5"
                                        >{{ __('Loading...') }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Tab panel-->
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';

export default {
    props: {
        user: Object,
    },

    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast };
    },

    data() {
        return {
            isLoading: false,
            referrals: [],
            pageLinks: {},
            pageFrom: 1,
            pageTo: 1,
            totalPages: 1,
            currentPage: 1,
            referralUrl: `/api/referral`,
        };
    },

    mounted() {
        this.fetchReferrals(this.referralUrl);
    },

    methods: {
        fetchReferrals(url, status = 'active') {
            if (!url) {
                return false;
            }
            this.isLoading = true;

            let vm = this;

            let requestUrl = url.includes('page')
                ? `${url}&status=${status}`
                : `${url}?status=${status}`;

            axios
                .get(requestUrl)
                .then((response) => {
                    vm.isLoading = false;
                    vm.referrals = response.data.data;
                    vm.pageLinks = response.data.links;
                    vm.pageFrom = response.data.from;
                    vm.pageTo = response.data.to;
                    vm.totalPages = response.data.total;
                    vm.currentPage = response.data.current_page;
                })
                .catch((error) => {
                    vm.toast.error(vm.__('Some error occured'));
                });
        },
    },
};
</script>
