<template>
    <div
        class="logo-mark-svg"
        :class="cssClasses"
        :style="{ width: width + 'px', height: height + 'px' }"
    >
        <svg
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 128.98 113.78"
        >
            <defs>
                <linearGradient
                    :id="'linear-gradient-' + randomId"
                    x1="0.99"
                    y1="49.81"
                    x2="104.18"
                    y2="108.06"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stop-color="#91f24a" />
                    <stop offset="0.64" stop-color="#2bc7d4" />
                    <stop offset="0.72" stop-color="#2ac3d3" />
                    <stop offset="0.8" stop-color="#28b7d0" />
                    <stop offset="0.88" stop-color="#25a3cb" />
                    <stop offset="0.96" stop-color="#2088c4" />
                    <stop offset="1" stop-color="#1d75bf" />
                </linearGradient>
                <linearGradient
                    :id="'linear-gradient-' + randomId + '-2'"
                    x1="31.47"
                    y1="-4.18"
                    x2="134.66"
                    y2="54.06"
                    :xlink:href="'#linear-gradient-' + randomId"
                />
                <linearGradient
                    :id="'linear-gradient-' + randomId + '-3'"
                    x1="-5.65"
                    y1="61.59"
                    x2="97.53"
                    y2="119.83"
                    :xlink:href="'#linear-gradient-' + randomId"
                />
                <linearGradient
                    :id="'linear-gradient-' + randomId + '-4'"
                    x1="19.48"
                    y1="17.06"
                    x2="122.67"
                    y2="75.3"
                    :xlink:href="'#linear-gradient-' + randomId"
                />
            </defs>
            <g
                :id="'Creaitor_Mark_' + randomId"
                :data-name="'Creaitor_Mark' + randomId"
            >
                <g
                    :id="'Creaitor_Mark_Layer_' + randomId"
                    :data-name="'Creaitor_Mark_Layer' + randomId"
                >
                    <circle
                        cx="13.53"
                        cy="56.89"
                        r="13.53"
                        :style="'fill:url(#linear-gradient-' + randomId + ')'"
                    />
                    <path
                        d="M88.26,0a13.43,13.43,0,0,0-7.18,2.07L81,2.14c-.56.46-1.14.9-1.73,1.32A28.25,28.25,0,0,1,62.84,8.67,28.24,28.24,0,0,1,46.46,3.46c-.47-.33-.94-.68-1.39-1l-.34-.27a13.53,13.53,0,1,0-.46,23.05c.26-.23.53-.45.8-.67.61-.48,1.23-.95,1.88-1.39a28.42,28.42,0,0,1,31.79,0,28.71,28.71,0,0,1,2.34,1.77l.33.29A13.53,13.53,0,1,0,88.26,0Z"
                        :style="'fill:url(#linear-gradient-' + randomId + '-2)'"
                    />
                    <path
                        d="M88.26,86.73a13.42,13.42,0,0,0-7.18,2.06l-.12.08c-.56.46-1.14.9-1.73,1.32a28.41,28.41,0,0,1-32.77,0c-.47-.34-.94-.69-1.39-1l-.34-.27a13.53,13.53,0,1,0-.46,23l.8-.66c.61-.49,1.23-1,1.88-1.39a28.42,28.42,0,0,1,31.79,0,26.6,26.6,0,0,1,2.34,1.77l.33.28a13.4,13.4,0,0,0,6.85,1.87,13.53,13.53,0,1,0,0-27Z"
                        :style="'fill:url(#linear-gradient-' + randomId + '-3)'"
                    />
                    <path
                        d="M115.46,43.36a13.49,13.49,0,0,0-7.19,2.07l-.11.07c-.56.47-1.14.9-1.74,1.32a28.37,28.37,0,0,1-32.77,0c-.47-.33-.94-.68-1.39-1l-.34-.28a13.52,13.52,0,1,0-.45,23c.26-.22.52-.45.79-.66.61-.49,1.23-1,1.88-1.4a28.42,28.42,0,0,1,31.79,0,25.63,25.63,0,0,1,2.34,1.77l.34.29a13.53,13.53,0,1,0,6.85-25.19Z"
                        :style="'fill:url(#linear-gradient-' + randomId + '-4)'"
                    />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        cssClasses: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            default: '64',
        },
        height: {
            type: String,
            default: '64',
        },
    },
    data() {
        return {
            randomId: Math.floor(Math.random() * 1000) + 1,
        };
    },
};
</script>
