<template>
    <div class="container">
        <div class="row align-items-start mt-0 uploadedimage-body">
            <div class="mb-10">
                <div class="text-end close-bg" @click="$emit('remove-image')">
                    <i class="bi bi-x"></i>
                </div>
                <img :src="previewImageUrl" alt="resized-image" class="w-100" />
            </div>
            <div class="form-check mb-10 resize-field-set">
                <input class="form-check-input" type="checkbox" value="" id="resize-enable" v-model="enableResize">
                <label class="form-check-label" for="resize-enable">
                    {{ __('Resize?') }}
                </label>
            </div>
            <div class="row resize-form-block" v-if="enableResize">
                <div class="mb-10 resize-field">
                    <input class="form-control" type="number" v-model="width" :placeholder="__('Width')" />
                </div>
                <div class="mb-10 resize-field">
                    <input class="form-control" type="number" v-model="height" :placeholder="__('Height')" />
                </div>
                <div class="mb-10 text-end">
                    <button class="btn btn-danger me-5" @click="enableResize = false">{{ __('Cancel') }}</button>
                    <button class="btn btn-primary" @click="resizeImage" :class="[isLoading ? 'disabled' : '']">
                        <span v-if="isResizing">{{ __('Loading..') }}</span>
                        <span v-else>{{ __('Resize') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useToast } from 'vue-toastification';

export default {

    props: {
        imageUrl: String,
    },

    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast }
    },

    data() {
        return {
            enableResize: false,
            isResizing: false,
            width: null,
            height: null,
            resizedImageUrl: null
        };
    },

    computed: {
        previewImageUrl() {
            return this.resizedImageUrl || this.imageUrl;
        },
    },

    methods: {
        async resizeImage() {
            this.isResizing = true;
            try {
                const response = await fetch(this.imageUrl);
                if (!response.ok) {
                    throw new Error('Failed to fetch image');
                }

                const blob = await response.blob();
                const img = await createImageBitmap(blob);
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, this.width, this.height);
                this.resizedImageUrl = canvas.toDataURL('image/jpeg');

                this.$emit('image-resized', this.previewImageUrl);
                this.toast.success(this.__('Image resized'));
            } catch (error) {
                this.toast.error(this.__('Some error occured'));
            } finally {
                this.isResizing = false;
                this.enableResize = false;
            }
        },
    },
};
</script>
  