<template>
  <div class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">
    <div class="sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        {{ __('Session Expired') }}
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        {{ message }}
      </p>
      <div class="mt-8 text-center space-y-6">
        <div>
          <button @click="refreshPage" class="btn btn-primary group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            {{ __('Refresh Page') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
  },
}
</script>
