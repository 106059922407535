<template>
    <div class="w-100 writing-points">
        <!-- Article Title -->
        <Title :title="articleWizardStore.getArticleTitle" />

        <!-- Check if there are any writing points -->
        <div v-if="writingPoints.length > 0">
            <!-- H2 Headings and Content -->
            <div v-for="(heading, h2Index) in writingPoints" :key="h2Index">
                <!-- Heading Section for H2 -->
                <HeadingSection
                    :heading="getH2Title(heading)"
                    headingType="H2"
                    :isH2="true"
                    :h2Index="h2Index"
                    :headingData.sync="heading"
                    @toggleCollapse="toggleCollapse(heading)"
                >
                    <!-- Default Slot for H2 Content -->
                    <template v-slot:default>
                        <div v-show="!heading.collapsed">
                            <!-- Editable Content for H2 Heading Title -->
                            <div v-if="loadingStates[`${h2Index}-${null}`]" class="bg-lighten p-20 mt-2">
                                <div class="d-flex align-items-center justify-content-center p-10">
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">{{ __('Loading...') }}</span>
                                    </div>
                                </div>
                            </div>
                            <draggable
                                v-else
                                class="w-100 mt-2"
                                :list="getH2WritingPoints(heading)"
                                handle=".bi-grip-vertical"
                                @change="rebuildSelectedWritingPoints">
                                <EditableContent
                                    v-for="(content, index) in getH2WritingPoints(heading)"
                                    :content="content"
                                    :pointIndex="index"
                                    :h2Index="h2Index"
                                    :h3Index="null"
                                    @update="updateWritingPoint"
                                    @remove="removeWritingPoint"
                                />
                            </draggable>
                            <ActionButtonGroup
                                :isGenerating="loadingStates[`${h2Index}-null`]"
                                :h2Index="h2Index"
                                @add="addWritingPoint(h2Index)"
                                @generate="generateWritingPoints(getH2Title(heading), h2Index)"
                            />
                            <!-- Iterate over H3 Headings and Content if present -->
                            <div v-if="heading.heading_type_3">
                                <div v-for="(h3Heading, h3Index) in heading.heading_type_3" :key="`h3-${h3Index}`">
                                    <!-- Heading Section for H3 -->
                                    <HeadingSection
                                        :heading="getH3Title(h3Heading)"
                                        headingType="H3"
                                        :isH2="false"
                                        :h2Index="h2Index"
                                        v-model:headingData="heading.heading_type_3[h3Index]"
                                        @toggleCollapse="toggleCollapse(h3Heading)"
                                    >
                                        <!-- Default Slot for H3 Content -->
                                        <template v-slot:default>
                                            <div v-show="!h3Heading.collapsed">
                                                <!-- Editable Content for H3 -->
                                                <div v-if="loadingStates[`${h2Index}-${h3Index}`]" class="bg-lighten p-20 mt-2">
                                                    <div class="d-flex align-items-center justify-content-center p-10">
                                                        <div class="spinner-border spinner-border-sm" role="status">
                                                            <span class="visually-hidden">{{ __('Loading...') }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <draggable
                                                    v-else
                                                    class="w-100 mt-2"
                                                    :list="getH3WritingPoints(h3Heading)"
                                                    handle=".bi-grip-vertical"
                                                    @change="rebuildSelectedWritingPoints">
                                                    <EditableContent
                                                        v-for="(content, index) in getH3WritingPoints(h3Heading)"
                                                        :key="`content-${h3Index}-${index}`"
                                                        :content="content"
                                                        :pointIndex="index"
                                                        :h2Index="h2Index"
                                                        :h3Index="h3Index"
                                                        @update="updateWritingPoint"
                                                        @remove="removeWritingPoint"
                                                    />
                                                </draggable>
                                                <ActionButtonGroup
                                                    :isGenerating="loadingStates[`${h2Index}-${h3Index}`]"
                                                    :h2Index="h2Index"
                                                    :h3Index="h3Index"
                                                    @add="addWritingPoint(h3Index)"
                                                    @generate="generateWritingPoints(getH3Title(h3Heading), h2Index, h3Index)"
                                                />
                                            </div>
                                        </template>
                                    </HeadingSection>
                                </div>
                            </div>
                        </div>
                    </template>
                </HeadingSection>
            </div>
        </div>
        <!-- Loading or Empty State -->
        <div v-else class="d-flex align-items-center justify-content-center bg-lighten p-10">
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">{{ __('Loading...') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Title from './Partials/WritingPoints/Title.vue';
import HeadingSection from './Partials/WritingPoints/HeadingSection.vue';
import EditableContent from './Partials/WritingPoints/EditableContent.vue';
import ActionButtonGroup from './Partials/WritingPoints/ActionButtonGroup.vue';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import { VueDraggableNext } from 'vue-draggable-next';
import { useToast } from 'vue-toastification';

export default {
    components: {
        Title,
        HeadingSection,
        EditableContent,
        ActionButtonGroup,
        draggable: VueDraggableNext,
    },

    setup() {
        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { articleWizardStore, toast };
    },

    data() {
        return {
            generatingWritingPoints: false,
            generatingH2Index: null,
            generatingH3Index: null,
            loadingStates: {},
        };
    },

    computed: {
        writingPoints() {
            return this.articleWizardStore?.getAiGeneratedWritingPoints;
        },
    },

    watch: {
        'articleWizardStore.currentStep': {
            handler(newVal, oldVal) {
                let activeStep = this.articleWizardStore.getSerpBeater.uuid
                    ? 4
                    : 3;
                if(newVal === activeStep) {
                    this.$nextTick(() => {
                        this.writingPoints.forEach((heading, h2Index) => {
                            // Only generate if content doesn't exist
                            if (!heading.heading_type_2 || this.getH2WritingPoints(heading).length === 0) {
                                this.generateWritingPoints(this.getH2Title(heading), h2Index);
                            }

                            if (heading.heading_type_3) {
                                heading.heading_type_3.forEach((h3Heading, h3Index) => {
                                    // Only generate if content doesn't exist
                                    if (!h3Heading || this.getH3WritingPoints(h3Heading).length === 0) {
                                        this.generateWritingPoints(this.getH3Title(h3Heading), h2Index, h3Index);
                                    }
                                });
                            }
                        });
                    });
                }
            },
            immediate: true
        }
    },

    methods: {
        toggleCollapse(item) {
            if (typeof item === 'object') {
                item.collapsed = !item.collapsed;
            }
        },
        getH2Title(heading) {
            return Object.keys(heading.heading_type_2)[0];
        },
        getH3Title(heading) {
            // Handle the case where heading might be a string
            if (typeof heading === 'string') return heading;

            return Object.keys(heading)[0];
        },
        getH2WritingPoints(heading) {
            let writingPoints = heading.heading_type_2 ? Object.values(heading.heading_type_2)[0] : [];

            return writingPoints.length > 0 ? writingPoints : [];
        },
        getH3WritingPoints(heading) {
        // Handle the case where heading might be directly an array
            if (Array.isArray(heading)) return heading;

            let writingPoints = heading ? Object.values(heading)[0] : [];
            // Ensure we're returning an array
            return Array.isArray(writingPoints) ? writingPoints : [];
        },
        rebuildSelectedWritingPoints() {
            //TODO:: for rebuilding writing poitns after drag.
        },
        getHeadingContents(headingObject) {
            for (let key in headingObject) {
                if (headingObject.hasOwnProperty(key)) {
                    return headingObject[key];
                }
            }
        },
        addWritingPoint(h2Index, h3Index = null) {
            const newWritingPoint = this.__('New Writing Point');
            this.articleWizardStore.addToAiGeneratedWritingPoints(
                newWritingPoint,
                h2Index,
                h3Index
            );
        },
        updateWritingPoint({ newValue, pointIndex, h2Index, h3Index = null }) {
            this.articleWizardStore.updateAiGeneratedWritingPoints(
                newValue,
                pointIndex,
                h2Index,
                h3Index
            );
        },
        removeWritingPoint({ pointIndex, h2Index, h3Index = null }) {
            this.articleWizardStore.removeFromAiGeneratedWritingPoints(
                pointIndex,
                h2Index,
                h3Index
            );
        },
        generateWritingPoints(heading, h2Index, h3Index = null) {
            let vm = this;

            this.loadingStates[`${h2Index}-${h3Index}`] = true;

            let payload = {
                formality: this.articleWizardStore.getFormality,
                heading: heading,
                keyword: this.articleWizardStore.getFocusKeyword,
                lang: this.articleWizardStore.getLanguage,
            };
            vm.generatingWritingPoints = true;
            vm.generatingH2Index = h2Index;
            vm.generatingH3Index = h3Index;

            axios
                .post('/api/article/ai-assistant/generate-writing-points', payload)
                .then((response) => {
                    let writingPoints = [];

                    try {
                        writingPoints =
                            JSON.parse(response.data.writing_points[0]?.text)
                                ?.writing_points || [];
                    } catch (error) {
                        vm.toast.error(vm.__('Some error occurred'));
                        console.error(error);
                    }

                    vm.articleWizardStore.addOrUpdateAiGeneratedWritingPoints(
                        writingPoints,
                        h2Index,
                        h3Index
                    );
                })
                .catch((error) => {
                    vm.toast.error(vm.__('Some error occurred'));
                    console.error(error);
                })
                .finally(() => {
                    vm.generatingWritingPoints = false;
                    vm.generatingH2Index = null;
                    vm.generatingH3Index = null;
                    this.loadingStates[`${h2Index}-${h3Index}`] = false;
                });
        },
    },
};
</script>

<style lang="scss">
.writing-points {
    .heading-button {
        text-align: left;
        width: 95%;
    }

    .dragH2Area {
        width: 100% !important;
    }

    .btn-remove {
        width: 5%;
        text-align: right;
    }

    .h2-element-wrapper:hover {
        border: 1px solid #fff;
    }
}
</style>
