<template>
    <div class="d-flex flex-column flex-sm-row align-items-sm-center py-2">
        <!--begin::Label-->
        <label class="col-2 fs-7 text-gray-700 fw-bolder">{{
            __('Creativity')
        }}</label>
        <!--end::Label-->
        <div class="col-10">
            <Multiselect
                v-model="creativity"
                :options="selectCreativityLevelOptions"
                :searchable="true"
                class="form-control form-control-sm form-control-solid bg-white w-250px p-0 ms-0 ms-sm-6"
                track-by="level"
                label="name"
                @select="$emit('creativity-change', creativity)"
            >
                <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                        <div class="d-flex">
                            <div class="d-flex align-items-center ms-2">
                                {{ value.name }}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:option="{ option }">
                    <div class="d-flex">
                        <div class="d-flex align-items-center ms-2">
                            {{ option.name }}
                        </div>
                    </div>
                </template>
            </Multiselect>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
    props: {
        defaultCreativity: {
            type: Number,
            default: 0.7,
        },
    },

    components: {
        Multiselect,
    },

    data() {
        return {
            creativity: this.defaultCreativity || 0.7,
            creativityLevels: [
                {
                    level: 0.7,
                    name: this.__('Optimal'),
                },
                {
                    level: 0,
                    name: this.__('None'),
                },
                {
                    level: 0.2,
                    name: this.__('Low'),
                },
                {
                    level: 0.5,
                    name: this.__('Medium'),
                },
                {
                    level: 0.8,
                    name: this.__('High'),
                },
                { level: 1, name: this.__('Max') },
            ],
        };
    },
    watch: {
        defaultCreativity(newValue) {
            this.creativity = newValue;
        },
    },
    computed: {
        selectCreativityLevelOptions() {
            let entries = [];
            let vm = this;

            this.creativityLevels.forEach(function (entry) {
                entries.push({
                    value: entry.level,
                    name: entry.name,
                });
            });
            return entries;
        },
    },
});
</script>
