<template>
    <div class="py-10 d-flex flex-column">
        <file-pond
            name="variate-image"
            ref="variateImagePond"
            :label-idle="__('Drop files here...')"
            :label-file-loading="__('Loading')"
            :label-file-processing-complete="__('Upload complete')"
            :label-file-processing-aborted="__('Upload cancelled')"
            :label-file-processing-error="__('Error during upload')"
            :label-invalid-field="__('Field contains invalid files')"
            :label-ta-to-cancel="__('tap to cancel')"
            :label-ta-to-retry="__('tap to retry')"
            :label-ta-to-undo="__('tap to undo')"
            allow-multiple="false"
            accepted-file-types="image/png"
            credits="false"
            @processfile="prepareFile"
            @addfilestart="addFileStart"
        />
    </div>
</template>

<script>
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);

export default {
    components: {
        FilePond,
    },
    methods: {
        addFileStart() {
            this.$emit('change-create-disabled', true);
        },
        prepareFile() {
            let file = this.$refs.variateImagePond.getFile();
            emitter.emit('image-variate-uploaded', file);
            this.$emit('change-create-disabled', false);
        },
    },
};
</script>
