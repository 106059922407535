<template>
    <Link :href="'/'" class="d-flex justify-content-center mb-4">
        <LogoMark width="80" height="80"></LogoMark>
    </Link>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import LogoMark from '@/Jetstream/ApplicationMark.vue';

export default defineComponent({
    components: {
        Link,
        LogoMark,
    },
});
</script>
