<template>
    <Head :title="__('Forgot Password')" />

    <div class="d-flex flex-column flex-root">
        <!--begin::Authentication - Password reset -->
        <div
            class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style="background-image: url(assets/media/illustrations/sketchy-1/14.png"
        >
            <!--begin::Content-->
            <div
                class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
            >
                <!--begin::Logo-->
                <a href="/" class="mb-12">
                    <jet-authentication-card-logo
                        mark-width="64"
                        logo-text-classes="p-3 text-black"
                    />
                </a>
                <!--end::Logo-->
                <!--begin::Wrapper-->
                <div
                    class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto"
                >
                    <!--begin::Form-->
                    <form
                        class="form w-100"
                        novalidate="novalidate"
                        @submit.prevent="submit"
                    >
                        <!--begin::Heading-->
                        <div class="text-center mb-10">
                            <!--begin::Title-->
                            <h1 class="text-dark mb-3">
                                {{ __('Forgot Password ?') }}
                            </h1>
                            <!--end::Title-->
                            <!--begin::Link-->
                            <div class="text-gray-400 fw-bold fs-4">
                                {{
                                    __(
                                        'Enter your email to reset your password.'
                                    )
                                }}
                            </div>
                            <!--end::Link-->
                        </div>
                        <!--begin::Heading-->
                        <jet-validation-errors class="mb-3" />
                        <div
                            v-if="status"
                            class="notice d-flex bg-light-success rounded border-success border mb-12 p-6"
                        >
                            <!--begin::Wrapper-->
                            <div class="d-flex flex-stack flex-grow-1">
                                <!--begin::Content-->
                                <div class="fw-bold">
                                    <div class="fs-6 text-gray-700">
                                        {{ status }}
                                    </div>
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <!--begin::Input group-->
                        <div class="fv-row mb-10">
                            <label
                                class="form-label fw-bolder text-gray-900 fs-6"
                                >{{ __('Email') }}</label
                            >
                            <input
                                v-model="form.email"
                                class="form-control form-control-solid"
                                type="email"
                                placeholder=""
                                name="email"
                                autocomplete="off"
                            />
                        </div>
                        <!--end::Input group-->
                        <!--begin::Actions-->
                        <div
                            class="d-flex flex-wrap justify-content-center pb-lg-0"
                        >
                            <button
                                type="submit"
                                class="btn btn-lg btn-primary fw-bolder me-4"
                            >
                                <span class="indicator-label">{{
                                    __('Submit')
                                }}</span>
                                <span
                                    v-show="form.processing"
                                    class="indicator-progress"
                                    >{{ __('Please wait...') }}
                                    <span
                                        class="spinner-border spinner-border-sm align-middle ms-2"
                                    ></span
                                ></span>
                            </button>
                            <a
                                href="/login"
                                class="btn btn-lg btn-light-primary fw-bolder"
                                >{{ __('Cancel') }}</a
                            >
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end::Form-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Content-->
        </div>
        <!--end::Authentication - Password reset-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Head } from '@inertiajs/vue3';
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';

export default defineComponent({
    components: {
        Head,
        JetAuthenticationCard,
        JetAuthenticationCardLogo,
        JetButton,
        JetInput,
        JetLabel,
        JetValidationErrors,
    },

    props: {
        status: String,
    },

    data() {
        return {
            form: this.$inertia.form({
                email: '',
            }),
        };
    },

    methods: {
        submit() {
            this.form.post(this.route('password.email'));
        },
    },
});
</script>
