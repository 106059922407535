<template>
    <div class="d-flex flex-column flex-root bg-white">
        <!--begin::Authentication - Multi-steps-->
        <div
            class="d-flex flex-column flex-lg-row flex-column-fluid"
            data-kt-stepper="true"
        >
            <Prompt
                :user="user"
                :ai-assistant-templates="aiAssistantTemplates"
                :ai-assistant-template-uuid="uuid"
                :prompt-title="promptTitle"
                :prompt-info="promptInfo"
                :loading="loading"
                :fields="fields"
                @change-ai-assistant-template="onChangeAiAssistantTemplate"
                @prompt-change="onPromptChange"
                @creativity-change="(ev) => (options.creativity = ev)"
                @outputs-change="(ev) => (options.outputs = ev)"
                @tone-of-voice-change="(ev) => (options.toneOfVoice = ev)"
                @with-emojis-change="(ev) => (options.withEmojis = ev)"
                @language-change="(ev) => (lang = ev)"
                @formality-change="(ev) => (formality = ev)"
                @compose="compose"
            >
            </Prompt>

            <Result :result-title="resultTitle" :choices="choices"> </Result>
        </div>
        <!--end::Authentication - Multi-steps-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import NProgress from 'nprogress';
import Toolbar from '@/Layouts/Toolbar/Toolbar.vue';
import Prompt from '@/Layouts/AiAssistant/Partials/Prompt.vue';
import Result from '@/Layouts/AiAssistant/Partials/Result.vue';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';

export default defineComponent({
    setup() {
        // Get toast interface
        const toast = useToast();

        // Get user store
        const userStore = useUserStore();
        // Get team store
        const teamStore = useTeamStore();
        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        // Make it available inside methods
        return {
            toast,
            userStore,
            teamStore,
            subscriptionStore,
        };
    },

    components: {
        NProgress,
        Toolbar,
        Prompt,
        Result,
    },

    props: {
        user: Object,
    },

    data() {
        return {
            promptTitle: this.__('Text Body'),
            resultTitle: this.__('Choose from the results'),
            promptInfo: this.__(
                'You can play around. Tell or ask what you want / need.<br>The trained data are till June 2021, so current events are not included in the results.<br>Make sure, you follow our content guidelines. See the <a href="/terms" target="_blank">terms</a>.'
            ),
            aiAssistantTemplates: {},
            description: '',
            name: '',
            fields: [
                {
                    layout: 'description',
                    key: 'SZxmfJWMwfFimjBn',
                    attributes: {
                        title: '{"en":"Description","de":"Beschreibung","it":"Descrizione","fr":"Description"}',
                        placeholder:
                            '{"en":null,"de":null,"it":null,"fr":null}',
                    },
                },
            ],
            prompts: {},
            options: {
                creativity: 0.75,
                outputs: 3,
                toneOfVoice: '',
                withEmojis: false,
            },
            lang: 'en',
            formality: 'default',
            loading: false,
            choices: [],
        };
    },

    mounted() {
        this.userStore.setUser(this.user);
        this.subscriptionStore.setPlans(this.getPlans() || {});
    },

    methods: {
        onPromptChange(ev) {
            this.prompts[ev.component] = ev.value;
        },

        compose() {
            NProgress.start();
            this.loading = true;
            let vm = this;
            if (
                this.teamStore.hasCharacterCredits ||
                this.subscriptionStore.hasUnlimitedUsage
            ) {
                let data = {
                    uuid: this.uuid,
                    prompts: this.prompts,
                    options: this.options,
                    lang: this.lang,
                    formality: this.formality,
                };

                // Fake respond progress
                setTimeout(() => NProgress.set(0.4), 500);
                setTimeout(() => NProgress.inc(), 1500);
                setTimeout(() => NProgress.inc(), 3000);

                axios
                    .post('/api/compose/generate-text', data)
                    .then((response) => {
                        this.loading = false;
                        this.choices = response.data.choices;
                        Inertia.reload({ only: ['user'] });
                        NProgress.done();
                    })
                    .catch(function (error) {
                        vm.toast.error(error.response.data.message);
                    });
            } else {
                NProgress.done();
                this.toast.error(this.__('Your credits are over'));
                setTimeout(() => (window.location.href = '/billing'), 3000);
            }
        },
    },
});
</script>

<style scoped></style>
