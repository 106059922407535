<template>
    <div v-if="errors.length" class="invalid-feedback">
        <p v-for="error in errors" :key="error">
            {{ error }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: [Array, String],
            default: null,
        },
    },

    computed: {
        errors() {
            if ('string' === typeof this.message) {
                return [this.message];
            }

            return this.message;
        },
    },
};
</script>
