<template>
    <app-layout
        :title="__('Creations')"
        :view="__('AI Output')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('AI Output') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>AI Output</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{
                        __(
                            'Here you can find all the AI outputs you have created.'
                        )
                    }}
                </div>
            </div>
        </div>
        <div class="card card-bordered mb-7">
            <!--begin::Card body-->
            <div class="card-body">
                <!--begin::Compact form-->
                <div class="d-flex row align-items-center">
                    <!--begin::Input group-->
                    <div class="col-12 col-md-6 position-relative mb-4 mb-md-0">
                        <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                        <span
                            class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="currentColor"
                                ></rect>
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                        <input
                            v-model="search"
                            @input="onChangeSearch"
                            type="text"
                            class="form-control form-control-solid ps-10"
                            name="search"
                            :placeholder="__('Search')"
                        />
                    </div>
                    <!--end::Input group-->
                    <div class="col-12 col-md-6">
                        <!--begin::Select-->
                        <Multiselect
                            v-model="selectedCategory"
                            :options="selectTemplateCategoryOptions"
                            :searchable="true"
                            @change="onChangeCategory"
                            trackBy="name"
                            label="name"
                            class="px-1 py-2"
                            :placeholder="__('Category')"
                        >
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <div class="d-flex">
                                        <div
                                            class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90"
                                            :style="{
                                                backgroundColor: value.color,
                                            }"
                                        >
                                            <img
                                                :src="'/storage/' + value.icon"
                                            />
                                        </div>
                                        <div
                                            class="d-flex align-items-center ms-2"
                                        >
                                            {{ value.name[$page.props.locale] }}
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <div class="d-flex">
                                    <div
                                        class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90"
                                        :style="{
                                            backgroundColor: option.color,
                                        }"
                                    >
                                        <img :src="'/storage/' + option.icon" />
                                    </div>
                                    <div class="d-flex align-items-center ms-2">
                                        {{ option.name[$page.props.locale] }}
                                    </div>
                                </div>
                            </template>
                        </Multiselect>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap flex-stack pb-7">
            <!--begin::Title-->
            <div class="d-flex flex-wrap align-items-center my-1"></div>
            <!--end::Title-->

            <!--begin::Controls-->
            <div class="d-flex flex-wrap my-1">
                <!--begin::Tab nav-->
                <ul class="nav nav-pills me-0 mb-2 mb-sm-0" role="tablist">
                    <li class="nav-item m-0" role="presentation">
                        <button
                            class="btn btn-sm btn-icon btn-light me-3"
                            :class="{
                                'btn-active-primary active': isGridView,
                                'border border-active-primary border-gray-300':
                                    !isGridView,
                            }"
                            @click.prevent="toggleGridView('grid')"
                        >
                            <span class="svg-icon svg-icon-primary svg-icon-2">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </button>
                    </li>

                    <li class="nav-item m-0" role="presentation">
                        <button
                            class="btn btn-sm btn-icon btn-light"
                            :class="{
                                'btn-active-primary active': !isGridView,
                                'border border-active-primary border-gray-300':
                                    isGridView,
                            }"
                            @click.prevent="toggleGridView('list')"
                        >
                            <span class="svg-icon svg-icon-primary svg-icon-2">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        opacity="0.3"
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </button>
                    </li>
                </ul>
                <!--end::Tab nav-->
            </div>
            <!--end::Controls-->
        </div>
        <div class="row row-cols-1 row-cols-lg-3 g-9" v-if="isGridView">
            <div v-if="creations.length < 1" class="col-md-6 col-lg-12">
                <!--begin::Alert-->
                <div
                    class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10"
                >
                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column pe-0 pe-sm-10">
                        <!--begin::Content--><span class="text-gray-600">{{
                            __(
                                'No output yet. Create a AI-based output on the left side.'
                            )
                        }}</span
                        ><!--end::Content-->
                    </div>
                    <!--end::Wrapper--><!--end::Close-->
                </div>
                <!--end::Alert-->
            </div>
            <div v-for="(creation, index) in creations" class="col">
                <div class="card card-bordered text-gray-600">
                    <div class="card-header">
                        <h3 class="card-title">
                            <div
                                class="d-flex flex-center w-35px h-35px rounded-circle bg-opacity-90 me-3"
                                :style="{
                                    backgroundColor:
                                        creation.ai_assistant_template_category
                                            ? creation
                                                  .ai_assistant_template_category
                                                  .color
                                            : '#F5F8FA',
                                }"
                            >
                                <img
                                    :src="
                                        '/storage/' +
                                        (creation.ai_assistant_template_category
                                            ? creation.ai_assistant_template
                                                  .template_icon_path
                                            : 'Xq3uzyEGH9fqVE95bdCouoov5olaS5qK05Gk3CPr.svg')
                                    "
                                />
                            </div>
                            <h3
                                class="card-title card-label fw-bolder text-dark"
                            >
                                {{
                                    creation.ai_assistant_template
                                        ? creation.ai_assistant_template.name[
                                              $page.props.locale
                                          ]
                                        : creation.type === 'open-form'
                                          ? 'Open Form'
                                          : creation.type
                                }}
                            </h3>
                        </h3>
                        <div class="card-toolbar">
                            <a
                                href="#"
                                class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                                @click.prevent="onCopy(index)"
                            >
                                <span
                                    v-if="!creation.copyClicked"
                                    class="svg-icon svg-icon-muted svg-icon-1"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            opacity="0.3"
                                            d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                <span
                                    v-else
                                    class="svg-icon svg-icon-muted svg-icon-1"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            opacity="0.3"
                                            d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M10.3629 14.0084L8.92108 12.6429C8.57518 12.3153 8.03352 12.3153 7.68761 12.6429C7.31405 12.9967 7.31405 13.5915 7.68761 13.9453L10.2254 16.3488C10.6111 16.714 11.215 16.714 11.6007 16.3488L16.3124 11.8865C16.6859 11.5327 16.6859 10.9379 16.3124 10.5841C15.9665 10.2565 15.4248 10.2565 15.0789 10.5841L11.4631 14.0084C11.1546 14.3006 10.6715 14.3006 10.3629 14.0084Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                            </a>
                            <a
                                href="#"
                                class="btn btn-icon btn-bg-light btn-active-color-primary favorite btn-sm me-2"
                                @click.stop.prevent="
                                    onClickFavorite(creation.id, index)
                                "
                                :class="{ 'is-favorite': creation.is_favorite }"
                            >
                                <span class="svg-icon svg-icon-1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M13.0079 2.6L15.7079 7.2L21.0079 8.4C21.9079 8.6 22.3079 9.7 21.7079 10.4L18.1079 14.4L18.6079 19.8C18.7079 20.7 17.7079 21.4 16.9079 21L12.0079 18.8L7.10785 21C6.20785 21.4 5.30786 20.7 5.40786 19.8L5.90786 14.4L2.30785 10.4C1.70785 9.7 2.00786 8.6 3.00786 8.4L8.30785 7.2L11.0079 2.6C11.3079 1.8 12.5079 1.8 13.0079 2.6Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </span>
                            </a>
                            <a
                                href="#"
                                class="btn btn-icon btn-bg-light btn-active-color-primary delete-bin btn-sm"
                                @click.stop.prevent="onClickDelete(creation.id)"
                            >
                                <span class="svg-icon svg-icon-1">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            opacity="0.5"
                                            d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            opacity="0.5"
                                            d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                    <!--end::Svg Icon-->
                                </span>
                            </a>
                        </div>
                    </div>
                    <div
                        class="card-body card-scroll h-200px pt-5"
                        v-html="nl2br(creation.output)"
                    ></div>
                    <div class="card-footer d-flex flex-column p-0">
                        <div class="d-flex justify-content-between px-9 py-4">
                            <div class="d-flex">
                                <span class="text-gray-400 mt-1 fw-bold fs-8"
                                    ><i class="bi bi-tag-fill"></i>
                                    {{
                                        creation.ai_assistant_template_category
                                            ? creation
                                                  .ai_assistant_template_category
                                                  .name[$page.props.locale]
                                            : ''
                                    }}</span
                                >
                            </div>
                            <div class="d-flex">
                                <span class="text-gray-400 mt-1 fw-bold fs-8"
                                    >{{ __('Characters') }}:
                                    {{ creation.character_count }}</span
                                >
                            </div>
                        </div>

                        <div
                            v-if="creation?.created_at"
                            class="card-info bg-lighten px-9 py-2 fs-8"
                        >
                            {{ __('Created') }}:
                            {{ moment(creation.created_at).format('L LTS') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-bordered" v-else>
            <div class="card-header align-items-center py-5 gap-2 gap-md-5">
                <!--begin::Card title-->
                <div class="card-title">
                    <!--begin::Search-->
                    <div
                        class="d-flex align-items-center position-relative my-1"
                    ></div>
                    <!--end::Search-->
                </div>
                <div
                    class="card-toolbar flex-row-fluid justify-content-end gap-5"
                >
                    <a
                        href="#"
                        @click.prevent="sendBulkDataToModal"
                        class="btn btn-danger"
                        :class="{ disabled: creationIds.length === 0 }"
                    >
                        {{ __('Delete') }}
                    </a>
                </div>
            </div>
            <div class="card-body pt-0">
                <div
                    id="creations_table_wrapper"
                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                    <div class="table-responsive">
                        <table
                            class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                            id="creations_table"
                        >
                            <!--begin::Table head-->
                            <thead>
                                <!--begin::Table row-->
                                <tr
                                    class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                                >
                                    <th
                                        class="w-10px pe-2 sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label=""
                                    >
                                        <div
                                            class="form-check form-check-sm form-check-custom form-check-solid me-3"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="allSelected"
                                                @change="selectAll"
                                            />
                                        </div>
                                    </th>
                                    <th
                                        class="min-w-200px sorting"
                                        tabindex="0"
                                        aria-controls="creations_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Name"
                                    >
                                        {{ __('Name') }}
                                    </th>
                                    <th
                                        class="min-w-200px sorting"
                                        tabindex="0"
                                        aria-controls="creations_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Category"
                                    >
                                        {{ __('Category') }}
                                    </th>
                                    <th
                                        class="text-end min-w-100px sorting"
                                        tabindex="0"
                                        aria-controls="creations_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Updated At"
                                    >
                                        {{ __('Updated At') }}
                                    </th>
                                    <th
                                        class="text-end min-w-100px sorting"
                                        tabindex="0"
                                        aria-controls="creations_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Created At"
                                    >
                                        {{ __('Created At') }}
                                    </th>
                                    <th
                                        class="text-end min-w-70px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Actions"
                                    >
                                        {{ __('Actions') }}
                                    </th>
                                </tr>
                                <!--end::Table row-->
                            </thead>
                            <!--end::Table head-->
                            <!--begin::Table body-->
                            <tbody class="fw-bold text-gray-600">
                                <tr v-for="(creation, index) in creations">
                                    <!--begin::Checkbox-->
                                    <td>
                                        <div
                                            class="form-check form-check-sm form-check-custom form-check-solid"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="creationIds"
                                                @change="select"
                                                :value="creation.id"
                                            />
                                        </div>
                                    </td>
                                    <!--end::Checkbox-->
                                    <td>
                                        <div class="d-flex align-items-center">
                                            {{
                                                creation.ai_assistant_template
                                                    ? creation
                                                          .ai_assistant_template
                                                          .name[
                                                          $page.props.locale
                                                      ]
                                                    : creation.type ===
                                                        'open-form'
                                                      ? 'Open Form'
                                                      : creation.type
                                            }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            {{
                                                creation.ai_assistant_template_category
                                                    ? creation
                                                          .ai_assistant_template_category
                                                          .name[
                                                          $page.props.locale
                                                      ]
                                                    : ''
                                            }}
                                        </div>
                                    </td>

                                    <td class="text-end pe-0">
                                        {{
                                            moment(creation.updated_at).format(
                                                'L LTS'
                                            )
                                        }}
                                    </td>

                                    <td class="text-end pe-0" data-order="41">
                                        {{
                                            moment(creation.created_at).format(
                                                'L LTS'
                                            )
                                        }}
                                    </td>
                                    <!--begin::Action=-->
                                    <td
                                        class="text-end position-relative action-dropdown-menu"
                                    >
                                        <a
                                            href="#"
                                            class="btn btn-sm btn-light btn-active-light-primary"
                                            data-kt-menu-trigger="click"
                                            data-kt-menu-placement="bottom-end"
                                            @click.prevent="toggle"
                                            v-click-away="onClickAway"
                                            >{{ __('Actions') }}
                                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                                            <span
                                                class="svg-icon svg-icon-5 m-0"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                                        fill="currentColor"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon--></a
                                        >
                                        <!--begin::Menu-->
                                        <div
                                            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary position-absolute fw-bold fs-7 w-125px py-4"
                                            data-kt-menu="true"
                                        >
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a
                                                    href="#"
                                                    class="menu-link px-3"
                                                    @click.prevent="
                                                        onClickDelete(
                                                            creation.id
                                                        )
                                                    "
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_delete"
                                                    >{{ __('Delete') }}</a
                                                >
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                        <!--end::Menu-->
                                    </td>
                                    <!--end::Action=-->
                                </tr>
                            </tbody>
                        </table>
                        <!--end::Table body-->
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-stack flex-wrap pt-10">
            <div class="d-flex flex-1 align-items-center">
                <select
                    class="form-select form-select-sm border-0 mw-70px me-4"
                    v-model="selectedViewLengthOption"
                    @change="onChangeViewLength"
                >
                    <option v-for="option in viewLengthOptions" :value="option">
                        {{ option }}
                    </option>
                </select>
                <div class="fs-6 fw-bold text-gray-700">
                    {{ __('Showing') }} {{ from }} {{ __('to') }} {{ to }}
                    {{ __('of') }} {{ total }} {{ __('entries') }}
                </div>
            </div>
            <ul class="pagination">
                <li
                    v-for="(link, index) in pageLinks"
                    class="page-item"
                    :class="{
                        'prev previous': index === 0,
                        next: index === 14,
                        active: link.label == currentPage,
                    }"
                >
                    <a
                        href="#"
                        @click.prevent="fetchCreations(link.url)"
                        class="page-link"
                    >
                        <span v-if="index === 0"><i class="previous"></i></span>
                        <span
                            v-else-if="
                                index === Object.keys(pageLinks).length - 1
                            "
                            ><i class="next"></i
                        ></span>
                        <span v-else>{{ link.label }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Multiselect from '@vueform/multiselect';
import { directive } from 'vue3-click-away';
import moment from 'moment/moment';

export default defineComponent({
    components: {
        AppLayout,
        Multiselect,
    },

    directives: {
        ClickAway: directive,
    },

    data() {
        return {
            search: '',
            aiAssistantTemplateCategories: [],
            selectedCategory: null,
            creationsUrl: '/api/creations',
            creations: {},
            copyClicked: false,
            currentPage: 1,
            firstPageUrl: '',
            from: 1,
            to: 1,
            total: 1,
            lastPage: 1,
            lastPageUrl: '',
            nextPageUrl: '',
            prevPageUrl: '',
            pageLinks: {},
            isGridView: true,
            creationIds: [],
            currentMenu: {
                open: false,
                element: {},
            },
            moment: moment,
            allSelected: false,
            selectedViewLengthOption: 9,
            gridViewLengthOptions: [9, 30, 60, 90],
            listViewLengthOptions: [10, 25, 50, 100, 500],
        };
    },
    mounted() {
        this.fetchTemplateCategories();
        this.fetchCreations(this.creationsUrl);
    },
    methods: {
        fetchTemplateCategories() {
            axios
                .get('/api/ai-assistant-template-categories')
                .then((response) => {
                    this.aiAssistantTemplateCategories = response.data;
                });
        },
        fetchCreations(url) {
            if (url) {
                let filterParams =
                    'per-page=' +
                    this.selectedViewLengthOption +
                    '&search=' +
                    this.search +
                    '&category=' +
                    this.selectedCategory;
                let requestUrl = url.includes('page')
                    ? url + '&' + filterParams
                    : url + '?' + filterParams;

                axios.get(requestUrl).then((response) => {
                    this.creations = response.data.data;
                    this.currentPage = response.data.current_page;
                    this.firstPageUrl = response.data.first_page_url;
                    this.from = response.data.from;
                    this.to = response.data.to;
                    this.total = response.data.total;
                    this.lastPage = response.data.last_page;
                    this.lastPageUrl = response.data.last_page_url;
                    this.nextPageUrl = response.data.next_page_url;
                    this.prevPageUrl = response.data.prev_page_url;
                    this.pageLinks = response.data.links;
                });
            }
        },
        onChangeCategory(ev) {
            this.selectedCategory = ev;
            this.fetchCreations(this.creationsUrl);
        },
        onChangeSearch(ev) {
            this.fetchCreations(this.creationsUrl);
        },
        onChangeViewLength(ev) {
            this.fetchCreations(this.creationsUrl);
        },
        onCopy(index) {
            let copyText = this.creations[index].output;
            let vm = this;

            emitter.emit('clipboard', copyText);

            this.creations[index].copyClicked = true;

            setTimeout(function () {
                vm.creations[index].copyClicked = false;
            }, 2000);
        },
        onClickFavorite(id, index) {
            let vm = this;
            vm.creations[index].is_favorite = !vm.creations[index].is_favorite;
            let data = {
                user: {
                    creation_id: id,
                },
            };
            axios
                .put('/api/users/' + this.$page.props.user.id, data)
                .then((response) => {});
        },
        onClickDelete(id) {
            emitter.emit('set-delete-candidate', {
                id: id,
                url: this.creationsUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this output? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
        },
        deleteCallback(id) {
            this.fetchCreations(this.creationsUrl);
            this.allSelected = false;
        },
        nl2br(str) {
            return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
        toggleGridView(view) {
            if (view === 'grid') {
                this.isGridView = true;
                this.selectedViewLengthOption = 9;
            } else {
                this.isGridView = false;
                this.selectedViewLengthOption = 10;
            }

            this.fetchCreations(this.creationsUrl);
        },
        toggle($event) {
            let el = $event.target.closest('.action-dropdown-menu');

            let s = el.classList;
            let m =
                el.parentElement.getElementsByClassName('menu-sub-dropdown')[0]
                    .classList;

            s.contains('show') ? s.remove('show') : s.add('show');
            s.contains('menu-dropdown')
                ? s.remove('menu-dropdown')
                : s.add('menu-dropdown');
            m.contains('show') ? m.remove('show') : m.add('show');
            this.currentMenu.open = this.currentMenu.open !== true;
            this.currentMenu.element = el;
        },
        onClickAway(ev) {
            if (this.currentMenu.open) {
                let el = this.currentMenu.element;

                if (!el.parentElement.contains(ev.target)) {
                    let s = el.classList;
                    let m =
                        el.parentElement.getElementsByClassName(
                            'menu-sub-dropdown'
                        )[0].classList;

                    s.contains('show') ? s.remove('show') : s.add('show');
                    s.contains('menu-dropdown')
                        ? s.remove('menu-dropdown')
                        : s.add('menu-dropdown');
                    m.contains('show') ? m.remove('show') : m.add('show');
                    this.currentMenu.open = false;
                }
            }
        },
        selectAll() {
            let vm = this;
            vm.creationIds = [];
            if (this.allSelected) {
                this.creations.forEach(function (creation) {
                    vm.creationIds.push(creation.id);
                });
            }
        },

        select() {
            this.allSelected = false;
        },

        sendBulkDataToModal() {
            emitter.emit('set-delete-candidate', {
                ids: this.creationIds,
                url: this.creationsUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this output? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
        },
    },
    computed: {
        templateCategories() {
            return this.aiAssistantTemplateCategories.filter(
                (category) => category.highlight
            );
        },
        selectTemplateCategoryOptions() {
            let entries = [];
            for (const [key, value] of Object.entries(
                this.aiAssistantTemplateCategories
            )) {
                entries.push({
                    value: value.machine_name,
                    name: value.name,
                    color: value.color,
                    icon: value.category_icon_path,
                });
            }
            return entries;
        },
        viewLengthOptions() {
            return this.isGridView
                ? this.gridViewLengthOptions
                : this.listViewLengthOptions;
        },
    },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.multiselect {
    border-color: #dee2e6;
    padding: 13px 0;
}
.favorite {
    .svg-icon svg path {
        fill: #e4e6ef !important;
    }

    &.is-favorite {
        .svg-icon svg path {
            fill: #a1a5b7 !important;
        }
    }

    &:hover {
        .svg-icon svg path {
            fill: #009ef7 !important;
        }
    }
}
.delete-bin {
    .svg-icon svg path {
        fill: #d8dae1 !important;
    }

    &:hover {
        .svg-icon svg path {
            fill: #009ef7 !important;
        }
    }
}
</style>
