<template>
    <div class="d-flex position-relative">
        <div v-if="languages[lang]" class="m-0 toolbar-dropdown-menu">
            <a
                href="#"
                class="btn btn-flex btn-light btn-active-primary fw-bolder"
                :class="[{ disabled: disabled }, size]"
                @click.prevent.stop="toggle"
                v-click-away="onClickAway"
            >
                <span
                    v-if="showLabel && languages[lang] && languages[lang].name"
                    >{{ languages[lang].name.split(' ')[0] }}</span
                >
                <span v-if="showFlag">
                    <img
                        class="w-15px h-15px rounded-1 ms-2"
                        :src="languages[lang].flag"
                        alt=""
                    />
                </span>
            </a>
            <div
                class="menu-sub menu-sub-dropdown menu-state-bg menu-state-primary language-dropdown menu-gray-600 w-250px mh-250px scroll-y py-4"
                :class="{
                    'dropdown-bottom': dropdownPosition === 'bottom',
                    'dropdown-align-right': dropdownAlign === 'right',
                }"
            >
                <!--begin::Menu item-->
                <input
                    v-if="searchable"
                    id="languageSearch"
                    type="text"
                    class="form-control"
                    v-model="languageSearchValue"
                    :placeholder="__('Search...')"
                />
                <div v-for="language in languages" class="menu-item px-3">
                    <a
                        href="#"
                        @click.prevent.stop="
                            onSelectLanguage($event, language.code)
                        "
                        class="btn btn-sm btn-menu-link menu-link d-flex px-5"
                    >
                        <span v-if="showFlag" class="symbol symbol-20px me-4">
                            <img
                                class="rounded-1"
                                :src="language.flag"
                                alt=""
                            /> </span
                        >{{ language.name }}
                    </a>
                </div>
                <!--end::Menu item-->
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { directive } from 'vue3-click-away';

export default defineComponent({
    directives: {
        ClickAway: directive,
    },

    props: {
        size: {
            type: String,
            default: 'btn-sm',
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        lang: {
            type: String,
            default: 'en',
        },
        showFlag: {
            type: Boolean,
            default: true,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        dropdownPosition: {
            type: String,
            default: 'top',
        },
        dropdownAlign: {
            type: String,
            default: 'left',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            languages: {
                en: {
                    name: 'English',
                    code: 'en',
                    flag: '/media/flags/united-states.svg',
                },
                de: {
                    name: 'Deutsch',
                    code: 'de',
                    flag: '/media/flags/germany.svg',
                },
                fr: {
                    name: 'Français',
                    code: 'fr',
                    flag: '/media/flags/france.svg',
                },
                es: {
                    name: 'Español',
                    code: 'es',
                    flag: '/media/flags/spain.svg',
                },
                it: {
                    name: 'Italiano',
                    code: 'it',
                    flag: '/media/flags/italy.svg',
                },
            },
            documentBodyWidth: document.body.clientWidth,
            currentMenu: {
                open: false,
                element: {},
            },
            currentUserSystemLanguage: this.$page?.props?.user?.user_info
                ?.language
                ? this.$page.props.user.user_info.language
                : 'en',
            languageSearchValue: '',
        };
    },
    mounted() {
        window.addEventListener('resize', this.windowResize);
    },
    methods: {
        toggle($event) {
            let el = $event.target.closest('.toolbar-dropdown-menu');

            let s = el.classList;
            let m =
                el.parentElement.getElementsByClassName('menu-sub-dropdown')[0]
                    .classList;

            s.contains('show') ? s.remove('show') : s.add('show');
            s.contains('menu-dropdown')
                ? s.remove('menu-dropdown')
                : s.add('menu-dropdown');
            m.contains('show') ? m.remove('show') : m.add('show');
            this.currentMenu.open = this.currentMenu.open !== true;
            this.currentMenu.element = el;
        },
        onSelectLanguage(ev, code) {
            this.$emit('language-change', code);
            this.toggle(ev);
        },
        onClickAway(ev) {
            if (this.currentMenu.open) {
                let el = this.currentMenu.element;

                if (!el.parentElement.contains(ev.target)) {
                    let s = el.classList;
                    let m =
                        el.parentElement.getElementsByClassName(
                            'menu-sub-dropdown'
                        )[0].classList;

                    s.contains('show') ? s.remove('show') : s.add('show');
                    s.contains('menu-dropdown')
                        ? s.remove('menu-dropdown')
                        : s.add('menu-dropdown');
                    m.contains('show') ? m.remove('show') : m.add('show');
                    this.currentMenu.open = false;
                }
            }
        },
        windowResize() {
            this.documentBodyWidth = document.body.clientWidth;
        },
    },
    computed: {
        languageClass() {
            return {
                active: this.isActive && !this.error,
                'text-danger': this.error && this.error.type === 'fatal',
            };
        },
        languageMenuStyle() {
            let height = 286;

            if (Object.values(this.languages).length > 0) {
                height = 107 + (Object.values(this.languages).length - 1) * 38;
            }

            return this.mobileView
                ? 'transform: translate3d(0px, -' +
                      height +
                      'px, 0px); z-index: 105; position: absolute;'
                : 'transform: translate3d(0px, -' +
                      height +
                      'px, 0px); z-index: 105; position: fixed;';
        },
        mobileView() {
            return this.documentBodyWidth < 992;
        },
    },
});
</script>

<style lang="scss">
#languageSearch {
    width: 225px;
    padding: 6px;
    margin: 0px 10px;
}
.language-dropdown {
    bottom: 45px;
    position: absolute;
    z-index: 105;

    &.dropdown-bottom {
        top: 45px;
        bottom: auto;
    }

    &.dropdown-align-right {
        right: 0;
    }
}
.toolbar-dropdown-menu > .btn {
    border: 1px solid #e8e8e8 !important;

    &:hover {
        border-color: inherit !important;
    }
}
</style>
