<template>
    <app-layout
        :title="__('Billing')"
        :user="$attrs.user"
    >
        <template #header>
            <h2 class="h4 font-weight-bold">
                {{ __('Billing') }}
            </h2>
        </template>
        <div class="row gx-5 gx-xl-10 py-2">
            <div class="col-12 col-sm-6 offset-sm-3">
                <div class="card">
                    <div class="card-body">
                        <h3 class="font-weight-bold mb-5">
                            {{ __('Apply Discount') }}
                        </h3>
                        <!--end::Alert-->
                        <div v-if="error" class="alert alert-danger d-flex align-items-center p-5">
                            <div class="d-flex flex-column">
                                <!--begin::Title-->
                                <h4 class="mb-1 text-danger">{{ error }}</h4>
                                <!--end::Title-->
                            </div>
                        </div>
                        <!--begin::Alert-->
                        <div v-else class="alert d-flex align-items-center p-5"
                             :class="{'alert-success': success, 'alert-danger': !success}">
                            <!--begin::Wrapper-->
                            <div class="d-flex flex-column">
                                <!--begin::Title-->
                                <h4 class="mb-1"
                                    :class="{'text-success': success, 'text-danger': !success}">{{ message }}</h4>
                                <!--end::Title-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </app-layout>
</template>

<style scoped></style>

<script>
import { defineComponent } from 'vue';
import { Head, Link } from '@inertiajs/vue3';
import Typed from 'typed.js';
import ApplicationLogo from '@/Jetstream/ApplicationLogo.vue';
import Footer from './Partials/en/Footer.vue';
import { Carousel } from 'bootstrap';
import ImageCollectionsTable from "@/Components/ImageGenerator/ImageCollectionsTable.vue";
import ConversationsTable from "@/Components/AiChat/ConversationsTable.vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import Loader from "@/Pages/Loader.vue";
import SerpBeatersTable from "@/Components/SerpBeater/SerpBeatersTable.vue";
import KeywordClustersTable from "@/Components/KeywordResearch/KeywordClustersTable.vue";
import DocumentsTable from "@/Components/Documents/DocumentsTable.vue";

export default defineComponent({
    components: {
        DocumentsTable,
        KeywordClustersTable,
        SerpBeatersTable,
        Loader,
        AppLayout,
        ConversationsTable,
        ImageCollectionsTable,
        Head,
        Link,
        ApplicationLogo,
    },

    props: {
        canLogin: Boolean,
        canRegister: Boolean,
        laravelVersion: String,
        phpVersion: String,success: Boolean,
        message: String,
        error: String,
    },
});
</script>
