<template>
    <jet-form-section @submitted="resetPassword">
        <template #title>
            {{ __('Reset Password') }}
        </template>
        <template #description>
            {{
                __(
                    'Ensure your account is using a long, random password to stay secure.'
                )
            }}
        </template>
        <template #form>
            <jet-action-message :on="form.recentlySuccessful">
                {{ __('We have emailed your password reset link!') }}.
            </jet-action-message>
            <div class="w-75">
                <div class="mb-3">
                    <jet-label for="email" :value="'Email'" />
                    <jet-input
                        id="email"
                        type="email"
                        :class="{ 'is-invalid': form.errors.email }"
                        v-model="form.email"
                        ref="email"
                        autocomplete="email"
                    />
                    <jet-input-error
                        :message="form.errors.email"
                        class="mt-2"
                    />
                </div>
            </div>
        </template>
        <template #actions>
            <jet-button
                :class="{ 'text-white-50': form.processing }"
                :disabled="form.processing"
            >
                <div
                    v-show="form.processing"
                    class="spinner-border spinner-border-sm"
                    role="status"
                >
                    <span class="visually-hidden">{{ __('Loading...') }}</span>
                </div>
                {{ __('Send Reset Link') }}
            </jet-button>
        </template>
    </jet-form-section>
</template>
  <script>
import { defineComponent } from 'vue';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
export default defineComponent({
    components: {
        JetActionMessage,
        JetButton,
        JetFormSection,
        JetInput,
        JetInputError,
        JetLabel,
    },
    data() {
        return {
            form: this.$inertia.form({
                email: '',
            }),
        };
    },
    methods: {
        resetPassword() {
            this.form.post(route('reset.password'), {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    this.form.reset();
                },
                onError: () => {
                    if (this.form.errors.email) {
                        this.form.reset('email');
                        this.$refs.email.focus();
                    }
                },
            });
        },
    },
});
</script>