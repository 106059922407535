<template>
    <h2 class="text-primary">{{ __('Outline') }}</h2>
    <p class="text-muted">
        {{ __('Choose your suitable headings to finalyze your outline.') }}
    </p>
    <div class="step-body">
        <div class="d-flex align-items-center mb-3">
            <span class="badge badge-dark me-2">H1</span>
            <h1
                :title="articleWizardStore.getArticleTitle"
                class="text-truncate m-0 p-0"
            >
                {{ articleWizardStore.getArticleTitle }}
            </h1>
        </div>

        <div class="d-flex flex-column mt-4 mb-3">
            <h2>{{ __('Introduction Paragraph') }}</h2>
            <p class="text-muted">
                {{
                    __(
                        'This is a placeholder and will generate an introduction paragraph for your article.'
                    )
                }}
            </p>
        </div>

        <div class="d-flex flex-column mt-4 mb-3">
            <h2>{{ __('Headings') }}</h2>
            <p class="text-muted" v-if="!headingLists.length">
                {{ __('Create your outline here.') }}
            </p>
        </div>

        <draggable
            class="dragArea dragH2Area"
            :list="headingLists"
            @add="removeDuplicateHeading()"
            group="outlineHeadings"
            @change="rebuildSelectedArticleOutline"
            handle=".bi-grip-vertical"
            item-key="name"
        >
            <div
                class="h2-element-wrapper d-flex justify-content-between"
                v-for="(element, index) in headingLists"
                :key="element.id"
                @click="focusH2Heading(element)"
            >
                <div class="d-flex flex-column flex-1">
                    <div
                        class="d-flex flex-1 justify-content-between h2-element-box"
                    >
                        <button
                            class="h2-element heading-button btn d-flex flex-1 align-items-center p-0"
                        >
                            <span
                                class="arrow-icon bi bi-grip-vertical text-muted"
                            ></span>
                            <span
                                class="arrow-icon bi me-1 fw-bold"
                                :class="{
                                    'bi-chevron-right': element.collapsed,
                                    'bi-chevron-down': !element.collapsed,
                                }"
                                @click="element.collapsed = !element.collapsed"
                            ></span>
                            <span class="badge badge-secondary me-2">H2</span>
                            <h2
                                class="heading-content flex-1 text-start text-truncate fs-5 fw-normal m-0 p-0"
                                :title="element.name"
                                contenteditable="true"
                                @blur="
                                    updateHeading(
                                        element,
                                        $event.target.innerText
                                    )
                                "
                            >
                                {{ element.name }}
                            </h2>
                        </button>
                        <span
                            class="btn btn-sm btn-rephrase p-0 me-1"
                            @click.stop="rephrase(element.id)"
                        >
                            <i :class="{'bi bi-arrow-clockwise rotate-icon': element.isRephrasing, 'bi bi-arrow-clockwise': !element.isRephrasing}"></i>
                        </span>
                        <span
                            class="btn btn-sm btn-remove p-0"
                            @click.stop="removeH2ListItem(element.id)"
                        >
                            <i class="bi bi-trash"></i>
                        </span>
                    </div>

                    <div class="mt-2" v-show="!element.collapsed">
                        <draggable
                            class="w-100"
                            :list="element.subHeadings"
                            handle=".bi-grip-vertical"
                            @change="rebuildSelectedArticleOutline"
                            :group="{
                                name: 'outlineHeadings',
                                pull: 'clone',
                                put: true,
                            }"
                        >
                            <div
                                v-for="subElement in element.subHeadings"
                                :key="subElement.id"
                                class="h3-element-wrapper d-flex justify-content-sm-between"
                            >
                                <button
                                    class="h3-element heading-button draggable-element btn d-flex flex-1 align-items-center p-0"
                                    @click="
                                        subElement.collapsed =
                                            !subElement.collapsed
                                    "
                                >
                                    <span
                                        class="arrow-icon bi bi-grip-vertical text-muted"
                                    ></span>
                                    <span class="badge badge-light me-2"
                                        >H3</span
                                    >
                                    <h3
                                        class="heading-content flex-1 text-start text-truncate fs-6 fw-normal m-0 p-0"
                                        :title="subElement.name"
                                        contenteditable="true"
                                        @blur="
                                            updateSubHeading(
                                                element,
                                                subElement,
                                                $event.target.innerText
                                            )
                                        "
                                    >
                                        {{ subElement.name }}
                                    </h3>
                                </button>
                                <span
                                    class="btn btn-sm btn-rephrase p-0 me-1"
                                    @click.stop="rephrase(subElement.id, element.id)"
                                >
                                    <i :class="{'bi bi-arrow-clockwise rotate-icon': subElement.isRephrasing, 'bi bi-arrow-clockwise': !subElement.isRephrasing}"></i>
                                </span>
                                <span
                                    class="btn btn-sm btn-remove p-0"
                                    @click.stop="
                                        removeH3ListItem(
                                            element.id,
                                            subElement.id
                                        )
                                    "
                                >
                                    <i class="bi bi-trash"></i>
                                </span>
                            </div>
                        </draggable>
                        <div class="d-flex ms-10 mt-4">
                            <button
                                class="btn btn-sm btn-light-primary"
                                @click="addH3Element(element)"
                            >
                                {{ __('Add H3') }}
                            </button>
                            <button
                                class="btn btn-sm btn-light-primary ms-5"
                                :class="[generatingH3Ideas ? 'disabled' : '']"
                                @click="generateH3Ideas(element, element.id)"
                            >
                                {{
                                    generatingH3Ideas &&
                                    h3GeneratingParentId == element.id
                                        ? __('Generating...')
                                        : __('Generate H3 Ideas')
                                }}
                            </button>
                        </div>
                        <div
                            class="ms-10 me-10 my-4 p-4 border border-2 rounded border-secondary generated-ideas-accordion"
                            v-if="isVisibleGeneratedH3Section(element)"
                        >
                            <a
                                data-bs-toggle="collapse"
                                :href="`#generatedH3Collapse-${index}`"
                                role="button"
                                aria-expanded="true"
                                :aria-controls="`#generatedH3Collapse-${index}`"
                            >
                                {{ __('Generated H3 Ideas') }}
                                <i class="arrow-icon bi bi-chevron-down"></i>
                                <i class="arrow-icon bi bi-chevron-up"></i>
                            </a>
                            <div
                                class="collapse show"
                                :id="`generatedH3Collapse-${index}`"
                            >
                                <div class="card card-body">
                                    <GeneratedH3Ideas
                                        v-if="!generatingH3Ideas"
                                        :h2HeadingId="element.id"
                                        @rebuild-selected-article-outline="
                                            rebuildSelectedArticleOutline
                                        "
                                    ></GeneratedH3Ideas>
                                    <div
                                        v-else
                                        class="d-flex align-items-center justify-content-center bg-lighten p-10"
                                    >
                                        <div
                                            class="spinner-border spinner-border"
                                            role="status"
                                        >
                                            <span class="visually-hidden">{{
                                                __('Loading...')
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </draggable>

        <div class="d-flex mt-4">
            <button class="btn btn-sm btn-light-primary" @click="addH2Element">
                {{ __('Add H2') }}
            </button>
            <button
                class="btn btn-sm btn-light-primary ms-5"
                :class="[generatingH2Ideas ? 'disabled' : '']"
                @click="generateH2Ideas"
            >
                {{
                    generatingH2Ideas
                        ? __('Generating...')
                        : __('Generate H2 Ideas')
                }}
            </button>
        </div>

        <div
            class="my-2 p-4 border border-2 rounded border-primary generated-ideas-accordion"
        >
            <a
                data-bs-toggle="collapse"
                href="#generatedH2Collapse"
                role="button"
                aria-expanded="true"
                aria-controls="generatedH2Collapse"
            >
                {{ __('Generated H2 Ideas') }}

                <i class="arrow-icon bi bi-chevron-down"></i>
                <i class="arrow-icon bi bi-chevron-up"></i>
            </a>
            <div class="collapse show" id="generatedH2Collapse">
                <div class="card card-body">
                    <GeneratedH2Ideas
                        v-if="!generatingH2Ideas"
                        @rebuild-selected-article-outline="
                            rebuildSelectedArticleOutline
                        "
                    ></GeneratedH2Ideas>
                    <div
                        v-else
                        class="d-flex align-items-center justify-content-center bg-lighten p-10"
                    >
                        <div
                            class="spinner-border spinner-border"
                            role="status"
                        >
                            <span class="visually-hidden">{{
                                __('Loading...')
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FaqSelection
            @rebuild-selected-article-outline="rebuildSelectedArticleOutline"
        ></FaqSelection>
    </div>
</template>

<script>
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import { VueDraggableNext } from 'vue-draggable-next';
import GeneratedH2Ideas from '@/Layouts/Article/Partials/Wizard/Guided/Partials/GeneratedH2Ideas.vue';
import GeneratedH3Ideas from '@/Layouts/Article/Partials/Wizard/Guided/Partials/GeneratedH3Ideas.vue';
import FaqSelection from '@/Layouts/Article/Partials/Wizard/Guided/Partials/FaqSelection.vue';
import { useToast } from 'vue-toastification';

export default {
    setup() {
        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { articleWizardStore, toast };
    },

    components: {
        draggable: VueDraggableNext,
        GeneratedH2Ideas,
        GeneratedH3Ideas,
        FaqSelection,
    },

    data() {
        return {
            generatingH2Ideas: false,
            generatingH3Ideas: false,
            h3GeneratingParentId: '',
        };
    },

    watch: {
        headingLists() {
            this.rebuildSelectedArticleOutline();
        },
        'articleWizardStore.currentStep': {
            handler(newVal) {
                let activeStep = this.articleWizardStore.getSerpBeater.uuid
                    ? 3
                    : 2;
                if (newVal === activeStep) {
                    if (
                        this.articleWizardStore.getAiGeneratedH2Ideas.length ===
                            0 ||
                        this.articleWizardStore.getArticleTitle !==
                            this.articleWizardStore.getPreviousArticleTitle
                    ) {
                        this.articleWizardStore.setAiGeneratedH2Ideas([]);
                        this.generateH2Ideas();
                    }
                }
            },
            immediate: true,
        },
    },

    methods: {
        updateHeading(element, newHeading) {
            element.name = newHeading;
            this.rebuildSelectedArticleOutline();
        },
        updateSubHeading(element, subElement, newSubHeading) {
            subElement.name = newSubHeading;
            this.rebuildSelectedArticleOutline();
        },
        focusH2Heading(h2OutlineItem) {
            this.articleWizardStore.setCurrentH2Id(h2OutlineItem.id);
        },
        addH2Element() {
            const newH2Element = {
                id: Date.now(), // Generate a unique ID for the new element
                name: this.__('New H2 Element'),
                collapsed: true,
                subHeadings: [],
            };

            this.articleWizardStore.$patch((state) => {
                state.headingLists.push(newH2Element);
            });
        },
        addH3Element(parentElement) {
            const newH3Element = {
                id: Date.now(), // Generate a unique ID for the new element
                name: this.__('New H3 Element'),
                collapsed: true,
            };
            parentElement.subHeadings.push(newH3Element);
        },
        rebuildSelectedArticleOutline() {
            let articleOutlineStructure = {
                headings: this.headingLists,
                faqs: this.articleWizardStore.getSelectedFaqList,
            };

            this.articleWizardStore.setArticleOutline(articleOutlineStructure);
        },
        rephrase(headingId, parentHeadingId = null) {
            let vm = this;
            let headingList = vm.headingLists;
            let targetIndex = -1;
            let headingToRephrase = null;

            if (parentHeadingId) {
                // Find the parent H2 heading
                let parentIndex = headingList.findIndex(h2 => h2.id === parentHeadingId);
                if (parentIndex === -1) {
                    vm.toast.error(vm.__('Parent heading not found'));
                    return false;
                }
                // Now find the H3 within the subHeadings of this H2
                targetIndex = headingList[parentIndex].subHeadings.findIndex(h3 => h3.id === headingId);
                if (targetIndex === -1) {
                    vm.toast.error(vm.__('Sub-heading not found'));
                    return false;
                }
                headingToRephrase = headingList[parentIndex].subHeadings[targetIndex];
            } else {
                // It's an H2 heading
                targetIndex = headingList.findIndex(h2 => h2.id === headingId);
                if (targetIndex === -1) {
                    vm.toast.error(vm.__('Heading not found'));
                    return false;
                }
                headingToRephrase = headingList[targetIndex];
            }

            // Set rephrasing state
            headingToRephrase.isRephrasing = true;

            let payload = {
                prompts: {
                    description: headingToRephrase.name,
                },
                options: {
                    ...this.articleWizardStore.getOpenAiOptions,
                    outputs: 1
                },
                lang: this.articleWizardStore.getLanguage,
                formality: this.articleWizardStore.getFormality,
            };

            axios.post('/api/article/ai-assistant/rephrase-heading', payload)
                .then(response => {
                    let rephrasedText = response.data.generated_text.text; // Adjust according to your API response
                    headingToRephrase.name = rephrasedText;  // Update heading name with rephrased text
                })
                .catch(error => {
                    vm.toast.error(vm.__('Some error occurred'));
                })
                .finally(() => {
                    headingToRephrase.isRephrasing = false;  // Reset rephrasing state
                    vm.rebuildSelectedArticleOutline();  // Reflect changes in the UI
                });
        },

        removeH2ListItem(listId) {
            let updatedHeadingLists = this.headingLists.filter(
                (heading) => heading.id !== listId
            );

            this.articleWizardStore.setHeadingLists(updatedHeadingLists);
            this.articleWizardStore.removeAiGeneratedH3Ideas(listId);
        },
        removeH3ListItem(h2Id, h3Id) {
            let parentListKey = null;
            let currentHeadingList = this.articleWizardStore.getHeadingLists;

            let parentlist = currentHeadingList.filter((heading, index) => {
                if (heading.id == h2Id) {
                    parentListKey = index;
                    return true;
                }
            });

            if (0 === parentlist.length) {
                return false;
            }

            let updatedSubHeadingLists = parentlist[0]?.subHeadings?.filter(
                (subHeading) => subHeading.id !== h3Id
            );
            currentHeadingList[parentListKey].subHeadings =
                updatedSubHeadingLists;

            this.articleWizardStore.setHeadingLists(currentHeadingList);
        },
        getUniqueHeading() {
            return [
                ...new Map(
                    this.headingLists.map((headingList) => [
                        headingList.id,
                        headingList,
                    ])
                ).values(),
            ];
        },
        removeDuplicateHeading() {
            this.headingLists = this.getUniqueHeading();
        },
        generateH2Ideas() {
            let vm = this;
            vm.generatingH2Ideas = true;
            let payload = this.articleWizardStore.getArticleOutlineRequestPayload;
            payload.options.outputs = 1;

            axios
                .post('/api/article/ai-assistant/generate-h2-ideas', payload)
                .then((response) => {
                    let h2Ideas = [];

                    try {
                        h2Ideas = JSON.parse(
                            response.data.generated_h2_ideas[0]?.text
                        )?.h2_ideas;
                    } catch (error) {
                        vm.toast.error(vm.__('Some error occurred'));
                    }

                    vm.articleWizardStore.setAiGeneratedH2Ideas(h2Ideas);
                    vm.generatingH2Ideas = false;
                })
                .catch((error) => {
                    vm.toast.error(vm.__('Some error occurred'));
                    vm.generatingH2Ideas = false;
                });
        },
        generateH3Ideas(headingObject, h2Id) {
            if (!headingObject?.name) {
                return false;
            }

            let vm = this;
            vm.generatingH3Ideas = true;
            vm.h3GeneratingParentId = h2Id;
            let payload = this.getPayload();
            payload.prompts.description = headingObject?.name;
            // payload.outline = this.articleWizardStore.getArticleOutline;

            axios
                .post('/api/article/ai-assistant/generate-h3-ideas', payload)
                .then((response) => {
                    let h3Ideas = [];
                    try {
                        h3Ideas = JSON.parse(
                            response.data?.generated_h3_ideas[0]?.text
                        ).h3_ideas;
                    } catch (error) {
                        vm.toast.error(vm.__('Some error occurred'));
                    }

                    vm.articleWizardStore.setAiGeneratedH3Ideas(
                        h3Ideas,
                        headingObject.id
                    );
                    vm.generatingH3Ideas = false;
                })
                .catch((error) => {
                    vm.toast.error(vm.__('Some error occurred'));
                    vm.generatingH3Ideas = false;
                });
        },
        getPayload() {
            let payload = this.articleWizardStore.getArticleOutlineRequestPayload;

            if (0 !== Object.keys(payload).length) {
                payload.options = {
                    ...this.articleWizardStore.getOpenAiOptions,
                    ...{ outputs: 1 },
                };

                return payload;
            }

            return {
                prompts: {
                    description: this.articleWizardStore.getArticleTitle,
                    name: '',
                },
                options: {
                    ...this.articleWizardStore.getOpenAiOptions,
                    ...{ outputs: 1 },
                },
                lang: this.articleWizardStore.getLanguage,
                formality: this.articleWizardStore.getFormality,
                headline: this.articleWizardStore.getArticleTitle,
                outline: this.articleWizardStore.getArticleOutline,
                keyword: this.articleWizardStore.getFocusKeyword,
            };
        },
        isVisibleGeneratedH3Section(element) {
            return this.articleWizardStore.getAiGeneratedH3Ideas[element.id]
                ?.length
                ? true
                : false;
        },
    },
    computed: {
        headingLists: {
            get() {
                return this.articleWizardStore.getHeadingLists;
            },
            set(value) {
                this.articleWizardStore.setHeadingLists(value);
            },
        },
    },
};
</script>
<style lang="scss">
.h2-element-wrapper,
.h3-element-wrapper {
    border: 1px solid transparent;
    padding: 6px;
    border-radius: 4px;

    .btn-remove,
    .btn-rephrase {
        color: transparent;
    }

    [contenteditable='true'] {
        outline: none;

        &:focus {
            outline: none;
        }
    }

    .btn-rephrase {
        padding-right: 0.35rem;

        i {
            padding-right: 0;
        }
    }

    .btn-remove,
    .btn-rephrase {
        .bi-trash,
        .bi-arrow-clockwise {
            color: transparent;
        }
    }

    &:hover {
        border: 1px solid #00000020;

        > .btn-remove,
        > .btn-rephrase,
        > div > div > .btn-remove,
        > div > div > .btn-rephrase {
            .bi-trash,
            .bi-arrow-clockwise {
                color: var(--bs-gray-500);
            }

            &:hover {
                .bi-arrow-clockwise {
                    color: var(--bs-primary);
                }
                .bi-trash {
                    color: var(--bs-danger);
                }
            }
        }
    }
}

.article-wizard,
.serp-beater-wizard {
    .generated-ideas-accordion {
        a {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;

            &.collapsed {
                .bi-chevron-down {
                    display: block;
                }

                .bi-chevron-up {
                    display: none;
                }
            }

            .bi-chevron-down {
                display: none;
            }

            .bi-chevron-up {
                display: block;
            }

            .arrow-icon {
                color: var(--bs-link-color);
            }
        }

        .card-body {
            padding: 0.3rem 0rem;

            .list-group-item {
                border: none;
                padding-left: 0px;
                cursor: pointer;
            }
        }
    }

    .dragH2Area {
        width: 85%;
    }
}
.rotate-icon {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

</style>
