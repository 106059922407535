<template>
    <draggable
        v-model="selectedArticleTitle"
        @add="articleTitleDropped"
        class="drag-title-area choice-text w-100 border border-1 border-gray-200"
        :class="{
            'p-0': articleWizardStore.getArticleTitleDragging,
            'p-3': !articleWizardStore.getArticleTitleDragging,
        }"
        group="titles"
        item-key="h"
    >
        <div
            v-if="!articleWizardStore.getArticleTitleDragging"
            class="fs-3 fw-bolder"
            contenteditable="true"
            @blur="updateArticleTitle"
        >
            {{ articleWizardStore.getArticleTitle }}
        </div>
        <div
            v-else
            id="article-title-drop-zone"
            class="d-flex border-0 bg-lighten fs-4 justify-content-center align-items-center p-3"
            @dragover.prevent="dragOver"
            @dragleave="dragLeave"
            @drop="drop"
        >
            {{ __('Drop it here') }}
        </div>
    </draggable>
</template>

<script>
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import { VueDraggableNext } from 'vue-draggable-next';

export default {
    setup() {
        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        return { articleWizardStore };
    },

    components: {
        draggable: VueDraggableNext,
    },

    data() {
        return {
            selectedArticleTitle: [],
        };
    },

    methods: {
        articleTitleDropped(event) {
            event.preventDefault();
            this.articleWizardStore.setPreviousArticleTitle(
                this.articleWizardStore.getArticleTitle
            );
            this.articleWizardStore.setArticleTitle(event.item._underlying_vm_.h);
        },
        dragOver() {
            let dropZone = document.getElementById('article-title-drop-zone');
            dropZone.classList.remove('bg-lighten');
            dropZone.classList.add('bg-light-primary');
        },
        dragLeave() {
            let dropZone = document.getElementById('article-title-drop-zone');
            dropZone.classList.remove('bg-light-primary');
            dropZone.classList.add('bg-lighten');
        },
        drop() {
            let dropZone = document.getElementById('article-title-drop-zone');
            dropZone.classList.remove('bg-light-primary');
            dropZone.classList.add('bg-lighten');
        },
        updateArticleTitle(event) {
            this.articleWizardStore.setArticleTitle(event.target.innerHTML);
        },
        updateArticleBody(event) {
            this.selectedArticleBody = event.target.innerHTML;
            this.articleWizardStore.setArticleBody(event.target.innerHTML);
        },
    },
};
</script>

<style scoped lang="scss"></style>
