import { defineStore } from 'pinia';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';
function getDefaultState() {
    return {
        user: null,
    };
}
export const useUserStore = defineStore('user', {
    state: () => {
        return getDefaultState();
    },
    getters: {
        getUser: (state) => state.user,
        getUserInfo: (state) => {
            return state.user?.user_info;
        },
        subscriptionStore: () => {
            const subscription = useSubscriptionStore();

            return subscription;
        },
        getUserLocale: (state) => {
            return `${state.getUserInfo?.language}-${state.getUserInfo?.country}`;
        },
        getCurrentProject: (state) => {
            return state.user?.current_project;
        },
        hasAccessToSeo: (state) => {
            return (
                state.subscriptionStore.isUserOnTrial ||
                state.subscriptionStore.hasStandardPlan ||
                state.subscriptionStore.hasProfessionalPlan ||
                state.subscriptionStore.hasLifetimeSubscription
            );
        },
        hasAccessToNlp: (state) => {
            return (
                state.subscriptionStore.isUserOnTrial ||
                state.subscriptionStore.hasProfessionalPlan ||
                state.subscriptionStore.getLifetimeSubscriptions?.length > 1
            );
        },
    },
    actions: {
        setUser(value) {
            this.user = value;
        },
    },
});
