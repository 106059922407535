<template>
    <node-view-wrapper class="vue-component">
        <Vue3DraggableResizable :initW="initialWidth" :initH="initialHeight" v-model:x="xAxis" v-model:y="yAxis"
            v-model:w="width" v-model:h="height" v-model:active="active" :draggable="true" :resizable="true"
            @resize-end="resizeEnd" :class="alignment">
            <p class="img-blk">
                <img v-bind="node.attrs" :alt="node.attrs.alt">
            </p>
        </Vue3DraggableResizable>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper } from '@tiptap/vue-3';
import Vue3DraggableResizable from 'vue3-draggable-resizable';
//default styles
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

export default {

    components: {
        NodeViewWrapper,
        Vue3DraggableResizable,
    },

    props: {
        node: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            xAxis: 0,
            yAxis: 0,
            height: 100,
            width: 100,
            active: false,
        }
    },

    methods: {
        resizeEnd() {
            let currentDimensions = {
                height: this.height,
                width: this.width,
            };

            emitter.emit('document-image-insert-with-meta', { ...this.node.attrs, ...currentDimensions });
        }
    },

    computed: {
        initialWidth() {
            return this.node?.attrs?.width || 500;
        },
        initialHeight() {
            return this.node?.attrs?.height || 500;
        },
        alignment() {
            return this.node?.attrs?.align ? `align-${this.node.attrs.align}` : 'align-center';
        },
    }

};
</script>
<style lang="scss">
.vdr-container.dragging {
    border-color: #009EF7;
    border-style: solid;
}

.vdr-container.active {
    border-color: #009EF7;
    border-style: solid;
}

.vdr-handle {
    border: 1px solid #009EF7;
}

.vdr-container,
.vdr-container.active {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    margin: 0 auto;
    &.align-left{
        margin: 0;
    }
    &.align-center{
        margin: 0 auto;
    }
    &.align-right{
        margin: auto;
    margin-right: 0;
    }
}

.img-blk {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100% !important;
    }
}

</style>