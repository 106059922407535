<template>

    <div class="border border-gray-300 ">
        <!-- H1 Display Section -->
        <div class="h1-element d-flex p-2 ms-0 bg-light"
             @click="addAll(headingLists)">

            <div class="d-flex flex-1">
                <span class="headline">{{ articleWizardStore?.getArticleTitle }}</span>
            </div>
            <div class="d-flex add-action">
                    <span class="arrow-left me-1">
                        <i class="bi bi-arrow-left"></i>
                    </span>
            </div>
        </div>
        <draggable
            class="dragArea outline-elements w-100 p-2"
            :list="headingLists"
            :group="{ name: 'outlineHeadings', pull: 'clone', put: false }"
        >
            <div
                v-if="!outlineItem.isLoading"
                v-for="element in headingLists"
                :key="element.id"
                class="list-group-item py-1"
                :class="{ 'added-item': isAddedH2(element) }"
            >
                <div
                    class="h2-element d-flex draggable-element p2 ms-0 mb-1"
                    @click="addToCurrentFocusedH2Heading(element)"
                >
                    <div class="d-flex align-items-baseline">
                        <span
                            class="arrow-icon text-muted bi bi-grip-vertical"
                        ></span>
                        <span class="badge badge-secondary me-2">H2</span>
                    </div>
                    <div class="d-flex flex-1">
                        <span class="headline">{{ element.name }}</span>
                    </div>
                    <div class="d-flex add-action">
                        <span class="arrow-left me-1"
                            ><i class="bi bi-arrow-left"></i
                        ></span>
                    </div>
                </div>
                <draggable
                    v-if="element.subHeadings.length > 0"
                    class="outline-elements w-100 px-2"
                    :list="element.subHeadings"
                    :group="{ name: 'outlineHeadings', pull: 'clone', put: false }"
                >
                    <div
                        v-for="subElement in element.subHeadings"
                        :key="subElement.id"
                        class="list-group-item p-1"
                        :class="{ 'added-item': isAddedH3(subElement) }"
                    >
                        <div
                            class="h3-element d-flex draggable-element ms-0"
                            @click="addToCurrentFocusedH3Heading(subElement)"
                        >
                            <div class="d-flex align-items-baseline">
                                <span
                                    class="arrow-icon text-muted bi bi-grip-vertical"
                                ></span>
                                <span class="badge badge-light me-2">H3</span>
                            </div>
                            <div class="d-flex flex-1">
                                <span class="headline">{{ subElement.name }}</span>
                            </div>
                            <div class="d-flex add-action">
                                <span class="arrow-left"
                                    ><i class="bi bi-arrow-left"></i
                                ></span>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>

            <div
                v-else
                class="d-flex align-items-center justify-content-center bg-lighten px-10 py-20"
            >
                <div class="spinner-border spinner-border" role="status">
                    <span class="visually-hidden">{{ __('Loading...') }}</span>
                </div>
            </div>
        </draggable>
    </div>
</template>

<script>
import { computed, ref, watchEffect } from 'vue';
import { getCurrentInstance } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import { useToast } from 'vue-toastification';
import articleWizardModal from "../../../../../../Modals/ArticleWizardModal.vue";

export default {
    setup(props) {
        const instance = getCurrentInstance();
        const toast = useToast();

        // Access the store
        const articleWizardStore = useArticleWizardStore();

        const addAll = (element) => {
            // Helper function to add H3 items under a specific H2
            const addAllH3 = (h2Id, h3Items) => {
                h3Items.forEach(h3Item => {
                    const clonedH3Item = JSON.parse(JSON.stringify(h3Item));
                    articleWizardStore.addToH3HeadingList(h2Id, clonedH3Item);
                });
            };

            // Adding all H2 items and their nested H3 items
            element.forEach(heading => {
                const clonedHeading = JSON.parse(JSON.stringify(heading));
                articleWizardStore.addToH2HeadingList(clonedHeading);  // Add H2
                articleWizardStore.setCurrentH2Id(clonedHeading.id);  // Set current H2 ID to add H3 items under it

                if (heading.headings && heading.headings.length > 0) {
                    addAllH3(clonedHeading.id, heading.subHeadings);  // Add all H3 items under this H2
                }
            });
        };

        const addToCurrentFocusedH2Heading = (outlineItem) => {
            const clonedOutlineItem = JSON.parse(JSON.stringify(outlineItem));
            articleWizardStore.addToH2HeadingList(clonedOutlineItem);
            articleWizardStore.setCurrentH2Id(clonedOutlineItem.id);
        };

        const addToCurrentFocusedH3Heading = (outlineItem) => {
            const currentH2Id = articleWizardStore.getCurrentH2Id; // fetch current H2 ID
            if (currentH2Id !== null) {
                const clonedOutlineItem = JSON.parse(
                    JSON.stringify(outlineItem)
                );
                articleWizardStore.addToH3HeadingList(
                    currentH2Id,
                    clonedOutlineItem
                );
            } else {
                toast.error(
                    instance.proxy.__('Please select a H2 heading first')
                );
            }
        };

        const isAddedH2 = (h2Element) => {
            return articleWizardStore.getHeadingLists.some(
                (heading) => heading.id === h2Element.id
            );
        };

        const isAddedH3 = computed(() => {
            return (outlineItem) => {
                let isAdded = false;
                const headingLists = articleWizardStore.getHeadingLists;

                headingLists.forEach((h2) => {
                    const foundH3 = h2.subHeadings.some((h3) => {
                        return h3.id === outlineItem.id;
                    });

                    if (foundH3) {
                        isAdded = true;
                    }
                });

                return isAdded;
            };
        });

        const getOutlineContent = (outline) => {
            try {
                return JSON.parse(outline.data.text);
            } catch (error) {
                return {
                    h: [],
                };
            }
        };

        const parsedOutline = computed(() =>
            getOutlineContent(props.outlineItem)
        );

        const headingLists = computed(() => {
            let i = 0;
            const newHeadingLists = [];

            if (parsedOutline.value.h) {
                parsedOutline.value.h.forEach((heading) => {
                    for (const key in heading) {
                        const headingItem = {
                            name: key,
                            id: `${props.index}-${++i}`,
                            subHeadings: [],
                        };

                        if (heading.hasOwnProperty(key)) {
                            heading[key].forEach((subheading) => {
                                if ('object' == typeof subheading) {
                                    subheading = Object.keys(subheading)[0];
                                }

                                headingItem.subHeadings.push({
                                    name: subheading,
                                    id: `${props.index}-${++i}`,
                                });
                            });
                        }

                        newHeadingLists.push(headingItem);
                    }
                });
            }

            return newHeadingLists;
        });

        return {
            articleWizardStore,
            toast,
            addAll,
            addToCurrentFocusedH2Heading,
            addToCurrentFocusedH3Heading,
            isAddedH2,
            isAddedH3,
            headingLists,
            getOutlineContent,
            parsedOutline,
        };
    },

    props: {
        outlineItem: Object,
        index: Number,
    },

    components: {
        draggable: VueDraggableNext,
    },
};
</script>

<style lang="scss">
.outline-elements {
    .h3-element {
        margin-left: 10px;
    }

    .list-group-item {
        border-radius: 0;

        &:hover,
        &:focus {
            background-color: rgba(54, 153, 255, 0.1);
        }
    }
}
.added-item {
    > div > div > .headline {
        color: var(--bs-gray-400);
    }
}
</style>
