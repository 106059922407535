<template>
    <app-layout
        :title="__('Bulk processing')"
        :view="__('Files')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Bulk processing') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('Bulk processing') }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{
                        __('Here you can find all the files you have created.')
                    }}
                </div>
            </div>
        </div>
        <div class="card card-flush card-bordered">
            <!--begin::Card header-->
            <div class="card-header align-items-center py-5 gap-2 gap-md-5">
                <!--begin::Card title-->
                <div class="card-title">
                    <!--begin::Search-->
                    <div
                        class="d-flex align-items-center position-relative my-1"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                        <span
                            class="svg-icon svg-icon-1 position-absolute ms-4"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="currentColor"
                                ></rect>
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                        <input
                            type="text"
                            v-model="search"
                            @input="onChangeSearch"
                            class="form-control form-control-solid w-250px ps-14"
                            :placeholder="__('Search File')"
                        />
                    </div>
                    <!--end::Search-->
                </div>
                <!--end::Card title-->
                <!--begin::Card toolbar-->
                <div
                    class="card-toolbar flex-row-fluid justify-content-end gap-5"
                >
                    <!--begin::Add product-->
                    <a
                        href="#"
                        @click.prevent="sendBulkDataToModal"
                        class="btn btn-danger"
                    >
                        {{ __('Delete') }}
                    </a>
                    <!--begin::Add product-->
                    <a
                        href="#"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_bulk_processing"
                    >
                        {{ __('New') }}
                    </a>
                    <!--end::Add product-->
                </div>
                <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
                <!--begin::Table-->
                <div
                    id="files_table_wrapper"
                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                    <div class="table-responsive">
                        <table
                            class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                            id="files_table"
                        >
                            <!--begin::Table head-->
                            <thead>
                                <!--begin::Table row-->
                                <tr
                                    class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                                >
                                    <th
                                        class="w-10px pe-2 sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label=""
                                    >
                                        <div
                                            class="form-check form-check-sm form-check-custom form-check-solid me-3"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="allSelected"
                                                @change="selectAll"
                                            />
                                        </div>
                                    </th>
                                    <th
                                        class="min-w-200px sorting"
                                        tabindex="0"
                                        aria-controls="files_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Name"
                                    >
                                        {{ __('Name') }}
                                    </th>
                                    <th
                                        class="text-end min-w-100px sorting"
                                        tabindex="0"
                                        aria-controls="files_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Updated At"
                                    >
                                        {{ __('Updated At') }}
                                    </th>
                                    <th
                                        class="text-end min-w-100px sorting"
                                        tabindex="0"
                                        aria-controls="files_table"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Created At"
                                    >
                                        {{ __('Created At') }}
                                    </th>
                                    <th
                                        class="text-end min-w-70px sorting_disabled"
                                        rowspan="1"
                                        colspan="1"
                                        aria-label="Actions"
                                    >
                                        {{ __('Actions') }}
                                    </th>
                                </tr>
                                <!--end::Table row-->
                            </thead>
                            <!--end::Table head-->
                            <!--begin::Table body-->
                            <tbody class="fw-bold text-gray-600">
                                <!--begin::Table row-->

                                <!--end::Table row-->
                                <tr v-for="(file, index) in files">
                                    <!--begin::Checkbox-->
                                    <td>
                                        <div
                                            class="form-check form-check-sm form-check-custom form-check-solid"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="fileIds"
                                                @change="select"
                                                :value="file.uuid"
                                            />
                                        </div>
                                    </td>
                                    <!--end::Checkbox-->
                                    <!--begin::Category=-->
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <!--begin::Title-->
                                            <span v-if="file.progress < 100">{{
                                                file.name
                                            }}</span>
                                            <a
                                                v-else
                                                :href="'/bulk/' + file.uuid"
                                                class="text-gray-700"
                                                target="_blank"
                                            >
                                                {{ file.name }}
                                            </a>
                                            <!--end::Title-->
                                        </div>
                                    </td>
                                    <!--end::Category=-->
                                    <!--begin::SKU=-->
                                    <td class="text-end pe-0">
                                        {{
                                            moment(file.updated_at).format(
                                                'L LTS'
                                            )
                                        }}
                                    </td>
                                    <!--end::SKU=-->
                                    <!--begin::Qty=-->
                                    <td class="text-end pe-0" data-order="41">
                                        {{
                                            moment(file.created_at).format(
                                                'L LTS'
                                            )
                                        }}
                                    </td>
                                    <!--end::Qty=-->
                                    <!--begin::Action=-->
                                    <td
                                        class="text-end position-relative action-dropdown-menu"
                                    >
                                        <div
                                            v-if="file.progress < 100"
                                            class="progress"
                                        >
                                            <div
                                                class="progress-bar progress-bar-striped progress-bar-animated"
                                                role="progressbar"
                                                :style="{
                                                    width: file.progress + '%',
                                                }"
                                                :aria-valuenow="file.progress"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            >
                                                {{ file.progress }}%
                                            </div>
                                        </div>
                                        <div v-else>
                                            <a
                                                href="#"
                                                class="btn btn-sm btn-light btn-active-light-primary"
                                                data-kt-menu-trigger="click"
                                                data-kt-menu-placement="bottom-end"
                                                @click.prevent="toggle"
                                                v-click-away="onClickAway"
                                                >{{ __('Actions') }}
                                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                                                <span
                                                    class="svg-icon svg-icon-5 m-0"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon--></a
                                            >
                                            <!--begin::Menu-->
                                            <div
                                                class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary position-absolute fw-bold fs-7 w-125px py-4"
                                                data-kt-menu="true"
                                            >
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a
                                                        :href="
                                                            '/bulk/' + file.uuid
                                                        "
                                                        class="menu-link px-3"
                                                        target="_blank"
                                                        >{{ __('Download') }}</a
                                                    >
                                                </div>
                                                <!--end::Menu item-->
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a
                                                        href="#"
                                                        class="menu-link px-3"
                                                        @click.prevent="
                                                            sendDataToModal(
                                                                file.uuid
                                                            )
                                                        "
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_delete"
                                                        >{{ __('Delete') }}</a
                                                    >
                                                </div>
                                                <!--end::Menu item-->
                                            </div>
                                            <!--end::Menu-->
                                        </div>
                                    </td>
                                    <!--end::Action=-->
                                </tr>
                            </tbody>
                            <!--end::Table body-->
                        </table>
                    </div>
                    <div class="row">
                        <div
                            class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                        >
                            <div
                                class="dataTables_length"
                                id="files_table_length"
                            >
                                <label
                                    >{{ __('Showing') }} {{ from }}
                                    {{ __('to') }} {{ to }} {{ __('of') }}
                                    {{ total }} {{ __('entries') }}</label
                                >
                            </div>
                        </div>
                        <div
                            class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                        >
                            <div
                                class="dataTables_paginate paging_simple_numbers"
                                id="files_table_paginate"
                            >
                                <ul class="pagination">
                                    <li
                                        v-for="(link, index) in pageLinks"
                                        class="page-item"
                                        :class="{
                                            'prev previous': index === 0,
                                            next: index === 14,
                                            active: link.label == currentPage,
                                        }"
                                    >
                                        <a
                                            href="#"
                                            @click.prevent="
                                                fetchFiles(link.url)
                                            "
                                            class="page-link"
                                        >
                                            <span v-if="index === 0"
                                                ><i class="previous"></i
                                            ></span>
                                            <span
                                                v-else-if="
                                                    index ===
                                                    Object.keys(pageLinks)
                                                        .length -
                                                        1
                                                "
                                                ><i class="next"></i
                                            ></span>
                                            <span v-else>{{ link.label }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
import Multiselect from '@vueform/multiselect';
import { directive } from 'vue3-click-away';
import moment from 'moment/moment';

export default defineComponent({
    components: {
        Link,
        AppLayout,
        Multiselect,
    },

    directives: {
        ClickAway: directive,
    },

    data() {
        return {
            search: '',
            filesUrl: '/api/bulk-processings',
            files: {},
            copyClicked: false,
            currentPage: 1,
            firstPageUrl: '',
            from: 1,
            to: 1,
            total: 1,
            lastPage: 1,
            lastPageUrl: '',
            nextPageUrl: '',
            prevPageUrl: '',
            pageLinks: {},
            selected: [],
            allSelected: false,
            fileIds: [],
            currentMenu: {
                open: false,
                element: {},
            },
            moment: moment,
            inProgress: false,
            progressInterval: null,
        };
    },

    mounted() {
        this.fetchFiles(this.filesUrl);
        this.checkProgress();

        let vm = this;
        emitter.on('new-bulk-processing', function () {
            vm.fetchFiles(vm.filesUrl);
            vm.checkProgress();
        });
    },

    beforeUnmount() {
        emitter.off('new-bulk-processing');
        clearInterval(this.progressInterval);
    },

    methods: {
        checkProgress() {
            let vm = this;

            clearInterval(this.progressInterval);
            vm.progressInterval = setInterval(function () {
                vm.fetchFiles(vm.filesUrl);
                vm.files.forEach(function (file) {
                    if (file.progress < 100) {
                        vm.inProgress = true;
                    }
                });
                if (!vm.inProgress) {
                    clearInterval(vm.progressInterval);
                }
                vm.inProgress = false;
            }, 5000);
        },

        sendDataToModal(uuid) {
            emitter.emit('set-delete-candidate', {
                uuid: uuid,
                url: this.filesUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this file? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
            this.$page.props.deleteCandidate = uuid;
        },

        sendBulkDataToModal() {
            emitter.emit('set-delete-candidate', {
                ids: this.fileIds,
                url: this.filesUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this file? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
        },

        deleteCallback(uuid) {
            this.fetchFiles(this.filesUrl);
        },

        fetchFiles(url) {
            if (url) {
                let filterParams = 'search=' + this.search;
                let requestUrl = url.includes('page')
                    ? url + '&' + filterParams
                    : url + '?' + filterParams;

                axios.get(requestUrl).then((response) => {
                    this.files = response.data.data;
                    this.currentPage = response.data.current_page;
                    this.firstPageUrl = response.data.first_page_url;
                    this.from = response.data.from;
                    this.to = response.data.to;
                    this.total = response.data.total;
                    this.lastPage = response.data.last_page;
                    this.lastPageUrl = response.data.last_page_url;
                    this.nextPageUrl = response.data.next_page_url;
                    this.prevPageUrl = response.data.prev_page_url;
                    this.pageLinks = response.data.links;
                });
            }
        },

        onChangeSearch(ev) {
            this.fetchFiles(this.filesUrl);
        },

        selectAll() {
            let vm = this;
            vm.fileIds = [];
            if (this.allSelected) {
                this.files.forEach(function (file) {
                    vm.fileIds.push(file.uuid);
                });
            }
        },

        select() {
            this.allSelected = false;
        },

        toggle($event) {
            let el = $event.target.closest('.action-dropdown-menu');

            let s = el.classList;
            let m =
                el.parentElement.getElementsByClassName('menu-sub-dropdown')[0]
                    .classList;

            s.contains('show') ? s.remove('show') : s.add('show');
            s.contains('menu-dropdown')
                ? s.remove('menu-dropdown')
                : s.add('menu-dropdown');
            m.contains('show') ? m.remove('show') : m.add('show');
            this.currentMenu.open = this.currentMenu.open !== true;
            this.currentMenu.element = el;
        },

        onClickAway(ev) {
            if (this.currentMenu.open) {
                let el = this.currentMenu.element;

                if (!el.parentElement.contains(ev.target)) {
                    let s = el.classList;
                    let m =
                        el.parentElement.getElementsByClassName(
                            'menu-sub-dropdown'
                        )[0].classList;

                    s.contains('show') ? s.remove('show') : s.add('show');
                    s.contains('menu-dropdown')
                        ? s.remove('menu-dropdown')
                        : s.add('menu-dropdown');
                    m.contains('show') ? m.remove('show') : m.add('show');
                    this.currentMenu.open = false;
                }
            }
        },
    },
    computed: {},
});
</script>

<style lang="scss">
.action-dropdown-menu {
    .menu-sub-dropdown {
        top: 51px;
        right: 20px;
    }
}
</style>
