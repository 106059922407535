<template>
    <div
        class="list-item d-flex flex-stack px-2 py-1"
        :class="{ active: currentChatUuid === chatGptConversation.uuid }"
        @mouseenter="showDeleteBtn = true"
        @mouseleave="showDeleteBtn = false"
        v-click-outside="exitEditMode"
    >
        <!--begin::Details-->
        <div
            class="d-flex flex-fill align-items-center"
            @click="$emit('single-chat-selected', chatGptConversation.uuid)"
        >
            <!--begin::Avatar-->
            <div class="symbol symbol-45px symbol-circle">
                <span class="symbol-label text-danger fs-6 fw-bolder">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                    >
                        <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                                opacity="0.3"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.4862 18L12.7975 21.0566C12.5304 21.54 11.922 21.7153 11.4386 21.4483C11.2977 21.3704 11.1777 21.2597 11.0887 21.1255L9.01653 18H5C3.34315 18 2 16.6569 2 15V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V15C22 16.6569 20.6569 18 19 18H14.4862Z"
                                fill="black"
                            ></path>
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7ZM6 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11Z"
                                fill="black"
                            ></path>
                        </g>
                    </svg>
                </span>
            </div>
            <!--end::Avatar-->
            <!--begin::Details-->
            <div class="ms-5">
                <a
                    href="#"
                    @click.stop.prevent="
                        $emit('single-chat-selected', chatGptConversation.uuid)
                    "
                    class="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                >
                    <div v-if="isEditing">
                        <input
                            type="text"
                            class="border border-gray-300 px-2"
                            v-model="name"
                            @keyup.enter="saveName"
                            @blur="saveName"
                        />
                    </div>
                    <div v-else @dblclick.stop.prevent="enableEditing">
                        {{ name }}
                    </div>
                </a>
            </div>
            <!--end::Details-->
        </div>
        <!--end::Details-->

        <!-- Menu with 3 dots -->
        <div
            v-if="currentChatUuid === chatGptConversation.uuid"
            class="menu"
            @click.stop="toggleDropdown"
            ref="menu"
        >
            <span class="text-gray-700 svg-icon svg-icon-primary svg-icon-1">
                <!-- 3 Dots Icon -->
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                >
                    <circle cx="5" cy="12" r="2"></circle>
                    <circle cx="12" cy="12" r="2"></circle>
                    <circle cx="19" cy="12" r="2"></circle>
                </svg>
            </span>
            <div v-if="isDropdownOpen" class="dropdown-menu show">
                <a
                    href="#"
                    class="dropdown-item"
                    @click="renameChat(chatGptConversation.uuid)"
                    >{{ __('Rename') }}</a
                >
                <a
                    href="#"
                    class="dropdown-item"
                    @click="deleteGptConversation(chatGptConversation.uuid)"
                    >{{ __('Delete') }}</a
                >
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import clickOutside from '@/Directives/click-outside';

export default {
    directives: {
        clickOutside,
    },

    props: {
        chatGptConversation: Object,
        currentChatUuid: String,
    },

    data() {
        return {
            name: '',
            showDeleteBtn: false,
            isDropdownOpen: false,
            isEditing: false,
        };
    },
    mounted() {
        this.name = this.chatGptConversation.name;
        document.addEventListener('click', this.toggleDropdown);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.toggleDropdown);
    },
    watch: {
        'chatGptConversation.name'(newValue) {
            this.name = newValue;
        },
    },
    methods: {
        enableEditing() {
            this.name = this.chatGptConversation.name;
            this.isEditing = true;
            this.$nextTick(() => {
                this.$refs.editInput.focus();
            });
        },
        saveName() {
            this.$emit(
                'update-chat-name',
                this.chatGptConversation.uuid,
                this.name
            );
            this.isEditing = false;
        },
        toggleDropdown(event) {
            if (this.$refs.menu && !this.$refs.menu.contains(event.target)) {
                this.isDropdownOpen = false;
            } else {
                this.isDropdownOpen = !this.isDropdownOpen;
            }
        },
        renameChat() {
            this.enableEditing();
        },
        deleteGptConversation(uuid) {
            emitter.emit('set-delete-candidate', {
                uuid: uuid,
                url: '/api/chat-gpt-conversation',
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this conversation? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
        },
        deleteCallback() {
            this.$emit('single-chat-deleted');
        },
        exitEditMode() {
            if (this.isEditing) {
                this.saveName();
            }
        },
    },
};
</script>

<style lang="scss">
.chat-time {
    flex: 0 0 70px;
    max-width: 70px;
    margin: 5px;
}
</style>
