<template>
    <div
        class="table-button-set"
        :class="this.isActive ? 'active' : ''"
        v-click-away="onClickAway"
    >
        <button
            type="button"
            class="table-button-item"
            @click="
                this.editor
                    .chain()
                    .focus()
                    .insertTable({ rows: 3, cols: 3, withHeaderRow: false })
                    .run()
            "
        >
            <span class="icon">
                <div class="remix fs-3">
                    <i class="fs-3 ri-table-2"></i>
                </div>
            </span>
            <span>{{ __('Insert Table') }}</span>
        </button>
        <div
            class="btn-group table-button-item align-items-center"
            role="group"
        >
            <button
                type="button"
                class="btn dropdown-toggle d-flex border-0 bg-transparent align-items-center p-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span class="icon">
                    <div class="remix">
                        <i :class="`fs-2 ri-insert-column-right`"
                        />
                    </div>
                </span>
                <span>{{ __('Add') }}</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-text-group">
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().addColumnBefore().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-insert-column-left`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Column Before') }}</span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().addColumnAfter().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-insert-column-right`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Column After') }}</span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().addRowBefore().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-insert-row-top`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Row Above') }}</span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().addRowAfter().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-insert-row-bottom`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Row After') }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div
            class="btn-group table-button-item align-items-center"
            role="group"
        >
            <button
                type="button"
                class="btn dropdown-toggle d-flex border-0 bg-transparent align-items-center p-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span class="icon">
                    <div class="remix">
                        <i :class="`fs-2 ri-delete-column`" />
                    </div>
                </span>
                <span>{{ __('Delete') }}</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-text-group">
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().deleteColumn().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-delete-column`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Column') }}</span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().deleteRow().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-delete-row`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Row') }}</span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().deleteTable().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-delete-bin-2-line`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Table') }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div
            class="btn-group table-button-item align-items-center"
            role="group"
        >
            <button
                type="button"
                class="btn dropdown-toggle d-flex border-0 bg-transparent align-items-center p-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span class="icon">
                    <div class="remix">
                        <i :class="`fs-2 ri-merge-cells-horizontal`"
                        />
                    </div>
                </span>
                <span>{{ __('Merge / Split') }}</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-text-group">
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().mergeOrSplit().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-a-b`" />
                            </div>
                        </span>
                        <span>{{ __('Merge or Split') }}</span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().splitCell().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-merge-cells-horizontal`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Merge Cells') }}</span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().toggleHeaderRow().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-split-cells-horizontal`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Split Cell') }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div
            class="btn-group table-button-item align-items-center"
            role="group"
        >
            <button
                type="button"
                class="btn dropdown-toggle d-flex border-0 bg-transparent align-items-center p-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span class="icon">
                    <div class="remix">
                        <i :class="`fs-2 ri-repeat-line`" />
                    </div>
                </span>
                <span>{{ __('Toggle') }}</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-text-group">
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().toggleHeaderColumn().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-table-line`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Header Column') }}</span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().toggleHeaderRow().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-table-line`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Header Row') }}</span>
                    </a>
                </li>
                <li>
                    <a
                        class="dropdown-item d-flex"
                        href="#"
                        @click.prevent="
                            editor.chain().focus().toggleHeaderCell().run()
                        "
                    >
                        <span class="icon">
                            <div class="remix fs-3">
                                <i :class="`fs-2 ri-table-line`"
                                />
                            </div>
                        </span>
                        <span>{{ __('Header Cell') }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <!--        <button type="button" class="table-button-item"
                        @click="this.editor.chain().focus().setCellAttribute('colspan', 2).run()">
                    <span class="icon">
                        <div class="remix fs-3">
                          <i :class="`fs-2 ri-insert-column-left`"/>
                          <i :class="`fs-2 ri-insert-column-left`"/>
                        </svg>
                    </span>
                    <span>{{ __('Set Cell Attribute') }}</span>
                </button>-->
        <!--        <button type="button" @click="this.editor.chain().focus().fixTables().run()">
                    <span class="icon">
                        <div class="remix fs-3">
                          <i :class="`fs-2 ri-insert-column-left`"/>
                          <i :class="`fs-2 ri-insert-column-left`"/>
                        </svg>
                    </span>
                    <span>{{ __('Fix Tables') }}</span>
                </button>-->
        <!--        <div class="btn-group table-button-item align-items-center" role="group">
                    <button type="button"
                            class="btn dropdown-toggle d-flex border-0 bg-transparent align-items-center p-2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="icon">
                            <div class="remix fs-3">
                              <i :class="`fs-2 ri-insert-column-left`"/>
                              <i :class="`fs-2 ri-insert-column-left`"/>
                            </svg>
                        </span>
                        <span>{{ __('Go to') }}</span>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="btn-text-group">
                        <li>
                            <a class="dropdown-item d-flex"
                               href="#"
                               @click.prevent="editor.chain().focus().toggleHeaderColumn().run();">
                                <span class="icon">
                                    <div class="remix fs-3">
                                      <i :class="`fs-2 ri-insert-column-left`"/>
                                      <i :class="`fs-2 ri-insert-column-left`"/>
                                    </svg>
                                </span>
                                <span>{{ __('Next Cell') }}</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item d-flex"
                               href="#"
                               @click.prevent="editor.chain().focus().toggleHeaderRow().run();">
                                <span class="icon">
                                    <div class="remix fs-3">
                                      <i :class="`fs-2 ri-insert-column-left`"/>
                                      <i :class="`fs-2 ri-insert-column-left`"/>
                                    </svg>
                                </span>
                                <span>{{ __('Previous Cell') }}</span>
                            </a>
                        </li>
                    </ul>
                </div>-->
    </div>
</template>

<script>
import { directive } from 'vue3-click-away';
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';

export default {
    directives: {
        ClickAway: directive,
    },

    props: {
        editor: {
            type: Object,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: true,
        },
        currentMenu: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },

    data() {
        return {
            remixiconUrl: remixiconUrl,
        };
    },

    methods: {
        onClickAway(ev) {
            if (this.isActive) {
                let el = this.currentMenu;
                if (!el.contains(ev.target)) {
                    this.$emit('updateActiveStatus', false);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.table-button-set {
    position: absolute;
    top: 42px;
    z-index: 9;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    border: 1px solid #f1f1f1;
    padding: 15px 0;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    transform: translate(-50%, 0px);
    background: #fff;
    box-shadow: 10px 13px 30px #f2f2f2;
    border-radius: 3px;
    display: none;

    &.active {
        display: flex;
    }

    &::before {
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translate(-50%, 0px);
        margin-left: auto;
        margin-right: auto;
        content: '';
        display: block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #f1f1f1;
    }

    .table-button-item {
        width: auto;
    }

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 0.4rem;

        svg {
            margin-top: -3px;
            margin-right: 4px;
            color: #7e8299;

            path {
                fill: #7e8299;
            }
        }
    }

    button,
    .dropdown-item {
        cursor: pointer;
        border: 0;
        color: #7e8299;
        background-color: transparent !important;
        padding: 5px 10px;
        border-radius: 0;
        display: flex;
        align-items: center;
        font-size: 12px;

        span {
            cursor: pointer;
        }

        &:hover {
            background-color: #eff2f5 !important;
            border: 0 !important;
            color: #181c32;

            .icon {
                svg {
                    color: #181c32;

                    path {
                        fill: #181c32;
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            padding: 4px 10px;
            font-size: 12px;
        }
    }
}
</style>
