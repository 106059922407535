<template>
    <Impersonate />
    <div class="d-flex flex-column flex-root vh-100">
        <!--begin::Authentication - Multi-steps-->
        <div
            class="d-flex flex-column flex-lg-row flex-column-fluid justify-content-start justify-content-lg-between stepper stepper-pills"
            :class="{ 'stepper-column': shouldUseStepperColumn }"
            id="kt_create_onboard_process_stepper"
        >
            <!--begin::Aside-->
            <div
                class="d-flex flex-column flex-lg-row-auto w-xl-500px bg-lighten shadow-sm"
            >
                <!--begin::Wrapper-->
                <div
                    class="d-flex flex-column flex-sm-grow-1 position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y stepper-column-bg"
                >
                    <!--begin::Header-->
                    <div
                        class="d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20"
                    >
                        <!--begin::Logo-->
                        <Link href="/" class="mb-10 mb-lg-20">
                            <jet-authentication-card-logo
                                mark-width="64"
                                mark-height="64"
                                logo-text-classes="p-3 text-white"
                            />
                        </Link>
                        <!--end::Logo-->

                        <!--begin::Nav-->
                        <div class="stepper-nav">
                            <!--begin::Steps-->
                            <div
                                class="stepper-item"
                                :class="getStepperStatusClass(index)"
                                data-kt-stepper-element="nav"
                                v-for="(step, index) in stepDetails"
                                :key="step.title"
                            >
                                <!--begin::Line-->
                                <div class="stepper-line w-40px"></div>
                                <!--end::Line-->
                                <!--begin::Icon-->
                                <div class="stepper-icon w-40px h-40px">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 448 512"
                                        class="stepper-check"
                                    >
                                        <path
                                            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                        />
                                    </svg>
                                    <span class="stepper-number">
                                        {{ index + 1 }}
                                    </span>
                                </div>
                                <!--end::Icon-->
                                <!--begin::Label-->
                                <div class="stepper-label">
                                    <h3 class="stepper-title">
                                        {{ step.title }}
                                    </h3>
                                    <div class="stepper-desc fw-bold">
                                        {{ step.short_description }}
                                    </div>
                                </div>
                                <!--end::Label-->
                            </div>
                            <!--end::Steps-->
                        </div>
                        <!--end::Nav-->
                    </div>
                </div>
                <!--end::Wrapper-->
            </div>
            <!--begin::Aside-->
            <!--begin::Body-->
            <div class="d-flex flex-column flex-lg-row-fluid py-10">
                <div
                    v-if="0 === onboardProcessStore.getCurrentStep"
                    class="d-flex justify-content-end w-lg-700px w-md-650px w-100 p-5 p-lg-15 pb-0 pb-lg-0 mx-auto"
                >
                    <SystemLanguage
                        :lang="lang"
                        dropdown-position="bottom"
                        dropdown-align="right"
                        :searchable="false"
                        @language-change="onSelectLanguage"
                    ></SystemLanguage>
                </div>
                <!--begin::Content-->
                <div class="d-flex flex-center flex-column flex-column-fluid">
                    <!--begin::Wrapper-->
                    <div
                        class="w-lg-700px w-md-650px w-100 p-5 p-lg-15 mx-auto"
                    >
                        <!--begin::Form-->
                        <form
                            class="my-auto pb-5"
                            novalidate="novalidate"
                            id="kt_create_account_form"
                        >
                            <!--begin::Step 1-->
                            <div
                                :class="getStepperStatusClass(0)"
                                data-kt-stepper-element="content"
                            >
                                <AccountType
                                    v-if="
                                        0 === onboardProcessStore.getCurrentStep
                                    "
                                >
                                </AccountType>
                            </div>
                            <!--end::Step 1-->
                            <!--begin::Step 2-->
                            <div
                                :class="getStepperStatusClass(1)"
                                data-kt-stepper-element="content"
                            >
                                <AccountInfo
                                    v-if="
                                        1 === onboardProcessStore.getCurrentStep
                                    "
                                >
                                </AccountInfo>
                            </div>
                            <!--end::Step 2-->
                            <!--begin::Actions-->
                            <div class="d-flex flex-stack pt-5">
                                <div class="mr-2">
                                    <button
                                        v-if="
                                            onboardProcessStore.getCurrentStep >
                                                0 &&
                                            onboardProcessStore.getCurrentStep
                                        "
                                        type="button"
                                        class="btn btn-lg btn-light-primary me-3"
                                        :class="{
                                            disabled:
                                                onboardProcessStore.isLoading,
                                        }"
                                        data-kt-stepper-action="previous"
                                        @click="previousStep"
                                    >
                                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr063.svg-->
                                        <span class="svg-icon svg-icon-4 me-1">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <rect
                                                    opacity="0.5"
                                                    x="6"
                                                    y="11"
                                                    width="13"
                                                    height="2"
                                                    rx="1"
                                                    fill="black"
                                                />
                                                <path
                                                    d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                                                    fill="black"
                                                />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                        {{ __('Back') }}
                                    </button>
                                </div>
                                <div>
                                    <button
                                        v-if="
                                            onboardProcessStore.getCurrentStep ===
                                            stepDetails.length - 1
                                        "
                                        type="button"
                                        class="btn btn-lg btn-primary"
                                        :class="{
                                            disabled:
                                                onboardProcessStore.isLoading,
                                        }"
                                        data-kt-stepper-action="submit"
                                        @click="submit"
                                    >
                                        <span
                                            v-if="
                                                !onboardProcessStore.isLoading
                                            "
                                            class="indicator-label"
                                        >
                                            {{ __('Continue') }}
                                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                                            <span
                                                class="svg-icon svg-icon-4 ms-2"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <rect
                                                        opacity="0.5"
                                                        x="18"
                                                        y="13"
                                                        width="13"
                                                        height="2"
                                                        rx="1"
                                                        transform="rotate(-180 18 13)"
                                                        fill="black"
                                                    />
                                                    <path
                                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                        fill="black"
                                                    />
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <span v-else>
                                            {{ __('Please wait...') }}
                                            <span
                                                class="spinner-border spinner-border-sm align-middle ms-2"
                                            ></span>
                                        </span>
                                    </button>
                                    <button
                                        v-if="
                                            onboardProcessStore.getCurrentStep <
                                            stepDetails.length - 1
                                        "
                                        type="button"
                                        class="btn btn-lg btn-primary"
                                        :class="{
                                            disabled:
                                                onboardProcessStore.isLoading ||
                                                !canProceed,
                                        }"
                                        data-kt-stepper-action="next"
                                        @click="nextStep"
                                    >
                                        <span
                                            v-if="
                                                !onboardProcessStore.isLoading
                                            "
                                            class="indicator-label"
                                        >
                                            {{ __('Continue') }}

                                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                                            <span
                                                class="svg-icon svg-icon-4 ms-1"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <rect
                                                        opacity="0.5"
                                                        x="18"
                                                        y="13"
                                                        width="13"
                                                        height="2"
                                                        rx="1"
                                                        transform="rotate(-180 18 13)"
                                                        fill="black"
                                                    />
                                                    <path
                                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                        fill="black"
                                                    />
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </span>
                                        <span v-else>
                                            {{ __('Please wait...') }}
                                            <span
                                                class="spinner-border spinner-border-sm align-middle ms-2"
                                            ></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <!--end::Actions-->
                        </form>
                        <!--end::Form-->
                    </div>
                    <!--end::Wrapper-->
                </div>
                <!--end::Content-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Authentication - Multi-steps-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import Impersonate from '@/Components/Impersonate/Impersonate.vue';
import { Link } from '@inertiajs/vue3';
import { useOnboardProcessStore } from '@/Stores/OnboardProcessStore';
import { useToast } from 'vue-toastification';
import _ from 'lodash';
import AccountInfo from '@/Pages/Onboard/Partials/AccountInfo.vue';
import AccountType from '@/Pages/Onboard/Partials/AccountType.vue';
import Completed from '@/Pages/Onboard/Partials/Completed.vue';
import JetAuthenticationCardLogo from '@/Jetstream/ApplicationLogo.vue';
import Swal from 'sweetalert2';
import {router as Inertia} from '@inertiajs/vue3';
import SystemLanguage from '../../Layouts/SettingElements/SystemLanguage.vue';

export default defineComponent({
    components: {
        Impersonate,
        SystemLanguage,
        AccountInfo,
        AccountType,
        Completed,
        JetAuthenticationCardLogo,
        Link,
    },

    setup() {
        // Get onboardProcess store
        const onboardProcessStore = useOnboardProcessStore();

        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return {
            onboardProcessStore,
            toast,
        };
    },

    data() {
        return {
            options: {
                minimizable: false,
                playerSize: 'standard',
                backgroundColor: '#fff',
                backgroundStyle: 'color',
                theme: {
                    controlsView: 'standard',
                    active: 'light',
                    light: {
                        color: '#3D4852',
                        backgroundColor: '#fff',
                        opacity: '0.7',
                    },
                    dark: {
                        color: '#fff',
                        backgroundColor: '#202020',
                        opacity: '0.7',
                    },
                },
            },
            stepDetails: [
                {
                    title: this.__('Account Type'),
                    short_description: this.__('Select Your Account Type'),
                },
                {
                    title: this.__('Account Info'),
                    short_description: this.__('Setup Your Account Info'),
                },
            ],
            loadingText: this.__('Loading...'),
            lang: this.$page.props?.user?.user_info?.language || 'en',
            windowWidth: window.innerWidth,
        };
    },

    mounted() {
        let tones = this.$page.props?.tones || [];
        this.onboardProcessStore.setAvailableTones(tones);

        window.addEventListener('resize', this.handleResize);

        if (!this.$attrs.user.current_project_id) {
            this.$gtm.trackEvent({
                userId: this.$attrs.user.id,
                userEmail: this.$attrs.user.email,
                new_customer: true,
                event: 'customer-registration',
                category: 'customer',
                action: 'registration',
                label: 'Customer has registered',
                value: 0,
            });

            this.$posthog.capture('registration', {
                user_id: this.$attrs.user.id,
                email: this.$attrs.user.email,
                signup_date: new Date().toISOString(),
                utm_source: this.$attrs.user.utm_source,
                utm_medium: this.$attrs.user.utm_medium,
                utm_campaign: this.$attrs.user.utm_campaign,
                utm_term: this.$attrs.user.utm_term,
                utm_content: this.$attrs.user.utm_content,
            });

            let data = {
                name:
                    this.$attrs.user.name.split(' ', 2)[0] +
                    "'s" +
                    this.__('Project'),
            };
            axios.post('/api/projects', data).then((response) => {
                Inertia.reload({ only: ['user'] });
            });
            window.fpr('referral', {
                email: this.$attrs.user.email,
            });
        }

        Intercom('boot', {
            app_id: INTERCOM_APP_ID,
            user_id: this.$attrs.user.id,
            name: this.$attrs.user.name,
            email: this.$attrs.user.email,
            user_hash: this.$attrs.user.intercom_hash,
            signup_time: Math.floor(Date.now() / 1000),
            subscription_customer:
                this.$attrs.user.current_team.subscriptions.length > 0,
            subscription_plan:
                this.$attrs.user.current_team.subscriptions.length > 0
                    ? this.getPlanNames(
                          this.$attrs.user.current_team.subscriptions[0]
                              .paddle_plan
                      )
                    : null,
            appsumo_ltd_customer:
                this.$attrs.user.current_team.lifetime_subscriptions.length > 0,
            appsumo_tier:
                this.$attrs.user.current_team.lifetime_subscriptions.length,
            subscription_paused_from:
                this.$attrs.user.current_team.subscriptions.length > 0
                    ? this.$attrs.user.current_team.subscriptions[0].paused_from
                    : null,
            subscription_ends_at:
                this.$attrs.user.current_team.subscriptions.length > 0
                    ? this.$attrs.user.current_team.subscriptions[0].ends_at
                    : null,
            user_created_at: this.$attrs.user.created_at,
            created_at: this.$attrs.user.created_at,
        });

        document.querySelectorAll('.modal-backdrop').forEach((el) => {
            el.remove();
        });
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        onSelectLanguage(code) {
            let data = {
                language: code,
            };
            axios
                .put(
                    '/api/user-infos/' + this.$page.props.user.user_info.id,
                    data
                )
                .then((response) => {
                    window.location.reload();
                });
        },
        getStepperStatusClass(currentIndex) {
            if (this.onboardProcessStore.getCurrentStep === currentIndex) {
                return 'current';
            } else if (this.onboardProcessStore.getCurrentStep < currentIndex) {
                return 'pending';
            } else {
                return 'completed';
            }
        },
        previousStep() {
            this.onboardProcessStore.decrementCurrentStep();
        },
        async nextStep() {
            let currentStep = this.onboardProcessStore.getCurrentStep;

            this.$posthog.capture('onboarding-process', {
                step: currentStep
            });

            this.onboardProcessStore.incrementCurrentStep();
        },
        submit() {
            if (!this.isValidAccountInfo()) {
                this.triggerErrorModal();

                return false;
            }

            this.$posthog.capture('onboarding-process-finished');

            this.saveAccountInfos();
        },
        resetStep() {
            this.onboardProcessStore.setCurrentStep(0);
        },
        isValidAccountInfo() {
            let accountInfo = this.onboardProcessStore.getAccountInfo;
            let isValid = true;

            if (
                !accountInfo?.business_name &&
                this.onboardProcessStore.getAccountType.account_type ===
                    'business'
            ) {
                this.onboardProcessStore.setFormErrors(
                    'account_info.business_name',
                    [this.__('Please enter your business name')]
                );

                isValid = false;
            }

            if (!accountInfo?.team_size) {
                this.onboardProcessStore.setFormErrors(
                    'account_info.team_size',
                    [this.__('Please select a team size')]
                );

                isValid = false;
            }

            if (!accountInfo?.content_creation_amount) {
                this.onboardProcessStore.setFormErrors(
                    'account_info.content_creation_amount',
                    [this.__('Please select an option')]
                );

                isValid = false;
            }

            if (
                (this.onboardProcessStore.getAccountType.account_type ===
                    'business' &&
                    (!accountInfo?.website_url ||
                        !this.isValidDomain(accountInfo?.website_url))) ||
                (this.onboardProcessStore.getAccountType.account_type ===
                    'personal' &&
                    accountInfo?.website_url &&
                    !this.isValidDomain(accountInfo?.website_url))
            ) {
                this.onboardProcessStore.setFormErrors(
                    'account_info.website_url',
                    [this.__('Please enter a valid website URL')]
                );

                isValid = false;
            }

            return isValid;
        },
        isValidDomain(domain) {
            return /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/.test(
                domain
            );
        },
        saveAccountInfos() {
            let vm = this;
            let payLoad = {
                account_type: this.onboardProcessStore.getAccountType,
                account_info: this.onboardProcessStore.getAccountInfo,
                ga_client_id: this.getGaClientId(),
                ga_session_id: this.getGaSessionId(),
                cookie_string: this.getCookieString(),
                gclid: this.getGoogleAdsClientId(),
                accept_language:
                    this.$page.props?.user?.user_info?.language || 'en',
                custom_user_agent: window.navigator.userAgent,
            };

            this.onboardProcessStore.setLoading(true);

            axios
                .post('/api/onboard-process/store-account-infos', payLoad)
                .then((response) => {
                    Inertia.visit(route('dashboard.onboarding-finished'));
                })
                .catch((error) => {
                    let errorMessage =
                        error.response?.data?.message ||
                        vm.__('Some error occurred');

                    if (422 == error.response.status) {
                        vm.triggerErrorModal();

                        for (let key in error.response.data.errors) {
                            try {
                                vm.onboardProcessStore.setFormErrors(
                                    key,
                                    error.response.data.errors[key]
                                );
                            } catch (error) {
                                console.error(
                                    `Error while assigning form error property : ${key}`
                                );
                            }
                        }
                    }

                    vm.toast.error(errorMessage);
                    vm.onboardProcessStore.setLoading(false);
                });
        },
        triggerErrorModal() {
            Swal.fire({
                text: this.__(
                    'Not all required fields are filled in. Please check the form and try again.'
                ),
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: this.__('Ok'),
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
            }).then((result) => {});
        },
        getGaClientId() {
            let gaClientId = this.getCookie('_ga');

            if (gaClientId) {
                gaClientId = gaClientId.split('.').slice(-2).join('.');
            }

            return gaClientId;
        },
        getGaSessionId: function () {
            const cookieValue = this.getCookie('_ga_EWRGSZNZEQ');
            if (cookieValue && cookieValue !== 'deleted') {
                const match = cookieValue.match(/GS1\.1\.(\d+)/);
                return match ? match[1] : null;
            }

            return '';
        },
        getGoogleAdsClientId() {
            return this.getCookie('gclid');
        },
        getCookie(c_name) {
            if (document.cookie.length > 0) {
                let c_start = document.cookie.indexOf(c_name + '=');
                if (c_start != -1) {
                    c_start = c_start + c_name.length + 1;
                    let c_end = document.cookie.indexOf(';', c_start);
                    if (c_end == -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return null;
        },
        getCookieString() {
            return document.cookie;
        },
    },
    computed: {
        canProceed() {
            if (
                this.onboardProcessStore.getCurrentStep === 0 &&
                this.onboardProcessStore.getAccountType?.account_type !== null
            ) {
                return true;
            }
        },

        shouldUseStepperColumn() {
            return this.windowWidth >= 991;
        },
    },
});
</script>

<style lang="scss">
#kt_create_onboard_process_stepper [data-kt-stepper-action='previous'] {
    display: inline-block;
}
#kt_create_onboard_process_stepper [data-kt-stepper-action='submit'] {
    display: inline-block;
}
#kt_create_onboard_process_stepper {
    .btn-light-primary {
        svg {
            margin-top: -3px;
        }
    }
    .stepper-column-bg {
        background-color: rgb(28, 50, 94) !important;
    }
    &.stepper.stepper-pills
        .stepper-item.current
        .stepper-label
        .stepper-title {
        color: #fff;
    }
    &.stepper.stepper-pills .stepper-item .stepper-label .stepper-title {
        color: #fff;
    }
    .noUi-value-horizontal {
        transform: translate(-50%, 80%);
    }
    .pip-slider.slider {
        margin: 0 auto;
        width: 75% !important;
        margin-top: 20px;
        margin-bottom: 85px !important;
    }
    .form-label {
        font-weight: 600;
    }
    .noUi-horizontal .noUi-handle {
        top: -12px;
    }
    &.stepper.stepper-pills .stepper-item .stepper-icon {
        background-color: #1c315c;
        border: 1px dashed #f1faff;
        .stepper-number {
            color: #fff !important;
        }
        .stepper-check {
            margin-top: 2px;
            path {
                fill: #fff;
            }
        }
    }
    &.stepper.stepper-pills .stepper-item.current .stepper-icon {
        background-color: #009ef7;
        border: 0px;
    }
    .form-control.is-invalid,
    .form-select.is-invalid:not([multiple]):not([size]) {
        border: 1px solid #e4e6ef;
    }
}
</style>
