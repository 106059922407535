<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_confirmation"
        tabindex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content rounded" :class="`bg-light-${theme}`">
                <!--begin::Modal header-->
                <div class="modal-header pb-0 border-0 justify-content-end">
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                        @click="$emit('close-modal')"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body scroll-y py-0">
                    <!--begin:Form-->
                    <!--begin::Alert-->
                    <div
                        class="alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10"
                        :class="`bg-light-${theme}`"
                    >
                        <!--begin::Icon-->
                        <span
                            class="svg-icon svg-icon-5tx mb-5"
                            :class="`svg-icon-${theme}`"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.3"
                                    x="2"
                                    y="2"
                                    width="20"
                                    height="20"
                                    rx="10"
                                    fill="currentColor"
                                ></rect>
                                <rect
                                    x="11"
                                    y="14"
                                    width="7"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-90 11 14)"
                                    fill="currentColor"
                                ></rect>
                                <rect
                                    x="11"
                                    y="17"
                                    width="2"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-90 11 17)"
                                    fill="currentColor"
                                ></rect>
                            </svg>
                        </span>
                        <!--end::Icon-->
                        <!--begin::Wrapper-->
                        <div class="text-center">
                            <!--begin::Title-->
                            <h1 class="fw-bolder mb-5">{{ title }}</h1>
                            <!--end::Title-->
                            <!--begin::Separator-->
                            <div
                                class="separator separator-dashed opacity-25 mb-5"
                                :class="`border-${theme}`"
                            ></div>
                            <!--end::Separator-->
                            <!--begin::Content-->
                            <div class="mb-9 text-dark">
                                {{ description }}
                            </div>
                            <!--end::Content-->
                            <!--begin::Buttons-->
                            <slot name="action">
                                <div class="d-flex flex-center flex-wrap">
                                    <a
                                        href="#"
                                        class="btn btn-outline m-2"
                                        :class="`btn-outline-${theme} btn-active-${theme}`"
                                        @click.prevent="$emit('close-modal')"
                                    >
                                        {{
                                            buttonLabels?.cancel || __('Cancel')
                                        }}
                                    </a>
                                    <a
                                        href="#"
                                        class="btn m-2"
                                        :class="`btn-${theme}`"
                                        @click.prevent="$emit('confirmed')"
                                    >
                                        {{
                                            buttonLabels?.confirm ||
                                            __('Confirm')
                                        }}
                                    </a>
                                </div>
                            </slot>
                            <!--end::Buttons-->
                        </div>
                        <!--end::Wrapper-->
                    </div>
                    <!--end::Alert-->
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { Modal } from 'bootstrap';
export default {
    props: {
        showModal: {
            default: false,
            type: Boolean,
        },
        title: {
            default: '',
            type: String,
        },
        description: {
            default: '',
            type: String,
        },
        theme: {
            default: 'danger',
            type: String,
        },
        buttonLabels: {
            default() {
                return {};
            },
            type: Object,
        },
    },
    watch: {
        showModal(newValue) {
            if (newValue) {
                this.modal.show();
            } else {
                this.modal.hide();
            }
        },
    },
    data() {
        return {
            modal: {},
        };
    },
    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_confirmation')
        );
    },
};
</script>
