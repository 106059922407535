<template>
    <div v-if="isImpersonating" class="impersonate-bar">
        <span><b>{{ __('You are in impersonating mode') }}</b></span>
        <span class="mt-1"><b>{{ __('Name') }}</b>:<br />{{ impersonatedUserName }}.</span>
        <span class="mt-1"><b>{{ __('Email') }}</b>:<br />{{ impersonatedUserEmail }}.</span>
        <button class="btn btn-sm btn-primary mt-2" @click="revertImpersonation">{{ __('Revert to Your Account') }}</button>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import {router as Inertia} from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3';

export default {
    setup() {
        const page = usePage();

        const isImpersonating = computed(() => page.props?.isImpersonating ?? false);
        const impersonatedUserName = computed(() => page.props?.impersonatedUser?.name ?? '');
        const impersonatedUserEmail = computed(() => page.props?.impersonatedUser?.email ?? '');
        const revertImpersonation = () => {
            Inertia.get('/impersonate/stop');
        };

        return { isImpersonating, impersonatedUserName, impersonatedUserEmail, revertImpersonation };
    },
};
</script>

<style>
.impersonate-bar {
    position: absolute;
    z-index: 100000000000;
    right: 0;
    top: 50%;
    background: white;
    border: 1px solid #e8e8e8;
    border-radius: 12px 0 0 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
</style>
