<template>
    <div id="kt_header" style="" class="header flex-column align-items-stretch">
        <SmartBar></SmartBar>
        <div
            class="header-container pt-4 pt-md-2 pt-lg-0 d-flex align-items-stretch"
        >
            <!--begin::Container-->
            <div
                class="container-fluid d-flex align-items-stretch justify-content-between"
            >
                <!--begin::Aside mobile toggle-->
                <div
                    class="d-flex align-items-center d-lg-none ms-n2 me-2"
                    title="Show aside menu"
                >
                    <div
                        class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                        @click="openAsideMobileMenu"
                        id="kt_aside_mobile_toggle"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                    fill="black"
                                />
                                <path
                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                </div>
                <!--end::Aside mobile toggle-->
                <!--begin::Mobile logo-->
                <div class="d-flex align-items-center flex-grow-0">
                    <a href="/" class="d-lg-none">
                        <ApplicationMark
                            width="30"
                            height="30"
                        ></ApplicationMark>
                    </a>
                </div>
                <!--end::Mobile logo-->
                <!--begin::Wrapper-->
                <div
                    class="d-flex align-items-stretch justify-content-between flex-grow-1 my-lg-3"
                >
                    <!--begin::Navbar-->
                    <div class="d-flex align-items-center" id="kt_header_nav">
                        <!--begin::Page title-->
                        <div
                            data-kt-swapper="true"
                            data-kt-swapper-mode="prepend"
                            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                            class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0"
                        >
                            <!--begin::Title-->
                            <h1
                                class="d-flex align-items-center text-dark fw-bolder fs-3 mb-0"
                            >
                                {{ title }}
                            </h1>
                            <!--end::Title-->
                            <!--begin::Breadcrumb-->
                            <ul
                                class="breadcrumb breadcrumb-separatorless fw-bold fs-7 pt-1"
                            >
                                <!--begin::Item-->
                                <li class="breadcrumb-item text-muted">
                                    <a
                                        href="/dashboard"
                                        class="text-muted text-hover-primary"
                                        >Home</a
                                    >
                                </li>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <li class="breadcrumb-item">
                                    <span
                                        class="bullet bg-gray-300 w-5px h-2px"
                                    ></span>
                                </li>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <li class="breadcrumb-item text-muted">
                                    {{ title }}
                                </li>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <li v-if="view" class="breadcrumb-item">
                                    <span
                                        class="bullet bg-gray-300 w-5px h-2px"
                                    ></span>
                                </li>
                                <!--end::Item-->
                                <!--begin::Item-->
                                <li
                                    v-if="view"
                                    class="breadcrumb-item text-dark"
                                >
                                    {{ view }}
                                </li>
                                <!--end::Item-->
                            </ul>
                            <!--end::Breadcrumb-->
                        </div>
                        <!--end::Page title-->
                    </div>
                    <!--end::Navbar-->

                    <div
                        v-if="showNotice"
                        class="notice d-flex flex-grow-1 bg-light-warning rounded border-warning border m-1 mx-lg-4 mx-md-20 px-2"
                    >
                        <!--begin::Icon-->
                        <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->
                        <span class="svg-icon svg-icon-2tx svg-icon-warning">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.3"
                                    x="2"
                                    y="2"
                                    width="20"
                                    height="20"
                                    rx="10"
                                    fill="black"
                                ></rect>
                                <rect
                                    x="11"
                                    y="14"
                                    width="7"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-90 11 14)"
                                    fill="black"
                                ></rect>
                                <rect
                                    x="11"
                                    y="17"
                                    width="2"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-90 11 17)"
                                    fill="black"
                                ></rect>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                        <!--end::Icon-->
                        <!--begin::Wrapper-->
                        <div class="row align-items-center ps-2">
                            <div
                                class="fs-fluid text-gray-900 col d-none d-lg-block m-0"
                            >
                                {{ __('You are currently on trial period.') }}
                                <span class="text-gray-700 ms-4"
                                    >{{ __('Your trial ends') }}
                                    <b>{{ trialEnds }}</b></span
                                >.
                                <a
                                    href="#"
                                    @click.prevent="openChoosePlanModal"
                                    class="fw-bolder ms-2"
                                    >{{ __('Choose a plan') }}</a
                                >.
                            </div>
                            <div
                                class="fs-fluid text-gray-900 col d-none d-lg-none d-sm-block m-0"
                            >
                                {{
                                    __(
                                        'You are currently on trial which ends in'
                                    )
                                }}
                                <b>{{ trialEnds }}</b
                                >.<a
                                    href="#"
                                    @click.prevent="openChoosePlanModal"
                                    class="fw-bolder ms-2"
                                    >{{ __('Choose a plan') }}</a
                                >.
                            </div>
                            <div
                                class="fs-fluid text-gray-900 col d-block d-sm-none m-0"
                            >
                                {{ __('Your trial ends in') }}
                                <b>{{ trialEnds }}</b
                                >.
                                <a
                                    href="#"
                                    @click.prevent="openChoosePlanModal"
                                    class="fw-bolder"
                                    >{{ __('Choose a plan') }}</a
                                >.
                            </div>
                        </div>
                        <!--end::Wrapper-->
                    </div>

                    <!--begin::Toolbar wrapper-->
                    <div class="d-flex align-items-stretch flex-shrink-0">
                        <!--begin::Referral-->
                        <Referral :user="user"></Referral>
                        <!--end::Referral-->

                        <!--begin::Notifications-->
                        <Notifications :user="user"></Notifications>
                        <!--end::Notifications-->

                        <!--begin::User menu-->
                        <UserMenu :user="user"></UserMenu>
                        <!--end::User menu-->
                        <!--begin::Header menu toggle-->
                        <div
                            class="d-flex align-items-center d-none ms-2 me-n3"
                            title="Show header menu"
                        >
                            <div
                                class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                                id="kt_header_menu_mobile_toggle"
                            >
                                <!--begin::Svg Icon | path: icons/duotune/text/txt001.svg-->
                                <span class="svg-icon svg-icon-1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                            </div>
                        </div>
                        <!--end::Header menu toggle-->
                    </div>
                    <!--end::Toolbar wrapper-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Container-->
        </div>
    </div>
</template>

<script>
import UserMenu from '@/Layouts/Header/Partials/UserMenu.vue';
import JetDropdown from '@/Jetstream/Dropdown.vue';
import JetDropdownLink from '@/Jetstream/DropdownLink.vue';
import SmartBar from '@/Layouts/SmartBar/SmartBar.vue';
import QuickLinks from './Partials/QuickLinks.vue';
import Notifications from './Partials/Notifications.vue';
import Referral from './Partials/Referral.vue';
import ApplicationMark from '@/Jetstream/ApplicationMark.vue';
import moment from 'moment';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';
import {router as Inertia} from '@inertiajs/vue3';

export default {
    components: {
        Notifications,
        Referral,
        QuickLinks,
        UserMenu,
        JetDropdown,
        JetDropdownLink,
        SmartBar,
        ApplicationMark,
    },
    props: {
        user: Object,
        title: String,
        view: String,
    },

    setup() {
        // Get user store
        const userStore = useUserStore();
        // Get team store
        const teamStore = useTeamStore();
        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        // Make it available inside methods
        return {
            userStore,
            teamStore,
            subscriptionStore,
        };
    },

    mounted() {
        window.Echo.private('creaitor-notifications').listen(
            'BroadcastAdminNotification',
            (e) => {
                Inertia.reload({ only: ['user'] });
            }
        );

        if (!this.userStore.getUser) {
            this.userStore.setUser(this.user);
        }

        if (!this.subscriptionStore.getPlans) {
            this.subscriptionStore.setPlans(this.getPlans() || {});
        }
    },
    methods: {
        toggle($event) {
            let el = $event.target.closest('[data-kt-menu-trigger]');
            let s = el.classList;
            let m =
                el.parentElement.getElementsByClassName('menu-sub-dropdown')[0]
                    .classList;

            s.contains('show') ? s.remove('show') : s.add('show');
            s.contains('menu-dropdown')
                ? s.remove('menu-dropdown')
                : s.add('menu-dropdown');
            m.contains('show') ? m.remove('show') : m.add('show');
        },
        openAsideMobileMenu() {
            emitter.emit('open-aside-mobile-menu');
        },
        openChoosePlanModal() {
            emitter.emit('open-choose-plan');
        },
    },
    computed: {
        trialEnds() {
            moment.locale(this.user.user_info.language);
            return this.user.current_team.customer
                ? moment(
                      this.user.current_team.customer.trial_ends_at
                  ).fromNow()
                : null;
        },
        userIsOnTrial() {
            return this.user.on_trial;
        },
        showNotice() {
            return (
                !this.subscriptionStore.hasSubscription &&
                this.subscriptionStore.isUserOnTrial &&
                this.trialEnds
            );
        },
    },
};
</script>

<style scoped></style>
