<template>
    <!--begin::Wrapper-->
    <div class="w-100">
        <!--begin::Heading-->
        <div class="pb-8 pb-lg-10">
            <!--begin::Title-->
            <h2 class="fw-bolder text-dark">{{ __('Completed') }}</h2>
            <!--end::Title-->
        </div>
        <!--end::Heading-->
        <!--begin::Body-->
        <div class="mb-0">
            <!--begin::Text-->
            <div class="fs-6 text-gray-600 mb-5">
                {{ __('Your Are Done!') }}
            </div>
            <!--end::Text-->
            <!--begin::Actions-->
            <div class="d-flex flex-stack pt-15">
                <div class="mr-2">
                    <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click="doneAction"
                    >
                        {{ __('Done') }}
                    </button>
                </div>
            </div>
            <!--end::Actions-->
        </div>
        <!--end::Body-->
    </div>
    <!--end::Wrapper-->
</template>

<script>
import { defineComponent } from 'vue';
import {router as Inertia} from '@inertiajs/vue3';
import { useOnboardProcessStore } from '@/Stores/OnboardProcessStore';

export default defineComponent({
    setup() {
        // Get onboardProcess store
        const onboardProcessStore = useOnboardProcessStore();

        // Make it available inside methods
        return {
            onboardProcessStore,
        };
    },

    methods: {
        doneAction() {
            this.onboardProcessStore.resetState();
            Inertia.visit(route('onboard.process'));
        },
    },
});
</script>
