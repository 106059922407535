<template>
    <div class="d-flex flex-column flex-root bg-white">
        <!--begin::Authentication - Multi-steps-->
        <div
            class="d-flex flex-column flex-lg-row flex-column-fluid"
            data-kt-stepper="true"
        >
            <Prompt
                :user="user"
                :ai-assistant-templates="aiAssistantTemplates"
                :ai-assistant-template-uuid="uuid"
                :prompt-title="promptTitle"
                :loading="loading"
                :fields="fields"
                @change-ai-assistant-template="onChangeAiAssistantTemplate"
                @prompt-change="onPromptChange"
                @creativity-change="(ev) => (options.creativity = ev)"
                @outputs-change="(ev) => (options.outputs = ev)"
                @tone-of-voice-change="(ev) => (options.toneOfVoice = ev)"
                @target-audience-change="(ev) => (options.targetAudience = ev)"
                @with-emojis-change="(ev) => (options.withEmojis = ev)"
                @language-change="onLanguageChange"
                @formality-change="onFormalityChange"
                @prompt-settings-accordion-open-change="
                    onPromptSettingsAccordionOpenChange
                "
                @compose="compose"
            >
            </Prompt>

            <Result
                :result-title="resultTitle"
                :choices="choices"
                :loading="loading"
            >
            </Result>
        </div>
        <!--end::Authentication - Multi-steps-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import NProgress from 'nprogress';
import Toolbar from '@/Layouts/Toolbar/Toolbar.vue';
import Prompt from '@/Layouts/AiAssistant/Partials/Prompt.vue';
import Result from '@/Layouts/AiAssistant/Partials/Result.vue';
import { debounce } from 'lodash';
import { useUserStore } from '@/Stores/UserStore';
import { useTeamStore } from '@/Stores/TeamStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';
import trialLimitExhausted from '@/Mixins/trialLimitExhausted';

export default defineComponent({
    mixins: [trialLimitExhausted],
    setup() {
        // Get toast interface
        const toast = useToast();

        // Get user store
        const userStore = useUserStore();
        // Get team store
        const teamStore = useTeamStore();
        // Get subscription store
        const subscriptionStore = useSubscriptionStore();

        // Make it available inside methods
        return {
            toast,
            userStore,
            teamStore,
            subscriptionStore,
        };
    },
    components: {
        NProgress,
        Toolbar,
        Prompt,
        Result,
    },

    props: {
        user: Object,
    },

    data() {
        let projectSettings = this.user?.current_project?.prompt_settings || {};
        let userSettings =
            this.$page.props.user.user_info.prompt_settings || {};

        return {
            uuid: this.$page.props.ai_assistant.uuid,
            promptTitle: this.__('Text Body'),
            promptInfo: '',
            resultTitle: this.__('Choose from the results'),
            aiAssistantTemplates: {},
            description: '',
            name: '',
            fields: {},
            prompts: {},
            options: {
                creativity:
                    projectSettings.creativity ||
                    userSettings.creativity ||
                    0.75,
                outputs: projectSettings.outputs || userSettings.outputs || 3,
                toneOfVoice:
                    projectSettings.toneOfVoice ||
                    userSettings.toneOfVoice ||
                    '',
                targetAudience:
                    projectSettings.targetAudience ||
                    userSettings.targetAudience ||
                    '',
                withEmojis:
                    projectSettings.withEmojis ||
                    userSettings.withEmojis ||
                    false,
                targetLang:
                    projectSettings.targetLang ||
                    userSettings.targetLang ||
                    'en',
                formality:
                    projectSettings.formality ||
                    userSettings.formality ||
                    'default',
            },
            promptSettingsAccordionOpen:
                userSettings &&
                userSettings.promptSettingsAccordionOpen !== undefined
                    ? userSettings.promptSettingsAccordionOpen
                    : true,
            loading: false,
            choices: [],
        };
    },

    mounted() {
        this.fetchAiAssistantTemplates();

        this.userStore.setUser(this.user);
        this.subscriptionStore.setPlans(this.getPlans() || {});
    },

    methods: {
        fetchAiAssistantTemplates() {
            let vm = this;
            axios.get('/api/ai-assistant-templates').then((response) => {
                // reset prompt values but keep description
                let description = vm.prompts['description']
                    ? vm.prompts['description']
                    : '';
                vm.prompts = {};
                vm.prompts['description'] = description;

                response.data.forEach(function (template) {
                    vm.aiAssistantTemplates[template.uuid] = template;
                });
                vm.promptTitle =
                    vm.aiAssistantTemplates[vm.uuid].name[
                        vm.$page.props.locale
                    ];
                vm.resultTitle = vm.__('Choose from the results');

                vm.name = vm.aiAssistantTemplates[vm.uuid].name;
                vm.description =
                    vm.aiAssistantTemplates[vm.uuid].description;
                vm.fields = vm.aiAssistantTemplates[vm.uuid].fields;
                vm.aiAssistantTemplates[vm.uuid].fields.forEach(
                    function (field) {
                        vm.prompts[field.layout] =
                            vm.prompts[field.layout] !== undefined
                                ? vm.prompts[field.layout]
                                : '';
                    }
                );
                vm.aiAssistantTemplates[vm.uuid].options.forEach(
                    function (option) {
                        Object.assign(vm.options, option);
                    }
                );

                emitter.emit(
                    'change-template-name',
                    vm.aiAssistantTemplates[vm.uuid].name[
                        vm.$page.props.locale
                    ]
                );
            });
        },

        onChangeAiAssistantTemplate(value) {
            window.history.pushState(
                {},
                document.title,
                window.location.origin + '/ai-assistant/' + value
            );
            this.uuid = value;
            let prompts = this.prompts;
            this.fetchAiAssistantTemplates();
            this.choices = [];
        },

        onPromptChange(ev) {
            this.prompts[ev.component] = ev.value;
        },

        onLanguageChange(ev) {
            this.options.targetLang = ev;
        },

        onFormalityChange(ev) {
            this.options.formality = ev;
        },

        onPromptSettingsAccordionOpenChange(ev) {
            this.promptSettingsAccordionOpen = ev;
        },

        changePromptSettings() {
            let data = {
                prompt_settings: this.promptSettings,
            };
            axios
                .put(
                    '/api/user-infos/' + this.$page.props.user.user_info.id,
                    data
                )
                .then((response) => {
                    Inertia.reload({ only: ['user'] });
                });
        },

        compose() {
            NProgress.start();
            this.loading = true;
            let vm = this;
            if (
                this.teamStore.hasCharacterCredits ||
                this.subscriptionStore.hasUnlimitedUsage
            ) {
                let data = {
                    uuid: this.uuid,
                    prompts: this.prompts,
                    options: this.options,
                    lang: this.options.targetLang,
                    formality: this.options.formality,
                };

                // Fake respond progress
                setTimeout(() => NProgress.set(0.4), 500);
                setTimeout(() => NProgress.inc(), 1500);
                setTimeout(() => NProgress.inc(), 3000);

                axios
                    .post('/api/ai-assistant/compose', data)
                    .then((response) => {
                        this.choices = response.data.choices;
                        Inertia.reload({ only: ['user'] });
                        NProgress.done();
                    })
                    .catch(function (error) {
                        vm.toast.error(error.response.data.message);
                        if (
                            error.response.data.error_code ==
                            'trial_credit_limit_exhausted'
                        ) {
                            vm.showTrialLimitExhaustedPopUp();
                        }
                    })
                    .finally(function () {
                        vm.loading = false;
                        NProgress.done();
                    });
            } else {
                NProgress.done();
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
            }
        },
    },
    watch: {
        promptSettings: debounce(function () {
            this.changePromptSettings();
        }, 500),
    },
    computed: {
        promptSettings() {
            return {
                targetLang: this.options.targetLang,
                formality: this.options.formality,
                promptSettingsAccordionOpen: this.promptSettingsAccordionOpen,
                creativity: this.options.creativity,
                outputs: this.options.outputs,
                toneOfVoice: this.options.toneOfVoice,
                targetAudience: this.options.targetAudience,
            };
        },
    },
});
</script>

<style scoped></style>
