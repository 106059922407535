<template>
    <div
        class="stepper stepper-links d-flex flex-column align-items-end gap-5"
        id="add_addon_stepper"
        data-kt-stepper="true"
    >
        <!--begin::Nav-->
        <div
            class="stepper-nav bg-white"
            :class="{ fullwidth: true == headerFullwidth }"
        >
            <div
                v-for="(stepDetail, index) in stepDetails"
                :key="stepDetail.title"
                :class="{
                    current: index === step,
                    pending: index !== step,
                    completed: index <= finishedSteps,
                }"
                class="stepper-item"
            >
                <h3 class="stepper-title">
                    <span v-if="stepNumber == true">
                        <span class="step-count">
                            <i
                                v-if="index <= finishedSteps"
                                class="bi bi-check-lg"
                            ></i>
                            <span v-if="index > finishedSteps">{{
                                index + 1
                            }}</span>
                        </span> </span
                    >{{ __(stepDetail.title) }}
                </h3>
                <span v-if="stepNaviagationIcon == true">
                    <span class="stepper-next">
                        <i class="bi bi-chevron-right"></i>
                    </span>
                </span>
            </div>
        </div>
        <!--end::Nav-->
        <div
            class="step-content d-flex"
            :class="{ fullwidth: true == contentFullwidth }"
        >
            <!-- Slot for tab contents begins-->
            <slot name="tabs"></slot>
            <!-- Slot for tab contents ends -->
        </div>
        <div class="step-footer">
            <div class="d-flex justify-content-between w-100">
                <!--begin::Actions-->
                <div class="d-flex flex-stack">
                    <!--begin::Wrapper-->
                    <div class="me-2">
                        <button
                            type="button"
                            class="btn btn-lg btn-white btn-large me-3"
                            @click.stop="previousStep"
                            v-show="step > 0 && step !== stepDetails.length"
                        >
                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr063.svg-->
                            <span class="svg-icon svg-icon-3 me-1">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        opacity="0.5"
                                        x="6"
                                        y="11"
                                        width="13"
                                        height="2"
                                        rx="1"
                                        fill="currentColor"
                                    ></rect>
                                    <path
                                        d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                            {{ __('Back') }}
                        </button>
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Wrapper-->
                    <div>
                        <button
                            type="button"
                            :class="{
                                disabled: true === loading || !canProceed,
                            }"
                            class="btn btn-lg btn-primary btn-large"
                            @click.stop="wizardSubmit"
                            v-if="step === stepDetails.length - 1"
                        >
                            <span
                                v-if="loading === false"
                                class="indicator-label"
                            >
                                {{ __('Submit') }}
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                                <span class="svg-icon svg-icon-3 ms-2 me-0">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            opacity="0.5"
                                            x="18"
                                            y="13"
                                            width="13"
                                            height="2"
                                            rx="1"
                                            transform="rotate(-180 18 13)"
                                            fill="currentColor"
                                        ></rect>
                                        <path
                                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                            </span>
                            <span v-if="loading === true">
                                {{ __('Please wait...') }}
                                <span
                                    class="spinner-border spinner-border-sm align-middle ms-2"
                                ></span>
                            </span>
                        </button>
                        <button
                            type="button"
                            :class="{
                                disabled: true === loading || !canProceed,
                            }"
                            class="btn btn-lg btn-primary btn-large"
                            @click.stop="nextStep"
                            v-if="step < stepDetails.length - 1"
                            data-kt-stepper-action="next"
                            :disabled="!canProceed"
                        >
                            <span
                                class="svg-icon svg-icon-3 ms-1 me-0"
                                v-if="loading === false"
                            >
                                {{ __('Continue') }}
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        opacity="0.5"
                                        x="18"
                                        y="13"
                                        width="13"
                                        height="2"
                                        rx="1"
                                        transform="rotate(-180 18 13)"
                                        fill="currentColor"
                                    ></rect>
                                    <path
                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                            <span v-if="loading === true">
                                {{ __('Please wait...') }}
                                <span
                                    class="spinner-border spinner-border-sm align-middle ms-2"
                                ></span>
                            </span>
                        </button>
                    </div>
                    <!--end::Wrapper-->
                </div>
                <!--end::Actions-->
                <div>
                    <slot name="footer-middle"></slot>
                </div>
                <div>
                    <slot name="footer-right"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        user: Object | null,
        stepDetails: {
            type: Array,
            default: [],
        },
        step: {
            type: Number,
            default: 0,
        },
        finishedSteps: {
            type: Number,
            default: -1,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        stepNumber: {
            type: Boolean,
            default: true,
        },
        stepNaviagationIcon: {
            type: Boolean,
            default: true,
        },
        headerFullwidth: {
            type: Boolean,
            default: true,
        },
        contentFullwidth: {
            type: Boolean,
            default: true,
        },
        canProceed: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async nextStep() {
            this.$emit('horizontal-wizard-next-step');
        },
        previousStep() {
            this.$emit('horizontal-wizard-previous-step');
        },
        wizardSubmit() {
            this.$emit('horizontal-wizard-submit');
        },
    },
};
</script>

<style lang="scss">
.stepper [data-kt-stepper-action='submit'] {
    display: inline-block;
}

.stepper [data-kt-stepper-action='previous'] {
    display: inline-block;
}
</style>
