<template>
    <!-- Meta fields template -->
    <div class="px-10 px-lg-15 py-10">
        <div class="d-flex justify-content-between">
            <h3 class="h3 my-5">{{ __('Preview') }}</h3>
            <CreaitorButton
                :loading="loadingAll"
                :buttonText="__('Generate Meta Tags')"
                :buttonClasses="'btn-light-primary'"
                @click="generateMetaTags"
            ></CreaitorButton>
        </div>
        <div class="serp-snippet-preview">
            <div class="serp-snippet-preview-bg">
                <div
                    class="serp-snippet-preview-input"
                >
                    <input
                        type="text"
                        disabled=""
                        v-model="documentStore.keyword"
                    />
                    <span class="serp-icon-search">
                                                <svg
                                                    focusable="false"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                                                    ></path>
                                                </svg>
                                            </span>
                    <span
                        class="serp-icon-mic"
                    ></span>
                </div>
                <div
                    class="serp-snippet-preview-menus"
                >
                    <ul>
                        <li class="current">
                            <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGZpbGw9IiMzNEE4NTMiIGQ9Ik0xMCAydjJhNiA2IDAgMCAxIDYgNmgyYTggOCAwIDAgMC04LTh6Ii8+PHBhdGggZmlsbD0iI0VBNDMzNSIgZD0iTTEwIDRWMmE4IDggMCAwIDAtOCA4aDJjMC0zLjMgMi43LTYgNi02eiIvPjxwYXRoIGZpbGw9IiNGQkJDMDQiIGQ9Ik00IDEwSDJhOCA4IDAgMCAwIDggOHYtMmMtMy4zIDAtNi0yLjY5LTYtNnoiLz48cGF0aCBmaWxsPSIjNDI4NUY0IiBkPSJNMjIgMjAuNTlsLTUuNjktNS42OUE3Ljk2IDcuOTYgMCAwIDAgMTggMTBoLTJhNiA2IDAgMCAxLTYgNnYyYzEuODUgMCAzLjUyLS42NCA0Ljg4LTEuNjhsNS42OSA1LjY5TDIyIDIwLjU5eiIvPjwvc3ZnPgo="
                                alt=""
                                data-atf="1"
                            />All
                        </li>
                        <li>
                            <svg
                                focusable="false"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                ></path>
                                <path
                                    d="M14 13l4 5H6l4-4 1.79 1.78L14 13zm-6.01-2.99A2 2 0 0 0 8 6a2 2 0 0 0-.01 4.01zM22 5v14a3 3 0 0 1-3 2.99H5c-1.64 0-3-1.36-3-3V5c0-1.64 1.36-3 3-3h14c1.65 0 3 1.36 3 3zm-2.01 0a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h7v-.01h7a1 1 0 0 0 1-1V5z"
                                ></path>
                            </svg>
                            {{ __('Images') }}
                        </li>
                        <li>
                            <svg
                                focusable="false"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    clip-rule="evenodd"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                ></path>
                                <path
                                    clip-rule="evenodd"
                                    d="M10 16.5l6-4.5-6-4.5v9zM5 20h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1zm14.5 2H5a3 3 0 0 1-3-3V4.4A2.4 2.4 0 0 1 4.4 2h15.2A2.4 2.4 0 0 1 22 4.4v15.1a2.5 2.5 0 0 1-2.5 2.5z"
                                    fill-rule="evenodd"
                                ></path>
                            </svg>
                            {{ __('Videos') }}
                        </li>
                        <li>
                            <svg
                                focusable="false"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                ></path>
                                <path
                                    d="M12 11h6v2h-6v-2zm-6 6h12v-2H6v2zm0-4h4V7H6v6zm16-7.22v12.44c0 1.54-1.34 2.78-3 2.78H5c-1.64 0-3-1.25-3-2.78V5.78C2 4.26 3.36 3 5 3h14c1.64 0 3 1.25 3 2.78zM19.99 12V5.78c0-.42-.46-.78-1-.78H5c-.54 0-1 .36-1 .78v12.44c0 .42.46.78 1 .78h14c.54 0 1-.36 1-.78V12zM12 9h6V7h-6v2z"
                                ></path>
                            </svg>
                            {{ __('News') }}
                        </li>
                        <li>
                            <svg
                                focusable="false"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    d="M7.503 0c3.09 0 5.502 2.487 5.502 5.427 0 2.337-1.13 3.694-2.26 5.05-.454.528-.906 1.13-1.358 1.734-.452.603-.754 1.508-.98 1.96-.226.452-.377.829-.904.829-.528 0-.678-.377-.905-.83-.226-.451-.527-1.356-.98-1.959-.452-.603-.904-1.206-1.356-1.734C3.132 9.121 2 7.764 2 5.427 2 2.487 4.412 0 7.503 0zm0 1.364c-2.283 0-4.14 1.822-4.14 4.063 0 1.843.86 2.873 1.946 4.177.468.547.942 1.178 1.4 1.79.34.452.596.99.794 1.444.198-.455.453-.992.793-1.445.459-.61.931-1.242 1.413-1.803 1.074-1.29 1.933-2.32 1.933-4.163 0-2.24-1.858-4.063-4.139-4.063zm0 2.734a1.33 1.33 0 11-.001 2.658 1.33 1.33 0 010-2.658"
                                ></path>
                            </svg>
                            {{ __('Maps') }}
                        </li>
                        <li>
                            <svg
                                focusable="false"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                                ></path>
                            </svg>
                            {{ __('More') }}
                        </li>
                    </ul>
                </div>
                <div
                    class="serp-snippet-preview-result-stats"
                >
                    {{
                        __(
                            'About 28,386,315 results (0.19 seconds)'
                        )
                    }}
                </div>
            </div>
            <div class="serp-snippet-preview__url">
                                        <span
                                            class="serp-snippet-preview__url__text"
                                        >https://{{
                                                documentStore.metaContent.domain || __('www.example.com')
                                            }}/{{ documentStore.metaContent.slug }}</span
                                        >
            </div>
            <div
                class="serp-snippet-preview__title"
            >
                                        <span
                                            class="serp-snippet-preview__title__text"
                                        >{{ metaTitle }}</span
                                        >
            </div>
            <div
                class="serp-snippet-preview__description"
            >
                                        <span
                                            class="serp-snippet-preview__description__text"
                                        >{{
                                                documentStore.metaContent.description
                                            }}</span
                                        >
            </div>
        </div>
        <div class="meta-fields">
            <div class="row">
                <div class="col-12">
                    <div
                        class="card bg-lighten p-4"
                    >
                        <div
                            class="d-flex justify-content-between"
                        >
                            <label
                                class="form-label fs-6 fw-bold no-right-padding"
                            >{{
                                    __('Title')
                                }}</label
                            >
                            <div
                                class="d-flex w-50 mb-2"
                            >
                                <div
                                    class="d-flex w-50 me-2 align-items-center justify-content-end"
                                >
                                                            <span
                                                                class="text-gray-600"
                                                            >{{
                                                                    amountOfTitleCharacters
                                                                }}
                                                                / 60 ({{
                                                                    calculatedPixelsOfTitleCharacters
                                                                }}px /
                                                                580px)</span
                                                            >
                                </div>
                                <div
                                    class="d-flex w-50 align-items-center"
                                >
                                    <div
                                        class="progress position-relative meta-indicator-progress w-100"
                                    >
                                        <div
                                            class="progress-bar position-absolute"
                                            role="progressbar"
                                            :style="{
                                                                        left:
                                                                            (100 /
                                                                                60) *
                                                                                amountOfTitleCharacters +
                                                                            '%',
                                                                    }"
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control"
                                v-model="
                                                            metaTitle
                                                        "
                            />

                            <CreaitorButton
                                :loading="loadingTitle"
                                buttonClasses="btn-input-group btn-outline p-1"
                                @click="generateMetaTitle"></CreaitorButton>
                        </div>
                        <div class="d-flex mt-5">
                                                    <span
                                                        class="fs-7 text-gray-600"
                                                    >{{
                                                            __(
                                                                'This is what will appear in the first line when this post shows up in the search results.'
                                                            )
                                                        }}</span
                                                    >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div
                        class="card bg-lighten p-4"
                    >
                        <div
                            class="d-flex justify-content-between"
                        >
                            <label
                                class="form-label fs-6 fw-bold no-right-padding"
                            >{{
                                    __('Url')
                                }}</label
                            >
                            <div
                                class="d-flex w-50 mb-2"
                            >
                                <div
                                    class="d-flex w-50 me-2 align-items-center justify-content-end"
                                >
                                                            <span
                                                                class="text-gray-600"
                                                            >{{
                                                                    amountOfUrlCharacters
                                                                }}
                                                                / 75</span
                                                            >
                                </div>
                                <div
                                    class="d-flex w-50 align-items-center"
                                >
                                    <div
                                        class="progress position-relative meta-indicator-progress w-100"
                                    >
                                        <div
                                            class="progress-bar position-absolute"
                                            role="progressbar"
                                            :style="{
                                                                        left:
                                                                            (100 /
                                                                                75) *
                                                                                amountOfUrlCharacters +
                                                                            '%',
                                                                    }"
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="input-group mb-5"
                        >
                                                    <span
                                                        class="input-group-text"
                                                        id="basic-addon3"
                                                    >https://</span
                                                    >
                            <input
                                type="text"
                                class="form-control"
                                v-model="
                                                            documentStore.metaContent.domain
                                                        "
                                :placeholder="
                                                            __('Domain')
                                                        "
                                aria-label="Domain"
                            />
                            <span
                                class="input-group-text"
                            >/</span
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="
                                                            documentStore.metaContent.slug
                                                        "
                                :placeholder="
                                                            __('Slug')
                                                        "
                                aria-label="Slug"
                            />
                            <CreaitorButton
                                :loading="loadingSlug"
                                buttonClasses="btn-input-group btn-outline p-1"
                                @click="generateMetaSlug"></CreaitorButton>
                        </div>
                        <div class="d-flex">
                                                    <span
                                                        class="fs-7 text-gray-600"
                                                    >{{
                                                            __(
                                                                'This is just for illustration, you have to adjust it in your website.'
                                                            )
                                                        }}</span
                                                    >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div
                        class="card bg-lighten p-4"
                    >
                        <div
                            class="d-flex justify-content-between"
                        >
                            <label
                                class="form-label fs-6 fw-bold no-right-padding"
                            >{{
                                    __(
                                        'Description'
                                    )
                                }}</label
                            >
                            <div
                                class="d-flex w-50 mb-2"
                            >
                                <div
                                    class="d-flex w-50 me-2 align-items-center justify-content-end"
                                >
                                                            <span
                                                                class="text-gray-600"
                                                            >{{
                                                                    amountOfDescriptionCharacters
                                                                }}
                                                                / 160 ({{
                                                                    calculatedPixelsOfDescriptionCharacters
                                                                }}px /
                                                                920px)</span
                                                            >
                                </div>
                                <div
                                    class="d-flex w-50 align-items-center"
                                >
                                    <div
                                        class="progress position-relative meta-indicator-progress w-100"
                                    >
                                        <div
                                            class="progress-bar position-absolute"
                                            role="progressbar"
                                            :style="{
                                                                        left:
                                                                            (100 /
                                                                                160) *
                                                                                amountOfDescriptionCharacters +
                                                                            '%',
                                                                    }"
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="input-group mb-5">
                            <textarea
                                class="form-control"
                                rows="3"
                                v-model="
                                                            documentStore.metaContent.description
                                                        "
                            ></textarea>


                            <CreaitorButton
                                :loading="loadingDescription"
                                buttonClasses="btn-input-group btn-outline p-1"
                                @click="generateMetaDescription"></CreaitorButton>
                        </div>
                        <div class="d-flex mt-5">
                                                    <span
                                                        class="fs-7 text-gray-600 mt-1"
                                                    >{{
                                                            __(
                                                                'This is what will appear as the description when this post shows up in the search results.'
                                                            )
                                                        }}</span
                                                    >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import {useToast} from "vue-toastification";
import { useDocumentStore } from '@/Stores/DocumentStore';
import CreaitorButton from "../Form/CreaitorButton.vue";

export default defineComponent({
    components: {CreaitorButton},
    setup() {
        // Get document store
        const documentStore = useDocumentStore();

        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { documentStore, toast };
    },
    name: 'MetaFields',
    data() {
        return {
            loadingAll: false,
            loadingTitle: false,
            loadingDescription: false,
            loadingSlug: false,
        };
    },
    mounted() {
        emitter.on('generate-meta-tags', () => {
            this.generateMetaTags();
        });
    },
    methods: {
        async generateMetaTags() {
            if (!this.documentStore || !this.documentStore.keyword) {
                console.error('DocumentStore or keyword is undefined');
                return;
            }

            this.loadingAll = true;

            const response = await axios.post('/api/document/generate-meta-tags', {
                'content': this.prepareContentForMetaTags(),
                'keyword': this.documentStore.getKeyword,
                'lang': this.documentStore.getPromptSettings.targetLang,
                'formality': this.documentStore.getPromptSettings.formality,
            });
            this.documentStore.updateMetaTitle(response.data.meta_title);
            this.documentStore.updateMetaDescription(response.data.meta_description);
            this.documentStore.updateMetaSlug(response.data.meta_slug);

            this.loadingAll = false;
        },
        async generateMetaTitle() {
            this.loadingTitle = true;

            const response = await axios.post('/api/document/generate-meta-title', {
                'content': this.prepareContentForMetaTags(),
                'keyword': this.documentStore.getKeyword,
                'lang': this.documentStore.getPromptSettings.targetLang,
                'formality': this.documentStore.getPromptSettings.formality,
            });
            this.documentStore.updateMetaTitle(response.data);

            this.loadingTitle = false;
        },
        async generateMetaDescription() {
            this.loadingDescription = true;

            const response = await axios.post('/api/document/generate-meta-description', {
                'content': this.prepareContentForMetaTags(),
                'keyword': this.documentStore.getKeyword,
                'lang': this.documentStore.getPromptSettings.targetLang,
                'formality': this.documentStore.getPromptSettings.formality,
            });
            this.documentStore.updateMetaDescription(response.data);

            this.loadingDescription = false;
        },
        async generateMetaSlug() {
            this.loadingSlug = true;

            const response = await axios.post('/api/document/generate-meta-slug', {
                'content': this.prepareContentForMetaTags(),
                'keyword': this.documentStore.getKeyword,
                'lang': this.documentStore.getPromptSettings.targetLang,
                'formality': this.documentStore.getPromptSettings.formality,
            });
            this.documentStore.updateMetaSlug(response.data);

            console.log(response.data);

            this.loadingSlug = false;
        },
        prepareContentForMetaTags() {
            const content = this.documentStore.getContent; // Assuming getContent is a getter

            let introduction = '';
            let keyPoints = '';
            let conclusion = '';
            let firstParagraphFound = false;
            let lastParagraphText = '';

            if (content) {
                content.content.forEach(node => {
                    if (node.type === 'paragraph' && node.content?.[0] && node.content[0].text) {
                        if (!firstParagraphFound) {
                            introduction = node.content[0].text;
                            firstParagraphFound = true;
                        }
                        lastParagraphText = node.content[0].text;
                    } else if (node.type === 'heading' && node.content[0] && node.content[0].text) {
                        // Extract key points from headings
                        keyPoints += node.content[0].text + ': ';
                    }
                });

                conclusion = lastParagraphText;
            }

            const summary = `${introduction.trim()} ${keyPoints.trim()} ${conclusion.trim()}`.trim();
            return summary; // Return combined summary
        },
    },
    computed: {
        metaTitle: {
            get() {
                return this.documentStore.getMetaContent.title;
            },
            set(value) {
                this.documentStore.updateMetaTitle(value);
            }
        },
        amountOfTitleCharacters() {
            return this.documentStore.getMetaContent.title
                ? this.documentStore.getMetaContent.title.length
                : 0;
        },
        amountOfUrlCharacters() {
            return this.documentStore.getMetaContent.slug
                ? this.documentStore.getMetaContent.slug.length
                : 0;
        },
        amountOfDescriptionCharacters() {
            return this.documentStore.getMetaContent.description
                ? this.documentStore.getMetaContent.description.length
                : 0;
        },
        calculatedPixelsOfTitleCharacters() {
            return this.amountOfTitleCharacters * 8;
        },
        calculatedPixelsOfDescriptionCharacters() {
            return this.amountOfTitleCharacters * 8;
        },

    },
});
</script>

<style lang="scss">
.btn {
    &.btn-input-group {
        color: #3F4254;
        background-color: #F5F8FA;
        border: 1px solid #E4E6EF;

        &:hover {
            background-color: #f1f3f6;
            border: 1px solid #E4E6EF;
        }

        &:active {
            border-color: #E4E6EF;
        }
    }
}
</style>