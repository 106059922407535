<template>
    <div class="tools-block mb-md-5">
        <div class="row">
            <div class="col-md-4" v-for="tool in tools" :key="tool">
                <div
                    class="card card-flush card-bordered card-ai-assistant w-100 hoverable my-5"
                >
                    <div class="card-header flex-row">
                        <div class="d-flex flex-column">
                            <div
                                class="d-flex flex-center w-60px h-60px rounded-circle bg-opacity-90 mt-10 mb-5"
                                :style="{
                                        backgroundColor:
                                            tool.background_color || '#eeedfe',
                                    }"
                            >
                                <font-awesome-icon class="h-20px" :icon="tool.icon_string" />
                            </div>
                            <h3
                                class="card-title card-label fw-bolder text-dark"
                            >
                                {{ tool.heading }}
                            </h3>
                        </div>
                    </div>
                    <div class="card-body text-gray-700 py-5">
                        {{ tool.short_description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Link } from '@inertiajs/vue3';

export default defineComponent({
    components: {
        // eslint-disable-next-line vue/no-reserved-component-names
        Link,
    },

    props: {
        user: {
            type: Object,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: {},
        },
    },

    data() {
        return {
            tools: [
                {
                    heading: this.__('AI Tools'),
                    short_description: this.__(
                        'Content Improve, LinkedIn Post, Product Description'
                    ),
                    link: '/ai-assistant',
                    icon_string: 'fa-duotone fa-wand-magic-sparkles',
                    background_color: '#eeedfe',
                },
                {
                    heading: this.__('Article Wizard'),
                    short_description: this.__('Guided, one-click, etc.'),
                    link: '/wizard',
                    icon_string: 'fa-duotone fa-book-sparkles',
                    background_color: '#e3f7f7',
                },
                {
                    heading: this.__('Image Generator'),
                    short_description: this.__('Create image with prompts'),
                    link: '/image-generator-collections',
                    icon_string: 'fa-duotone fa-image',
                    background_color: '#ffdddb',
                },
            ],
        };
    },

    computed: {
        userLocale() {
            let language = this.user?.user_info?.language || 'en';
            let country = this.user?.user_info?.country || 'US';

            return `${language}-${country}`;
        },
    },

    mounted() {
        this.fetchCharacterUsage('lastFilled');
        this.fetchImageUsage('lastFilled');
    },

    methods: {
        fetchCharacterUsage(range) {
            let vm = this;

            // eslint-disable-next-line no-undef
            axios
                .get(`/api/statistics/used-characters?range=${range}`)
                .then((response) => {
                    vm.tools
                        .filter((tool) => tool.type === 'character_credits')
                        .forEach((tool) => {
                            tool.remaining_token = response.data.totalCredits;
                            tool.total_token =
                                response.data.totalCredits +
                                response.data.totalCharacterCount;
                        });
                });
        },
        fetchImageUsage(range) {
            let vm = this;

            // eslint-disable-next-line no-undef
            axios
                .get(`/api/statistics/used-images?range=${range}`)
                .then((response) => {
                    vm.tools
                        .filter((tool) => tool.type === 'image_credits')
                        .forEach((tool) => {
                            tool.remaining_token = response.data.totalCredits;
                            tool.total_token =
                                response.data.totalCredits +
                                response.data.totalImageCount;
                        });
                });
        },
    },
});
</script>

<style lang="scss" scoped>
.card-ai-assistant {
    &:hover {
        box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.1) !important;
    }
}
</style>