<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_new_project"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header pb-0 border-0 justify-content-end">
                    <!--begin::Close-->
                    <div
                        v-if="!userHasCurrentProject"
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                    <!--begin:Form-->
                    <form
                        id="kt_modal_new_project_form"
                        class="form"
                        action="#"
                        @submit.prevent="sendSubmission"
                    >
                        <!--begin::Heading-->
                        <div class="mb-13 text-center">
                            <!--begin::Title-->
                            <h1 class="mb-3">{{ __('Add a new Project') }}</h1>
                            <!--end::Title-->
                        </div>
                        <!--end::Heading-->
                        <!--begin::Input group-->
                        <div class="d-flex flex-column mb-8 fv-row">
                            <!--begin::Label-->
                            <label
                                class="d-flex align-items-center fs-6 fw-bold mb-2"
                            >
                                <span class="required">{{
                                    __('Project Title')
                                }}</span>
                                <i
                                    class="fas fa-exclamation-circle ms-2 fs-7"
                                    data-bs-toggle="tooltip"
                                    title="{{ __('Specify a project name for future usage and reference') }}"
                                ></i>
                            </label>
                            <!--end::Label-->
                            <input
                                type="text"
                                class="form-control form-control-solid"
                                :placeholder="__('Enter Project Title')"
                                name="project_title"
                                v-model="name"
                            />
                        </div>
                        <!--end::Input group-->
                        <!--begin::Input group-->
                        <div class="d-flex flex-column mb-8">
                            <label class="fs-6 fw-bold mb-2">{{
                                __('Project Description')
                            }}</label>
                            <textarea
                                class="form-control form-control-solid"
                                rows="3"
                                name="description"
                                :placeholder="__('Type Project Details')"
                                v-model="description"
                            ></textarea>
                        </div>
                        <!--end::Input group-->
                        <!--begin::Actions-->
                        <div class="text-center">
                            <button
                                v-if="!userHasCurrentProject"
                                type="reset"
                                id="kt_modal_new_project_cancel"
                                class="btn btn-light me-3"
                                @click="closeModal"
                            >
                                {{ __('Cancel') }}
                            </button>
                            <button
                                type="submit"
                                id="kt_modal_new_project_submit"
                                class="btn btn-primary"
                            >
                                <span v-if="!loading" class="indicator-label">{{
                                    __('Submit')
                                }}</span>
                                <span v-else class="indicator-progress d-block"
                                    >{{ __('Please wait...') }}
                                    <span
                                        class="spinner-border spinner-border-sm align-middle ms-2"
                                    ></span
                                ></span>
                            </button>
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end:Form-->
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import { Modal } from 'bootstrap';

export default {
    props: {
        user: Object,
    },
    data() {
        return {
            name: '',
            description: '',
            modal: {},
            loading: false,
        };
    },
    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_new_project'),
            {
                backdrop: this.userHasCurrentProject ? 'dynamic' : 'static',
            }
        );
    },
    methods: {
        sendSubmission() {
            this.loading = true;
            let data = {
                name: this.name,
                description: this.description,
            };
            axios.post('/api/projects', data).then((response) => {
                this.loading = false;
                this.closeModal();
                Inertia.reload({ only: ['user'] });
            });
        },
        closeModal() {
            this.modal.hide();
        },
    },
    computed: {
        userHasCurrentProject() {
            return this.user.current_project_id === null;
        },
    },
};
</script>
