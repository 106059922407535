<template>
    <!--begin::Footer Section-->
    <div class="mb-0">
        <!--begin::Curve top-->
        <div class="landing-curve landing-dark-color">
            <svg
                viewBox="15 -1 1470 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                    fill="currentColor"
                ></path>
            </svg>
        </div>
        <!--end::Curve top-->
        <!--begin::Wrapper-->
        <div class="landing-dark-bg pt-20">
            <!--begin::Separator-->
            <div class="landing-dark-separator"></div>
            <!--end::Separator-->
            <!--begin::Container-->
            <div class="container">
                <!--begin::Wrapper-->
                <div
                    class="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10"
                >
                    <!--begin::Copyright-->
                    <div class="d-flex align-items-center">
                        <!--begin::Logo-->
                        <a href="/">
                            <img
                                alt="Logo"
                                src="/media/logos/logo-landing.svg"
                                class="h-15px h-md-20px"
                            />
                        </a>
                        <!--end::Logo image-->
                        <!--begin::Logo image-->
                        <span
                            class="mx-5 fs-6 fw-bold text-gray-600 pt-1"
                            href="/"
                            >© 2022 Creaitor.ai |
                            <a class="text-gray-600" href="/de/agb">AGB</a> |
                            <a class="text-gray-600" href="/de/impressum"
                                >Impressum</a
                            >
                            |
                            <a
                                class="text-gray-600"
                                href="https://partners.creaitor.ai"
                                >Affiliate Programm</a
                            ></span
                        >
                        <!--end::Logo image-->
                    </div>
                    <div class="d-flex">
                        <img
                            src="/media/logos/swissmadesoftware.png"
                            class="h-30px mt-1"
                            alt="swiss made software"
                        />
                    </div>
                    <div class="d-flex">
                        <a
                            href="https://www.facebook.com/groups/creaitor/"
                            target="_blank"
                            class="d-flex"
                        >
                            <span
                                class="text-white opacity-50 text-hover-primary fs-5"
                                >Trete der offiziellen Facebook Gruppe bei</span
                            >
                            <img
                                src="/media/svg/brand-logos/facebook-4.svg"
                                class="h-20px ms-2"
                                alt=""
                            />
                        </a>
                    </div>
                    <!--end::Copyright-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Footer Section-->
</template>
