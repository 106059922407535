import Image from '@tiptap/extension-image';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import ResizableImageTemplate from './ResizableImageTemplate.vue';

export default Image.extend({
    name: 'ResizableImage',

    addOptions() {
        return {
            ...this.parent?.(),
            alignDirections: ['left', 'center', 'right'],
        }
    },

    addAttributes() {
        return {
            ...this.parent?.(),
            class: {
                default: '',
            },
            width: {
                default: 500,
            },
            height: {
                default: 500,
            },
            align: {
                default: 'center',
            },
        };
    },

    addCommands() {
        return {
            setResizeImage: options => ({ tr, dispatch, editor, chain, commands }) => {
                const { node } = editor.state.selection;
                let currentAttributes = node?.attrs ? node?.attrs : {};

                options = { ...currentAttributes, ...options };

                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                });
            },

            setAlignment: direction => ({ editor, commands }) => {

                if (!this.options.alignDirections.includes(direction)) {
                    return false;
                }

                const { node } = editor.state.selection;
                let currentAttributes = node?.attrs ? node?.attrs : {};
                let options = { ...currentAttributes, ...{ align: direction } };

                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                });
            },
        }
    },

    addNodeView() {
        return VueNodeViewRenderer(ResizableImageTemplate);
    }
});