<template>
    <!--begin::Modal - New Project-->
    <div
        class="modal fade"
        id="kt_modal_bulk_processing"
        tabindex="-1"
        aria-hidden="true"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-1000px">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header pb-0 border-0 justify-content-end">
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div
                    class="modal-body px-10 px-lg-15 pt-0 pb-15"
                    :class="{ 'scroll-y': selectedTemplate }"
                >
                    <!--begin::Heading-->
                    <div class="mb-13 text-center">
                        <!--begin::Title-->
                        <h1 class="mb-3">{{ __('Bulk processing') }}</h1>
                        <!--end::Title-->
                    </div>
                    <!--end::Heading-->
                    <file-pond
                        name="file"
                        ref="pond"
                        class-name="border-dashed bg-light-primary rounded"
                        :label-idle="__('Drop files here...')"
                        :label-file-loading="__('Loading')"
                        :label-file-processing-complete="__('Upload complete')"
                        :label-file-processing-aborted="__('Upload cancelled')"
                        :label-file-processing-error="__('Error during upload')"
                        :label-invalid-field="
                            __('Field contains invalid files')
                        "
                        :label-ta-to-cancel="__('tap to cancel')"
                        :label-ta-to-retry="__('tap to retry')"
                        :label-ta-to-undo="__('tap to undo')"
                        allow-multiple="false"
                        max-files="1"
                        accepted-file-types="text/csv,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/xml, application/xml"
                        credits="false"
                        v-bind:files="files"
                        @processfile="prepareFile"
                    />
                    <div
                        v-if="fileHeader.length > 0"
                        class="d-flex w-75 pt-4 pb-6"
                    >
                        <div
                            class="d-flex fs-7 text-gray-700 fw-bolder align-items-center me-2 choose-ai-template-label"
                        >
                            {{ __('Choose AI template') }}:
                        </div>

                        <Multiselect
                            v-model="selectedTemplate"
                            :options="selectTemplateOptions"
                            :searchable="true"
                            trackBy="name"
                            mode="single"
                            label="name"
                            class="px-1 py-2"
                            :placeholder="__('AI template')"
                        >
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <div class="d-flex">
                                        <div
                                            class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90"
                                        >
                                            <img
                                                :src="'/storage/' + value.icon"
                                            />
                                        </div>
                                        <div
                                            class="d-flex align-items-center ms-2"
                                        >
                                            {{ value.name[$page.props.locale] }}
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <div class="d-flex">
                                    <div
                                        class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90"
                                    >
                                        <img :src="'/storage/' + option.icon" />
                                    </div>
                                    <div class="d-flex align-items-center ms-2">
                                        {{ option.name[$page.props.locale] }}
                                    </div>
                                </div>
                            </template>
                        </Multiselect>
                    </div>
                    <div v-if="selectedTemplate" class="table-responsive">
                        <table
                            class="file-settings-table table table-striped gy-7 gs-7"
                        >
                            <thead>
                                <tr
                                    class="fw-semibold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                                >
                                    <th class="min-w-150px">
                                        {{ __('Options') }}
                                    </th>
                                    <th v-for="header in fileHeader">
                                        {{ header }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="min-w-150px">
                                        {{ __('Input') }}
                                    </td>
                                    <td v-for="header in fileHeader">
                                        <div
                                            class="form-check form-switch form-check-custom form-check-solid me-10"
                                        >
                                            <input
                                                v-model="
                                                    fileSettings.input[header]
                                                "
                                                class="form-check-input h-20px w-30px"
                                                type="checkbox"
                                                value=""
                                                id="flexSwitch20x30"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="min-w-150px">
                                        {{ __('Output') }}
                                    </td>
                                    <td v-for="header in fileHeader">
                                        <div
                                            class="form-check form-check-custom form-check-solid"
                                        >
                                            <input
                                                v-model="fileSettings.output"
                                                name="output"
                                                class="form-check-input"
                                                type="radio"
                                                :value="header"
                                                id="flexRadioDefault"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr v-for="translation in fileTranslations">
                                    <td class="min-w-150px">
                                        <span>
                                            <img
                                                class="w-15px h-15px rounded-1 me-2"
                                                :src="
                                                    languages[translation].flag
                                                "
                                                alt=""
                                            />
                                        </span>
                                        <span>
                                            {{
                                                languages[translation].name[
                                                    lang
                                                ] ||
                                                languages[translation].name[
                                                    'en'
                                                ]
                                            }}
                                        </span>
                                    </td>
                                    <td v-for="header in fileHeader">
                                        <div
                                            class="form-check form-check-custom form-check-solid"
                                        >
                                            <input
                                                v-model="
                                                    fileSettings.translations[
                                                        translation
                                                    ]
                                                "
                                                :name="translation"
                                                class="form-check-input"
                                                type="radio"
                                                :value="header"
                                                id="flexRadioDefault"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div
                        v-if="selectedTemplate"
                        class="d-flex mt-4 d-flex gap-15"
                    >
                        <div class="d-flex">
                            <div
                                class="d-flex fs-7 text-gray-700 fw-bolder align-items-center me-2"
                            >
                                {{ __('Add translation') }}:
                            </div>
                            <Language
                                :show-formality="false"
                                :lang="lang"
                                @language-change="addTranslation"
                            >
                            </Language>
                        </div>
                    </div>
                </div>
                <!--end::Modal body-->
                <div class="modal-footer modal-footer justify-content-between">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div
                            class="d-flex fs-7 text-gray-700 fw-bolder align-items-center me-2"
                        >
                            {{ __('Language') }}:
                        </div>
                        <Language
                            :lang="lang"
                            @language-change="(ev) => (lang = ev)"
                            @formality-change="(ev) => (formality = ev)"
                        >
                        </Language>
                    </div>
                    <!--begin::Actions-->
                    <div class="text-center">
                        <button
                            type="reset"
                            class="btn btn-light me-3"
                            @click="closeModal"
                        >
                            {{ __('Cancel') }}
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary"
                            @click="processFile"
                        >
                            <span v-if="!loading" class="indicator-label">{{
                                __('Next')
                            }}</span>
                            <span v-else class="indicator-progress d-block"
                                >{{ __('Please wait...') }}
                                <span
                                    class="spinner-border spinner-border-sm align-middle ms-2"
                                ></span
                            ></span>
                        </button>
                    </div>
                    <!--end::Actions-->
                </div>
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import {router as Inertia} from '@inertiajs/vue3';
import vueFilePond, { setOptions } from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import 'filepond/dist/filepond.min.css';
import Language from '@/Layouts/SettingElements/Language.vue';
import Multiselect from '@vueform/multiselect';
import { useToast } from 'vue-toastification';
import { Modal } from 'bootstrap';

const FilePond = vueFilePond(FilePondPluginFileValidateType);
setOptions({
    server: {
        url: '/filepond/api',
        process: '/process',
        revert: '/process',
        patch: '?patch=',
    },
});

export default {
    components: {
        FilePond,
        Language,
        Multiselect,
    },

    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast };
    },

    data() {
        return {
            modal: {},
            projectsUrl: '/api/projects',
            loading: false,
            lang:
                this.$page.props.user.user_info.prompt_settings &&
                this.$page.props.user.user_info.prompt_settings.targetLang !==
                    undefined
                    ? this.$page.props.user.user_info.prompt_settings.targetLang
                    : 'en',
            formality:
                this.$page.props.user.user_info.prompt_settings &&
                this.$page.props.user.user_info.prompt_settings.formality
                    ? this.$page.props.user.user_info.prompt_settings.formality
                    : 'default',
            languages: {},
            files: [],
            fileHeader: [],
            fileSettings: {
                input: {},
                output: {},
                translations: {},
            },
            fileTranslations: [],
            aiTemplates: [],
            selectedTemplate: null,
        };
    },
    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_bulk_processing')
        );
        this.fetchData();
        this.fetchLanguage();
    },
    methods: {
        fetchLanguage() {
            let vm = this;
            vm.loading = true;
            axios
                .get(`/api/languages`)
                .then((response) => {
                    vm.languages = response.data;
                })
                .catch((error) => {
                    console.error(error.response.data.message);
                })
                .then(() => {
                    vm.loading = false;
                });
        },
        fetchData() {
            let vm = this;
            vm.loading = true;

            axios
                .get('/api/bulk/fetch-data')
                .then((response) => {
                    vm.aiTemplates = response.data.ai_templates;
                })
                .catch((error) => {
                    console.error(error.response.data.message);
                })
                .then(() => {
                    vm.loading = false;
                });
        },
        prepareFile() {
            let vm = this;
            axios
                .post('/api/bulk/prepare', {
                    serverId: this.$refs.pond.getFile().serverId,
                    filename: this.$refs.pond.getFile().filename,
                })
                .then((response) => {
                    vm.fileHeader = response.data;
                });
        },
        processFile() {
            let vm = this;

            if (!this.selectedTemplate) {
                this.toast.error(this.__('Please choose an AI template'));

                return false;
            }

            this.loading = true;
            axios
                .post('/api/bulk/process', {
                    serverId: this.$refs.pond.getFile().serverId,
                    filename: this.$refs.pond.getFile().filename,
                    fileHeader: this.fileHeader,
                    fileSettings: this.fileSettings,
                    lang: this.lang,
                    formality: this.formality,
                    selectedTemplateUuid: this.selectedTemplate,
                })
                .then((response) => {
                    emitter.emit('new-bulk-processing');
                    vm.loading = false;
                    this.resetForm();
                    vm.closeModal();
                })
                .catch((error) => {
                    console.error(error.response.data.message);
                    this.toast.error(error.response.data.message);
                    vm.loading = false;
                });
        },
        addTranslation(code) {
            this.fileTranslations.push(code);
        },
        resetForm() {
            this.lang =
                this.$page.props.user.user_info.prompt_settings &&
                this.$page.props.user.user_info.prompt_settings.targetLang !==
                    undefined
                    ? this.$page.props.user.user_info.prompt_settings.targetLang
                    : 'en';
            this.formality =
                this.$page.props.user.user_info.prompt_settings &&
                this.$page.props.user.user_info.prompt_settings.formality
                    ? this.$page.props.user.user_info.prompt_settings.formality
                    : 'default';
            this.files = [];
            this.fileHeader = [];
            this.fileSettings = {
                input: {},
                output: {},
                translations: {},
            };
            this.fileTranslations = [];
            this.selectedTemplate = null;

            if (this.$refs.pond) {
                this.$refs.pond.getFiles().forEach((file) => {
                    this.$refs.pond.removeFile(file.id);
                });
            }
        },
        closeModal() {
            this.modal.hide();
        },
    },
    computed: {
        selectTemplateOptions() {
            return this.aiTemplates?.map((aiTemplate) => {
                return {
                    value: aiTemplate.uuid,
                    name: aiTemplate.name,
                    icon: aiTemplate.template_icon_path,
                };
            });
        },
    },
};
</script>

<style lang="scss">
.filepond--panel-root {
    background-color: transparent;
    border-color: var(--kt-primary);
}
.file-settings-table {
    > thead,
    > tbody {
        > tr > {
            th:first-child,
            td:first-child {
                position: sticky;
                left: 0;
                background-color: #f8fafb !important;
                color: #373737;
                border-color: transparent;
            }
        }
    }
}

.choose-ai-template-label {
    width: 40%;
}
</style>
