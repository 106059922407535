<template>
    <div class="logo-wrapper d-flex">
        <div class="logo-mark" :class="logoMarkClasses">
            <ApplicationMark
                :width="markWidth"
                :height="markHeight"
            ></ApplicationMark>
        </div>
        <div
            :class="logoTextClasses"
            class="logo-text d-flex align-items-center"
            :style="{ fontSize: logoTextFontSize }"
        >
            Creaitor.ai
        </div>
    </div>
</template>
<script>
import ApplicationMark from '@/Jetstream/ApplicationMark.vue';

export default {
    components: {
        ApplicationMark,
    },

    props: {
        markWidth: {
            type: String,
            default: '32',
        },
        markHeight: {
            type: String,
            default: '32',
        },
        color: {
            type: String,
            default: 'black',
        },
        fontSize: {
            type: String,
            default: '2rem',
        },
        logoMarkClasses: {
            type: String,
            default: 'py-3',
        },
        logoTextClasses: {
            type: String,
            default: 'p-3 text-white',
        },
    },
    computed: {
        logoTextFontSize() {
            let factor = ((100 / 32) * this.markWidth) / 100;
            let fontSize = 1.5 * factor;
            return fontSize + 'rem';
        },
    },
};
</script>

<style lang="scss">
.logo-wrapper {
    .logo-text {
        font-family: 'Lato', sans-serif;
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 1;
    }
}
</style>
