import TipTapLink from '@tiptap/extension-link';
import { Plugin } from 'prosemirror-state';

const Link = TipTapLink.extend({
    name: 'link',

    // Inheriting the default options from Link and adding custom behavior
    addOptions() {
        return {
            ...this.parent?.(),
            openOnClick: false, // Prevent default link click action
        };
    },

    // Adding custom ProseMirror plugins to the extension
    addProseMirrorPlugins() {
        const extension = this;

        // Plugin to prevent the default behavior when clicking on a link
        const preventDefaultLinkClick = new Plugin({
            props: {
                handleClick: (view, pos, event) => {
                    // Only prevent default if the click is on an anchor tag (a link)
                    if (event.target.closest('a')) {
                        event.preventDefault();
                        return true; // Indicate that this plugin handled the click
                    }
                    return false; // Not handled by this plugin
                },
            },
        });

        // Plugin to attach a method for retrieving link attributes
        const attachGetLinkAttributesMethod = new Plugin({
            view(editorView) {
                editorView.getLinkAttributes = function () {
                    const { from, to } = editorView.state.selection;
                    let attrs = {};

                    // This checks for a link within the current selection or at the cursor position
                    editorView.state.doc.nodesBetween(from, to, (node, pos) => {
                        if (node.isInline && node.marks.length) {
                            // Filters for link marks
                            const linkMark = node.marks.find(mark => mark.type.name === 'link');
                            if (linkMark) {
                                attrs = { ...linkMark.attrs };
                                return false; // Stop the nodesBetween iteration once we find a link
                            }
                        }
                    });

                    return attrs;
                };

                return {};
            },
        });

        // Returning the inherited plugins plus our custom plugins
        return [
            ...(this.parent?.() || []),
            preventDefaultLinkClick,
            attachGetLinkAttributesMethod,
        ];
    },
});

export default Link;
