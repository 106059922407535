<template>
    <!--begin::Heading-->
    <div class="mb-5">
        <!--begin::Title-->
        <h2 class="mb-3">{{ __('Generated Outline') }}</h2>
    </div>
    <!--end::Heading-->
    <div class="step-body">
        <!--begin::Input group-->
        <div
            class="fv-row mb-15 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid select-item-box generated-outline-blk"
            data-kt-buttons="true"
            data-kt-initialized="1"
        >
            <div class="row">
                <div
                    class="d-flex align-items-start outline-group col-6 py-2"
                    v-for="(outlineState, index) in outlinesState"
                    :key="index"
                >
                    <OutlineItem
                        :outlineItem="outlineState"
                        :index="index"
                    ></OutlineItem>
                </div>
            </div>
            <div class="row">
                <!--        <div class="load-more-btn-container">
          <button @click="loadMoreOutlines" class="btn btn-primary">{{ __('Load More') }}</button>
        </div>-->
            </div>
        </div>
        <!--end::Input group-->
    </div>
</template>

<script>
import { getCurrentInstance, watch, ref } from 'vue';
import OutlineItem from '@/Layouts/Article/Partials/Wizard/Guided/Partials/OutlineItem.vue';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';
import { useToast } from 'vue-toastification';

export default {
    components: {
        OutlineItem,
    },

    setup() {
        // Get current instance
        const instance = getCurrentInstance();

        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        // Get toast interface
        const toast = useToast();

        // Use a ref to make outlines reactive
        const outlines = ref(articleWizardStore.getAiGeneratedOutlines);

        // Watch the Pinia store state and update outlines ref when it changes
        watch(
            () => articleWizardStore.getAiGeneratedOutlines,
            (newOutlines) => {
                outlines.value = newOutlines;
            }
        );

        const currentCount = ref(2); // start with 2 outlines
        const outlinesState = ref([]);

        // Function to initialize the state
        const initializeState = (count) => {
            for (let i = 0; i < count; i++) {
                outlinesState.value.push({ isLoading: true, data: null });
            }
        };

        initializeState(currentCount.value);

        const fetchArticleOutlines = async (count = 2) => {
            outlinesState.value = Array(count)
                .fill()
                .map(() => ({ isLoading: true, data: null })); // Initialize with loading state

            try {
                const responses = await Promise.all(
                    Array(count)
                        .fill()
                        .map(() =>
                            axios.post(
                                '/api/article/ai-assistant/outline',
                                articleWizardStore.getArticleOutlineRequestPayload
                            )
                        )
                );

                responses.forEach((response, i) => {
                    outlinesState.value[i] = {
                        isLoading: false,
                        data: response.data.choices[0],
                    };
                    articleWizardStore.addToAiGeneratedOutlines(
                        response.data.choices
                    );
                });
            } catch (error) {
                toast.error(instance.proxy.__('An error occurred'));
                outlinesState.value = Array(count)
                    .fill()
                    .map(() => ({ isLoading: false, data: null })); // Reset to error state
            }
        };

        const loadMoreOutlines = () => {
            currentCount.value += 2;
            fetchArticleOutlines(2);
        };

        const getOutlineContent = (outline) => {
            try {
                return JSON.parse(outline);
            } catch (error) {
                return {
                    headings: [],
                };
            }
        };

        const articleTitleHasChanged = () => {
            const currentTitle = articleWizardStore.getArticleTitle;
            const previousTitle = articleWizardStore.getPreviousArticleTitle;

            return currentTitle !== previousTitle;
        };

        watch(
            () => articleWizardStore.currentStep,
            (currentStep, prevStep) => {
                if (currentStep === 2) {
                    if (
                        articleWizardStore.getAiGeneratedOutlines.length === 0 ||
                        articleTitleHasChanged()
                    ) {
                        articleWizardStore.setAiGeneratedOutlines([]);
                        articleWizardStore.setHeadingLists([]);
                        initializeState(2);
                        fetchArticleOutlines();
                        articleWizardStore.setPreviousArticleTitle(
                            articleWizardStore.getArticleTitle
                        );
                    } else {
                        outlinesState.value =
                            articleWizardStore.getAiGeneratedOutlines.map(
                                (outline) => ({
                                    isLoading: false,
                                    data: outline[0],
                                })
                            );
                    }
                }
            },
            { immediate: true }
        );

        return {
            articleWizardStore,
            toast,
            outlines,
            currentCount,
            outlinesState,
            fetchArticleOutlines,
            loadMoreOutlines,
            getOutlineContent,
            articleTitleHasChanged,
        };
    },
};
</script>
