<template>
    <Head :title="__('Email Verification')" />

    <div class="d-flex flex-column flex-root">
        <!--begin::Authentication - Sign-in -->
        <div
            class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style="
                background-image: url(assets/media/illustrations/sketchy-1/14.png);
            "
        >
            <!--begin::Content-->
            <div
                class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
            >
                <!--begin::Logo-->
                <a href="/" class="mb-12">
                    <jet-authentication-card-logo
                        mark-width="64"
                        logo-text-classes="p-3 text-black"
                    />
                </a>
                <!--end::Logo-->
                <!--begin::Wrapper-->
                <div
                    class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto"
                >
                    <!--begin::Form-->
                    <form
                        class="form w-100"
                        novalidate="novalidate"
                        action="#"
                        @submit.prevent="submit"
                    >
                        <!--begin::Heading-->
                        <div class="text-center mb-10">
                            <!--begin::Title-->
                            <h1 class="text-dark mb-3">
                                {{ __('Just One Step Left') }}
                            </h1>
                            <!--end::Title-->
                            <!--begin::Link-->
                            <div class="text-gray-400 fw-bold fs-4">
                                {{ __('Please verify your email') }}
                            </div>
                            <!--end::Link-->
                        </div>
                        <!--begin::Heading-->
                        <!--begin::Input group-->
                        <div class="fs-3 text-gray-800 mb-10">
                            {{
                                __(
                                    "Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another."
                                )
                            }}
                        </div>
                        <!--end::Input group-->
                        <!--begin::Actions-->
                        <div class="text-center">
                            <!--begin::Submit button-->
                            <form @submit.prevent="submit">
                                <div
                                    class="mt-4 d-flex justify-content-between"
                                >
                                    <jet-button
                                        :class="{
                                            'text-white-50': form.processing,
                                        }"
                                        :disabled="form.processing"
                                    >
                                        <div
                                            v-show="form.processing"
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                        >
                                            <span class="visually-hidden"
                                                >Loading...</span
                                            >
                                        </div>

                                        {{ __('Resend Verification Email') }}
                                    </jet-button>

                                    <Link
                                        :href="route('logout')"
                                        method="post"
                                        as="button"
                                        class="btn btn-link"
                                        >{{ __('Log out') }}</Link
                                    >
                                </div>
                            </form>
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end::Form-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Content-->
        </div>
        <!--end::Authentication - Sign-in-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

export default defineComponent({
    components: {
        Head,
        JetAuthenticationCard,
        JetAuthenticationCardLogo,
        JetButton,
        Link,
    },

    props: {
        status: String,
    },

    data() {
        return {
            form: useForm({}),
        };
    },

    methods: {
        submit() {
            // eslint-disable-next-line no-undef
            this.form.post(route('verification.send'));
        },
    },

    computed: {
        verificationLinkSent() {
            return this.status === 'verification-link-sent';
        },
    },
});
</script>
