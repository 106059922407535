<template>
    <div class="seo-score">
        <svg
            class="seo-score__circle"
            viewBox="0 0 100 50"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <linearGradient
                    id="seo-score-gradient"
                    x1="0%"
                    y1="100%"
                    x2="100%"
                    y2="100%"
                >
                    <stop offset="0%" style="stop-color: red" />
                    <stop offset="20%" style="stop-color: orange" />
                    <stop offset="50%" style="stop-color: yellow" />
                    <stop offset="80%" style="stop-color: chartreuse" />
                    <stop offset="100%" style="stop-color: lime" />
                </linearGradient>
            </defs>
            <path
                ref="circlePath"
                :d="halfCirclePath"
                fill="none"
                stroke="url(#seo-score-gradient)"
                :stroke-width="circleStrokeWidth"
                stroke-opacity="0.3"
            />
            <path
                ref="gradientPath"
                :d="halfCirclePath"
                fill="none"
                stroke="url(#seo-score-gradient)"
                :stroke-width="fullColorStrokeWidth"
                stroke-opacity="0.3"
            />
            <path
                ref="gradientPath"
                :d="halfCirclePath"
                fill="none"
                stroke="url(#seo-score-gradient)"
                :stroke-width="fullColorStrokeWidth"
                :stroke-dasharray="circleCircumference"
            />
            <circle
                ref="circleThumb"
                :cx="thumbPosition.x"
                :cy="thumbPosition.y"
                :r="circleThumbRadius"
                :fill="thumbFillColor"
                :stroke="thumbBorderColor"
                stroke-width="2"
            />
        </svg>
        <div class="seo-score__value">
            <div class="seo-score__rating">
                <span class="seo-score__current">{{ score }}</span>
                <span class="seo-score__max text-muted">/100</span>
            </div>
            <div class="seo-score__suggestion">
                <span>{{ __('Suggested') }}: {{ suggestedScore }}+</span>
            </div>
        </div>
        <div v-if="avgScore && topScore" class="seo-score__info">
            <span>{{ __('Ø') }}: {{ avgScore }}</span>
        <span>{{ __('Top') }}: {{ topScore }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SeoScore',
    props: {
        score: {
            type: Number,
            required: true,
            validator: (value) => value >= 0 && value <= 100,
        },
        avgScore: {
            type: Number,
            required: true,
            validator: (value) => value >= 0 && value <= 100,
        },
        topScore: {
            type: Number,
            required: true,
            validator: (value) => value >= 0 && value <= 100,
        },
        suggestedScore: {
            type: Number,
            required: true,
            validator: (value) => value >= 0 && value <= 100,
        },
    },
    data() {
        return {
            thumbPosition: { x: 0, y: 0 },
            thumbFillColor: '',
            thumbBorderColor: '',
            animationDuration: 500, // Add this line
        };
    },
    watch: {
        score(newScore, oldScore) {
            this.animateThumb(oldScore, newScore, 500);
            this.updateThumbColor();
        },
    },
    methods: {
        updateThumbPosition() {
            const positionOnPath =
                (this.score / 100) * this.$refs.circlePath.getTotalLength();
            const point =
                this.$refs.circlePath.getPointAtLength(positionOnPath);
            this.thumbPosition = { x: point.x, y: point.y };
        },
        getColorAtPosition(score) {
            const percentage = score / 100;
            const r = 255 * (1 - percentage);
            const g = 255 * percentage;
            const b = 0;
            return `rgb(${r}, ${g}, ${b})`;
        },
        updateThumbColor() {
            const fillOpacity = 1; // Set fill color opacity to 100%
            const borderOpacity = 0.5; // Set border color opacity to 50%
            const fillColor = this.getColorAtPosition(this.score);
            const borderColor = `rgba(${fillColor.slice(
                4,
                -1
            )}, ${borderOpacity})`;
            this.thumbFillColor = `rgba(${fillColor.slice(
                4,
                -1
            )}, ${fillOpacity})`;
            this.thumbBorderColor = borderColor;
        },
        animateThumb(start, end, duration) {
            const startTime = performance.now();
            const animate = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1);
                const easedProgress = this.easeInOutQuad(progress);

                const score = start + (end - start) * easedProgress;
                const positionOnPath =
                    (score / 100) * this.$refs.circlePath.getTotalLength();
                const point =
                    this.$refs.circlePath.getPointAtLength(positionOnPath);
                this.thumbPosition = { x: point.x, y: point.y };

                // Update stroke dashoffset based on the current score
                this.$refs.gradientPath.style.strokeDashoffset =
                    this.circleCircumference - positionOnPath;

                // Update thumbFillColor and thumbBorderColor based on the current score
                const fillColor = this.getColorAtPosition(score);
                const fillOpacity = 1;
                const borderOpacity = 0.5;
                this.thumbFillColor = `rgba(${fillColor.slice(
                    4,
                    -1
                )}, ${fillOpacity})`;
                this.thumbBorderColor = `rgba(${fillColor.slice(
                    4,
                    -1
                )}, ${borderOpacity})`;

                if (progress < 1) {
                    requestAnimationFrame(animate);
                }
            };
            requestAnimationFrame(animate);
        },

        easeInOutQuad(t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.updateThumbPosition();
            this.updateThumbColor();

            this.$refs.gradientPath.style.strokeDashoffset =
                this.circleCircumference;
        });
    },
    computed: {
        circleRadius() {
            return 45;
        },
        circleStrokeWidth() {
            return 6;
        },
        fullColorStrokeWidth() {
            return 3;
        },
        circleCircumference() {
            return Math.PI * this.circleRadius;
        },
        halfCirclePath() {
            return `M 5 45 A ${this.circleRadius} ${this.circleRadius} 0 0 1 95 45`;
        },
        circleThumbRadius() {
            return 3;
        },
    },
};
</script>

<style scoped>
.seo-score {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 90%;
    font-family: sans-serif;
}

.seo-score__circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.seo-score__value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-flow: column;
}

.seo-score__rating {
    font-size: 2vw;
    font-weight: normal;
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.seo-score__current {
    font-weight: bold;
    font-size: 2vw;
}

.seo-score__max {
    margin-left: 2px;
    font-size: 1vw;
}

.seo-score__suggestion {
    font-size: 0.7vw;
}

.seo-score__info {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 0.8vw;
    padding: 0.8vw 0;
}

.seo-score__info span {
    display: inline-block;
    margin: 0 4px;
}
</style>
