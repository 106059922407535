<template>
    <div
        class="modal fade"
        id="kt_modal_notification_detail_modal"
        tabindex="-1"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!--begin::Modal dialog-->
        <div class="modal-dialog">
            <!--begin::Modal content-->
            <div class="modal-content bg-white">
                <!--begin::Modal header-->
                <div class="modal-header border-0 pb-0">
                    <!--begin::Heading-->
                    <div class="symbol symbol-circle symbol-40px me-4">
                        <div class="symbol-label bg-light">
                            <DynamicIcons
                                :icon="
                                    notificationDetails?.notification?.data
                                        ?.symbol
                                "
                            ></DynamicIcons>
                        </div>
                    </div>
                    <!--begin::Close-->
                    <div
                        class="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                    >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <rect
                                    opacity="0.5"
                                    x="6"
                                    y="17.3137"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(-45 6 17.3137)"
                                    fill="black"
                                />
                                <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="black"
                                />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body m-5 px-0 mx-0 my-0">
                    <component
                        v-if="!loading"
                        :is="component"
                        :notificationDetails="notificationDetails"
                    ></component>
                    <div v-else class="container">
                        <div class="col-md-12 p-10">
                            <div class="align-items-center text-center">
                                <div
                                    class="d-flex flex-column align-items-center text-center"
                                >
                                    <span
                                        class="spinner-border text-primary"
                                        role="status"
                                    ></span>
                                    <span
                                        class="text-gray-800 fs-6 fw-semibold mt-5"
                                        >{{ __('Loading...') }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import AdminNotification from '@/Layouts/Notification/DetailModal/AdminNotification.vue';
import DynamicIcons from '@/Components/Icons/DynamicIcons.vue';
import { Modal } from 'bootstrap';

export default {
    props: {
        notificationDetailModalData: Object,
    },

    components: {
        AdminNotification,
        DynamicIcons,
    },

    data() {
        return {
            modal: {},
            language: this.$page?.props?.user?.user_info?.language
                ? this.$page.props.user.user_info.language
                : 'en',
            notificationDetails: {},
            component: null,
            loading: false,
        };
    },
    mounted() {
        this.modal = new Modal(
            document.getElementById('kt_modal_notification_detail_modal')
        );
    },
    watch: {
        notificationDetailModalData() {
            this.getNotification();
        },
    },
    methods: {
        getNotification() {
            this.loading = true;
            let vm = this;
            axios
                .get(
                    `/api/get-notification-details/${this.notificationDetailModalData.notification_id}`
                )
                .then((response) => {
                    vm.notificationDetails = response.data;
                    vm.component = response.data.component;
                    this.loading = false;
                });
        },
    },
};
</script>
