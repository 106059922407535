<template>
    <div class="container">
        <div class="row">
            <div class="border border-1 mt-5 col-12">
                <div class="py-10">
                    <h3 class="h3 my-5">{{ __('Settings') }}</h3>

                    <!--begin:Form-->
                    <form
                        id="document_prompt_form"
                        class="form"
                        action="#"
                        @submit.prevent="updatePromptSettings"
                        v-if="!initialLoading"
                    >
                        <!--begin::Input group-->
                        <div class="d-flex flex-column mb-8">
                            <Creativity
                                @creativity-change="creativityChanged"
                                :defaultCreativity="documentStore.getCreativity"
                            ></Creativity>

                            <Outputs
                                @outputs-change="outputsChanged"
                                :defaultOutputs="
                                    documentStore.promptSettings?.outputs
                                "
                            >
                            </Outputs>

                            <ToneOfVoice
                                @tone-of-voice-change="toneOfVoiceChanged"
                                :defaultToneOfVoice="
                                    documentStore.getToneOfVoice
                                "
                            ></ToneOfVoice>

                            <TargetAudience
                                @target-audience-change="targetAudienceChanged"
                                :defaultTargetAudience="
                                    documentStore.getTargetAudience
                                "
                            ></TargetAudience>

                            <Language
                                :allowed-languages="allowedLanguages"
                                :lang="documentStore.getTargetLang"
                                @language-change="onLanguageChange"
                                @formality-change="onFormalityChange"
                            >
                            </Language>
                        </div>
                        <!--end::Input group-->
                        <!--begin::Actions-->
                        <div class="">
                            <button type="submit" class="btn btn-primary">
                                <span v-if="!loading" class="indicator-label">{{
                                    __('Save')
                                }}</span>
                                <span v-else class="indicator-progress d-block"
                                    >{{ __('Please wait...') }}
                                    <span
                                        class="spinner-border spinner-border-sm align-middle ms-2"
                                    ></span
                                ></span>
                            </button>
                        </div>
                        <!--end::Actions-->
                    </form>
                    <!--end:Form-->

                    <div v-else class="container">
                        <div class="col-md-12 p-10">
                            <div class="align-items-center text-center">
                                <div
                                    class="d-flex flex-column align-items-center text-center"
                                >
                                    <span
                                        class="spinner-border text-primary"
                                        role="status"
                                    ></span>
                                    <span
                                        class="text-gray-800 fs-6 fw-semibold mt-5"
                                        >{{ __('Loading...') }}</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import Outputs from '@/Layouts/SettingElements/Outputs.vue';
import ToneOfVoice from '@/Layouts/SettingElements/ToneOfVoice.vue';
import TargetAudience from '@/Layouts/SettingElements/TargetAudience.vue';
import Creativity from '@/Layouts/SettingElements/Creativity.vue';
import Multiselect from '@vueform/multiselect';
import Language from '@/Layouts/SettingElements/Language.vue';
import { useDocumentStore } from '@/Stores/DocumentStore';

export default defineComponent({
    props: {
        user: Object,
        documentUuid: String,
    },

    components: {
        Multiselect,
        Outputs,
        ToneOfVoice,
        TargetAudience,
        Creativity,
        Language,
    },

    setup() {
        // Get toast interface
        const toast = useToast();

        // Get document store
        const documentStore = useDocumentStore();

        // Make it available inside methods
        return {
            toast,
            documentStore,
        };
    },

    data() {
        return {
            loading: false,
            initialLoading: false,
            allowedLanguages: [],
        };
    },

    watch: {
        documentUuid: {
            immediate: true,
            handler() {
                this.fetchPromptSettings();
            },
        },
    },

    methods: {
        async fetchPromptSettings() {
            let vm = this;
            vm.initialLoading = true;
            if (this.documentUuid) {
                try {
                    // Fetch settings from the server
                    let response = await axios.get(
                        `/api/document/${this.documentUuid}/get-prompt-settings`
                    );

                    // Update the Pinia store with the fetched settings
                    const documentStore = useDocumentStore();
                    documentStore.updatePromptSettings(
                        response.data.prompt_settings
                    );
                } catch (error) {
                    // Handle any errors
                    vm.toast.error(vm.__('Some error occurred'));
                } finally {
                    // Set initial loading to false regardless of success or failure
                    vm.initialLoading = false;
                }
            }
        },
        onLanguageChange(event) {
            this.documentStore.updateTargetLang(event);
        },
        onFormalityChange(event) {
            this.documentStore.updateFormality(event);
        },
        creativityChanged(event) {
            this.documentStore.updateCreativity(event);
        },
        outputsChanged(event) {
            this.documentStore.updateOutputs(event);
        },
        toneOfVoiceChanged(event) {
            this.documentStore.updateToneOfVoice(event);
        },
        targetAudienceChanged(event) {
            this.documentStore.updateTargetAudience(event);
        },
        updatePromptSettings() {
            let vm = this;
            let payLoad = {
                prompt_settings: this.documentStore.getPromptSettings,
            };

            this.loading = true;

            axios
                .post(
                    `/api/document/${this.documentUuid}/prompt-settings`,
                    payLoad
                )
                .then((response) => {
                    vm.toast.success(vm.__('Settings updated'));
                })
                .catch((error) => {
                    vm.toast.error(vm.__('Some error occurred'));
                })
                .then(() => {
                    vm.loading = false;
                });
        },
    },
});
</script>
