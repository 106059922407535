<template>
    <Head :title="__('Register Lifetime')" />

    <div class="d-flex flex-column flex-root">
        <!--begin::Authentication - Signup Free Trial-->
        <div class="d-flex flex-column flex-xl-row flex-column-fluid">
            <!--begin::Aside-->
            <div class="d-flex flex-column flex-center flex-lg-row-fluid">
                <!--begin::Content-->
                <div class="d-flex align-items-start flex-column p-5 p-lg-15">
                    <!--begin::Logo-->
                    <a href="/" class="mb-15">
                        <jet-authentication-card-logo
                            mark-width="64"
                            mark-height="64"
                            logo-text-classes="p-3 text-black"
                        />
                    </a>
                    <!--end::Logo-->
                    <!--begin::Title-->
                    <h1 class="text-dark fs-2x mb-3">{{ __('Welcome') }}</h1>
                    <!--end::Title-->
                    <!--begin::Description-->
                    <div
                        class="fw-bold fs-4 text-gray-600 mb-10"
                        v-html="
                            __(
                                'Start writing your article post, creating topic ideas,<br />Facebook Ads and more.'
                            )
                        "
                    ></div>
                    <!--begin::Description-->
                </div>
                <!--end::Content-->
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div
                class="flex-row-fluid d-flex flex-center justfiy-content-xl-first p-10"
            >
                <!--begin::Wrapper-->
                <div
                    v-if="!$page.props.user"
                    class="d-flex flex-center p-15 shadow-sm bg-white rounded w-100 w-md-550px mx-auto ms-xl-20"
                >
                    <!--begin::Form-->
                    <form
                        class="form"
                        novalidate="novalidate"
                        @submit.prevent="submit"
                    >
                        <!--begin::Heading-->
                        <div class="text-center mb-10">
                            <!--begin::Title-->
                            <h1
                                class="text-dark mb-3"
                                v-html="__('Lifetime access')"
                            ></h1>
                            <!--end::Title-->
                            <!--begin::Link-->
                            <div
                                class="text-gray-400 fw-bold fs-4"
                                v-html="
                                    __(
                                        'Redeem your code and get your lifetime access.'
                                    )
                                "
                            ></div>
                            <!--end::Link-->
                        </div>
                        <!--begin::Heading-->
                        <jet-validation-errors class="mb-3" />
                        <!--begin::Input group-->
                        <div class="fv-row mb-10">
                            <label
                                class="form-label fw-bolder text-dark fs-6"
                                >{{ __('Name') }}</label
                            >
                            <input
                                v-model="form.name"
                                class="form-control form-control-solid"
                                type="text"
                                placeholder=""
                                name="name"
                                autocomplete="off"
                                required
                            />
                        </div>
                        <!--begin::Input group-->
                        <div class="fv-row mb-10">
                            <label
                                class="form-label fw-bolder text-dark fs-6"
                                >{{ __('Email') }}</label
                            >
                            <input
                                v-model="form.email"
                                class="form-control form-control-solid"
                                type="email"
                                placeholder=""
                                name="email"
                                autocomplete="off"
                                required
                            />
                        </div>
                        <!--end::Input group-->
                        <!--begin::Input group-->
                        <div class="mb-7 fv-row" data-kt-password-meter="true">
                            <!--begin::Wrapper-->
                            <div class="mb-1">
                                <!--begin::Label-->
                                <label
                                    class="form-label fw-bolder text-dark fs-6"
                                    >{{ __('Password') }}</label
                                >
                                <!--end::Label-->
                                <!--begin::Input wrapper-->
                                <div class="position-relative mb-3">
                                    <input
                                        v-model="form.password"
                                        @input="computeScore"
                                        class="form-control form-control-solid"
                                        type="password"
                                        placeholder=""
                                        name="password"
                                        autocomplete="off"
                                    />
                                    <span
                                        class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                                        data-kt-password-meter-control="visibility"
                                    >
                                        <i class="bi bi-eye-slash fs-2"></i>
                                        <i class="bi bi-eye fs-2 d-none"></i>
                                    </span>
                                </div>
                                <!--end::Input wrapper-->
                                <!--begin::Meter-->
                                <div
                                    class="d-flex align-items-center mb-3"
                                    data-kt-password-meter-control="highlight"
                                >
                                    <div
                                        :class="{ active: checkSteps > 0 }"
                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                    ></div>
                                    <div
                                        :class="{ active: checkSteps > 1 }"
                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                    ></div>
                                    <div
                                        :class="{ active: checkSteps > 2 }"
                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                                    ></div>
                                    <div
                                        :class="{ active: checkSteps > 3 }"
                                        class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
                                    ></div>
                                </div>
                                <!--end::Meter-->
                            </div>
                            <!--end::Wrapper-->
                            <!--begin::Hint-->
                            <div class="text-muted">
                                {{
                                    __(
                                        'Use 8 or more characters with a mix of letters, numbers &amp; symbols.'
                                    )
                                }}
                            </div>
                            <!--end::Hint-->
                        </div>
                        <!--end::Input group=-->
                        <!--begin::Row-->
                        <div class="fv-row mb-10">
                            <label
                                class="form-label fw-bolder text-dark fs-6"
                                >{{ __('Confirm Password') }}</label
                            >
                            <input
                                v-model="form.password_confirmation"
                                class="form-control form-control-solid"
                                type="password"
                                placeholder=""
                                name="confirm-password"
                                autocomplete="off"
                            />
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div class="fv-row mb-10">
                            <label
                                class="form-label fw-bolder text-dark fs-6"
                                >{{ __('Coupon Code') }}</label
                            >
                            <input
                                v-model="form.couponCode"
                                class="form-control form-control-solid"
                                type="text"
                                placeholder=""
                                name="coupon-code"
                                autocomplete="off"
                            />
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div
                            class="fv-row mb-10"
                            v-if="
                                $page.props.jetstream
                                    .hasTermsAndPrivacyPolicyFeature
                            "
                        >
                            <div
                                class="form-check form-check-custom form-check-solid form-check-inline mb-5"
                            >
                                <jet-checkbox
                                    name="terms"
                                    id="terms"
                                    v-model:checked="form.terms"
                                />
                                <label class="custom-control-label" for="terms">
                                    I agree to the
                                    <a
                                        target="_blank"
                                        :href="route('terms.show')"
                                        >{{ __('Terms of Service') }}</a
                                    >
                                    {{ __('and') }}
                                    <a
                                        target="_blank"
                                        :href="route('policy.show')"
                                        >{{ __('Privacy Policy') }}</a
                                    >
                                </label>
                            </div>
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div class="fv-row mb-10">
                            <div
                                class="form-check form-check-custom form-check-solid form-check-inline mb-5"
                            >
                                <jet-checkbox
                                    name="marketing"
                                    id="marketing"
                                    v-model:checked="form.marketing"
                                />
                                <label
                                    class="custom-control-label ms-2"
                                    for="marketing"
                                >
                                    {{
                                        __(
                                            'I would like to be informed about news and offers'
                                        )
                                    }}
                                </label>
                            </div>
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div class="text-center pb-lg-0 pb-8">
                            <button
                                type="submit"
                                id="kt_free_trial_submit"
                                class="btn btn-lg btn-primary fw-bolder"
                            >
                                <span class="indicator-label">{{
                                    __('Create an Account')
                                }}</span>
                                <span
                                    v-show="form.processing"
                                    class="indicator-progress"
                                    >{{ __('Please wait...') }}
                                    <span
                                        class="spinner-border spinner-border-sm align-middle ms-2"
                                    ></span
                                ></span>
                            </button>
                        </div>
                        <!--end::Row-->
                        <!--begin::Row-->
                        <div class="text-center mt-4 pb-lg-0 pb-8">
                            <Link
                                :href="route('login')"
                                class="text-muted me-3 text-decoration-none"
                            >
                                {{ __('Already registered?') }}
                            </Link>
                        </div>
                    </form>
                    <!--end::Form-->
                </div>
                <div
                    v-else
                    class="d-flex flex-center p-15 shadow-sm bg-white rounded w-100 w-md-550px mx-auto ms-xl-20"
                >
                    <!--begin::Form-->
                    <form
                        class="form"
                        novalidate="novalidate"
                        @submit.prevent="submitRedeem"
                    >
                        <!--begin::Heading-->
                        <div class="text-center mb-10">
                            <!--begin::Title-->
                            <h1
                                class="text-dark mb-3"
                                v-html="__('Lifetime access')"
                            ></h1>
                            <!--end::Title-->
                            <!--begin::Link-->
                            <div
                                class="text-gray-400 fw-bold fs-4 mb-10"
                                v-html="
                                    __(
                                        'Redeem your code and get your lifetime access.'
                                    )
                                "
                            ></div>
                            <!--end::Link-->
                            <div
                                class="alert alert-danger text-sm p-2"
                                role="alert"
                                v-if="hasErrors"
                            >
                                <div
                                    class="font-weight-bold"
                                    v-html="__('Whoops! Something went wrong.')"
                                ></div>
                                <ul>
                                    <li
                                        v-for="(error, key) in errors"
                                        :key="key"
                                    >
                                        {{ error }}
                                    </li>
                                </ul>
                            </div>
                            <!--begin::Row-->
                            <div class="fv-row mb-10">
                                <label
                                    class="form-label fw-bolder text-dark fs-6"
                                    >{{ __('Coupon Code') }}</label
                                >
                                <input
                                    v-model="form.couponCode"
                                    class="form-control form-control-solid"
                                    type="text"
                                    placeholder=""
                                    name="coupon-code"
                                    autocomplete="off"
                                />
                            </div>
                            <!--end::Row-->
                            <!--begin::Row-->
                            <div class="text-center pb-lg-0 pb-8">
                                <button
                                    type="submit"
                                    id="redeem_code_submit"
                                    class="btn btn-lg btn-primary fw-bolder"
                                >
                                    <span class="indicator-label">{{
                                        __('Redeem')
                                    }}</span>
                                    <span
                                        v-show="form.processing"
                                        class="indicator-progress"
                                        >{{ __('Please wait...') }}
                                        <span
                                            class="spinner-border spinner-border-sm align-middle ms-2"
                                        ></span
                                    ></span>
                                </button>
                            </div>
                            <!--end::Row-->
                        </div>
                        <!--begin::Heading-->
                    </form>
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Right Content-->
        </div>
        <!--end::Authentication - Signup Free Trial-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/ApplicationLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import { Head, Link } from '@inertiajs/vue3';

export default defineComponent({
    components: {
        Head,
        JetAuthenticationCard,
        JetAuthenticationCardLogo,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        JetValidationErrors,
        Link,
    },

    data() {
        return {
            form: this.$inertia.form({
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                couponCode: '',
                language: navigator.language,
                terms: false,
                marketing: false,
                optInMarketing: false,
                gaClientId: null,
                gclid: null,
                gaSessionId: null,
                cookieString: null,
                errors: {},
            }),
            checkSteps: 0,
        };
    },

    mounted() {
        Intercom('boot', {
            app_id: INTERCOM_APP_ID,
        });
        this.form.gclid = this.getGclidFromCookie();
    },

    methods: {
        submit() {
            this.form.gaClientId = this.getGaClientId();
            this.form.gaSessionId = this.getGaSessionId();
            this.form.cookieString = this.getCookieString();

            this.form.post(this.route('register'), {
                onFinish: () =>
                    this.form.reset('password', 'password_confirmation'),
            });
        },

        submitRedeem() {
            this.form.gaClientId = this.getGaClientId();
            this.form.gaSessionId = this.getGaSessionId();
            this.form.cookieString = this.getCookieString();

            this.form.post(this.route('register-lifetime'), {});
        },

        getGaClientId() {
            let gaClientId = this.getCookie('_ga');

            if (gaClientId) {
                gaClientId = gaClientId.split('.').slice(-2).join('.');
            }

            return gaClientId;
        },

        getGaSessionId: function () {
            const cookieValue = this.getCookie('_ga_EWRGSZNZEQ');
            if (cookieValue && cookieValue !== 'deleted') {
                const match = cookieValue.match(/GS1\.1\.(\d+)/);
                return match ? match[1] : null;
            }

            return '';
        },

        getGclidFromCookie() {
            return this.getCookie('gclid');
        },

        getCookie(c_name) {
            if (document.cookie.length > 0) {
                let c_start = document.cookie.indexOf(c_name + '=');
                if (c_start != -1) {
                    c_start = c_start + c_name.length + 1;
                    let c_end = document.cookie.indexOf(';', c_start);
                    if (c_end == -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return null;
        },

        getCookieString() {
            return document.cookie;
        },

        checkLowercase() {
            return /[a-z]/.test(this.form.password) ? true : false; // 20 score
        },

        checkUppercase() {
            return /[A-Z]/.test(this.form.password) ? true : false; // 20 score
        },

        minimumEightSigns() {
            return this.form.password.length > 7 ? true : false;
        },

        minimumOneSpecialCharacter() {
            return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
                this.form.password
            )
                ? true
                : false;
        },

        passwordMatch() {
            return this.form.password === this.form.password_confirmation;
        },

        computeScore() {
            let count = 0;

            if (this.checkLowercase) {
                count++;
            }
            if (this.checkUppercase) {
                count++;
            }
            if (this.minimumEightSigns()) {
                count++;
            }
            if (this.minimumOneSpecialCharacter()) {
                count++;
            }

            this.checkSteps = count;
        },
    },
    computed: {
        errors() {
            return this.$page.props.lifetimeErrors;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    },
});
</script>
