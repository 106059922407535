<template>
    <jet-action-section>
        <template #title>
            {{ __('Delete Account') }}
        </template>

        <template #description>
            {{ __('Permanently delete your account.') }}
        </template>

        <template #content>
            <div v-if="hasActiveSubscription">
                {{
                    __(
                        'Please note that if you have an active subscription, you will need to cancel it before deleting your account.'
                    )
                }}
            </div>
            <div v-else>
                {{
                    __(
                        'Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.'
                    )
                }}
            </div>

            <div class="mt-3">
                <jet-danger-button
                    @click="confirmUserDeletion"
                    :class="{ disabled: hasActiveSubscription }"
                >
                    {{ __('Delete Account') }}
                </jet-danger-button>
            </div>

            <!-- Delete Account Confirmation Modal -->
            <jet-dialog-modal id="confirmingUserDeletionModal">
                <template #title>
                    {{ __('Delete Account') }}
                </template>

                <template #content>
                    {{ __('Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted.') }}
                </template>

                <template #footer>
                    <jet-secondary-button
                        data-dismiss="modal"
                        @click="closeModal"
                    >
                        {{ __('Cancel') }}
                    </jet-secondary-button>

                    <jet-danger-button
                        @click="deleteUser"
                        :class="{ 'text-white-50': form.processing }"
                        :disabled="form.processing"
                    >
                        <div
                            v-show="form.processing"
                            class="spinner-border spinner-border-sm"
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>

                        {{ __('Delete Account') }}
                    </jet-danger-button>
                </template>
            </jet-dialog-modal>
        </template>
    </jet-action-section>
</template>

<script>
import { defineComponent } from 'vue';
import JetActionSection from '@/Jetstream/ActionSection.vue';
import JetDialogModal from '@/Jetstream/DialogModal.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue';
import { Modal } from 'bootstrap';

export default defineComponent({
    components: {
        JetActionSection,
        JetDangerButton,
        JetDialogModal,
        JetInput,
        JetInputError,
        JetSecondaryButton,
    },

    data() {
        return {
            modal: null,
            form: this.$inertia.form({
                password: '',
            }),
        };
    },

    computed: {
        hasActiveSubscription() {
            let paddleStatus =
                this.$page.props.user?.current_team?.subscriptions[0]
                    ?.paddle_status || ''; // get the paddle status from the user object

            return ['active', 'trialing'].includes(paddleStatus);
        },
    },

    methods: {
        confirmUserDeletion() {

            let el = document.querySelector('#confirmingUserDeletionModal');
            this.modal = new Modal(el);
            this.modal.show();
        },

        deleteUser() {
            this.form.delete(route('delete-account'), {
                preserveScroll: true,
                onSuccess: () => this.closeModal(),
                onFinish: () => this.form.reset(),
            });
        },

        closeModal() {
            this.form.reset();

            this.modal.hide();
        },
    },
});
</script>
