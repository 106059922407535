<template>
    <div
        class="accordion-item rounded-0 border-top-0"
    >
        <SeoCheckAccordionHeader
            :title="accordionItem.title"
            :id="accordionItem.id"
            :index="index"
            :majorCount="countIssues(accordionItem.checks, 'major')"
            :minorCount="countIssues(accordionItem.checks, 'minor')"
        >
        </SeoCheckAccordionHeader>
        <SeoCheckAccordionBody
            :id="accordionItem.id"
            :checks="accordionItem.checks"
        >
        </SeoCheckAccordionBody>
    </div>
</template>

<script>
import SeoCheckAccordionHeader from './SeoCheckAccordionHeader.vue';
import SeoCheckAccordionBody from './SeoCheckAccordionBody.vue';

export default {
    components: {
        SeoCheckAccordionHeader,
        SeoCheckAccordionBody,
    },
    props: {
        accordionItem: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    methods: {
        countIssues(checks, issueType) {
            return checks.filter((check) => check.type === issueType).length;
        },
    },
};
</script>

<style scoped>
.svg-icon {
    display: inline-block;
}
</style>
