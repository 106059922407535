<template>
    <button :type="type" class="btn btn-secondary btn-outline-secondary">
        <slot></slot>
    </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        type: {
            type: String,
            default: 'button',
        },
    },
});
</script>
