<template>
    <div class="history-block mb-5">
        <div class="row">
            <div class="col-md-12">
                <div class="history-head">
                    <h4 class="mb-0">{{ __('Generation history') }}</h4>
                    <p class="mb-0">
                        {{
                            __(
                                'Keep track of vendor and their security ratings.'
                            )
                        }}
                    </p>
                </div>
                <div class="history-body">
                    <div class="tabs-block">
                        <ul class="nav nav-tabs" id="myTab">
                            <li
                                class="nav-item"
                                v-for="(
                                    generationHistory, index
                                ) in generationHistories"
                                :key="generationHistory.type"
                            >
                                <button
                                    class="nav-link"
                                    :class="[{ active: index === activeIndex }]"
                                    :id="generationHistory.type"
                                    data-bs-toggle="tab"
                                    :data-bs-target="`#${generationHistory.type}`"
                                    type="button"
                                    role="tab"
                                    :aria-controls="generationHistory.type"
                                    aria-selected="true"
                                    @click="activeIndex = index"
                                >
                                    {{ generationHistory.tab_name }}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="history-subheading-block">
                        <h6 class="mb-0">
                            {{ __('History') }}
                            <img src="/media/images/arr-down.svg" />
                        </h6>
                    </div>
                    <div class="tab-content" id="myTabContent">
                        <div
                            class="tab-pane fade show"
                            :class="[{ active: index === activeIndex }]"
                            :id="generationHistory.type"
                            role="tabpanel"
                            :aria-labelledby="generationHistory.type"
                            v-for="(
                                generationHistory, index
                            ) in generationHistories"
                            :key="generationHistory.type"
                        >
                            <div class="history-list">
                                <ul class="p-0 m-0">
                                    <li
                                        v-for="history in generationHistory.histories"
                                        :key="history.id"
                                    >
                                        <Link
                                            :href="history.url"
                                            class="d-flex align-items-center"
                                        >
                                            <div class="list-image text-gray-600 me-5">
                                                <div
                                                    class="d-flex flex-center w-30px h-30px rounded-circle bg-opacity-90 mt-10 mb-5"
                                                    :style="{
                                                        backgroundColor:
                                                            generationHistory.background_color || '#eeedfe',
                                                    }"
                                                >
                                                    <font-awesome-icon class="" :icon="generationHistory.icon_string" />
                                                </div>
                                            </div>
                                            <div class="list-text">
                                                <h6 class="mb-0">
                                                    {{ history.heading }}
                                                    <span>
                                                        {{ history.timestamp }}
                                                    </span>
                                                </h6>
                                                <p class="mb-0">
                                                    {{ history.description }}
                                                </p>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { Link } from '@inertiajs/vue3';

export default defineComponent({
    components: {
        // eslint-disable-next-line vue/no-reserved-component-names
        Link,
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return {
            toast,
        };
    },

    data() {
        return {
            loading: false,
            activeIndex: 0,
            creationHistories: [],
            chatHistories: [],
            imageGenerationHistories: [],
        };
    },

    mounted() {
        this.fetchGenerationHistories();
    },

    computed: {
        generationHistories() {
            let vm = this;
            let creationHistories = this.creationHistories?.map((creation) => {
                return {
                    id: creation.id,
                    url: '',
                    description: creation.input || vm.__('No description'),
                    timestamp: creation.created_at,
                };
            });
            let chatHistories = this.chatHistories?.map((chat) => {
                return {
                    id: chat.id,
                    type: 'chat_history',
                    heading: vm.__('Write copy'),
                    // eslint-disable-next-line no-undef
                    url: route('chat-gpt.conversation', chat?.uuid),
                    description: chat.name,
                    timestamp: chat.created_at,
                };
            });
            let imageGenerationHistories = this.imageGenerationHistories?.map(
                (imageGeneration) => {
                    return {
                        id: imageGeneration.id,
                        heading: vm.__('Image generator'),
                        // eslint-disable-next-line no-undef
                        url: route(
                            'image-generator-collections.single',
                            imageGeneration?.uuid
                        ),
                        description: imageGeneration.name,
                        timestamp: imageGeneration.created_at,
                    };
                }
            );

            return [
                {
                    type: 'creation_history',
                    tab_name: this.__('My documents'),
                    icon_string: 'fa-duotone fa-folder',
                    background_color: '#eeedfe',
                    histories: creationHistories,
                },
                {
                    type: 'chat_history',
                    tab_name: this.__('AI chat conversation'),
                    icon_string: 'fa-duotone fa-message-dots',
                    background_color: '#c5effb',
                    histories: chatHistories,
                },
                {
                    type: 'image_generation_history',
                    tab_name: this.__('Image generator'),
                    icon_string: 'fa-duotone fa-image',
                    background_color: '#ffdddb',
                    histories: imageGenerationHistories,
                },
            ];
        },
    },

    methods: {
        fetchGenerationHistories() {
            let vm = this;
            vm.loading = true;
            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .get(route('home.generation-histories'))
                .then((response) => {
                    vm.creationHistories = response.data?.creations || [];
                    vm.chatHistories = response.data?.chats || [];
                    vm.imageGenerationHistories =
                        response.data?.image_generations || [];
                })
                .catch((error) => {
                    const errorMessage =
                        vm.__(error.response?.data?.message) ||
                        vm.__('Some error occurred');

                    vm.toast.error(errorMessage);
                })
                .then(() => {
                    vm.loading = false;
                });
        },
    },
});
</script>
