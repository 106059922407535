<template>
    <div class="chat-action flex-1">
        <!--begin:Toolbar-->
        <div class="d-flex align-items-center relative">
            <div class="d-flex align-items-center me-2"></div>
            <!--begin::Send-->
            <!--begin::Input-->
            <textarea
                class="form-control"
                rows="1"
                data-kt-element="input"
                :placeholder="__('How can I help you?')"
                v-model="question"
                @keypress="keyPressed"
            ></textarea>
            <!--end::Input-->
            <button
                v-if="!isStreaming"
                class="btn btn-primary"
                :class="[isStreaming ? 'disabled' : '']"
                type="button"
                data-kt-element="send"
                @click="submitQuestion"
                :disabled="isSendButtonDisabled"
            >
                {{ __('Send') }}
            </button>
            <button
                v-else
                class="btn btn-danger"
                type="button"
                data-kt-element="stop"
                @click="stopStreaming"
            >
                {{ __('Stop') }}
            </button>
            <!--end::Send-->
        </div>
        <!--end::Toolbar-->
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { useDocumentStore } from '@/Stores/DocumentStore';
import { useDocumentChatStore } from '@/Stores/DocumentChatStore';
import trialLimitExhausted from '@/Mixins/trialLimitExhausted';

export default defineComponent({
    mixins: [trialLimitExhausted],
    setup() {
        // Get document chat store
        const documentStore = useDocumentStore();

        // Get document chat store
        const documentChatStore = useDocumentChatStore();

        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { documentStore, documentChatStore, toast };
    },

    props: {
        user: Object,
        page: Object,
        creaitorDocumentChat: Object,
        previousQuestion: {
            type: String,
            default: null,
        },
        isStreaming: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            question: '',
            query: '',
            responseLoading: false,
            error: false,
            isSendButtonDisabled: true,
            chatEventSource: null,
        };
    },

    mounted() {
        let vm = this;

        // eslint-disable-next-line no-undef
        emitter.on('document-chat-regenerate-response', function () {
            vm.regenerateResponse();
        });
    },

    watch: {
        question(newValue) {
            this.isSendButtonDisabled = newValue.length > 32764;
        },
    },

    methods: {
        keyPressed(event) {
            if (13 === event.keyCode && !event.shiftKey) {
                event.preventDefault();
                this.submitQuestion();
            }
        },

        submitQuestion() {
            this.query = this.question;
            this.question = '';

            if (!this.documentChatStore.getUuid) {
                this.createChatAndSendMessage();
            } else {
                this.fetchAnswer();
            }
        },

        createChatAndSendMessage() {
            let vm = this;
            if (
                !(this.userTeamHasEnoughCredits || this.userHasUnlimitedUsage)
            ) {
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
                return false;
            }
            // Request to create a new chat
            // eslint-disable-next-line no-undef
            axios
                .post('/api/chat-gpt-conversation', {})
                .then((response) => {
                    this.$emit('update-chat-uuid', response.data.data.uuid);
                    vm.$nextTick(() => {
                        let promptForChatName = this.question;
                        this.fetchAnswer();
                        this.generateChatName(promptForChatName);
                    });
                })
                .catch((error) => {
                    console.error('Error creating new chat', error);
                    // Handle error
                });
        },

        generateChatName(prompt) {
            let requestUrl = `/api/chat-gpt-conversation/generate-chat-name`;
            let requestParams = {
                prompt: this.documentChatStore.getCurrentQuestion,
                chat_uuid: this.documentChatStore.getUuid,
            };
            const vm = this;

            // eslint-disable-next-line no-undef
            axios.post(requestUrl, requestParams).catch((error) => {
                console.error('Error generating chat name', error);
                vm.toast.error(error.response.data.message);
                if (
                    error.response.data.error_code ==
                    'trial_credit_limit_exhausted'
                ) {
                    vm.showTrialLimitExhaustedPopUp();
                }
            });
        },

        fetchAnswer() {
            let vm = this;

            if (!this.query) {
                return false;
            }

            this.documentChatStore.setCurrentQuestion(this.query);

            if (
                !(this.userTeamHasEnoughCredits || this.userHasUnlimitedUsage)
            ) {
                this.toast.error(this.__('Your credits are over'));
                this.showTrialLimitExhaustedPopUp();
                return false;
            }

            this.documentChatStore.setLoading(true);
            this.$emit('streaming-started');

            try {
                this.$emit('send-button-triggered', { question: this.query });
                let requestParams = {
                    prompt: this.documentChatStore.getCurrentQuestion,
                    chat_uuid: this.documentChatStore.getUuid,
                };

                this.chatEventSource = new EventSource(
                    // eslint-disable-next-line no-undef
                    route('chat-start', requestParams)
                );

                this.chatEventSource.onmessage = function (event) {
                    const data = JSON.parse(event.data);
                    vm.$emit('on-streaming', data);

                    if (data.isFinalMessage) {
                        vm.chatEventSource.close();
                    }

                    if (data.hasError) {
                        vm.error = true;
                        vm.toast.error(data.error);
                        vm.showTrialLimitExhaustedPopUp();
                        vm.$emit('got-chat-response-error', data.error);
                        vm.$emit('streaming-stopped');
                        vm.chatEventSource.close();
                    }
                };

                this.chatEventSource.onerror = function (error) {
                    vm.$emit('streaming-stopped');
                    console.error('EventSource failed:', error);
                    vm.$emit('got-chat-response-error', error);
                    vm.chatEventSource.close();
                };
            } catch (error) {
                vm.documentChatStore.setLoading(false);
                console.error('Error fetching answer', error);
                vm.error = true;
            } finally {
                if (this.error) {
                    this.query = '';
                }

                this.error = false;
            }
        },

        stopStreaming() {
            this.responseLoading = false;
            this.$emit('streaming-stopped');
            this.chatEventSource.close();
        },

        async regenerateResponse() {
            this.fetchAnswer();
        },
    },
    computed: {
        userTeamHasEnoughCredits() {
            return (
                this.user.current_team.team_plan_credits?.character_credits >
                    0 ||
                this.user.current_team.team_bonus_credits.character_credits > 0
            );
        },

        userHasUnlimitedUsage() {
            let user = this.user;
            let plans = this.getPlans();

            // Check if the user has a normal standard or professional subscription
            let hasNormalSubscription = user.current_team.subscriptions.some(
                (subscription) => {
                    const paddlePlanId = subscription.paddle_plan;
                    const professionalPlans = plans.professional;
                    const standardPlans = plans.standard;

                    return (
                        professionalPlans.includes(paddlePlanId) ||
                        standardPlans.includes(paddlePlanId)
                    );
                }
            );

            // Check if the user has a special lifetime subscription based on plan name or having more than one lifetime subscription
            let hasLifetimeSubscription =
                user.current_team.lifetime_subscriptions.some(
                    (subscription) => {
                        // Check if the plan name is "professional" or "standard"
                        if (
                            subscription.plan &&
                            ['professional', 'standard'].includes(
                                subscription.plan.toLowerCase()
                            )
                        ) {
                            return true;
                        } else if (
                            subscription.plan &&
                            ['basic'].includes(subscription.plan.toLowerCase())
                        ) {
                            // Check if the plan name is "basic"
                            return false;
                        }

                        // Check if the user has more than one lifetime subscription
                        return (
                            user.current_team.lifetime_subscriptions.length > 0
                        );
                    }
                );

            // Return true if the user has a normal subscription or a special lifetime subscription
            return hasNormalSubscription || hasLifetimeSubscription;
        },

        planBasedLtd() {
            return (
                this.user.current_team.lifetime_subscriptions.filter(
                    (subscription) => {
                        return subscription.plan;
                    }
                ).length > 0
            );
        },
    },
});
</script>

<style lang="scss">
.chat-action {
    .btn {
        margin-left: 15px;
    }
}
</style>
