import { defineStore } from 'pinia';
import { useUserStore } from '@/Stores/UserStore';
import { useSubscriptionStore } from '@/Stores/SubscriptionStore';
export const useTeamStore = defineStore('team', {
    state: () => {
        return getDefaultState();
    },
    getters: {
        getTotalCharacterCount: (state) => {
            return state?.totalCharacterCount || 0;
        },
        getTotalImageCount: (state) => {
            return state?.totalImageCount || 0;
        },
        getUser: () => {
            const user = useUserStore();
            return user?.getUser;
        },
        subscriptionStore: () => {
            const subscription = useSubscriptionStore();

            return subscription;
        },
        getCurrentTeam: (state) => {
            return state?.getUser?.current_team;
        },
        getAllTeams: (state) => {
            return state?.getUser?.all_teams;
        },
        getTeamMembers: (state) => {
            return state?.getUser?.team_members;
        },
        getTeamMembersCount: (state) => {
            return state?.getTeamMembers?.length || 0;
        },
        getTeamRole: (state) => {
            return state?.getUser?.team_role;
        },
        //#credits section start
        getPlanCharacterCredits: (state) => {
            return state.getCurrentTeam?.plan_character_credits || 0;
        },
        getPlanImageCredits: (state) => {
            return state.getCurrentTeam?.plan_image_credits || 0;
        },
        getTeamPlanCredits: (state) => {
            return state.getCurrentTeam?.team_plan_credits;
        },
        getTeamBonusCredits: (state) => {
            return state.getCurrentTeam?.team_bonus_credits;
        },

        //## character credits start
        getTeamPlanCharacterCredits: (state) => {
            return state.getTeamPlanCredits?.character_credits || 0;
        },
        getTeamPlanBonusCharacterCredits: (state) => {
            return state.getTeamBonusCredits?.character_credits || 0;
        },
        hasCharacterCredits: (state) => {
            return Boolean(
                state.getTeamPlanCharacterCredits ||
                    state.getTeamPlanBonusCharacterCredits
            );
        },
        getCharacterCreditUsedPercentage: (state) => {
            let totalCredits = state.subscriptionStore.hasRecurringSubscription
                ? state.getPlanCharacterCredits
                : 0;

            if (totalCredits > 0) {
                let usedPercentage =
                    (state.getTotalCharacterCount / totalCredits) * 100;
                let remainingPercentage = 100 - usedPercentage;

                return Math.round(remainingPercentage);
            }

            return 0;
        },
        //##character credits end

        //## image credits start
        getImageCreditUsedPercentage: (state) => {
            let totalCredits = state.subscriptionStore.hasRecurringSubscription
                ? state.getPlanImageCredits
                : 0;

            if (totalCredits > 0) {
                let usedPercentage =
                    (state.getTotalImageCount / totalCredits) * 100;
                let remainingPercentage = 100 - usedPercentage;

                return Math.round(remainingPercentage);
            }

            return 0;
        },
        //## image credits ends

        //#credit section ends
    },
    actions: {
        setTotalCharacterCount(value) {
            this.totalCharacterCount = value;
        },
        setTotalImageCount(value) {
            this.totalImageCount = value;
        },
    },
});

function getDefaultState() {
    return {
        totalCharacterCount: 0,
        totalImageCount: 0,
    };
}
