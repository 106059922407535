<template>
    <div class="d-flex flex-column">
        <lottie-player
            ref="lottiePlayer"
            src="/media/animation/loading/creaitor_loading.json"
            :cssClasses="cssClasses"
        ></lottie-player>
        <div v-if="loadingText" class="text-center">
            <h3 class="text-muted">{{ loadingText }}</h3>
        </div>
    </div>
</template>

<script>
import LottiePlayer from '@/Components/LottiePlayer/LottiePlayer.vue';

export default {
    components: {
        LottiePlayer,
    },

    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        loadingText: {
            type: String,
        },
        cssClasses: {
            type: String,
            default: 'w-200px h-200px',
        },
    },
};
</script>
