<template>
    <div>
        <!-- Generate API Token -->
        <jet-form-section @submitted="createApiToken">
            <template #title>
                {{ __('Create API Token') }}
            </template>

            <template #description>
                {{
                    __(
                        'API tokens allow third-party services to authenticate with our application on your behalf.'
                    )
                }}
            </template>

            <template #form>
                <jet-action-message :on="createApiTokenForm.recentlySuccessful">
                    {{ __('Created') }}.
                </jet-action-message>

                <div class="w-75">
                    <!-- Token Name -->
                    <div class="mb-3">
                        <jet-label for="name" value="Name" />
                        <jet-input
                            id="name"
                            type="text"
                            v-model="createApiTokenForm.name"
                            autofocus
                            :class="{
                                'is-invalid': createApiTokenForm.errors.name,
                            }"
                        />
                        <jet-input-error
                            :message="createApiTokenForm.errors.name"
                        />
                    </div>

                    <!-- Token Permissions -->
                    <!-- <div v-if="availablePermissions.length > 0">-->
                    <!--            <jet-label for="permissions" value="Permissions" />-->

                    <!--            <div class="mt-2 row">-->
                    <!--              <div class="col-6" v-for="permission in availablePermissions" :key="permission">-->
                    <!--                <div class="mb-3">-->
                    <!--                  <div class="form-check">-->
                    <!--                    <jet-checkbox :value="permission" v-model:checked="createApiTokenForm.permissions" :id="`create-${permission}`"/>-->
                    <!--                    <label class="form-check-label" :for="`create-${permission}`">-->
                    <!--                      {{ permission }}-->
                    <!--                    </label>-->
                    <!--                  </div>-->
                    <!--                </div>-->
                    <!--              </div>-->
                    <!--            </div>-->
                    <!--          </div> -->
                </div>
            </template>

            <template #actions>
                <jet-button
                    :class="{ 'text-white-50': createApiTokenForm.processing }"
                >
                    <div
                        v-show="createApiTokenForm.processing"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    >
                        <span class="visually-hidden">{{
                            __('Loading...')
                        }}</span>
                    </div>
                    {{ __('Create') }}
                </jet-button>
            </template>
        </jet-form-section>

        <div v-if="tokens.length > 0">
            <jet-section-border />

            <!-- Manage API Tokens -->
            <div>
                <jet-action-section>
                    <template #title>
                        {{ __('Manage API Tokens') }}
                    </template>

                    <template #description>
                        {{
                            __(
                                'You may delete any of your existing tokens if they are no longer needed.'
                            )
                        }}
                    </template>

                    <!-- API Token List -->
                    <template #content>
                        <div>
                            <div class="d-flex flex-column">
                                <div
                                    class="d-flex align-items-center justify-content-between"
                                >
                                    <div
                                        class="text-left text-gray-500 w-200px"
                                    >
                                        {{ __('Name') }}
                                    </div>
                                    <div class="flex-1 text-left text-gray-500">
                                        {{ __('Token') }}
                                    </div>
                                    <div
                                        class="text-left text-gray-500 w-150px"
                                    >
                                        {{ __('Created') }}
                                    </div>
                                    <div
                                        class="d-flex justify-content-end w-100px"
                                    ></div>
                                </div>
                                <div
                                    class="d-flex align-items-center justify-content-between"
                                    v-for="token in tokens.sort(
                                        (a, b) =>
                                            new Date(b.created_at) -
                                            new Date(a.created_at)
                                    )"
                                    :key="token.id"
                                >
                                    <div class="text-left w-200px">
                                        <span>{{ token.name }}</span>
                                    </div>
                                    <div class="flex-1 text-left">
                                        <span
                                            >&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;{{
                                                token.token?.slice(60)
                                            }}</span
                                        >
                                    </div>
                                    <div class="text-left w-150px">
                                        <span>{{
                                            new Date(
                                                token.created_at
                                            ).toLocaleString()
                                        }}</span>
                                    </div>
                                    <div
                                        class="d-flex justify-content-end w-100px"
                                    >
                                        <a
                                            href="#"
                                            class="btn btn-icon rounded-0 btn-color-gray-400 btn-active-color-primary btn-sm"
                                            @click.prevent="
                                                copyTokenCode(token.token)
                                            "
                                        >
                                            <span class="svg-icon svg-icon-3">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="1em"
                                                    viewBox="0 0 512 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64 128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z"
                                                    />
                                                </svg>
                                            </span>
                                        </a>
                                        <a
                                            href="#"
                                            class="btn btn-icon rounded-0 btn-color-gray-400 btn-active-color-primary btn-active-color-danger btn-sm"
                                        >
                                            <span
                                                class="svg-icon svg-icon-3"
                                                @click.stop.prevent="
                                                    confirmApiTokenDeletion(
                                                        token.id
                                                    )
                                                "
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="1em"
                                                    viewBox="0 0 448 512"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                                                    />
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </jet-action-section>
            </div>
        </div>

        <!-- Token Value Modal -->
        <jet-dialog-modal id="displayingTokenModal">
            <template #title>
                {{ __('API Token') }}
            </template>

            <template #content>
                <div>
                    {{ __('Please copy your new API token.') }}
                </div>

                <div
                    class="bg-light rounded p-3 user-select-all"
                    v-if="$page.props.jetstream.flash.token"
                >
                    {{ $page.props.jetstream.flash.token }}
                </div>
            </template>

            <template #footer>
                <jet-secondary-button
                    @click="displayToken.hide()"
                    data-dismiss="modal"
                >
                    {{ __('Close') }}
                </jet-secondary-button>
            </template>
        </jet-dialog-modal>

        <!-- API Token Permissions Modal -->
        <jet-dialog-modal id="managingPermissionsForModal">
            <template #title>
                {{ __('API Token Permissions') }}
            </template>

            <template #content>
                <div class="mt-2 row">
                    <div
                        class="col-6"
                        v-for="permission in availablePermissions"
                        :key="permission"
                    >
                        <div class="mb-3">
                            <div class="form-check">
                                <jet-checkbox
                                    :value="permission"
                                    v-model:checked="
                                        updateApiTokenForm.permissions
                                    "
                                    :id="`update-${permission}`"
                                />
                                <label
                                    class="form-check-label"
                                    :for="`update-${permission}`"
                                >
                                    {{ permission }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <jet-secondary-button
                    data-dismiss="modal"
                    @click="managingPermissionsFor = null"
                >
                    {{ __('Cancel') }}
                </jet-secondary-button>

                <jet-button
                    class="ms-2"
                    @click="updateApiToken"
                    :class="{ 'text-black-50': updateApiTokenForm.processing }"
                    :disabled="updateApiTokenForm.processing"
                >
                    {{ __('Save') }}
                </jet-button>
            </template>
        </jet-dialog-modal>

        <!-- Delete Token Confirmation Modal -->
        <jet-confirmation-modal id="apiTokenBeingDeletedModal">
            <template #title>
                {{ __('Delete API Token') }}
            </template>

            <template #content>
                {{
                    __('Are you sure you would like to delete this API token?')
                }}
            </template>

            <template #footer>
                <jet-secondary-button
                    @click="deleteModal.hide()"
                    data-dismiss="modal"
                >
                    {{ __('Cancel') }}
                </jet-secondary-button>

                <jet-danger-button
                    class="ms-2"
                    @click="deleteApiToken"
                    :class="{ 'text-white-50': deleteApiTokenForm.processing }"
                    :disabled="deleteApiTokenForm.processing"
                >
                    {{ __('Delete') }}
                </jet-danger-button>
            </template>
        </jet-confirmation-modal>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { Modal } from 'bootstrap';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetActionSection from '@/Jetstream/ActionSection.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import JetDangerButton from '@/Jetstream/DangerButton.vue';
import JetDialogModal from '@/Jetstream/DialogModal.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue';
import JetSectionBorder from '@/Jetstream/SectionBorder.vue';
import { useToast } from 'vue-toastification';
import { useForm } from '@inertiajs/vue3';

export default defineComponent({
    setup() {
        // Get toast interface
        const toast = useToast();

        // Initialize forms using useForm
        const createApiTokenForm = useForm({
            name: '',
            permissions: [],  // This will be set in created/mounted
        });

        const updateApiTokenForm = useForm({
            permissions: [],
        });

        const deleteApiTokenForm = useForm({});

        // Make everything available inside methods
        return {
            toast,
            createApiTokenForm,
            updateApiTokenForm,
            deleteApiTokenForm
        };
    },

    components: {
        JetActionMessage,
        JetActionSection,
        JetButton,
        JetConfirmationModal,
        JetDangerButton,
        JetDialogModal,
        JetFormSection,
        JetInput,
        JetCheckbox,
        JetInputError,
        JetLabel,
        JetSecondaryButton,
        JetSectionBorder,
    },

    props: ['tokens', 'availablePermissions', 'defaultPermissions'],

    data() {
        return {
            managingPermissionsFor: null,
            apiTokenBeingDeleted: null,
            bootstrap: null,
            copiedSuccess: false,
            copiedError: false,
            deleteModal: null,
            displayToken: null,
        };
    },

    created() {
        // Set default permissions after component is created
        this.createApiTokenForm.permissions = this.defaultPermissions;
    },

    methods: {
        createApiToken() {
            this.createApiTokenForm.post(route('api-tokens.store'), {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    this.createApiTokenForm.reset();
                },
            });
        },

        manageApiTokenPermissions(token) {
            this.updateApiTokenForm.permissions = token.abilities;
            this.managingPermissionsFor = token;
            this.bootstrap = document.getElementById(
                'managingPermissionsForModal'
            );
            this.bootstrap.on('show.bs.modal', function () {
                this.bootstrap.focus();
            });
        },

        updateApiToken() {
            this.updateApiTokenForm.put(
                route('api-tokens.update', this.managingPermissionsFor),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => (this.managingPermissionsFor = null),
                }
            );
        },

        confirmApiTokenDeletion(token) {
            this.apiTokenBeingDeleted = token;
            this.deleteModal = new Modal(
                document.getElementById('apiTokenBeingDeletedModal')
            );
            this.deleteModal.show();
        },

        deleteApiToken() {
            this.deleteApiTokenForm.delete(
                route('api-tokens.destroy', this.apiTokenBeingDeleted),
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        (this.apiTokenBeingDeleted = null),
                            this.deleteModal.hide();
                    },
                }
            );
        },

        copyTokenCode(token) {
            try {
                const textarea = document.createElement('textarea');
                const textToCopy = token;
                textarea.value = textToCopy;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);

                this.toast.success(this.__('Token copied to the clipboard'));
            } catch (err) {
                setTimeout(() => {
                    this.toast.error(this.__('Oops, unable to copy'));
                }, 2000);
            }
        },
    },
});
</script>
