<template>
    <draggable
        class="dragArea list-group outline-elements w-100"
        :list="generatedUnselectedHeadings"
        :group="{ name: 'outlineHeadings', pull: 'clone', put: false }"
    >
        <div
            v-for="(heading, index) in generatedUnselectedHeadings"
            :key="index"
            class="list-group-item"
        >
            <div
                class="h2-element draggable-element"
                @click="addToHeadingList(heading)"
            >
                <span class="badge badge-secondary me-2">H2</span>
                <span>{{ heading.name }}</span>
                <span class="arrow-up"><i class="bi bi-arrow-up"></i></span>
            </div>
        </div>
    </draggable>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import { useArticleWizardStore } from '@/Stores/ArticleWizardStore';

export default {
    components: {
        draggable: VueDraggableNext,
    },

    setup() {
        // Get document chat store
        const articleWizardStore = useArticleWizardStore();

        // Make it available inside methods
        return { articleWizardStore };
    },

    methods: {
        addToHeadingList(heading) {
            this.articleWizardStore.addToH2HeadingList(heading);
            this.$emit('rebuild-selected-article-outline');
        },
    },

    computed: {
        generatedHeadings() {
            return this.articleWizardStore.getAiGeneratedH2Ideas.map(
                (heading, index) => {
                    return {
                        name: heading,
                        id: `generated-h2-${Date.now()}-${index}`,
                        subHeadings: [],
                        collapsed: true,
                    };
                }
            );
        },
        generatedUnselectedHeadings() {
            try {
                let currentSelectedHeadings =
                    this.articleWizardStore.getHeadingLists;

                if (0 === currentSelectedHeadings.length) {
                    return this.generatedHeadings;
                }

                const headingsNotInSubHeadingList =
                    this.generatedHeadings.filter((generatedHeading) => {
                        return !currentSelectedHeadings.find(
                            (currentSelectedHeading) =>
                                currentSelectedHeading.id ===
                                generatedHeading.id
                        );
                    });

                return headingsNotInSubHeadingList;
            } catch (error) {
                return this.generatedHeadings;
            }
        },
    },
};
</script>
