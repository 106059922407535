<template>
    <div class="container">
        <div class="row align-items-start mt-0">
            <div class="mb-10">
                <input class="form-control" type="text" v-model="imageMetaData.alt" :placeholder="__('Image title')" />
            </div>
            <div class="mb-10">
                <input class="form-control" type="text" v-model="imageMetaData.class"
                    :placeholder="__('Image css classes seperated by whitespace')" />
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        imageMetaData: Object,
    },

    watch: {
        imageMetaData(newValue) {
            this.$emit('image-meta-changed', newValue);
        },
    },
}
</script>