<template>
    <div class="modal fade" id="kt_modal_document_image_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="kt_modal_document_image_modal" aria-hidden="true">
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-800px">
            <!--begin::Modal content-->
            <div class="modal-content bg-white">
                <!--begin::Modal header-->
                <div class="modal-header border-0 pb-0">
                    <!--begin::Close-->
                    <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                    fill="black" />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
                <!--begin::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body m-5 px-0 mx-0 my-0">
                    <div v-if="!imageUrl">
                        <div class="d-flex justify-content-center p-4 border-bottom">
                            <ul class="btn-group w-100 w-lg-50">

                                <li class="btn btn-outline border btn-color-muted btn-active-primary bg-white"
                                    v-for="(tab, index) in availableTabs" :key="index"
                                    :class="[tab.slug === this.currentTab ? 'active' : '']" @click="onTabChange(tab.slug)">
                                    <span>{{ tab.label }}</span>
                                </li>

                            </ul>
                        </div>
                        <div class="card-body">
                            <div>

                                <div v-for="(tab, index) in availableTabs" :key="index">
                                    <div v-if="tab.slug === this.currentTab">
                                        <component :is="tab.layout" @image-ready="onImageReady($event)"></component>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <ImageMeta :imageMetaData="imageMetaData" @image-meta-changed="imageMetaData = $event"></ImageMeta>
                        <ResizeImage :imageUrl="imageUrl" @remove-image="imageUrl = ''" @image-resized="imageUrl = $event">
                        </ResizeImage>

                        <div class="mb-0 text-end container">
                            <button type="button" class="btn btn-primary" :class="[isLoading ? 'disabled' : '']"
                                @click="insertImage">
                                <span v-if="!isLoading">{{ __('Insert') }}</span>
                                <span v-else>{{ __('Loading..') }}</span>
                            </button>
                        </div>
                    </div>

                </div>
                <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import { Modal } from 'bootstrap';
import UploadImage from '@/Layouts/Image/UploadImage.vue';
import SearchImage from '@/Layouts/Image/SearchImage.vue';
import ResizeImage from '@/Layouts/Image/ResizeImage.vue';
import ImageMeta from '@/Layouts/Image/ImageMeta.vue';

export default {

    components: {
        UploadImage,
        SearchImage,
        ResizeImage,
        ImageMeta,
    },

    setup() {
        // Get toast interface
        const toast = useToast();
        // Make it available inside methods

        return { toast }
    },

    data() {
        return {
            isLoading: false,
            modal: {},
            availableTabs: [
                {
                    'slug': 'upload-image',
                    'label': this.__('Upload image'),
                    'layout': 'UploadImage',
                },
                {
                    'slug': 'search-image',
                    'label': this.__('Search image'),
                    'layout': 'SearchImage',
                },
            ],
            currentTab: '',
            imageUrl: null,
            imageMetaData: {
                alt: '',
                class: '',
                is_editing: false,
                src: '',
            },
        }
    },

    mounted() {
        this.currentTab = this.availableTabs[0].slug;
        let vm = this;
        this.modal = new Modal(document.getElementById('kt_modal_document_image_modal'));

        emitter.on('document-image-modal-opened', function (imageAttrs = {}) {

            if (imageAttrs?.is_editing) {
                vm.imageUrl = vm.imageMetaData.src = imageAttrs?.src;
                vm.imageMetaData.alt = imageAttrs?.alt;
                vm.imageMetaData.class = imageAttrs?.class;
                vm.imageMetaData.is_editing = imageAttrs?.is_editing;
            }

            vm.modal.show();
        })
    },

    beforeUnmount() {
        emitter.off('document-image-modal-opened');
    },

    methods: {
        onTabChange(selectedTab) {
            this.currentTab = this.availableTabs.find(tab => tab.slug === selectedTab)?.slug || this.availableTabs[0].slug;
        },
        onImageReady(imageUrl) {
            this.imageUrl = imageUrl;
        },
        insertImage() {

            if (this.imageMetaData.is_editing && this.imageUrl == this.imageMetaData.src) {
                emitter.emit('document-image-insert-with-meta', this.imageMetaData);
                this.imageUrl = null;
                this.modal.hide();

                return false;
            }

            let vm = this;
            this.isLoading = true;

            let data = {
                //new file url.
                file: this.imageUrl,
                //old file url, which we can delete.
                delete_file_src: this.imageMetaData.src,
            };
            axios.post(`/api/image/upload`, data)
                .then(response => {

                    if (response.data?.image_url) {
                        emitter.emit('document-image-insert-with-meta', { ...vm.imageMetaData, ...{ src: response.data?.image_url } });
                    }

                    vm.modal.hide();
                    vm.imageUrl = null;
                    vm.isLoading = false;
                })
                .catch(error => {
                    emitter.emit('document-image-insert-with-meta', vm.imageMetaData);
                    vm.toast.error(vm.__('Some error occured'));
                    vm.isLoading = false;
                });
        },
    },
}
</script>
<style lang="scss">
#kt_modal_document_image_modal .modal-header {
    justify-content: flex-end;
    position: absolute;
    right: -20px;
    z-index: 9;
    top: -18px;
}

#kt_modal_document_image_modal {
    .modal-body {
        padding: 60px 0px;

        ul {
            padding-left: 0px;
        }

        .uploadedimage-body {
            .close-bg {
                background-color: #000;
                float: right;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            i {
                color: #fff;
                font-size: 30px;
            }

            .resize-field-set {
                padding-left: 40px;
                margin-top: -10px;
            }

            .resize-field {
                width: 50%;
            }

            .resize-form-block {
                margin: 0;
                padding: 0;
            }

            .search-reslt-block {
                row-gap: 15px;
            }
        }
    }

    .card-body {
        .container {
            .row {
                .mb-10 {
                    width: 50%;

                    @media only screen and (min-width: 320px) and (max-width: 767px) {
                        width: 100%;
                    }

                    &.text-end {
                        width: 100%;
                    }
                }

                .input-file-label {
                    width: 50%;
                    margin: 0 auto;
                    margin-top: 50px;
                }
            }

            .search-image-block {
                margin-top: 50px;
            }
        }
    }
}
</style>